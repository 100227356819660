<template>
  <div id="navigation-left">
    <div class="aues-logo">
      <img src="@/assets/images/logo_aues_white.svg" />
    </div>
    <div class="user-name">
      <img :src="app.user.avatar" />
      {{ app.user.name }}
    </div>
    <div class="company">
      <b>{{ app.user.company.name }}</b>
      <p>{{ app.user.company.code_letter }} {{ app.user.company.code_number | fillZero(4) }}</p>
    </div>
    <div class="center">
      <b>{{ app.strings.components.navigation.left.center }}</b>
      <p>{{ app.user.center.code | fillZero(2) }}</p>
    </div>
    <div class="menu">
    </div>
    <div class="facilauto-logo">
      <img src="@/assets/images/logo_facilauto.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation-left-component',
  props: [
    'app'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

#navigation-left {
  position: fixed;
  top:0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: $color-background;
}

.aues-logo {
  padding: 20px 0;
  box-sizing: border-box;
  background-color: $color-complementary-1;
}

.aues-logo > img {
  display: block;
  width: 120px;
  margin: auto;
}

.user-name {
  display: flex;
  align-items:center;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: $color-complementary-2;
  color: #FFF;
}

.user-name > img {
  width: 32px;
  height: 32px;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #FFF;
  margin-right: 15px;
  font-size: 14px;
}

.company {
  margin-top: 5px;
}

.company,
.center {
  align-items:center;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: $color-complementary-3;
  color: #FFF;
  font-size: 14px;
}

.company > p,
.center > p {
  opacity: 0.8;
  margin: 5px 0;
}

.facilauto-logo {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
}

.facilauto-logo > img {
  display: block;
  width: 120px;
  margin: auto;
}
</style>
