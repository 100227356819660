<template>
  <div style="padding: 0;">
    <div class="calendar-view">
      <div class="main-content">
        <div id="calendar-menu" v-if="filters.enabled">
          <div class="h-menu-head">
            <div class="h-menu-title color-primary-2">
              <div class="h-menu-title-tooltip">
                <font-awesome-icon :icon="['far', 'calendar-alt']" /> {{ app.strings.practice.calendar.diary }}
              </div>
            </div>
          </div>

          <div class="calendar-filter">
            <div class="calendar-filter-title teachers">
              {{ app.strings.practice.calendar.teachers }}
            </div>
            <div>
              <multiselect class="color-purple" v-model="teacher.filter"
              :options="teacherDataAll"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :placeholder="app.strings.practice.calendar.practice_create_modal.teacher_search"
              @search-change="(query) => {
                teacher.query = query
              }"
              @select="(option) => {
                filters.teacher = option.code
                loadData()
                studentReadAll(option.code, '', false)
              }"
              :show-no-results="true"
              :loading="teacher.loading"
              :internal-search="false">
                <template slot="option" slot-scope="props">
                  <div class="option__desc" v-if="props.option.code !== 'all'">
                    {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }}
                  </div>
                  <div class="option__desc" v-else>
                    <b>{{ app.strings.common.all }}</b>
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="props">
                  <div class="option__desc" v-if="props.option.code !== 'all'">
                    {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }}
                  </div>
                  <div class="option__desc" v-else>
                    <b>{{ app.strings.common.all }}</b>
                  </div>
                </template>
                <span slot="noResult">{{ app.strings.practice.calendar.practice_create_modal.teacher_search_empty }}</span>
                <span slot="noOptions">{{ app.strings.practice.calendar.practice_create_modal.teacher_search_empty }}</span>
              </multiselect>
            </div>
          </div>

          <div class="calendar-filter">
            <div class="calendar-filter-title students">
              {{ app.strings.practice.calendar.students }}
            </div>
            <div>
              <multiselect class="color-primary-2" v-model="student.filter"
              :options="studentDataAll"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :placeholder="app.strings.practice.calendar.practice_create_modal.student_search"
              @search-change="(query) => {
                if (this.filters.teacher === 'all') {
                  this.studentSearch(query)
                } else {
                  this.student.query = query
                }
              }"
              @select="(option) => {
                filters.student = option.code
                loadData()
              }"
              :show-no-results="true"
              :loading="student.loading"
              :internal-search="false">
                <template slot="option" slot-scope="props">
                  <div class="option__desc" v-if="props.option.code !== 'all'">
                    {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }} - <b>{{ props.option.company.traffic_data.code_letter + ' ' + $options.filters.fillZero(props.option.company.traffic_data.code_number, 4) + '/' + $options.filters.fillZero(props.option.center.code, 2) }}</b>
                  </div>
                  <div class="option__desc" v-else>
                    <b>{{ app.strings.common.all }}</b>
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="props">
                  <div class="option__desc" v-if="props.option.code !== 'all'">
                    {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }} - <b>{{ props.option.company.traffic_data.code_letter + ' ' + $options.filters.fillZero(props.option.company.traffic_data.code_number, 4) + '/' + $options.filters.fillZero(props.option.center.code, 2) }}</b>
                  </div>
                  <div class="option__desc" v-else>
                    <b>{{ app.strings.common.all }}</b>
                  </div>
                </template>
                <span slot="noResult">{{ app.strings.practice.calendar.practice_create_modal.student_search_empty }}</span>
                <span slot="noOptions">{{ app.strings.practice.calendar.practice_create_modal.student_search_empty }}</span>
              </multiselect>
            </div>
          </div>

          <div class="calendar-filter">
            <div class="calendar-filter-title vehicles">
              {{ app.strings.practice.calendar.vehicles }}
            </div>
            <div>
              <multiselect class="color-orange" v-model="vehicle.filter"
              :options="vehicleDataAll"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              :placeholder="app.strings.practice.calendar.practice_create_modal.vehicle_search"
              @search-change="(query) => {
                this.vehicle.query = query
              }"
              @select="(option) => {
                filters.vehicle = option.code
                loadData()
              }"
              :show-no-results="true"
              :loading="vehicle.loading"
              :internal-search="false">
                <template slot="option" slot-scope="props">
                  <div class="option__desc" v-if="props.option.code !== 'all'">
                    {{ props.option.name }} - <b>{{ props.option.code }}</b>
                  </div>
                  <div class="option__desc" v-else>
                    <b>{{ app.strings.common.all }}</b>
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="props">
                  <div class="option__desc" v-if="props.option.code !== 'all'">
                    {{ props.option.name }} - <b>{{ props.option.code }}</b>
                  </div>
                  <div class="option__desc" v-else>
                    <b>{{ app.strings.common.all }}</b>
                  </div>
                </template>
                <span slot="noResult">{{ app.strings.practice.calendar.practice_create_modal.vehicle_search_empty }}</span>
                <span slot="noOptions">{{ app.strings.practice.calendar.practice_create_modal.vehicle_search_empty }}</span>
              </multiselect>
            </div>
          </div>

          <div id="calendar-menu-bottom">
            <div id="calendar-menu-bottom-all" v-if="noFilters">
              <div>
                <div v-html="app.strings.practice.calendar.total_class"></div>
                <div>{{ this.practices.data.length }}</div>
              </div>
              <!--<br>
              <br>
              <div class="legend color-white">
                <div>
                  <div style="background-color: #e8e9ea;"></div>
                  {{ app.strings.practice.calendar.legend.class_empty }}
                </div>
                <div>
                  <div style="background-color: rgb(110, 177, 64);"></div>
                  {{ app.strings.practice.calendar.legend.class_free }}
                </div>
                <div>
                  <div style="background-color: rgb(222, 95, 122);"></div>
                  {{ app.strings.practice.calendar.legend.class_full }}
                </div>
              </div>-->
            </div>
            <div id="calendar-menu-bottom-filters" v-else>
              <div>
                <div v-html="app.strings.practice.calendar.total_class"></div>
                <div>{{ this.practices.data.length }}</div>
              </div>
              <!--<br>
              <br>
              <div class="legend color-white">
                <div>
                  <div style="background-color: rgb(241, 152, 66);"></div>
                  {{ app.strings.practice.calendar.legend.class_active }}
                </div>
                <div>
                  <div style="background-color: rgb(132, 201, 247);"></div>
                  {{ app.strings.practice.calendar.legend.class_reserved }}
                </div>
                <div>
                  <div style="background-color: rgb(222, 95, 122);"></div>
                  {{ app.strings.practice.calendar.legend.class_cancelled }}
                </div>
                <div>
                  <div style="background-color: rgb(110, 177, 64);"></div>
                  {{ app.strings.practice.calendar.legend.class_ended }}
                </div>
                <div>
                  <div style="background-color: rgb(242, 214, 85);"></div>
                  {{ app.strings.practice.calendar.legend.exam }}
                </div>
              </div>-->
            </div>
          </div>

          <div id="calendar-menu-bottom-button">
            <div>
              {{ app.strings.practice.calendar.add }}
            </div>
            <div>
              <div>
                <button class="h-button w-100 default-2" v-on:click="addEvent(moment(), '08:00')" draggable="true" @dragstart="dragStart">
                  {{ app.strings.practice.calendar.add_event }}
                </button>
              </div>
              <div>
                <button class="h-button w-100 red" v-on:click="addPracticeClass(moment(), '08:00')" draggable="true" @dragstart="dragStart">
                  {{ app.strings.practice.calendar.add_class }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="calendar-container">
          <div>
            <div class="row-1" v-show="teacherApp !== true">
              <div id="calendar-selection-filters">
                <div>
                  {{ (noFilters ? app.strings.practice.calendar.no_selection : app.strings.practice.calendar.filtering) }}
                </div>
                <div>
                  <span v-if="filters.student !== 'all'">
                    {{ app.strings.practice.calendar.student }}: <i>{{ student.filter.first_name }} {{ student.filter.last_name_1 }} {{ student.filter.last_name_2 }}</i>
                  </span>
                  <span v-if="filters.student !== 'all' && filters.teacher !== 'all'">
                    ,
                  </span>
                  <span v-if="filters.teacher !== 'all'">
                    {{ app.strings.practice.calendar.teacher }}: <i>{{ teacher.filter.first_name }} {{ teacher.filter.last_name_1 }} {{ teacher.filter.last_name_2 }}</i>
                  </span>
                  <span v-if="(filters.student !== 'all' || filters.teacher !== 'all') && filters.vehicle !== 'all'">
                    ,
                  </span>
                  <span v-if="filters.vehicle !== 'all'">
                    {{ app.strings.practice.calendar.vehicle }}: <i>{{ vehicle.filter.code }}</i>
                  </span>
                </div>
              </div>
              <div id="back-button" v-if="embedded">
                <button class="h-button red" v-on:click="close()">VOLVER</button>
              </div>
            </div>
            <div class="row-2">
              <div id="calendar-selection-buttons" v-show="teacherApp !== true">
                <div v-on:click="changeMode('dayly')" :class="{selected: mode === 'dayly'}">
                  {{ app.strings.practice.calendar.dayly }}
                </div>
                <div v-on:click="changeMode('weekly')" :class="{selected: mode === 'weekly'}">
                  {{ app.strings.practice.calendar.weekly }}
                </div>
                <!--<div class="disabled">
                  {{ app.strings.practice.calendar.monthly }}
                </div>-->
              </div>
              <div id="zoom" v-show="teacherApp !== true">
                <vue-slider v-model="zoom" :contained="true" :min="50" :max="100" :interval="10"></vue-slider>
              </div>
              <div id="calendar-selection-day-current" v-if="mode === 'dayly'" :class="{'teacher-app': teacherApp === true}">
                {{ currentWeek(startDay, week, days)[0].locale('es').format('dddd DD [' + app.strings.common.of + '] MMMM [' + app.strings.common.of + '] YYYY') }}
              </div>
              <div id="calendar-selection-day-current" v-if="mode === 'weekly'" :class="{'teacher-app': teacherApp === true}">
                {{ currentWeek(startDay, week, days)[0].locale('es').format('DD') }} - {{ currentWeek(startDay, week, days)[6].locale('es').format('DD') }}
                {{ currentWeek(startDay, week, days)[0].locale('es').format('[' + app.strings.common.of + '] MMMM [' + app.strings.common.of + '] YYYY') }}
              </div>
              <div id="calendar-selection-day-buttons">
                <div v-on:click="moveCalendar(-1)">
                  <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </div>
                <div v-on:click="loadData()">
                  <font-awesome-icon :icon="['fas', 'redo']" v-if="!practices.loading" />
                  <loader-dot :size="'16px'" :color_1="'rgb(45, 68, 113)'" :color_2="'rgb(69, 159, 125)'" v-else/>
                </div>
                <div v-on:click="moveCalendar(1)">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
              </div>
            </div>
            <div id="calendar-content" :style="'zoom: ' + zoom + '%;'">
              <div>
                <div id="calendar-content-header">
                  <div class="calendar-content-hours">
                    <div class="clock">
                      <font-awesome-icon :icon="['far', 'clock']" />
                    </div>
                  </div>
                  <div class="calendar-content-days-head">
                    <div v-for="(day, i) in currentWeek(startDay, week, days)" :key="i">
                      {{ day.locale('es').format('dddd DD') }}
                    </div>
                  </div>
                </div>
                <div id="calendar-content-content">
                  <div id="calendar-time-marker" :style="'top: ' + timeMarkerPosition + 'px'"></div>
                  <div class="calendar-content-hours">
                    <div v-for="(hour, i) in hours" :key="i" :class="'hour-' + hour + ' ' + (hour.split(':')[1] === '00' ? 'hour-separator' : '')">
                      {{ hour }}
                    </div>
                  </div>
                  <div class="calendar-content-days">
                    <div v-for="(hour, i) in hours" :key="i" :class="{'hour-separator': hour.split(':')[1] === '00'}">
                      <div class="calendar-content-class" :class="{'class-first': practicePosition(day, hour) === 0, 'class-mid': practicePosition(day, hour) === 1, 'class-last': practicePosition(day, hour) === 2}" v-for="(day, j) in currentWeek(startDay, week, days)" :key="i + '-' + j" @dragover.prevent @dragenter="dragEnter" @dragleave="dragLeave" @dragend="dragEnd" @drop="dragFinish">
                        <template v-if="practiceCount(day, hour) > 0">
                          <div class="practice-flag" :class="{completed: practiceCount(day, hour) === vehicles.count}" v-if="noFilters">
                            <div>
                              {{ practiceCount(day, hour) }} {{ app.strings.common.of }} {{ vehicles.count }}
                            </div>
                            <div></div>
                          </div>
                          <div class="practice-flag-detailed" :class="{active: practiceActive(day, hour), reserved: !practiceEnded(day, hour) && !practiceActive(day, hour) && !practiceCancelled(day, hour), cancelled: practiceCancelled(day, hour), ended: practiceEnded(day, hour) && !practiceActive(day, hour) && !practiceCancelled(day, hour)}" v-on:dblclick="editPracticeClass(day, hour)" v-on:click="openPracticeMenu(day, hour)" v-else>
                            <div v-if="practicePosition(day, hour) === 0" :style="'background-color: ' + practiceGet(day, hour).color + ';'">
                              <div v-if="filters.student !== 'all' && filters.teacher !== 'all' && filters.vehicle !== 'all'">
                                <div>
                                  <font-awesome-icon :color="'#FFFFFF'" :icon="['far', 'clock']" />
                                </div>
                                <div>
                                  <b>{{ practiceTimes(day, hour) }}</b>
                                </div>
                              </div>
                              <div v-if="filters.student === 'all'">
                                <div>
                                  <font-awesome-icon :color="'#FFFFFF'" :icon="['fas', 'user']" />
                                  <div class="student-count" v-if="practiceGet(day, hour).count > 1">
                                    ({{ practiceGet(day, hour).count }})
                                  </div>
                                </div>
                                <div>
                                  {{ practiceGet(day, hour).student_name }}
                                </div>
                              </div>
                              <div v-if="filters.teacher === 'all'">
                                <div>
                                  <font-awesome-icon :color="'#FFFFFF'" :icon="['fas', 'chalkboard-teacher']" />
                                </div>
                                <div>
                                  {{ practiceGet(day, hour).teacher_name }}
                                </div>
                              </div>
                              <div v-if="filters.vehicle === 'all'">
                                <div>
                                  <font-awesome-icon :color="'#FFFFFF'" :icon="['fas', 'car']" />
                                </div>
                                <div>
                                  <b>{{ practiceGet(day, hour).vehicle }}</b>
                                </div>
                              </div>
                            </div>
                            <div v-if="practicePosition(day, hour) === 1" :style="'background-color: ' + practiceGet(day, hour).color + ';'"></div>
                            <div v-if="practicePosition(day, hour) === 2" :style="'background-color: ' + practiceGet(day, hour).color + ';'">
                              <div>
                                <div>
                                  <font-awesome-icon :color="'#FFFFFF'" :icon="['far', 'clock']" />
                                </div>
                                <div>
                                  <b>{{ practiceTimes(day, hour) }}</b>
                                </div>
                              </div>
                            </div>
                            <div :style="'background-color: ' + practiceGet(day, hour).color + ';'"></div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="practice-no-flag" v-on:dblclick="addPracticeClass(day, hour)" v-if="eventCount(day, hour) === 0">
                          </div>
                        </template>

                        <template v-if="eventCount(day, hour) > 0 && !noFilters">
                          <div class="practice-flag-detailed" :class="{active: eventActive(day, hour) && !eventEnded(day, hour), ended: eventEnded(day, hour) && !eventActive(day, hour)}" v-on:dblclick="editEvent(day, hour)">
                            <div v-if="eventPosition(day, hour) === 0" :style="'background-color: ' + eventGet(day, hour).color + ';'">
                              <div>
                                <div>
                                  <font-awesome-icon :color="'#FFFFFF'" :icon="['far', 'clock']" />
                                </div>
                                <div>
                                  <b>{{ eventTimes(day, hour) }}</b>
                                </div>
                              </div>
                              <div>
                                <div>

                                </div>
                                <div>
                                  {{ eventGet(day, hour).name }}
                                </div>
                              </div>
                            </div>
                            <div v-if="eventPosition(day, hour) === 1" :style="'background-color: ' + eventGet(day, hour).color + ';'"></div>
                            <div v-if="eventPosition(day, hour) === 2" :style="'background-color: ' + eventGet(day, hour).color + ';'">
                              <div>
                                <div>
                                  <font-awesome-icon :color="'#FFFFFF'" :icon="['far', 'clock']" />
                                </div>
                                <div>
                                  <b>{{ eventTimes(day, hour) }}</b>
                                </div>
                              </div>
                            </div>
                            <div :style="'background-color: ' + eventGet(day, hour).color + ';'"></div>
                          </div>
                        </template>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <event-form-modal ref="event-create-modal" :app="app" :teacherData="teacher.data" @onClose="loadData" />
    <practice-form-modal ref="practice-class-create-modal" :app="app" :practiceData="practice" :studentData="student.data" :teacherData="teacher.data" :vehicleData="vehicle.data" @onClose="loadData" />
  </div>
</template>

<script>
export default {
  name: 'practice-calendar-view',
  components: {
  },
  data () {
    return {
      embedded: false,
      zoom: 100,
      mode: 'weekly',
      startDay: 1,
      week: 0,
      days: 7,
      timeMarkerPosition: 0,
      practice: {
        id: 0,
        day: 0,
        hour: 0,
        student: '',
        teahcer: '',
        vehicle: '',
        last: {
          id: 0,
          student: '',
          teacher: '',
          vehicle: '',
          date: new Date(),
          hour: 0,
          minute: 0,
          time: 0,
          price: 0,
          color: 0
        }
      },
      student: {
        loading: false,
        query: '',
        timeout: '',
        data: [],
        filter: {
          code: 'all'
        }
      },
      teacher: {
        loading: false,
        query: '',
        data: [],
        filter: {
          code: 'all'
        }
      },
      vehicle: {
        loading: false,
        query: '',
        data: [],
        filter: {
          code: 'all'
        }
      },
      vehicles: {
        count: 0
      },
      practices: {
        loading: false,
        data: []
      },
      events: {
        loading: false,
        data: []
      },
      filters: {
        enabled: true,
        student: 'all',
        teacher: 'all',
        vehicle: 'all'
      }
    }
  },
  computed: {
    hours () {
      let hours = []

      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j+=15) {
          let hour = (i < 10 ? '0' : '') + i
          let minute = (j < 10 ? '0' : '') + j
          hours.push(hour + ':' + minute)
        }
      }

      return hours
    },
    noFilters () {
      return this.filters.student === 'all' && this.filters.teacher === 'all' && this.filters.vehicle === 'all'
    },
    studentDataAll () {
      let students = [{
        'code': 'all'
      }]

      return students.concat(this.studentDataForm)
    },
    studentDataForm () {
      let students = this.student.data

      if (this.student.query.trim().length > 0) {
        students = students.filter((student) => {
          if (student.code === 'all') {
            return false
          }
          let name = student.first_name.toLowerCase() + ' ' + student.last_name_1.toLowerCase() + ' ' + student.last_name_2.toLowerCase()
          return name.indexOf(this.student.query.toLowerCase()) != -1 || student.code.toLowerCase().indexOf(this.student.query.toLowerCase()) != -1
        })
      }

      return students
    },
    teacherDataAll () {
      let teachers = [{
        'code': 'all'
      }]

      return teachers.concat(this.teacherDataForm)
    },
    teacherDataForm () {
      let teachers = this.teacher.data

      if (this.student.filter.teachers !== undefined && this.student.filter.teachers !== null) {
        teachers = teachers.filter((teacher) => this.student.filter.teachers.includes(teacher.code))
      }

      if (this.teacher.query.trim().length > 0) {
        teachers = teachers.filter((teacher) => {
          if (teacher.code === 'all') {
            return false
          }
          let name = teacher.first_name.toLowerCase() + ' ' + teacher.last_name_1.toLowerCase() + ' ' + teacher.last_name_2.toLowerCase()
          return name.indexOf(this.teacher.query.toLowerCase()) != -1 || teacher.code.toLowerCase().indexOf(this.teacher.query.toLowerCase()) != -1
        })
      }

      return teachers
    },
    vehicleDataAll() {
      let vehicles = [{
        'code': 'all'
      }]

      return vehicles.concat(this.vehicleDataForm)
    },
    vehicleDataForm () {
      let vehicles = this.vehicle.data

      if (this.vehicle.query.trim().length > 0) {
        vehicles = vehicles.filter((vehicle) => {
          if (vehicle.code === 'all') {
            return false
          }
          return vehicle.name.toLowerCase().indexOf(this.vehicle.query.toLowerCase()) != -1 || vehicle.code.toLowerCase().indexOf(this.vehicle.query.toLowerCase()) != -1
        })
      }

      return vehicles
    }
  },
  props: [
    'app',
    'filtersEnabled',
    'teacherApp',
    'studentCode',
    'teacherCode',
    'vehicleCode'
  ],
  mounted () {
    this.embedded = this.$route.query.embedded
    this.filters.enabled = (this.$route.query.filters !== 'false')
    if (this.filtersEnabled !== undefined) {
      this.filters.enabled = this.filtersEnabled
    }

    setTimeout(() => {
      const el = this.$el.getElementsByClassName('hour-07:00')[0]

      if (el) {
        el.scrollIntoView()
      }
    }, 500)

    this.updateTimeMarkerPosition()
    setInterval(() => {
      this.updateTimeMarkerPosition()
    }, 60000)

    let student = this.$route.query.student
    if (this.studentCode !== undefined) {
      student = this.studentCode
    }
    if (student !== undefined && student !== null && student !== '') {
      this.filters.student = student
      this.studentSearch(student, true)
    }

    let teacher = this.$route.query.teacher
    if (this.teacherCode !== undefined) {
      teacher = this.teacherCode
    }
    if (teacher !== undefined && teacher !== null && teacher !== '') {
      this.filters.teacher = teacher
      this.teacherReadAll(teacher, true)
      if (this.filters.student === 'all') {
        this.studentReadAll(teacher, '', false)
      } else {
        this.studentReadAll(teacher, student, true)
      }
    } else {
      this.teacherReadAll(teacher, false)
    }

    let vehicle = this.$route.query.vehicle
    if (this.vehicleCode !== undefined) {
      vehicle = this.vehicleCode
    }
    if (vehicle !== undefined && vehicle !== null && vehicle !== '') {
      this.filters.vehicle = vehicle
      this.vehicleReadAll(vehicle, true)
    } else {
      this.vehicleReadAll(vehicle, false)
    }
    this.loadData()
  },
  updated () {
  },
  methods: {
    close () {
      if (window.require !== undefined) {
        const { ipcRenderer } = window.require('electron')
        ipcRenderer.send('exit')
      }
    },
    changeMode (mode) {
      this.mode = mode
      if (mode === 'dayly') {
        this.days = 1
        this.startDay = 1
      } else if (mode === 'weekly') {
        this.days = 7
        this.startDay = 1
      }
      this.loadData()
      this.$emit('onChangeMode', mode)
    },
    moveCalendar (inc) {
      if (this.mode === 'dayly') {
        this.startDay += inc
      } else if (this.mode === 'weekly') {
        this.week += inc
      }
      this.loadData()
    },
    updateTimeMarkerPosition () {
      let date = new Date()
      let seconds = (date.getMinutes() + (date.getHours() * 60)) * 60
      let positionPercentage = parseFloat(parseFloat(seconds * 100.0)/86400.0)
      this.timeMarkerPosition = parseFloat(parseFloat(4800.0 * positionPercentage)/100.0)
    },
    loadData () {
      this.practiceReadAll()
      this.eventReadAll()
    },
    practiceReadAll () {
      if (this.practices.loading) {
        return
      }

      this.practices.loading = true

      let startDate = this.currentWeek(this.startDay, this.week, this.days)[0].format('YYYY-MM-DD')
      let endDate = this.currentWeek(this.startDay, this.week, this.days)[0].format('YYYY-MM-DD')
      if (this.mode === 'weekly') {
        endDate = this.currentWeek(this.startDay, this.week, this.days)[6].format('YYYY-MM-DD')
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.filter,
        {
          start_date: startDate,
          end_date: endDate,
          student: this.filters.student,
          teacher: this.filters.teacher,
          vehicle: this.filters.vehicle
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practices.data = response.body.data.practices
            this.practice.last = response.body.data.practice
            this.vehicles.count = response.body.data.vehicles.count
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-filter', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.practices.loading = false
      }, () => {
        this.practices.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    eventReadAll () {
      if (this.events.loading) {
        return
      }

      this.events.loading = true

      let startDate = this.currentWeek(this.startDay, this.week, this.days)[0].format('YYYY-MM-DD')
      let endDate = this.currentWeek(this.startDay, this.week, this.days)[0].format('YYYY-MM-DD')
      if (this.mode === 'weekly') {
        endDate = this.currentWeek(this.startDay, this.week, this.days)[6].format('YYYY-MM-DD')
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.event.filter,
        {
          start_date: startDate,
          end_date: endDate,
          student: this.filters.student,
          teacher: this.filters.teacher,
          vehicle: this.filters.vehicle
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.events.data = response.body.data.events
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'event-filter', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.events.loading = false
      }, () => {
        this.events.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    addEvent (day, hour) {
      this.$refs['event-create-modal'].open(0, this.filters.teacher, day, hour)
    },
    editEvent (day, hour) {
      let id = this.eventGet(day, hour).id
      this.$refs['event-create-modal'].open(id, this.filters.teacher, day, hour)
    },
    addPracticeClass (day, hour) {
      this.practice.id = 0
      this.practice.day = day
      this.practice.hour = hour
      this.practice.student = this.filters.student
      this.practice.teacher = this.filters.teacher
      this.practice.vehicle = this.filters.vehicle
      this.$refs['practice-class-create-modal'].open()
    },
    editPracticeClass (day, hour) {
      this.practice.id = this.practiceGet(day, hour).id
      this.practice.ids = this.practiceGet(day, hour).ids
      this.$refs['practice-class-create-modal'].open()
    },
    studentSearch (query, autoselectFilter, callback) {
      if (this.student.loading) {
        return
      }

      this.student.query = query

      if (query === '') {
        return
      }

      if (this.student.timeout !== '') {
        clearTimeout(this.student.timeout)
      }

      this.student.timeout = setTimeout(() => {
        this.student.loading = true

        if (this.student.timeout !== '') {
          clearTimeout(this.student.timeout)
        }

        this.$http.post(
          this.app.url.api + this.app.endpoints.student.search,
          {
            query: query
          },
          {
            emulateJSON: true,
            headers: {
              Authorization: 'Token ' + this.app.token
            }
          }
        ).then(response => {
          if (response.status === 200) {
            if (response.body.success === true) {
              this.student.data = response.body.data.students
              if (autoselectFilter === true) {
                this.student.filter = this.student.data[0]
              }
              if (callback !== undefined) {
                callback(true)
              }
            } else {
              if (response.body.error.code === 'S100') {
                this.app.methods.logout()
              } else {
                let error = response.body.error.code
                this.showError(this.app.strings, 'student-search', error)
              }
              if (callback !== undefined) {
                callback(false)
              }
            }
          } else {
            this.showError(this.app.strings, 'request', 'status-' + response.status)
            if (callback !== undefined) {
              callback(false)
            }
          }
          this.student.loading = false
        }, () => {
          this.student.loading = false
          this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
          if (callback !== undefined) {
            callback(false)
          }
        })
      }, 1000)
    },
    studentReadAll (teacher, student, autoselectFilter) {
      if (this.student.loading) {
        return
      }

      this.student.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.student.read.all + '?teacher=' + teacher,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.student.data = response.body.data.students
            if (autoselectFilter === true) {
              for (let i in this.student.data) {
                if (this.student.data[i].code === student) {
                  this.student.filter = this.student.data[i]
                }
              }
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.student.loading = false
      }, () => {
        this.student.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    teacherReadAll (teacher, autoselectFilter) {
      if (this.teacher.loading) {
        return
      }

      this.teacher.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.teacher.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.teacher.data = response.body.data.teachers
            if (autoselectFilter === true) {
              for (let i in this.teacher.data) {
                if (this.teacher.data[i].code === teacher) {
                  this.teacher.filter = this.teacher.data[i]
                }
              }
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'teacher-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.teacher.loading = false
      }, () => {
        this.teacher.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    vehicleReadAll (vehicle, autoselectFilter) {
      if (this.vehicle.loading) {
        return
      }

      this.vehicle.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.vehicle.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.vehicle.data = response.body.data.vehicles
            if (autoselectFilter === true) {
              for (let i in this.vehicle.data) {
                if (this.vehicle.data[i].code === vehicle) {
                  this.vehicle.filter = this.vehicle.data[i]
                }
              }
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'vehicle-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.vehicle.loading = false
      }, () => {
        this.vehicle.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    eventCount (day, hour) {
      let count = 0
      for (let i in this.events.data) {
        let event = this.events.data[i]

        let date = new Date(event.date)
        date = this.dateFormat(date, 'YYYY-MM-DD')

        let hours = []
        let time = event.time/15

        let h = parseInt(event.hour)
        let m = parseInt(event.minute)

        hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
        for (let u = 0; u < time; u++) {
          if (m === 45) {
            h += 1
            m = 0
          } else {
            m += 15
          }
          hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
        }

        hours.pop()

        if (date === day.format('YYYY-MM-DD') && hours.includes(hour)) {
          count++
        }
      }

      return count
    },
    eventPosition (day, hour) {
      let event = this.eventGet(day, hour)
      if (this.noFilters || event === null) {
        return -1
      }

      let hours = []
      let time = event.time/15

      let h = parseInt(event.hour)
      let m = parseInt(event.minute)

      hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
      for (let u = 0; u < time; u++) {
        if (m === 45) {
          h += 1
          m = 0
        } else {
          m += 15
        }
        hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
      }

      hours.pop()

      if (hour === hours[0]) {
        return 0
      } else if (hour === hours[hours.length - 1]) {
        return 2
      } else {
        return 1
      }
    },
    eventActive (day, hour) {
      let date = new Date()
      date = this.dateFormat(date, 'YYYY-MM-DD HH:mm')

      let minutes = parseInt(hour.split(':')[1]) < parseInt(date.split(' ')[1].split(':')[1])  && (parseInt(hour.split(':')[1]) + 15) > parseInt(date.split(' ')[1].split(':')[1])

      if (date.split(' ')[0] === day.format('YYYY-MM-DD') && parseInt(date.split(' ')[1].split(':')[0]) === parseInt(hour.split(':')[0]) && minutes) {
        return true
      }

      return false
    },
    eventEnded (day, hour) {
      let date = new Date()
      date = this.dateFormat(date, 'YYYY-MM-DD HH:mm')

      let minutes = parseInt(hour.split(':')[1]) < parseInt(date.split(' ')[1].split(':')[1])  && (parseInt(hour.split(':')[1]) + 15) > parseInt(date.split(' ')[1].split(':')[1])

      if (date.split(' ')[0] > day.format('YYYY-MM-DD') || (date.split(' ')[0] === day.format('YYYY-MM-DD') && ((parseInt(date.split(' ')[1].split(':')[0]) > parseInt(hour.split(':')[0])) || parseInt(date.split(' ')[1].split(':')[0]) === parseInt(hour.split(':')[0]) && minutes))) {
        return true
      }

      return false
    },
    eventGet (day, hour) {
      for (let i in this.events.data) {
        let event = this.events.data[i]

        let date = new Date(event.date)
        date = this.dateFormat(date, 'YYYY-MM-DD')

        let hours = []
        let time = event.time/15

        let h = parseInt(event.hour)
        let m = parseInt(event.minute)

        hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
        for (let u = 0; u < time; u++) {
          if (m === 45) {
            h += 1
            m = 0
          } else {
            m += 15
          }
          hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
        }

        hours.pop()

        if (date === day.format('YYYY-MM-DD') && hours.includes(hour)) {
          return event
        }
      }

      return null
    },
    eventTimes (day, hour) {
      let event = this.eventGet(day, hour)

      let minutes = (event.hour * 60) + (event.minute) + event.time

      let hours = (minutes / 60)
      let hourEnd = Math.floor(hours)
      minutes = (hours - hourEnd) * 60
      let minuteEnd = Math.round(minutes)

      return (event.hour < 10 ? '0' : '') + event.hour + ':' + (event.minute < 10 ? '0' : '') + event.minute + ' → ' + (hourEnd < 10 ? '0' : '') + hourEnd + ':' + (minuteEnd < 10 ? '0' : '') + minuteEnd
    },
    practiceCount (day, hour) {
      let count = 0
      for (let i in this.practices.data) {
        let practice = this.practices.data[i]

        let date = new Date(practice.date)
        date = this.dateFormat(date, 'YYYY-MM-DD')

        let hours = []
        let time = practice.time/15

        let h = parseInt(practice.hour)
        let m = parseInt(practice.minute)

        hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
        for (let u = 0; u < time; u++) {
          if (m === 45) {
            h += 1
            m = 0
          } else {
            m += 15
          }
          hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
        }

        hours.pop()

        if (date === day.format('YYYY-MM-DD') && hours.includes(hour)) {
          count++
        }
      }

      return count
    },
    practicePosition (day, hour) {
      let practice = this.practiceGet(day, hour)
      if (this.noFilters || practice === null) {
        return -1
      }

      let hours = []
      let time = practice.time/15

      let h = parseInt(practice.hour)
      let m = parseInt(practice.minute)

      hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
      for (let u = 0; u < time; u++) {
        if (m === 45) {
          h += 1
          m = 0
        } else {
          m += 15
        }
        hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
      }

      hours.pop()

      if (hour === hours[0]) {
        return 0
      } else if (hour === hours[hours.length - 1]) {
        return 2
      } else {
        return 1
      }
    },
    practiceActive (day, hour) {
      let date = new Date()
      date = this.dateFormat(date, 'YYYY-MM-DD HH:mm')

      let minutes = parseInt(hour.split(':')[1]) < parseInt(date.split(' ')[1].split(':')[1])  && (parseInt(hour.split(':')[1]) + 15) > parseInt(date.split(' ')[1].split(':')[1])

      if (date.split(' ')[0] === day.format('YYYY-MM-DD') && parseInt(date.split(' ')[1].split(':')[0]) === parseInt(hour.split(':')[0]) && minutes) {
        return true
      }

      return false
    },
    practiceEnded (day, hour) {
      let date = new Date()
      date = this.dateFormat(date, 'YYYY-MM-DD HH:mm')

      let minutes = parseInt(hour.split(':')[1]) < parseInt(date.split(' ')[1].split(':')[1])  && (parseInt(hour.split(':')[1]) + 15) > parseInt(date.split(' ')[1].split(':')[1])

      if (date.split(' ')[0] > day.format('YYYY-MM-DD') || (date.split(' ')[0] === day.format('YYYY-MM-DD') && ((parseInt(date.split(' ')[1].split(':')[0]) > parseInt(hour.split(':')[0])) || parseInt(date.split(' ')[1].split(':')[0]) === parseInt(hour.split(':')[0]) && minutes))) {
        return true
      }

      return false
    },
    practiceGet (day, hour) {
      let practices = []
      for (let i in this.practices.data) {
        let practice = JSON.parse(JSON.stringify(this.practices.data[i]))

        let date = new Date(practice.date)
        date = this.dateFormat(date, 'YYYY-MM-DD')

        let hours = []
        let time = practice.time/15

        let h = parseInt(practice.hour)
        let m = parseInt(practice.minute)

        hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
        for (let u = 0; u < time; u++) {
          if (m === 45) {
            h += 1
            m = 0
          } else {
            m += 15
          }
          hours.push((h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m)
        }

        hours.pop()

        if (date === day.format('YYYY-MM-DD') && hours.includes(hour)) {
          practices.push(practice)
        }
      }

      if (practices.length === 1) {
        practices[0].count = 1
        return practices[0]
      } else if (practices.length > 1) {
        let names = practices[0].student_name
        let ids = [practices[0].id]
        for (let i = 1; i < practices.length; i++) {
          names += ', ' + practices[i].student_name
          ids.push(practices[i].id)
        }
        practices[0].student_name = names
        practices[0].ids = ids;
        practices[0].count = practices.length
        return practices[0]
      }

      return null
    },
    practiceTimes (day, hour) {
      let practice = this.practiceGet(day, hour)

      let minutes = (practice.hour * 60) + (practice.minute) + practice.time

      let hours = (minutes / 60)
      let hourEnd = Math.floor(hours)
      minutes = (hours - hourEnd) * 60
      let minuteEnd = Math.round(minutes)

      return (practice.hour < 10 ? '0' : '') + practice.hour + ':' + (practice.minute < 10 ? '0' : '') + practice.minute + ' → ' + (hourEnd < 10 ? '0' : '') + hourEnd + ':' + (minuteEnd < 10 ? '0' : '') + minuteEnd
    },
    practiceReserved (day, hour) {
      let practice = this.practiceGet(day, hour)

      if (practice === null) {
        return false
      } else {
        return practice.student === ''
      }
    },
    practiceCancelled (day, hour) {
      let practice = this.practiceGet(day, hour)

      if (practice === null) {
        return false
      } else {
        return practice.cancelled === 1
      }
    },
    openPracticeMenu (day, hour) {
      if (this.teacherApp) {
        this.$router.push({name: 'app.teacher.practice.menu', params: {id: this.practiceGet(day, hour).id}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
      }
    },
    dragStart(i, e) {
      console.log('Drag start')
      console.log(i)
      console.log(e)
    },
    dragEnter(e) {
      console.log('Drag enter')
      console.log(e)
    },
    dragLeave(e) {
      console.log('Drag leave')
      console.log(e)
    },
    dragEnd(e) {
      console.log('Drag end')
      console.log(e)
    },
    dragFinish(i, e) {
      console.log('Drag finish')
      console.log(i)
      console.log(e)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

@media (max-width: 2000px) {
  .calendar-view > .main-content > #calendar-container > div {
    zoom: 80%;
  }
}

@media (max-width: 1600px) {
  .calendar-view > .main-content > #calendar-container > div {
    zoom: 60%;
  }
}

@media (max-width: 1200px) {
  .calendar-view > .main-content > #calendar-container > div {
    zoom: 40%;
  }
}

.calendar-view {
  padding: 0 !important;
}

.calendar-view > .main-content {
  display: flex;
  width: 100%;
  height: 100%;
}

/* Menu */

#calendar-menu {
  display: flex;
  width: 250px;
  min-height: 100vh;
  background-color: $color-background;
  flex-direction: column;
  overflow: auto;
}

.calendar-filter {
  margin-top: 25px;
}

.calendar-filter-title {
  font-size: 22px;
  font-weight: bold;
  padding: 0 25px;
}

.calendar-filter-title.students {
  color: $color-primary-2;
}

.calendar-filter-title.teachers {
  color: $color-purple;
}

.calendar-filter-title.vehicles {
  color: $color-orange;
}

.calendar-filter > div:nth-of-type(2) {
  padding: 12px 25px;
}

#calendar-menu-bottom {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

#calendar-menu-bottom-all,
#calendar-menu-bottom-filters {
  padding: 25px;
}

#calendar-menu-bottom-all > div:nth-of-type(1),
#calendar-menu-bottom-filters > div:nth-of-type(1) {
  font-size: 25px;
  color: $color-info;
  font-weight: bold;
}

#calendar-menu-bottom-all > div:nth-of-type(1) > div:nth-of-type(2),
#calendar-menu-bottom-filters > div:nth-of-type(1) > div:nth-of-type(2) {
  font-size: 60px;
}

#calendar-menu-bottom-button {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  align-items: center;
  justify-content: center;
}

#calendar-menu-bottom-button > div {
  display: flex;
  padding: 6px;
  box-sizing: border-box;
}

#calendar-menu-bottom-button > div:nth-of-type(1) {
  color: #FFF;
  font-size: 20px;
  font-weight: bold;
}

#calendar-menu-bottom-button > div > div {
    padding: 6px;
    box-sizing: border-box;
    flex: 1;
}

#calendar-menu-bottom-button > div > div > button {
  font-size: 16px;
}

/* Container */

#calendar-container {
  flex: 1;
  height: 100vh;
}

#calendar-container > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 25px 50px;
  box-sizing: border-box;
}

#calendar-container > div > div.row-1,
#calendar-container > div > div.row-2 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}

#calendar-content {
  flex: 1;
  border: 1px solid $color-grey-1;
  overflow: auto;
}

#calendar-content > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#calendar-content-header {
  height: 100px;
  display: flex;
}

#calendar-content-content {
  position: relative;
  display: flex;
  flex: 1;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#calendar-content-content::-webkit-scrollbar {
  display: none;
}

#calendar-time-marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $color-danger;
}

.calendar-content-hours {
  width: 100px;
  height: 100%;
}

.calendar-content-hours > div {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-grey-1;
  border-right: 1px solid $color-grey-1;
  color: $text-color-3;
  font-size: 20px;
  font-weight: bold;
  background-color: $color-grey-light;
  box-sizing: border-box;
}

.calendar-content-hours > div.clock {
  height: 100px;
  background-color: $color-info;
  font-size: 25px;
  color: #FFF;
}

.calendar-content-hours > div.hour-separator {
  border-top: 2px solid $color-grey-2;
  box-sizing: border-box;
}

.calendar-content-days-head {
  flex: 1;
  display: flex;
}

.calendar-content-days-head > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  text-align: center;
  background-color: $color-grey-2;
  font-size: 20px;
  font-weight: bold;
  color: #FFF;
  text-transform: capitalize;
  border-right: 1px solid #FFF;
  border-bottom: 1px solid $color-grey-1;
  box-sizing: border-box;
}

.calendar-content-days-head > div:nth-last-of-type(1) {
  border-right: 0;
}

.calendar-content-days {
  flex: 1;
}

.calendar-content-days > div {
  display: flex;
  height: 100px;
}

.calendar-content-days > div.hour-separator {
  border-top: 2px solid $color-grey-2;
  box-sizing: border-box;
}

.calendar-content-days > div > div {
  flex: 1;
  height: 100%;
}

.calendar-content-class {
  cursor: pointer;
  border-bottom: 1px solid rgba($color-grey-1, 0.5);
  border-right: 1px solid rgba($color-grey-1, 0.5);
  box-sizing: border-box;
}

.calendar-content-days > div:nth-last-of-type(1) > .calendar-content-class {
  border-bottom: 0;
}

.calendar-content-days > div > .calendar-content-class:nth-last-of-type(1) {
  border-right: 0;
}

.calendar-content-class.class-first,
.calendar-content-class.class-mid {
  border-bottom: 0;
}

#zoom {
  width: 120px;
  padding: 0 0 0 12px;
  box-sizing: border-box;
}

/* Row 1 */
#calendar-selection-filters {
  display: flex;
  flex: 1;
  -webkit-box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
}

#calendar-selection-filters > div {
 padding: 12px 24px;
 color: $text-color-3;
 font-weight: bold;
}

#calendar-selection-filters > div:nth-of-type(1) {
  background-color: $color-primary-2;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #FFF;
  width: 175px;
  font-size: 20px;
}

#calendar-selection-filters > div:nth-of-type(2) {
  background-color: $color-grey-2;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #FFF;
  flex: 1;
  font-size: 20px;
}

#back-button {
  padding-left: 24px !important;
}

#back-button > button {
  padding: 13px 63px !important;
  border-radius: 10px !important;
}

/* Row 2 */
#calendar-selection-buttons {
  display: flex;
  -webkit-box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
}

#calendar-selection-buttons > div {
  padding: 12px 24px;
  border: 1px solid rgba($color-grey-1, 0.5);
  cursor: pointer;
  color: $text-color-3;
  font-weight: bold;
}

#calendar-selection-buttons > div:nth-of-type(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 0;
}

#calendar-selection-buttons > div:nth-last-of-type(1) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0;
}

#calendar-selection-buttons > div.selected {
  background-color: $color-grey-1;
  color: #FFF;
}

#calendar-selection-buttons > div.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

#calendar-selection-day-current {
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;
  padding: 12px 24px;
  border: 1px solid rgba($color-grey-1, 0.5);
  -webkit-box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
}

#calendar-selection-day-current.teacher-app {
  margin-left: 0px !important;
}

#calendar-selection-day-buttons {
  display: flex;
  -webkit-box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
  box-shadow: 0px 0px 31px -15px rgba(0,0,0,0.49);
}

#calendar-selection-day-buttons > div {
  padding: 12px 24px;
  border: 1px solid rgba($color-grey-1, 0.5);
  cursor: pointer;
  color: $text-color-3;
  font-weight: bold;
}

#calendar-selection-day-buttons > div:nth-of-type(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 1px solid rgba($color-grey-1, 0.5);
}

#calendar-selection-day-buttons > div:nth-last-of-type(1) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0;
}

.practice-no-flag {
  width: 100%;
  height: 100%;
}

.practice-flag,
.practice-flag-detailed {
  display: flex;
  width: 100%;
  height: 100%;
}

.practice-flag > div:nth-of-type(1),
.practice-flag-detailed > div:nth-of-type(1) {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  background-color: rgba($color-success, 0.5);
  color: $color-success;
}

.practice-flag > div:nth-of-type(2),
.practice-flag-detailed > div:nth-of-type(2) {
  width: 15px;
  opacity: 0.8;
  background-color: $color-success;
}

.practice-flag.completed > div:nth-of-type(1) {

}

.practice-flag.completed > div:nth-of-type(2) {

}

.practice-flag-detailed.active > div:nth-of-type(1) {

}

.practice-flag-detailed.active > div:nth-of-type(2) {

}

.practice-flag-detailed.reserved > div:nth-of-type(1) {

}

.practice-flag-detailed.reserved > div:nth-of-type(2) {

}

.practice-flag-detailed.cancelled > div:nth-of-type(1) {

}

.practice-flag-detailed.cancelled > div:nth-of-type(2) {

}

.practice-flag-detailed.ended > div:nth-of-type(1) {

}

.practice-flag-detailed.ended > div:nth-of-type(2) {

}

.practice-flag-detailed > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  padding: 0 12px;
  box-sizing: border-box;
}

.practice-flag-detailed > div:nth-of-type(1) > div {
  display: flex;
}

.practice-flag-detailed > div:nth-of-type(1) > div > div:nth-of-type(1) {
  width: 30px;
  padding: 0 10px 0 0;
  box-sizing: border-box;
  text-align: center;
}

.practice-flag-detailed > div:nth-of-type(1) > div > div:nth-of-type(2) {
  font-size: 20px;
  color: #FFFFFF;
  font-weight: lighter;
}

.student-count {
  color: #FFFFFF;
  text-align: center;
}

</style>
