<template>
  <div class="teacher-practice-menu navigation-top-app">
    <navigation-top-app
      :app="app"
      :back="() => {
        $router.push({name: 'app.practice.calendar', query: {origin: 'app', navigationLeft: 'false', token: app.token}})
      }"
    >
      <template v-slot:center>
        <div id="navigation-center">

        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="teacher-practice-menu-content" v-if="show && !loading">
        <div id="student-data">
          <img src="@/assets/images/avatar.svg" />
          <div v-if="practice.loading">...</div>
          <div v-else>{{ practice.student.first_name }} {{ practice.student.last_name_1 }} {{ practice.student.last_name_2 }}</div>
        </div>
        <div class="form-label" v-if="practice.data.started === 0">
          {{ app.strings.practice.menu.time_remaining }}
        </div>
        <div id="practice-timer" v-if="practice.data.started === 0">
          <template v-if="practice.time.remaining[0] === 0">
            {{ practice.time.remaining[1] | fillZero(1) }} : {{ practice.time.remaining[2] | fillZero(1) }} : {{ practice.time.remaining[3] | fillZero(1) }}
          </template>
          <template v-if="practice.time.remaining[0] === -1">
            ...
          </template>
          <template v-else>
            {{ practice.time.remaining[0] }}
            <span v-if="practice.time.remaining[0] === 1">{{ app.strings.common.day }}</span>
            <span v-else>{{ app.strings.common.days }}</span>
            {{ practice.time.remaining[1] }}
            <span v-if="practice.time.remaining[1] === 1">{{ app.strings.common.hour }}</span>
            <span v-else>{{ app.strings.common.hours }}</span>
          </template>
        </div>
        <div class="form-label" v-if="practice.data.started === 0">
          {{ practice.data.date | normalizeDate('DD/MM/YYYY - HH:mm:ss') }}
        </div>

        <div id="practice-timer" v-if="practice.data.started === 1 && practice.data.ended === 0">
          {{ practice.time.elapsed[1] | fillZero(1) }} : {{ practice.time.elapsed[2] | fillZero(1) }} : {{ practice.time.elapsed[3] | fillZero(1) }}
        </div>
        <div class="form-label" v-if="practice.data.started === 1">
          {{ practice.data.started_at | normalizeDate('DD/MM/YYYY - HH:mm:ss') }}
        </div>

        <div id="practice-timer" v-if="practice.data.ended === 1">
          {{ timeReal | normalizeTime(false) | truncate(5, '') }}
        </div>
        <div class="form-label" v-if="practice.data.ended === 1">
          {{ practice.data.ended_at | normalizeDate('DD/MM/YYYY - HH:mm:ss') }}
        </div>

        <menu-vertical
          :app="app"
          :color="'blue'"
          :bold="false"
          :padding="{
            top: 0,
            sides: 0
          }"
          :options="[
            {
              type: 'button',
              icon: ['fas', 'clipboard-list'],
              label: app.strings.practice.menu.start,
              action: () => {
                openAnimated('start')
              },
              disabled: practice.data.ended === 1
            },
            /*{
              type: 'button',
              icon: ['fas', 'car'],
              label: app.strings.practice.menu.simulator,
              disabled: practice.data.ended === 1
            },*/
            {
              type: 'button',
              icon: ['fas', 'user'],
              label: app.strings.practice.menu.student,
              action: () => {
                openAnimated('student.profile')
              }
            },
            {
              type: 'button',
              icon: ['fas', 'map-marker-alt'],
              label: app.strings.practice.menu.edit,
              disabled: practice.data.started === 1 || practice.data.ended === 1,
              action: () => {
                openAnimated('edit')
              }
            }
          ]"
        />
      </div>
    </transition>
    <transition name="fade">
      <div id="teacher-practice-menu-content" v-if="show && loading">
        <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app-teacher-practice-menu-view',
  data () {
    return {
      show: false,
      practice: {
        loading: false,
        stage: 0,
        time: {
          remaining: [-1,0,0,0],
          elapsed: [-1,0,0,0]
        },
        data: {
          started_at: new Date()
        },
        student: {
        }
      }
    }
  },
  props: [
    'app'
  ],
  computed: {
    loading () {
      if (this.practice.loading) {
        return true
      }

      return false
    },
    canStart () {
      if (this.practice.data.ended !== 0) {
        return false
      }

      if (this.compareDatesWithoutTime(this.practice.data.date, new Date()) === false) {
        return false
      }

      return true
    },
    timeReal () {
      return parseInt(this.diffSeconds(new Date(this.practice.data.started_at), new Date(this.practice.data.ended_at)))
    }
  },
  mounted () {
    this.show = true

    this.practice.data.id = this.$route.params.id
    this.practiceRead()

    setInterval(() => {
      this.practice.time.remaining = this.dateFineDiff(this.practice.data.date, 1)
      this.practice.time.elapsed = this.dateFineDiff(this.practice.data.started_at, -1)
    }, 1000)
  },
  methods: {
    practiceRead () {
      if (this.practice.loading) {
        return
      }

      this.practice.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.read.one + this.practice.data.id,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practice.data = response.body.data.practice

            this.practice.data.date = new Date(this.practice.data.date)
            this.practice.data.date.setHours(parseInt(this.practice.data.hour), parseInt(this.practice.data.minute), 0, 0)

            this.practice.data.started_at = new Date(this.practice.data.started_at)
            this.practice.data.started_at.setTime(this.practice.data.started_at.getTime() + (this.practice.data.started_at.getTimezoneOffset() * 60 * 1000))

            this.practice.student = response.body.data.student
            this.practice.teacher = response.body.data.teacher
            this.practice.vehicle = response.body.data.vehicle
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-read-one', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.practice.loading = false
      }, () => {
        this.practice.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    openAnimated (target) {
      this.show = false
      setTimeout(() => {
        this.open(target)
      }, 500)
    },
    open (target) {
      switch (target) {
        case 'start':
          this.$router.push({name: 'app.teacher.practice.start', params: {id: this.practice.data.id}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          break;
        case 'edit':
          this.$router.push({name: 'app.teacher.practice.create', query: {origin: 'app', navigationLeft: 'false', token: this.app.token, id: this.practice.data.id}})
          break;
        case 'student.profile':
          this.$router.push({name: 'app.student.profile', params: {code: this.practice.data.student}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#teacher-practice-menu-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}

#student-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
}

#student-data > img {
  width: 100px;
}

#student-data > div {
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  padding: 12px;
  box-sizing: border-box;
}

#practice-timer {
  display: block;
  padding: 12px 24px;
  background-color: $color-primary-1;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
}
</style>
