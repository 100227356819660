var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"student-profile navigation-top-app"},[_c('navigation-top-app',{attrs:{"app":_vm.app,"back":() => {
      if (_vm.app.user.role === 'teacher') {
        _vm.$router.push({name: 'app.student.list', query: {origin: 'app', navigationLeft: 'false', token: _vm.app.token}})
      } else if (_vm.app.user.role === 'student') {
        this.app.methods.back()
      }
    }},scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('div',{attrs:{"id":"navigation-center"}},[_vm._v(" "+_vm._s(_vm.app.strings.app.student.profile.title)+" ")])]},proxy:true}])}),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && !_vm.loading)?_c('div',{staticClass:"max-width-800 margin-auto",attrs:{"id":"student-profile-content"}},[_c('div',{staticClass:"student-data"},[_c('img',{attrs:{"src":require("@/assets/images/avatar.svg")}}),_c('div',[_vm._v(" "+_vm._s(_vm.student.data.first_name)+" "+_vm._s(_vm.student.data.last_name_1)+" "+_vm._s(_vm.student.data.last_name_2)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.student.data.code)+" "),(_vm.student.data.phone !== '' && _vm.student.data.email !== '')?[_c('br'),_c('div',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.app.strings.app.student.profile.contact)+" ")]),_vm._v(" "+_vm._s(_vm.student.data.phone)+" "),(_vm.student.data.phone !== '')?_c('br'):_vm._e(),_vm._v(" "+_vm._s(_vm.student.data.email)+" "),(_vm.app.user.role === 'teacher')?_c('p',{staticClass:"contact"},[_c('a',{attrs:{"href":'tel:' + _vm.student.data.phone}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'phone-square-alt']}})],1),_c('a',[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fab', 'whatsapp-square']},on:{"click":function($event){return _vm.openWhatsapp(_vm.student.data.phone)}}})],1),_c('a',{attrs:{"href":'mailto:' + _vm.student.data.email}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'envelope-square']}})],1)]):_vm._e()]:_vm._e()],2),_c('div',[_c('div',{staticClass:"form-label"},[_vm._v(" "+_vm._s(_vm.app.strings.app.student.profile.practice.balance)+" ")]),_vm._v(" "+_vm._s(_vm.student.data.config.practice.balance)+" ")])]),_c('menu-vertical',{attrs:{"app":_vm.app,"color":'blue',"bold":true,"padding":{
          top: 0,
          sides: 0
        },"options":_vm.options}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && _vm.loading)?_c('div',{staticClass:"max-width-800 margin-auto",attrs:{"id":"student-profile-content"}},[_c('loader-dot',{attrs:{"size":'16px',"color_1":'rgb(69, 130, 207)',"color_2":'rgb(69, 130, 207)'}})],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.practice.pending.length > 0),expression:"this.practice.pending.length > 0"}],staticClass:"student-practice-pending"},[_c('div',{staticClass:"student-practice-pending-form title"},[_c('div',{staticClass:"student-practice-pending-title"},[_vm._v(" "+_vm._s(_vm.app.strings.app.student.profile.practice.pending.title)+" ")])]),_c('div',{staticClass:"student-practice-pending-form"},[(this.practice.pending.length > 0)?_c('div',{staticClass:"student-practice-pending-data"},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.app.strings.app.student.profile.practice.pending.date))]),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm._f("normalizeDate")(this.practice.pending[0].date,'DD/MM/YYYY - HH:mm:ss'))+" ")]),_c('div',[_c('b',[_vm._v(_vm._s(_vm.app.strings.app.student.profile.practice.pending.time))]),_c('br'),_c('br'),_vm._v(" "+_vm._s(this.practice.pending[0].time_real)+" m ")])]):_vm._e(),_c('div',{staticClass:"student-practice-pending-signature"},[_c('div',{staticClass:"signature-pad",class:{'error-blink': _vm.signature.error}},[_c('div',[_c('VueSignaturePad',{ref:"signaturePad",attrs:{"height":"150px","options":{
              backgroundColor: 'rgb(230, 230, 230)',
              onBegin: () => {_vm.$refs.signaturePad.resizeCanvas()}
            }}})],1),(_vm.$refs.signaturePad !== undefined)?_c('font-awesome-icon',{staticClass:"icon clear",attrs:{"icon":['fas', 'trash']},on:{"click":_vm.$refs.signaturePad.clearSignature}}):_vm._e()],1),_c('br'),(_vm.signature.loading === false)?_c('button',{staticClass:"h-button w-100 default-2",on:{"click":function($event){return _vm.sign()}}},[_vm._v(" "+_vm._s(_vm.app.strings.app.student.profile.practice.pending.sign)+" ")]):_c('div',[_c('loader-dot',{attrs:{"size":'16px',"color_1":'rgb(69, 130, 207)',"color_2":'rgb(69, 130, 207)'}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }