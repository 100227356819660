<template>
  <div class="teacher-practice-menu navigation-top-app">
    <navigation-top-app
      :app="app"
      :back="back"
    >
      <template v-slot:center>
        <div id="navigation-center">
          {{ app.strings.practice.start.class }}
        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="teacher-practice-start-content" v-if="show && !loading && practice.data.started === 0">
        <div id="student-data">
          <img src="@/assets/images/avatar.svg" />
          <div v-if="practice.loading">...</div>
          <div v-else>{{ practice.student.first_name }} {{ practice.student.last_name_1 }} {{ practice.student.last_name_2 }}</div>
        </div>
        <div id="practice-timer">
          {{ practice.data.date | normalizeDate('DD/MM/YYYY - HH:mm:ss') }}
        </div>
        <br>
        <menu-vertical
          :app="app"
          :color="'grey'"
          :bold="true"
          :padding="{
            top: 0,
            sides: 0
          }"
          :options="[
            {
              type: 'button',
              icon: ['fas', 'car'],
              label: practice.vehicle.name + ' - ' + practice.vehicle.code
            },
            {
              type: 'input',
              icon: ['fas', 'gas-pump'],
              label: practice.data.km_start + ' ' + app.strings.practice.start.km_start,
              input: {
                type: 'number',
                model: practice.data.km_start,
                change: (value) => {
                  practice.data.km_start = parseInt(value)
                }
              }
            },
            {
              type: 'button',
              icon: ['far', 'clock'],
              label: practice.data.time + ' min'
            },
            {
              type: 'select',
              label: practice.evaluation.id === 0 ? app.strings.practice.start.evaluation : practiceEvaluation.key,
              input: {
                model: practiceEvaluation,
                options: practiceEvaluations,
                change: (value) => {
                  updateEvaluation(value.value)
                }
              }
            }
          ]"
        />

        <div class="signature-pad">
          <div class="label">
            {{ app.strings.practice.start.signature }}
          </div>
          <div>
            <VueSignaturePad height="150px" ref="signaturePad" :options="{
              backgroundColor: 'rgb(230, 230, 230)',
            }" />
          </div>
          <font-awesome-icon class="icon clear" :icon="['fas', 'trash']" v-on:click="$refs.signaturePad.clearSignature" v-if="$refs.signaturePad !== undefined" />
        </div>

        <transition name="fade">
          <p v-if="canStart === 0">
            <button class="h-button w-100 default-2" v-on:click="start()">
              {{ app.strings.practice.start.start }}
            </button>
          </p>
        </transition>
        <transition name="fade">
          <p class="alert-message danger" v-if="canStart === 1">
            {{ app.strings.practice.start.error.date_yesterday }}
          </p>
          <p class="alert-message danger" v-if="canStart === -1">
            {{ app.strings.practice.start.error.date_nextday }}
          </p>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <div id="teacher-practice-start-content" v-if="show && !loading && practice.data.started === 1 && ended">
        <div id="student-data">
          <img src="@/assets/images/avatar.svg" />
          <div v-if="practice.loading">...</div>
          <div v-else>{{ practice.student.first_name }} {{ practice.student.last_name_1 }} {{ practice.student.last_name_2 }}</div>
        </div>
        <div id="practice-timer">
          {{ practice.data.date | normalizeDate('DD/MM/YYYY - HH:mm:ss') }}
        </div>
        <br>
        <menu-vertical
          :app="app"
          :color="'grey'"
          :bold="true"
          :padding="{
            top: 0,
            sides: 0
          }"
          :options="[
            {
              type: 'button',
              icon: ['fas', 'car'],
              label: practice.vehicle.name + ' - ' + practice.vehicle.code
            },
            {
              type: 'button',
              icon: ['fas', 'gas-pump'],
              label: practice.data.km_start + ' ' + app.strings.practice.start.km_start
            },
            {
              type: 'input',
              icon: ['fas', 'gas-pump'],
              label: practice.data.km_end + ' ' + app.strings.practice.start.km_end,
              input: {
                type: 'number',
                model: practice.data.km_end,
                change: (value) => {
                  practice.data.km_end = parseInt(value)
                }
              }
            },
            {
              type: 'button',
              icon: ['far', 'clock'],
              label: practice.data.time + ' min'
            },
            {
              type: 'button',
              label: practiceEvaluation.key
            }
          ]"
        />

        <div class="signature-pad">
          <div class="label">
            {{ app.strings.practice.start.signature }}
          </div>
          <div>
            <VueSignaturePad height="150px" ref="signaturePad" :options="{
              backgroundColor: 'rgb(230, 230, 230)',
            }" />
          </div>
          <font-awesome-icon class="icon clear" :icon="['fas', 'trash']" v-on:click="$refs.signaturePad.clearSignature" v-if="$refs.signaturePad !== undefined" />
        </div>

        <transition name="fade">
          <p v-if="practice.data.ended === 0">
            <button class="h-button w-100 danger" v-on:click="endConfirm()">
              {{ app.strings.practice.start.end }}
            </button>
          </p>
        </transition>
        <transition name="fade">
          <p class="alert-message danger" v-if="practice.data.ended === 1">
            {{ app.strings.practice.start.error.ended }}
          </p>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <div id="teacher-practice-start-content" v-if="show && loading">
        <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
      </div>
    </transition>

    <PracticePerformView ref="practice-perform" :app="app" @onEnd="end" />
  </div>
</template>

<script>
import PracticePerformView from './practice-perform.view'

export default {
  name: 'app-teacher-practice-start-view',
  data () {
    return {
      show: false,
      ended: false,
      practice: {
        loading: false,
        data: {
          id: 0,
          date: new Date(),
          started: true,
          evaluation: {
            id: 0,
            business_id: 0
          }
        },
        student: {
          code: '',
          first_name: '',
          last_name_1: '',
          last_name_2: ''
        },
        teacher: {
          code: '',
          first_name: '',
          last_name_1: '',
          last_name_2: ''
        },
        vehicle: {
          code: '',
          name: ''
        },
        evaluation: {
          id: 0,
          business_id: 0
        }
      },
      evaluation: {
        loading: false,
        data: [],
        student: {
          data: []
        }
      }
    }
  },
  computed: {
    loading () {
      if (this.practice.loading) {
        return true
      }

      if (this.evaluation.loading) {
        return true
      }

      return false
    },
    canStart () {
      return this.yesterDay(new Date(), this.practice.data.date) ? -1 : (this.nextDay(new Date(), this.practice.data.date) ? 1 : 0)
    },
    practiceEvaluations () {
      let evaluations = []

      for (let i in this.evaluation.data) {
        let evaluation = this.evaluation.data[i]
        evaluations.push({
          key: evaluation.name,
          value: evaluation.id + '-' + evaluation.business_id
        })
      }

      return evaluations
    },
    practiceEvaluation () {
      let evaluation = this.evaluation.data.find(evaluation => evaluation.id + '-' + evaluation.business_id === this.practice.data.evaluation.id + '-' + this.practice.data.evaluation.business_id)
      if (evaluation !== undefined) {
        return {
          key: evaluation.name,
          value: evaluation.id
        }
      }

      return {
        key: '',
        value: 0
      }
    }
  },
  props: [
    'app'
  ],
  components: {
    PracticePerformView
  },
  mounted () {
    this.show = true

    this.practice.data.id = this.$route.params.id
    this.practiceRead()
  },
  methods: {
    back () {
      if (this.ended === false) {
        this.$router.push({name: 'app.teacher.practice.menu', params: {id: this.practice.data.id}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
      } else if (this.ended === true) {
        this.ended = false
        this.perform()
      }
    },
    updateEvaluation (id) {
      this.practice.evaluation.id = id.split('-')[0]
      this.practice.evaluation.business_id = id.split('-')[1]
      this.practice.data.evaluation.id = id.split('-')[0]
      this.practice.data.evaluation.business_id = id.split('-')[1]
    },
    practiceRead () {
      if (this.practice.loading) {
        return
      }

      this.practice.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.read.one + this.practice.data.id,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practice.data = response.body.data.practice
            this.practice.student = response.body.data.student
            this.practice.teacher = response.body.data.teacher
            this.practice.vehicle = response.body.data.vehicle

            this.practice.data.date = new Date(this.practice.data.date)
            this.practice.data.date.setHours(parseInt(this.practice.data.hour), parseInt(this.practice.data.minute), 0, 0)

            this.practice.data.started_at = new Date(this.practice.data.started_at)
            this.practice.data.started_at.setTime(this.practice.data.started_at.getTime() + (this.practice.data.started_at.getTimezoneOffset() * 60 * 1000))

            this.practiceEvaluationReadAll()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-read-one', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.practice.loading = false
      }, () => {
        this.practice.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    practiceEvaluationReadAll () {
      if (this.evaluation.loading) {
        return
      }

      this.evaluation.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.evaluation.read.all + '?student=' + this.practice.student.code,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.evaluation.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.evaluation.data = response.body.data.evaluations
            this.evaluation.student.data = response.body.data.student

            if (this.practice.data.started === 1) {
              this.perform()
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-evaluation-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.evaluation.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    start () {
      if (this.practice.data.km_start === 0) {
        this.showError(this.app.strings, 'practice-start', this.app.strings.practice.start.empty.km_start)
        return
      }

      if (this.practice.evaluation.id === 0) {
        this.showError(this.app.strings, 'practice-start', this.app.strings.practice.start.empty.evaluation)
        return
      }

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/jpeg')
      if (isEmpty) {
        this.showError(this.app.strings, 'practice-start', this.app.strings.practice.start.empty.signature)
        return
      }
      this.practice.data.signature = data

      if (this.practice.loading) {
        return
      }

      this.practice.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.start,
        {
          id: this.practice.data.id,
          evaluation_id: this.practice.evaluation.id,
          evaluation_business_id: this.practice.evaluation.business_id,
          km_start: this.practice.data.km_start,
          signature: data
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.practice.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.perform()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-start', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.practice.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    perform () {
      if (this.practice.data.started === 0) {
        this.practice.data.started = 1
        this.practice.data.started_at = new Date()
      }
      this.$refs['practice-perform'].open(this.practice, this.evaluation)
    },
    end () {
      this.practice.data.time_real = parseInt(this.diffMinutes(this.practice.data.started_at, new Date()))
      this.practice.data.km_end = this.practice.data.km_start
      this.ended = true
    },
    endConfirm () {
      if (this.practice.data.km_end === 0 || this.practice.data.km_end <= this.practice.data.km_start) {
        this.showError(this.app.strings, 'practice-end', this.app.strings.practice.start.empty.km_end)
        return
      }

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/jpeg')
      if (isEmpty) {
        this.showError(this.app.strings, 'practice-start', this.app.strings.practice.start.empty.signature)
        return
      }
      this.practice.data.signature = data

      if (this.practice.loading) {
        return
      }

      this.practice.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.end,
        {
          id: this.practice.data.id,
          time_real: this.practice.data.time_real,
          km_start: this.practice.data.km_end,
          signature: data
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.practice.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practice.view = false
            this.$router.push({name: 'app.teacher.practice.menu', params: {id: this.practice.data.id}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-start', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.practice.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#teacher-practice-start-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}

#student-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
}

#student-data > img {
  width: 100px;
}

#student-data > div {
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  padding: 12px;
  box-sizing: border-box;
}

#practice-timer {
  display: block;
  padding: 12px 24px;
  background-color: $color-primary-1;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
}

.signature-pad {
  position: relative;
  margin-top: 12px;
  border-radius: 6px;
  overflow: hidden;
}

.signature-pad .label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: $text-color-2;
  font-size: 14px;
  font-weight: bold;
}

.signature-pad .clear {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: $color-danger;
}
</style>
