<template>
  <div class="calendar-small">
    <div class="calendar-title" v-if="title === true">
      {{ app.strings.common.months[calendar.month] }}, {{ calendar.year }}
    </div>
    <div class="calendar-container">
      <div class="calendar-back" v-on:click="monthMove(-1)">
        <font-awesome-icon class="icon" :icon="['fas', 'chevron-left']"/>
      </div>
      <div class="calendar-content">
        <div v-for="(day, i) in currentWeek(calendar.week.start, calendar.week.week, calendar.week.days)" :key="'week-' + i" class="week">
          {{ day.locale('es').format('dddd') | truncate(3, '') }}
        </div>
        <div v-for="(day, i) in startDays()" :key="'day-off-' + i" class="day-off">
          {{ day }}
        </div>
        <div v-for="(day, i) in daysInMonth(calendar.month, calendar.year)" :key="'day-' + i" class="day" :class="{hilight: hilight(i)}">
          <div v-on:click="dayClick(day)">
            {{ day }}
          </div>
          <div class="practice-marked" :class="{visible: marked(i) && !hilight(i), hilight: hilight(i)}"></div>
        </div>
      </div>
      <div class="calendar-next" v-on:click="monthMove(1)">
        <font-awesome-icon class="icon" :icon="['fas', 'chevron-right']"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'calendar-small-component',
  data () {
    return {
      calendar: {
        week: {
          start: 1,
          week: 0,
          days: 7
        },
        day: 0,
        month: 0,
        year: 0,
        _hilighted: {
          day: 0,
          month: 0,
          year: 0
        }
      }
    }
  },
  computed: {

  },
  props: {
    app: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    hilighted: {
      type: Object,
      default: () => {
        return {
          day: 0,
          month: 0,
          year: 0
        }
      }
    },
    marks: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted () {
    this.normalizeHilight()

    let date = new Date();

    this.calendar.day = new Date().getDate()
    if (this._hilighted.day === 0) {
      this.$emit('onDayClick', this.calendar.day)
    }

    this.calendar.month = date.getMonth()
    if (this._hilighted.month === 0) {
      this.$emit('onMonthMove', this.calendar.month)
    }

    this.calendar.year = date.getFullYear()
    if (this._hilighted.year === 0) {
      this.$emit('onYearMove', this.calendar.year)
    }

    this.$emit('onMove')
  },
  methods: {
    normalizeHilight () {
      this._hilighted = this.hilighted

      if (this._hilighted.day === undefined || this._hilighted.day === null) {
        this._hilighted.day = 0
      }

      if (this._hilighted.month === undefined || this._hilighted.month === null) {
        this._hilighted.month = 0
      }

      if (this._hilighted.year === undefined || this._hilighted.year === null) {
        this._hilighted.year = 0
      }
    },
    startDays () {
      let lastMonth = this.calendar.month - 1
      let lastYear = this.calendar.year

      if (lastMonth === -1) {
        lastMonth = 11
        lastYear--
      }

      let startDay = this.daysInMonth(lastMonth, lastYear) - (this.firstDayInMonth(this.calendar.month, this.calendar.year) - 1)
      let days = []

      for (let i = startDay; i <= this.daysInMonth(lastMonth, lastYear); i++) {
        days.push(i)
      }

      return days
    },
    dayClick (day) {
      this.calendar.day = day
      this.$emit('onDayClick', this.calendar.day)
    },
    monthMove (increment) {
      if (increment === 1) {
        this.calendar.month++
        if (this.calendar.month > 11) {
          this.calendar.month = 0
          this.calendar.year++
          this.$emit('onYearMove', this.calendar.year)
        }
        this.$emit('onMonthMove', this.calendar.month)
      } else if (increment === -1) {
        this.calendar.month--
        if (this.calendar.month < 0) {
          this.calendar.month = 11
          this.calendar.year--
          this.$emit('onYearMove', this.calendar.year)
        }
        this.$emit('onMonthMove', this.calendar.month)
      }
      this.$emit('onMove')
    },
    hilight (day) {
      if (this._hilighted === undefined) {
        return false
      }
      return day + 1 === this._hilighted.day && this.calendar.month === this._hilighted.month
    },
    marked (day) {
      if (this.marks === undefined) {
        return false
      }

      for (let i in this.marks) {
        let mark = this.marks[i]

        if (mark === day + 1) {
          return true
        }
      }

      return false
    }
  },
  watch: {
    hilighted () {
      this.normalizeHilight()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/variables-app.scss';

.calendar-small {
  width: 100%;
  background-color: #FFF;
}

.calendar-title {
  background-color: $color-primary-1;
  color: #FFF;
  text-align: center;
  padding: 12px;
  box-sizing: border-box;
}

.calendar-container {
  display: flex;
  width: 100%;
  height: 250px;
}

.calendar-back,
.calendar-next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  box-sizing: border-box;
  color: $text-color-2;
  font-size: 14px;
  background-color: rgba($text-color-2, 0.05);
}

.calendar-content {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-content > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 12px;
}

.calendar-content > div.week {
  color: $color-primary-1;
}

.calendar-content > div.day {
  color: $text-color-2;
}

.calendar-content > div.day.hilight > div {
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: $color-danger;
  color: #FFFFFF;
  border-radius: 50px;
  padding: 10px;
  box-sizing: border-box;
}

.practice-marked {
  width: 5px;
  height: 5px;
  margin-top: 3px;
  border-radius: 5px;
  background-color: $color-primary-1;
  opacity: 0;
}

.practice-marked.visible {
  opacity: 1;
}

.practice-marked.hilight {
  padding: 3px !important;
}

.calendar-content > div.day-off {
  color: $text-color-2;
  opacity: 0.5;
}
</style>
