<template>
  <div class="h-modal" :class="{
    'color-primary-1': color === 'primary-1',
    'color-primary-2': color === 'primary-2'
  }" v-if="r_open">
    <div class="h-modal-container">
      <div class="h-modal-header">
        <div class="h-modal-title">
          <div class="h-modal-title-tooltip">
            <font-awesome-icon :icon="icon" /> {{ title }}
          </div>
        </div>
        <div class="h-modal-close" v-on:click="close()" v-if="closeable">
          <font-awesome-icon :icon="['fas', 'times']" />
        </div>
      </div>
      <div class="h-modal-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-component',
  data () {
    return {
      r_open: false
    }
  },
  methods: {
    open () {
      this.r_open = true
    },
    close () {
      this.$emit('close')
      this.r_open = false
    }
  },
  props: {
    closeable: Boolean,
    title: String,
    color: String,
    icon: Array
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.h-modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-background-dark, 0.5);
  padding: 25px;
  box-sizing: border-box;
  overflow: auto;
}

.h-modal-container {
  display: flex;
  width: 100%;
  max-width: 800px;
  background: $color-background-grey;
  border-radius: 10px;
  padding: 50px 25px;
  box-sizing: border-box;
  flex-direction: column;
  margin: auto;
}

.h-modal-header {
  position: relative;
  display: flex;
  width: 100%;
  height: 80px;
}

.h-modal-title {
  flex: 1;
}

.h-modal-title-tooltip {
  display: flex;
  position: absolute;
  padding: 12px 24px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
}

.h-modal.color-primary-1 .h-modal-title-tooltip {
  background-color: $color-primary-1;
  color: #FFF;
}

.h-modal.color-primary-2 .h-modal-title-tooltip {
  background-color: $color-primary-2;
  color: #FFF;
}

.h-modal-title-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 10px;
  border-width: 20px;
  border-style: double;
}

.h-modal.color-primary-1 .h-modal-title-tooltip:after {
  border-color: $color-primary-1 transparent transparent transparent;
}

.h-modal.color-primary-2 .h-modal-title-tooltip:after {
  border-color: $color-primary-2 transparent transparent transparent;
}

.h-modal-title-tooltip > svg {
  font-size: 22px;
  margin-right: 10px;
}

.h-modal-close {
  font-size: 20px;
  cursor: pointer;
  color: rgba($color-grey-2, 0.8);
}

.h-modal-close:hover {
  color: rgba($color-grey-2, 1);
}
</style>
