<template>
  <div>
    <div class="l-dropzone" :class="{disabled: disabled}" @dragover.prevent @drop="_dropHandler" v-on:click="click" v-show="dropzone">
      <input v-show="false" ref="upload-input" type="file" @change="_manageFiles($event.target.files)" :multiple="data.mode === 'multiple'" :accept="_accept" />
      <div class="l-dropzone__msg" v-if="!$data.res_uploading">
        <h3 class="l-dropzone__msg-title">{{ $data.res_strings.title }}</h3>
        <span class="l-dropzone__msg-desc">{{ $data.res_strings.desc }}</span>
      </div>
      <div class="l-dropzone__msg" v-else>
        <div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
        <p>
          <span class="l-dropzone__msg-desc" v-if="data.mode === 'multiple'">{{ $data.res_strings.uploading }} {{ $data.res_uploadCount }} {{ $data.res_strings.of }} {{ $data.res_queue.length }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'file-upload-component',
  data () {
    return {
      res_strings: {
        title: '',
        desc: ''
      },
      res_queue: [],
      res_uploading: false,
      res_uploadCount: 0
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    dropzone: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    data: {
      type: Object,
      default: () => {
        return {
          strings: {},
          mode: 'single',
          types: [],
          extensions: [],
          url: '',
          auth: '',
          debug: false
        }
      }
    }
  },
  computed: {
    _accept () {
      let accept = []
      if (this.data.types !== undefined) {
        for (let i = 0; i < this.data.types.length; i++) {
          let type = this.data.types[i]
          accept.push(type + '/*')
        }
      }
      if (this.data.extensions !== undefined) {
        if (this.data.extensions.length > 0) {
          accept = []
        }
        for (let i = 0; i < this.data.extensions.length; i++) {
          let extension = this.data.extensions[i]
          accept.push('.' + extension)
        }
      }
      return accept.join(',')
    }
  },
  components: {
  },
  mounted () {
    this.$data.res_strings = this.data.strings[this.data.mode]
  },
  methods: {
    click () {
      if (this.$data.res_uploading || this.disabled) {
        return
      }

      this.$refs['upload-input'].click()
    },
    _dropHandler (e) {
      e.stopPropagation()
      e.preventDefault()

      if (this.$data.res_uploading || this.disabled) {
        return
      }

      var files = e.dataTransfer.files
      this._manageFiles(files)
    },
    _manageFiles (files, owner) {
      let data = this.data

      data.types = (this.data.types !== undefined ? this.data.types : [])
      data.extensions = (this.data.extensions !== undefined ? this.data.extensions : [])

      if (files.length > 0) {
        if (data.mode === 'single') {
          if (this.$data.res_uploading) {
            return
          } else {
            this.$data.res_queue = []
          }

          let file = files[0]
          let type = file.type.split('/')[0]
          let extension = file.name.split('.')
          extension = extension[extension.length - 1]

          if (data.types.indexOf(type) !== -1 || data.types.length === 0) {
            if (data.extensions.indexOf(extension.toLowerCase()) !== -1 || data.extensions.length === 0) {
              this._addFileToQueue(file, owner)
            } else {
              console.error('Invalid extension')
              this.$emit('onError', 'extension', file.name)
            }
          } else {
            console.error('Invalid type')
            this.$emit('onError', 'type', file.name)
          }
        } else {
          for (let i = 0; i < files.length; i++) {
            let file = files[i]
            let type = file.type.split('/')[0]
            let extension = file.name.split('.')
            extension = extension[extension.length - 1]

            if (data.types.indexOf(type) !== -1 || data.types.length === 0) {
              if (data.extensions.indexOf(extension.toLowerCase()) !== -1 || data.extensions.length === 0) {
                this._addFileToQueue(file, owner)
              } else {
                console.error('Invalid extension')
                this.$emit('onError', 'extension', file.name, files, i)
              }
            } else {
              console.error('Invalid type')
              this.$emit('onError', 'type', file.name, files, i)
            }
          }
        }
      }
    },
    _addFileToQueue (file, owner) {
      file.uploaded = false
      file.error = false
      this.$data.res_queue.push(file)
      if (owner !== undefined) {
        this.$set(this.$data.res_queue[this.$data.res_queue.length - 1], 'owner', owner)
      }
    },
    _upload () {
      if (this.$data.res_queue.length === 0) {
        this.$data.res_uploading = false
        return
      }
      if (this.$data.res_uploading) {
        let upload = false
        for (let i = 0; i < this.$data.res_queue.length; i++) {
          let file = this.$data.res_queue[i]
          if (file.uploaded !== true && file.error === false) {
            upload = true
            break
          }
        }
        if (upload === false) {
          this.$data.res_uploadCount = 0
          this.$data.res_uploading = false
          return
        }
      }
      this.$data.res_uploading = true

      for (let i = 0; i < this.$data.res_queue.length; i++) {
        let file = this.$data.res_queue[i]
        if (file.uploaded !== true && file.error === false) {
          this.$data.res_uploadCount++
          this._uploadFile(i)
          break
        }
      }
    },
    _uploadFile (index) {
      let file = this.$data.res_queue[index]

      if (this.data.debug === true) {
        this.$set(this.$data.res_queue[index], 'error', false)
        this.$set(this.$data.res_queue[index], 'uploaded', true)
        this._upload()
        this.$emit('onSuccess', file, {
          name: 'debug',
          extension: 'debug',
          metadata: {}
        })
      } else {
        let formData = new FormData()
        formData.append('file', file, file.name)

        let headers = {
          Authorization: this.data.auth,
        }

        if (file.owner !== undefined) {
          headers['Owner'] = file.owner
        }

        this.$http.post(
          this.data.url,
          formData,
          {
            headers: headers
          }
        ).then(response => {
          if (response.status === 200) {
            if (response.body.success === true) {
              this.$set(this.$data.res_queue[index], 'error', false)
              this.$set(this.$data.res_queue[index], 'uploaded', true)
              this._upload()
              this.$emit('onSuccess', file, response.body.data.file)
              if (this._uploadEnd()) {
                const input = this.$refs['upload-input']
                input.type = 'text'
                input.type = 'file'
                this.$emit('onFinish')
              }
            } else {
              console.error('Error on success')
              this.$set(this.$data.res_queue[index], 'error', true)
              this.$set(this.$data.res_queue[index], 'uploaded', true)
              this._upload()
              this.$emit('onError', 'upload-success', file.name, this.$data.res_queue, index, response.body.error.code + ' - ' + response.body.error.detail)
            }
          } else {
            console.error('Error on response')
            this.$set(this.$data.res_queue[index], 'error', true)
            this.$set(this.$data.res_queue[index], 'uploaded', true)
            this._upload()
            this.$emit('onError', 'upload-' + response.status, file.name, this.$data.res_queue, index, 'Error on server request')
          }
        })
        .catch(err => {
          console.error('Error on connection to server')
          this.$set(this.$data.res_queue[index], 'error', true)
          this.$set(this.$data.res_queue[index], 'uploaded', true)
          this._upload()
          this.$emit('onError', 'upload-server', file.name, this.$data.res_queue, index, err)
        })
      }
    },
    _uploadEnd () {
      let ended = true
      for (let i in this.$data.res_queue) {
        if (this.$data.res_queue[i].uploaded === false) {
          ended = false
          break
        }
      }
      return ended
    }
  },
  watch:{
    '$data.res_queue': {
      handler () {
        this._upload()
      },
      _deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.l-dropzone {
  border: 2px dashed #ebedf2;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.l-dropzone.disabled {
  cursor: not-allowed !important;
}

.l-dropzone__msg {
  text-align: center;
  margin: 2em 0;
}

.l-dropzone__msg-title {
  color: #575962;
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.1rem;
}

.l-dropzone__msg-desc {
  font-size: .85rem;
}
</style>
