<template>
  <div class="speedtest">
    <div>
      <div>
        <radial-progress-bar
          :startColor="'rgb(110, 177, 64)'"
          :animateSpeed="100"
          :diameter="180"
          :completed-steps="percent"
          :total-steps="100">
          <p>
            {{ speed }} kbps
          </p>
          <p>
            <button class="h-button success w-100" v-on:click="start()" v-if="!testing">Start</button>
            <button class="h-button success w-100 disabled" v-else>Start</button>
          </p>
        </radial-progress-bar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'speedtest-view',
  components: {
  },
  data () {
    return {
      testing: false,
      percent: 0,
      speed: 0
    }
  },
  props: [
    'app'
  ],
  mounted () {
    this.app.navigationLeft(false)
  },
  methods: {
    start () {
      this.testing = true
      this.speed = 0

      let file = 'https://www.auesgestion.com/plugins/face-api/models/face_recognition_model.weights?n=' + Math.random()

      let startTime = new Date().getTime()

      let request = new XMLHttpRequest()

      request.responseType = 'blob'
      request.open('get', file, true)
      request.send()

      /*request.onreadystatechange = function() {
        if(this.readyState == 4 && this.status == 200) {
          let obj = window.URL.createObjectURL(this.response);

          document.getElementById('save-file').setAttribute('href', obj);
          document.getElementById('save-file').setAttribute('download', fileName);

          setTimeout(function() {
            window.URL.revokeObjectURL(obj);
          }, 60 * 1000);
        }
      }*/

      request.onprogress = (e) => {
        this.percent = Math.floor((e.loaded / e.total) * 100)

        let duration = (new Date().getTime() - startTime) / 1000

        let bps = e.loaded / duration

        this.speed = Math.floor(bps / 1024)
      }

      request.onloadend = () => {
        this.testing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.speedtest {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  background: $color-complementary-2;
}

.speedtest > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.speedtest > div > div:nth-of-type(1) {
  display: flex;
  width: 200px;
  height: 200px;
  background-color: #FFF;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

button {
  border-radius: 100px;
}

</style>
