<template>
  <div class="event-form-modal" v-show="r_open">
    <modal ref="event-form-modal"
      :title="app.strings.practice.calendar.event.modal.title"
      :icon="['fas', 'sliders-h']"
      :color="'primary-2'"
      :closeable="!event.form.loading"
      @close="clear">

      <div class="form-group">
        <label>{{ app.strings.practice.calendar.event.modal.name }}</label>
        <div>
          <input type="text" class="w-100" v-model="event.form.name" />
        </div>
      </div>

      <div class="form-group">
        <label>{{ app.strings.practice.calendar.event.modal.teacher }}</label>
        <div>
          <multiselect v-model="event.form.teacher.value"
          :options="teacherDataForm"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="app.strings.practice.calendar.event.modal.teacher_search"
          @search-change="(query) => {
            this.event.form.teacher.query = query
          }"
          @select="(option) => {
            check('teacher', option)
          }"
          :show-no-results="true"
          :loading="teacher.loading"
          :internal-search="false">
            <template slot="option" slot-scope="props">
              <div class="option__desc" v-if="props.option.code !== 'all'">
                {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }}
              </div>
              <div class="option__desc" v-else>
                <b>{{ app.strings.common.empty }}</b>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
              <div class="option__desc" v-if="props.option.code !== 'all'">
                {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }}
              </div>
              <div class="option__desc" v-else>
                <b>{{ app.strings.common.empty }}</b>
              </div>
            </template>
            <span slot="noResult">{{ app.strings.practice.calendar.event.modal.teacher_search_empty }}</span>
            <span slot="noOptions">{{ app.strings.practice.calendar.event.modal.teacher_search_empty }}</span>
          </multiselect>
        </div>
      </div>

      <div class="form-group">
        <div class="form-group-flex-row">
          <div class="form-group">
            <label>{{ app.strings.practice.calendar.event.modal.date }}</label>
            <div>
              <datepicker
                v-model="event.form.date"
                :language="datepicker.language.es"
                :monday-first="true"
                :disabled-dates="datepicker.disabledDates"
                @input="dateUpdated()"></datepicker>
            </div>
          </div>
          <div class="form-group">
            <label>{{ app.strings.practice.calendar.event.modal.hour }}</label>
            <div>
              <vue-timepicker
                v-model="event.form.hour"
                :minute-interval="15"
                @change="dateUpdated()" hide-clear-button></vue-timepicker>
            </div>
          </div>
          <div class="form-group">
            <label>{{ app.strings.practice.calendar.event.modal.time }}</label>
            <div>
              <vue-number-input
                size="small"
                v-model="event.form.time"
                :step="15"
                :min="15"
                @change="() => {
                  check('teacher', event.form.teacher.value)
                }" inline center controls></vue-number-input>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>{{ app.strings.practice.calendar.event.modal.color }}</label>
        <div class="color-selector">
          <div class="color-selector-item" :class="{selected: color === event.form.color}" v-for="color in colors" v-bind:key="color" v-on:click="() => {
            event.form.color = color
          }">
            <div :style="'background-color: ' + color + ';'">
              <font-awesome-icon v-if="color === event.form.color" :icon="['fas', 'check']" />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>{{ app.strings.practice.calendar.event.modal.observations }}</label>
        <div>
          <textarea class="h-textarea no-resize w-100 min-h-100" v-model="event.form.observations">
          </textarea>
        </div>
        <div class="error" v-if="event.form.name === ''">
          {{ app.strings.practice.calendar.event.modal.name_empty }}
        </div>
        <div class="error" v-if="event.form.teacher.exists">
          {{ app.strings.practice.calendar.event.modal.teacher_exists }}
        </div>
        <div class="error" v-if="event.form.teacher.value.code === 'all'">
          {{ app.strings.practice.calendar.event.modal.teacher_empty }}
        </div>
        <div class="error" v-if="event.form.date < (new Date())">
          {{ app.strings.practice.calendar.event.modal.date_bad }}
        </div>
        <div class="error" v-if="event.form.color === ''">
          {{ app.strings.practice.calendar.event.modal.color_empty }}
        </div>
      </div>

      <div class="text-right">
        <button class="h-button danger"  v-if="event.form.id !== 0 && !event.form.loading" v-on:click="deleteAlert()">
          {{ app.strings.practice.calendar.event.modal.delete }}
        </button>
        <button class="h-button danger ml-10 disabled"  v-if="event.form.loading">
          <loader-dot :size="'18px'"/>
        </button>

        <button class="h-button default ml-10" v-on:click="save()" v-if="eventCanSave && !event.form.loading">
          {{ app.strings.practice.calendar.event.modal.save }}
        </button>
        <button class="h-button default ml-10 disabled"  v-if="!eventCanSave && !event.form.loading">
          {{ app.strings.practice.calendar.event.modal.save }}
        </button>
        <button class="h-button default ml-10 disabled"  v-if="event.form.loading">
          <loader-dot :size="'18px'"/>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import {es} from 'vuejs-datepicker/dist/locale'

export default {
  name: 'event-form-modal-component',
  data () {
    return {
      r_open: false,
      event: {
        form: {
          loading: false,
          id: 0,
          name: '',
          teacher: {
            value: {
              code: 'all'
            },
            exists: false,
            query: '',
            timeout: ''
          },
          date: new Date(),
          hour: '08:00',
          time: 45,
          color: '#2665A1',
          observations: ''
        }
      },
      teacher: {
        loading: false,
        query: '',
        timeout: '',
        data: [],
        filter: {
          code: 'all'
        }
      },
      colors: [
        '#2665A1',
        '#D43A7C',
        '#FF9424',
        '#14A341',
        '#E72949',
        '#03B8BD',
        '#78679F'
      ],
      datepicker: {
        language: {
          es: es
        },
        disabledDates: {
          to: new Date()
        }
      }
    }
  },
  computed: {
    eventCanSave () {
      if (this.event.form.name === '') {
        return false
      }

      if (this.event.form.teacher.value === '' || this.event.form.teacher.value.code === 'all' || this.event.form.teacher.value.code === '') {
        return false
      }

      if (this.event.form.teacher.exists === true) {
        return false
      }

      if (this.teacher.loading === true) {
        return false
      }

      if (this.event.form.date < new Date()) {
        return false
      }

      if (this.event.form.color === '') {
        return false
      }

      return true
    },
    teacherDataForm () {
      let teachers = this.teacher.data

      if (this.teacher.query.trim().length > 0) {
        teachers = teachers.filter((teacher) => {
          if (teacher.code === 'all') {
            return false
          }
          let name = teacher.first_name.toLowerCase() + ' ' + teacher.last_name_1.toLowerCase() + ' ' + teacher.last_name_2.toLowerCase()
          return name.indexOf(this.teacher.query.toLowerCase()) != -1 || teacher.code.toLowerCase().indexOf(this.teacher.query.toLowerCase()) != -1
        })
      }

      return teachers
    }
  },
  props: [
    'app',
    'teacherData'
  ],
  mounted () {
    this.datepicker.disabledDates.to.setDate(this.datepicker.disabledDates.to.getDate() - 1)
  },
  methods: {
    open (id, teacher, day, hour) {
      this.r_open = true

      this.teacher.data = this.teacherData

      if (teacher !== 'all') {
        this.event.form.teacher.value = this.teacher.data.find((t) => t.code === teacher)
      }

      this.event.form.id = id
      if (this.event.form.id === 0) {
        this.event.form.date = day.toDate()
        this.event.form.hour = hour
        this.event.form.date.setHours(parseInt(this.event.form.hour.split(':')[0]), parseInt(this.event.form.hour.split(':')[1]), 0, 0)
      } else {
        this.eventRead()
      }

      this.$refs['event-form-modal'].open()
      this.$emit('onOpen')
    },
    close () {
      this.$refs['event-form-modal'].close()
      this.r_open = false
      this.$emit('onClose')
    },
    eventRead () {
      if (this.event.loading) {
        return
      }

      this.event.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.event.read.one + this.event.form.id,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            let event = response.body.data.event

            this.event.form.name = event.name
            this.event.form.date = this.dateParse(event.date, 'YYYY-MM-DD')
            let h = event.hour
            let m = event.minute
            this.event.form.hour = (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m
            this.event.form.time = event.time
            this.event.form.color = event.color
            this.event.form.observations = event.observations

            this.dateUpdated()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'event-read-one', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.event.loading = false
      }, () => {
        this.event.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    clear () {
      this.event.form.name = ''
      this.event.form.date = new Date()
      this.event.form.hour = '08:00'
      this.event.form.time = 45
      this.event.form.observations = ''
    },
    check (target, option) {
      if (option === undefined || option === '' || option.code === '' || option.code === 'all') {
        return
      }

      if (target === 'student') {
        if (this.student.loading) {
          return
        }

        this.student.loading = true
      }

      if (target === 'teacher') {
        if (this.teacher.loading) {
          return
        }

        this.teacher.loading = true
      }

      if (target === 'vehicle') {
        if (this.vehicle.loading) {
          return
        }

        this.vehicle.loading = true
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.check,
        {
          id: this.event.form.id,
          target: target,
          code: option.code,
          date: this.dateFormat(this.event.form.date, 'YYYY-MM-DD'),
          hour: parseInt(this.event.form.hour.split(':')[0]),
          minute: parseInt(this.event.form.hour.split(':')[1]),
          time: parseInt(this.event.form.time)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            if (target === 'student') {
              this.event.form.student.exists = response.body.exists
            } else if (target === 'teacher') {
              this.event.form.teacher.exists = response.body.exists
            } else if (target === 'vehicle') {
              this.event.form.vehicle.exists = response.body.exists
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'event-check', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        if (target === 'student') {
          this.student.loading = false
        } else if (target === 'teacher') {
          this.teacher.loading = false
        } else if (target === 'vehicle') {
          this.vehicle.loading = false
        }
      }, () => {
        if (target === 'student') {
          this.student.loading = false
        } else if (target === 'teacher') {
          this.teacher.loading = false
        } else if (target === 'vehicle') {
          this.vehicle.loading = false
        }
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    dateUpdated () {
      this.check('teacher', this.event.form.teacher.value)
      this.event.form.date.setHours(parseInt(this.event.form.hour.split(':')[0]), parseInt(this.event.form.hour.split(':')[1]), 0, 0)
    },
    save () {
      let hour = parseInt(this.event.form.hour.split(':')[0])
      let minute = parseInt(this.event.form.hour.split(':')[1])

      if (this.event.form.loading) {
        return
      }

      this.event.form.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.event.create,
        {
          event: JSON.stringify({
            id: this.event.form.id,
            name: this.event.form.name,
            teacher: this.event.form.teacher.value.code,
            date: this.dateFormat(this.event.form.date, 'YYYY-MM-DD'),
            hour: hour,
            minute: minute,
            time: parseInt(this.event.form.time),
            color: this.event.form.color,
            observations: this.event.form.observations
          })
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.event.form.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.clear()
            this.close()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'event-create', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.event.form.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    deleteAlert () {
      this.$swal({
        title: this.app.strings.practice.calendar.event.delete.alert.title,
        text: this.app.strings.practice.calendar.event.delete.alert.content,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm
      }).then((result) => {
        if (result.value) {
          this.deleteConfirm()
        }
      })
    },
    deleteConfirm () {
      if (this.event.form.loading) {
        return
      }

      this.event.form.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.event.delete,
        {
          id: this.event.form.id
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.event.form.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.clear()
            this.close()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'event-delete', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.event.form.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.color-selector {
  display: flex;
}

.color-selector.disabled {
  opacity: 0.5;
}

.color-selector-item {
  padding: 6px;
  box-sizing: border-box;
}

.color-selector-item > div {
  width: 28px;
  height: 28px;
  opacity: 0.5;
  cursor: pointer;
}

.color-selector-item > div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 12px;
}

.color-selector.disabled > .color-selector-item > div {
  cursor: not-allowed !important;
}

.color-selector-item:hover > div,
.color-selector-item.selected > div {
  width: 24px;
  height: 24px;
  border: 2px solid $text-color-1;
  opacity: 1;
}

.color-selector.disabled > .color-selector-item:hover > div {
  width: 28px !important;
  height: 28px !important;
  border: 0px !important;
  opacity: 0.5 !important;
}
</style>
