var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"teacher-practice-menu navigation-top-app"},[_c('navigation-top-app',{attrs:{"app":_vm.app,"back":_vm.back},scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('div',{attrs:{"id":"navigation-center"}},[_vm._v(" "+_vm._s(_vm.app.strings.practice.start.class)+" ")])]},proxy:true}])}),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && !_vm.loading && _vm.practice.data.started === 0)?_c('div',{attrs:{"id":"teacher-practice-start-content"}},[_c('div',{attrs:{"id":"student-data"}},[_c('img',{attrs:{"src":require("@/assets/images/avatar.svg")}}),(_vm.practice.loading)?_c('div',[_vm._v("...")]):_c('div',[_vm._v(_vm._s(_vm.practice.student.first_name)+" "+_vm._s(_vm.practice.student.last_name_1)+" "+_vm._s(_vm.practice.student.last_name_2))])]),_c('div',{attrs:{"id":"practice-timer"}},[_vm._v(" "+_vm._s(_vm._f("normalizeDate")(_vm.practice.data.date,'DD/MM/YYYY - HH:mm:ss'))+" ")]),_c('br'),_c('menu-vertical',{attrs:{"app":_vm.app,"color":'grey',"bold":true,"padding":{
          top: 0,
          sides: 0
        },"options":[
          {
            type: 'button',
            icon: ['fas', 'car'],
            label: _vm.practice.vehicle.name + ' - ' + _vm.practice.vehicle.code
          },
          {
            type: 'input',
            icon: ['fas', 'gas-pump'],
            label: _vm.practice.data.km_start + ' ' + _vm.app.strings.practice.start.km_start,
            input: {
              type: 'number',
              model: _vm.practice.data.km_start,
              change: (value) => {
                _vm.practice.data.km_start = parseInt(value)
              }
            }
          },
          {
            type: 'button',
            icon: ['far', 'clock'],
            label: _vm.practice.data.time + ' min'
          },
          {
            type: 'select',
            label: _vm.practice.evaluation.id === 0 ? _vm.app.strings.practice.start.evaluation : _vm.practiceEvaluation.key,
            input: {
              model: _vm.practiceEvaluation,
              options: _vm.practiceEvaluations,
              change: (value) => {
                _vm.updateEvaluation(value.value)
              }
            }
          }
        ]}}),_c('div',{staticClass:"signature-pad"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.app.strings.practice.start.signature)+" ")]),_c('div',[_c('VueSignaturePad',{ref:"signaturePad",attrs:{"height":"150px","options":{
            backgroundColor: 'rgb(230, 230, 230)',
          }}})],1),(_vm.$refs.signaturePad !== undefined)?_c('font-awesome-icon',{staticClass:"icon clear",attrs:{"icon":['fas', 'trash']},on:{"click":_vm.$refs.signaturePad.clearSignature}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.canStart === 0)?_c('p',[_c('button',{staticClass:"h-button w-100 default-2",on:{"click":function($event){return _vm.start()}}},[_vm._v(" "+_vm._s(_vm.app.strings.practice.start.start)+" ")])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.canStart === 1)?_c('p',{staticClass:"alert-message danger"},[_vm._v(" "+_vm._s(_vm.app.strings.practice.start.error.date_yesterday)+" ")]):_vm._e(),(_vm.canStart === -1)?_c('p',{staticClass:"alert-message danger"},[_vm._v(" "+_vm._s(_vm.app.strings.practice.start.error.date_nextday)+" ")]):_vm._e()])],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && !_vm.loading && _vm.practice.data.started === 1 && _vm.ended)?_c('div',{attrs:{"id":"teacher-practice-start-content"}},[_c('div',{attrs:{"id":"student-data"}},[_c('img',{attrs:{"src":require("@/assets/images/avatar.svg")}}),(_vm.practice.loading)?_c('div',[_vm._v("...")]):_c('div',[_vm._v(_vm._s(_vm.practice.student.first_name)+" "+_vm._s(_vm.practice.student.last_name_1)+" "+_vm._s(_vm.practice.student.last_name_2))])]),_c('div',{attrs:{"id":"practice-timer"}},[_vm._v(" "+_vm._s(_vm._f("normalizeDate")(_vm.practice.data.date,'DD/MM/YYYY - HH:mm:ss'))+" ")]),_c('br'),_c('menu-vertical',{attrs:{"app":_vm.app,"color":'grey',"bold":true,"padding":{
          top: 0,
          sides: 0
        },"options":[
          {
            type: 'button',
            icon: ['fas', 'car'],
            label: _vm.practice.vehicle.name + ' - ' + _vm.practice.vehicle.code
          },
          {
            type: 'button',
            icon: ['fas', 'gas-pump'],
            label: _vm.practice.data.km_start + ' ' + _vm.app.strings.practice.start.km_start
          },
          {
            type: 'input',
            icon: ['fas', 'gas-pump'],
            label: _vm.practice.data.km_end + ' ' + _vm.app.strings.practice.start.km_end,
            input: {
              type: 'number',
              model: _vm.practice.data.km_end,
              change: (value) => {
                _vm.practice.data.km_end = parseInt(value)
              }
            }
          },
          {
            type: 'button',
            icon: ['far', 'clock'],
            label: _vm.practice.data.time + ' min'
          },
          {
            type: 'button',
            label: _vm.practiceEvaluation.key
          }
        ]}}),_c('div',{staticClass:"signature-pad"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.app.strings.practice.start.signature)+" ")]),_c('div',[_c('VueSignaturePad',{ref:"signaturePad",attrs:{"height":"150px","options":{
            backgroundColor: 'rgb(230, 230, 230)',
          }}})],1),(_vm.$refs.signaturePad !== undefined)?_c('font-awesome-icon',{staticClass:"icon clear",attrs:{"icon":['fas', 'trash']},on:{"click":_vm.$refs.signaturePad.clearSignature}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.practice.data.ended === 0)?_c('p',[_c('button',{staticClass:"h-button w-100 danger",on:{"click":function($event){return _vm.endConfirm()}}},[_vm._v(" "+_vm._s(_vm.app.strings.practice.start.end)+" ")])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.practice.data.ended === 1)?_c('p',{staticClass:"alert-message danger"},[_vm._v(" "+_vm._s(_vm.app.strings.practice.start.error.ended)+" ")]):_vm._e()])],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && _vm.loading)?_c('div',{attrs:{"id":"teacher-practice-start-content"}},[_c('loader-dot',{attrs:{"size":'16px',"color_1":'rgb(69, 130, 207)',"color_2":'rgb(69, 130, 207)'}})],1):_vm._e()]),_c('PracticePerformView',{ref:"practice-perform",attrs:{"app":_vm.app},on:{"onEnd":_vm.end}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }