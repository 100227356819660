<template>
  <div id="practice-perform" :class="{open: opened, iphonex: app.config.style.iphonex}">
    <div id="practice-perform-content">
      <div class="form-label primary">
        {{ app.strings.practice.perform.timer }}
      </div>
      <div id="practice-timer" :class="{'error-blink-background': offTime}">
        {{ practice.time.elapsed[1] | fillZero(1) }} : {{ practice.time.elapsed[2] | fillZero(1) }} : {{ practice.time.elapsed[3] | fillZero(1) }}
      </div>
      <div id="practice-buttons">
        <div>
          <div v-on:click="practice.comments.opened = true">
            <div>
              <font-awesome-icon class="icon clear" :icon="['far', 'comment-alt']" /> <span>{{ practice.comments.data.length }}</span>
            </div>
          </div>
          <div>
            <div class="selected" v-on:click="togglePracticeView()" v-if="withoutPracticed">
              <font-awesome-icon class="icon clear" :icon="['fas', 'exclamation-triangle']" /> <span>{{ withoutPracticedCount }}</span>
            </div>
            <div :class="{disabled: withoutPracticedCount === 0}" v-on:click="togglePracticeView()" v-else>
              <font-awesome-icon class="icon clear" :icon="['fas', 'exclamation-triangle']" /> <span>{{ withoutPracticedCount }}</span>
            </div>
          </div>
          <div>
            <div v-on:click="openReport">
              <font-awesome-icon class="icon clear" :icon="['far', 'file']" />
            </div>
          </div>
        </div>
      </div>
      <student-evaluation-practice-component :app="app" :student="this.practice.data.student" :evaluation="evaluation" :withoutPracticed="withoutPracticed" @onPractice="withoutPracticed = false" :editable="true" />
      <br>
      <button class="h-button w-100 danger" v-on:click="practiceEnd()">
        {{ app.strings.practice.perform.end }}
      </button>
    </div>

    <div id="student-comments" :class="{open: practice.comments.opened}">
      <div id="student-comments-content">
        <div class="title">
          <div v-on:click="practice.comments.opened = false">
            <div>
              <font-awesome-icon class="icon clear" :icon="['far', 'comment-alt']" />
            </div>
            <div>{{ app.strings.practice.perform.comments }}</div>
            <div>
              <div>{{ practice.comments.data.length }}</div>
            </div>
          </div>
        </div>
        <div id="student-comments-list">
          <div :class="'comment-' + comment.inserted_at" v-for="comment in practice.comments.data" v-bind:key="comment.id">
            <div>
              {{ comment.inserted_at | normalizeDate('DD/MM/YYYY - HH:mm:ss') }}
            </div>
            <div>
              {{ comment.comment }}
            </div>
          </div>
        </div>
        <div id="student-comments-form">
          <p>
            <textarea ref="comments-input" class="h-textarea white w-100 no-resize" v-model="practice.comments.input"></textarea>
          </p>
          <p>
            <button class="h-button default w-100" v-on:click="practiceCommentCreate" v-if="!practice.comments.loading">
              {{ app.strings.practice.perform.add_comment }}
            </button>
            <button class="h-button default w-100" v-else>
              <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
            </button>
          </p>
        </div>
      </div>
    </div>

    <student-dgt-report-component ref="student-report" :app="app" />
  </div>
</template>

<script>
export default {
  name: 'practice-perform-view',
  data () {
    return {
      rc: 0,
      opened: false,
      withoutPracticed: false,
      practice: {
        time: {
          elapsed: [0,0,0,0]
        },
        data: {
          started_at: new Date()
        },
        evaluation: {
          data: [],
          student: {
            data: []
          }
        },
        comments: {
          loading: false,
          opened: false,
          input: '',
          data: []
        }
      },
      evaluation: {
        groups: []
      }
    }
  },
  computed: {
    offTime () {
      return this.diffMinutes(this.practice.data.started_at, new Date()) > this.practice.data.time
    },
    withoutPracticedCount () {
      let count = this.rc
      count = 0

      for (let i in this.evaluation.groups) {
        let group = this.evaluation.groups[i]
        for (let j in group.items) {
          let item = group.items[j]
          if (item.value === 1) {
            count++
          }
        }
      }

      return count
    }
  },
  props: {
    app: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    setInterval(() => {
      this.practice.time.elapsed = this.dateFineDiff(this.practice.data.started_at, -1)
    }, 1000)
  },
  methods: {
    open (practice, evaluation) {
      this.practice.data = practice.data
      this.practice.evaluation.data = evaluation.data
      this.practice.evaluation.student.data = evaluation.student.data

      this.updateEvaluationValues()
      this.practiceCommentRead()

      this.opened = true
    },
    close () {
      this.opened = false
    },
    updateEvaluationValues () {
      for (let i in this.practice.evaluation.data) {
        let evaluation = this.practice.evaluation.data[i]
        for (let j in evaluation.groups) {
          let group = evaluation.groups[j]
          for (let u in group.items) {
            let item = group.items[u]
            group.items[u].value = 1
            for (let i in this.practice.evaluation.student.data) {
              let evaluation = this.practice.evaluation.student.data[i]
              if (evaluation.item === item.id && evaluation.business_id === item.business_id) {
                group.items[u].value = evaluation.value
              }
            }
          }
        }
      }

      for (let i in this.practice.evaluation.data) {
        if (parseInt(this.practice.evaluation.data[i].id) === parseInt(this.practice.data.evaluation.id) && parseInt(this.practice.evaluation.data[i].business_id) === parseInt(this.practice.data.evaluation.business_id)) {
          this.evaluation = this.practice.evaluation.data[i]
        }
      }
    },
    practiceEnd () {
      this.$swal({
        title: this.app.strings.practice.perform.end_confirm.title,
        text: this.app.strings.practice.perform.end_confirm.content,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm
      }).then((result) => {
        if (result.value) {
          this.close()
          this.$emit('onEnd')
        }
      })
    },
    togglePracticeView () {
      if (this.withoutPracticedCount === 0) {
        this.withoutPracticed = false
      } else {
        this.withoutPracticed = !this.withoutPracticed
      }
    },
    practiceCommentRead () {
      if (this.practice.comments.loading) {
        return
      }

      this.practice.comments.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.comment.read.all + '?student=' + this.practice.data.student,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.practice.comments.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practice.comments.data = response.body.data.comments
            if (this.practice.comments.data.length > 0) {
              let comment = this.practice.comments.data[this.practice.comments.data.length - 1]
              for (let i = 0; i <= 5; i++) {
                setTimeout(() => {
                  const el = this.$el.getElementsByClassName('comment-' + comment.inserted_at)[0]

                  if (el) {
                    el.scrollIntoView()
                  }
                }, i * 100)
              }
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-comment-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.practice.comments.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    practiceCommentCreate () {
      if (this.practice.comments.input.length === 0) {
        return
      }

      if (this.practice.comments.loading) {
        return
      }

      this.practice.comments.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.comment.create,
        {
          id: this.practice.data.id,
          student: this.practice.data.student,
          comment: this.practice.comments.input
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.practice.comments.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practice.comments.input = ''
            this.practiceCommentRead()
            this.$refs['comments-input'].focus()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-comment-create', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.practice.comments.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    openReport () {
      console.log(this.app.url.api + '/practice/report/' + this.practice.data.license + '/' + this.practice.data.circuit + '/' + this.practice.data.student + '?token=' + this.app.token)
      this.$refs['student-report'].open(this.app.url.api + '/practice/report/' + this.practice.data.license + '/' + this.practice.data.circuit + '/' + this.practice.data.student + '?token=' + this.app.token)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#practice-perform {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: top .5s;
}

#practice-perform.open {
  top: 0;
}

#practice-perform-content {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 25px;
  box-sizing: border-box;
  flex-direction: column;
}

#practice-perform.iphonex > #practice-perform-content {
  padding-top: 65px !important;
}

#practice-timer {
  display: block;
  padding: 12px 24px;
  background-color: $color-primary-1;
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
}

#practice-buttons {
  width: 100%;
  height: 78px;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  box-sizing: border-box;
}

#practice-buttons > div {
  display: flex;
  width: 100%;
  height: 60px;
}

#practice-buttons > div > div {
  flex: 1;
  height: 100%;
  padding: 6px;
  box-sizing: border-box;
}

#practice-buttons > div > div > div {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: $color-grey-light-2;
  border-radius: 12px;
}

#practice-buttons > div > div > div .icon {
  font-size: 20px;
}

#practice-buttons > div > div:nth-of-type(1) > div {
  background-color: rgb(220, 230, 245);
}

#practice-buttons > div > div:nth-of-type(1) > div .icon {
  color: $color-primary-1;
}

#practice-buttons > div > div:nth-of-type(2) > div {
  background-color: rgb(210, 240, 230);
}

#practice-buttons > div > div:nth-of-type(2) > div .icon {
  color: $color-success;
}

#practice-buttons > div > div:nth-of-type(2) > div.selected {
  background-color: $color-success;
}

#practice-buttons > div > div:nth-of-type(2) > div.selected .icon,
#practice-buttons > div > div:nth-of-type(2) > div.selected span {
  color: #FFFFFF;
}

#practice-buttons > div > div:nth-of-type(2) > div.disabled {
  opacity: 0.5;
}

#practice-buttons > div > div:nth-of-type(3) > div {
  background-color: rgba($color-grey-light-2, 0.5);
}

#practice-buttons > div > div:nth-of-type(3) > div .icon {
  color: $text-color-2;
}

#practice-buttons > div > div > div > span {
  color: $text-color-2;
  font-size: 16px;
  font-weight: bold;
  padding: 0 12px;
}

#student-comments {
  position: fixed;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding-top: 120px;
  box-sizing: border-box;
  transition: top .5s;
}

#student-comments.open {
  top: 0;
}

#student-comments-content {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  box-sizing: border-box;
  flex-direction: column;
}

#student-comments-content .title {
  padding: 12px 25px;
  box-sizing: border-box;
}

#student-comments-content .title > div {
  display: flex;
  background-color: rgb(220, 230, 245);
  border-radius: 6px;
  align-items: center;
}

#student-comments-content .title > div > div {
  padding: 12px;
}

#student-comments-content .title > div > div:nth-of-type(1) {
  color: $color-primary-1;
  font-size: 26px;
}

#student-comments-content .title > div > div:nth-of-type(2) {
  flex: 1;
  color: $color-primary-1;
  font-weight: bold;
  font-size: 14px;
}

#student-comments-content .title > div > div:nth-of-type(3) > div {
  display: flex;
  background-color: $color-primary-1;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: #FFF;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 18px;
}

#student-comments-list {
  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  overflow: auto;
}

#student-comments-list > div {
  width: 100%;
  background-color: $color-grey-light-3;
  border-radius: 6px;
  padding: 12px;
  box-sizing: border-box;
  margin: 6px 0;
}

#student-comments-list > div > div:nth-of-type(1) {
  color: $color-primary-1;
  font-size: 16px;
  font-weight: bold;
}

#student-comments-list > div > div:nth-of-type(2) {
  color: $text-color-1;
  font-size: 14px;
}

#student-comments-form {
  background-color: rgb(220, 230, 245);
  padding: 0px 25px;
  box-sizing: border-box;
}

#student-comments-form > div {

}
</style>
