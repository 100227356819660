import PracticeCalendarView from '@/views/app/practice/calendar.view'

import TeacherHomeView from '@/views/app/teacher/home.view'
import TeacherPracticeCreateView from '@/views/app/teacher/practice/create.view'
import TeacherPracticeMenuView from '@/views/app/teacher/practice/menu.view'
import TeacherPracticeStartView from '@/views/app/teacher/practice/start.view'
import TeacherBiometricCoursesView from '@/views/app/teacher/biometric/courses.view'
import TeacherBiometricCourseView from '@/views/app/teacher/biometric/course.view'

import StudentListView from '@/views/app/student/list.view'
import StudentProfileView from '@/views/app/student/profile.view'
import StudentEvolutionView from '@/views/app/student/evolution.view'
import StudentDGTReportView from '@/views/app/student/dgtreport.view'
import StudentPracticeBagListView from '@/views/app/student/practice/bag/list.view'
import StudentPracticeBagShopView from '@/views/app/student/practice/bag/shop.view'

const AppRoutes = [
  {
    path: '/app/teacher/home',
    name: 'app.teacher.home',
    component: TeacherHomeView
  },
  {
    path: '/app/practice/calendar',
    name: 'app.practice.calendar',
    component: PracticeCalendarView
  },
  {
    path: '/app/teacher/practice/create',
    name: 'app.teacher.practice.create',
    component: TeacherPracticeCreateView
  },
  {
    path: '/app/teacher/practice/menu/:id',
    name: 'app.teacher.practice.menu',
    component: TeacherPracticeMenuView
  },
  {
    path: '/app/teacher/practice/start/:id',
    name: 'app.teacher.practice.start',
    component: TeacherPracticeStartView
  },
  {
    path: '/app/teacher/biometric/courses',
    name: 'app.teacher.biometric.courses',
    component: TeacherBiometricCoursesView
  },
  {
    path: '/app/teacher/biometric/course/:id',
    name: 'app.teacher.biometric.course',
    component: TeacherBiometricCourseView
  },
  {
    path: '/app/student/list',
    name: 'app.student.list',
    component: StudentListView
  },
  {
    path: '/app/student/profile/:code',
    name: 'app.student.profile',
    component: StudentProfileView
  },
  {
    path: '/app/student/profile',
    name: 'app.student.profile',
    component: StudentProfileView
  },
  {
    path: '/app/student/evolution/:code',
    name: 'app.student.evolution',
    component: StudentEvolutionView
  },
  {
    path: '/app/student/dgtreport/:code',
    name: 'app.student.dgtreport',
    component: StudentDGTReportView
  },
  {
    path: '/app/student/practice/bag/list',
    name: 'app.student.practice.bag.list',
    component: StudentPracticeBagListView
  },
  {
    path: '/app/student/practice/bag/shop',
    name: 'app.student.practice.bag.shop',
    component: StudentPracticeBagShopView
  }
]

export default AppRoutes
