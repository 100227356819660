<template>
  <div class="list">
    <div class="search" v-if="search">
      <input type="text" :placeholder="app.strings.common.search" v-model="query" />
      <font-awesome-icon class="icon" :icon="['fas', 'search']" />
    </div>
    <div class="list">
      <div class="row" v-for="item in itemsFiltered" v-bind:key="item[index]" v-on:click="$emit('onSelect', item)">
        <div>{{ item[value] | truncate(40) }}</div>
        <font-awesome-icon class="icon chevron" :icon="['fas', 'chevron-right']" />
      </div>
      <slot name="rows"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'list',
  data () {
    return {
      query: ''
    }
  },
  computed: {
    itemsFiltered () {
      let items = this.items

      if (this.query.trim().length > 0) {
        items = items.filter((item) => {
          return item.toLowerCase().indexOf(this.query.toLowerCase()) != -1
        })
      }

      this.$emit('onSearch', items)

      return items
    }
  },
  props: {
    'app': {
      type: Object,
      default: () => {
        return {}
      }
    },
    'items': {
      type: Array,
      default: () => {
        return []
      }
    },
    'index': {
      type: String,
      default: () => {
        return 'id'
      }
    },
    'value': {
      type: String,
      default: () => {
        return 'name'
      }
    },
    'search': {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mounted () {

  },
  methods: {

  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/variables-app.scss';

.search {
  position: relative;
}

.search > input {
  width: 100%;
  height: 40px;
  background-color: rgba($color-primary-1, 0.25);
  border-radius: 10px;
  color: $text-color-1;
  font-size: 16px;
  padding-left: 40px;
}

.search > input::placeholder {
  color: $color-primary-1;
  font-size: 16px;
  font-weight: bold;
}

.search > .icon {
  position: absolute;
  top: 12px;
  left: 10px;
  color: $color-primary-1;
}

.row {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 6px 12px;
  box-sizing: border-box;
  background-color: $color-grey-light;
  margin: 12px 0;
  border-radius: 12px;
}

.row > img {
  width: 38px;
}

.row > div {
  flex: 1;
  padding: 0 0 0 12px;
  color: $text-color-1;
  font-size: 14px;
  font-weight: 500;
}

.row > div.right {
  text-align: right;
}

.row > div > span.badge {
  padding: 6px 10px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #FFF;
}

.row.danger {
  background-color: $color-danger;
}

.row.danger > div {
  color: #FFF;
}

.row.danger > div > span.badge {
  background-color: rgba(255,255,255,0.5);
}
</style>
