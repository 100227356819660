<template>
  <div class="loader-dot">
    <div class="spinner" :style="{'width': size, 'height': size}">
      <div class="double-bounce1" :style="{'background-color': color_1}"></div>
      <div class="double-bounce2" :style="{'background-color': color_2}"></div>
    </div>
    <div class="loader-percentage" :style="{'color': color_1}" v-if="percentage !== ''">{{ percentage }}%</div>
  </div>
</template>

<script>
export default {
  name: 'loader-dot-component',
  props: {
    size: {
      type: String,
      default: () => {
        return '40px'
      }
    },
    color_1: {
      type: String,
      default: () => {
        return '#FFF'
      }
    },
    color_2: {
      type: String,
      default: () => {
        return '#FFF'
      }
    },
    percentage: {
      type: String,
      default: () => {
        return ''
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader-dot {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.loader-percentage {
  font-size: 14px;
  font-weight: bold;
  padding: 6px;
  box-sizing: border-box;
}
</style>
