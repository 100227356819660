<template>
  <div class="practice-form-modal" v-show="r_open">
    <modal ref="practice-form-modal"
      :title="app.strings.practice.calendar.practice_create_modal.title"
      :icon="['fas', 'sliders-h']"
      :color="'primary-2'"
      :closeable="!practice.form.loading"
      @close="practiceClear">

      <div class="form-group">
        <label>{{ app.strings.practice.calendar.practice_create_modal.teacher }}</label>
        <div>
          <multiselect v-model="practice.form.teacher.value"
          :options="teacherDataForm"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :allow-empty="false"
          :placeholder="app.strings.practice.calendar.practice_create_modal.teacher_search"
          @search-change="(query) => {
            this.practice.form.teacher.query = query
          }"
          @select="(option) => {
            practiceCheck('teacher', option)
          }"
          :show-no-results="true"
          :loading="teacher.loading"
          :disabled="practiceData.teacher !== 'all'"
          :internal-search="false">
            <template slot="option" slot-scope="props">
              <div class="option__desc" v-if="props.option.code !== 'all'">
                {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }}
              </div>
              <div class="option__desc" v-else>
                <b>{{ app.strings.common.empty }}</b>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
              <div class="option__desc" v-if="props.option.code !== 'all'">
                {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }}
              </div>
              <div class="option__desc" v-else>
                <b>{{ app.strings.common.empty }}</b>
              </div>
            </template>
            <span slot="noResult">{{ app.strings.practice.calendar.practice_create_modal.teacher_search_empty }}</span>
            <span slot="noOptions">{{ app.strings.practice.calendar.practice_create_modal.teacher_search_empty }}</span>
          </multiselect>
        </div>
      </div>

      <div class="form-group">
        <label>{{ app.strings.practice.calendar.practice_create_modal.student }}</label>
        <div>
          <multiselect v-model="practice.form.student.value"
          :options="studentDataForm"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :allow-empty="false"
          :placeholder="app.strings.practice.calendar.practice_create_modal.student_search"
          @search-change="(query) => {
            if (this.studentData.length === 0) {
              this.student.query = query
            } else {
              this.studentSearch(query)
            }
          }"
          @select="(option) => {
            this.practice.form.color = option.center.color
            practiceCheck('student', option)
          }"
          :show-no-results="true"
          :loading="student.loading"
          :disabled="practiceData.student !== 'all'"
          :internal-search="false">
            <template slot="option" slot-scope="props">
              <div class="option__desc" v-if="props.option.code !== 'all'">
                {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }} - <b>{{ props.option.company.traffic_data.code_letter + ' ' + $options.filters.fillZero(props.option.company.traffic_data.code_number, 4) + '/' + $options.filters.fillZero(props.option.center.code, 2) }}</b>
              </div>
              <div class="option__desc" v-else>
                <b>{{ app.strings.common.empty }}</b>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
              <div class="option__desc" v-if="props.option.code !== 'all'">
                {{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }} - <b>{{ props.option.company.traffic_data.code_letter + ' ' + $options.filters.fillZero(props.option.company.traffic_data.code_number, 4) + '/' + $options.filters.fillZero(props.option.center.code, 2) }}</b>
              </div>
              <div class="option__desc" v-else>
                <b>{{ app.strings.common.empty }}</b>
              </div>
            </template>
            <span slot="noResult">{{ app.strings.practice.calendar.practice_create_modal.student_search_empty }}</span>
            <span slot="noOptions">{{ app.strings.practice.calendar.practice_create_modal.student_search_empty }}</span>
          </multiselect>
        </div>
      </div>

      <div class="form-group">
        <label>{{ app.strings.practice.calendar.practice_create_modal.vehicle }}</label>
        <div>
          <multiselect v-model="practice.form.vehicle.value"
          :options="vehicleDataForm"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :allow-empty="false"
          :placeholder="app.strings.practice.calendar.practice_create_modal.vehicle_search"
          @search-change="(query) => {
            this.practice.form.vehicle.query = query
          }"
          @select="(option) => {
            practiceCheck('vehicle', option)
          }"
          :show-no-results="true"
          :loading="vehicle.loading"
          :disabled="practiceData.vehicle !== 'all'"
          :internal-search="false">
            <template slot="option" slot-scope="props">
              <div class="option__desc" v-if="props.option.code !== 'all'">
                {{ props.option.name }} - <b>{{ props.option.code }}</b>
              </div>
              <div class="option__desc" v-else>
                <b>{{ app.strings.common.empty }}</b>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
              <div class="option__desc" v-if="props.option.code !== 'all'">
                {{ props.option.name }} - <b>{{ props.option.code }}</b>
              </div>
              <div class="option__desc" v-else>
                <b>{{ app.strings.common.empty }}</b>
              </div>
            </template>
            <span slot="noResult">{{ app.strings.practice.calendar.practice_create_modal.vehicle_search_empty }}</span>
            <span slot="noOptions">{{ app.strings.practice.calendar.practice_create_modal.vehicle_search_empty }}</span>
          </multiselect>
        </div>
      </div>

      <div class="form-group">
        <div class="form-group-flex-row">
          <div class="form-group" style="flex: 1; padding-right: 12px;">
            <label>{{ app.strings.practice.calendar.practice_create_modal.type }}</label>
            <div style="width: 100%;">
              <multiselect v-model="practice.form.type.value"
              :options="types.data"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="app.strings.practice.calendar.practice_create_modal.type_select"
              @select="(option) => {
                updatePracticeType(option)
              }"
              :show-no-results="true"
              :loading="types.loading"
              :internal-search="false">
                <template slot="option" slot-scope="props">
                  <div class="option__desc" v-if="props.option.id !== 0">
                    {{ props.option.name }} ({{ props.option.description | truncate(40) }}) - <b>{{ props.option.license }} | {{ props.option.time }}m {{ props.option.price }}€</b>
                  </div>
                  <div class="option__desc" v-else>
                    <b>{{ app.strings.common.empty }}</b>
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="props">
                  <div class="option__desc" v-if="props.option.id !== 0">
                    {{ props.option.name }} ({{ props.option.description | truncate(40) }}) - <b>{{ props.option.license }} | {{ props.option.time }}m {{ props.option.price }}€</b>
                  </div>
                  <div class="option__desc" v-else>
                    <b>{{ app.strings.common.empty }}</b>
                  </div>
                </template>
                <span slot="noResult">{{ app.strings.practice.calendar.practice_create_modal.type_search_empty }}</span>
                <span slot="noOptions">{{ app.strings.practice.calendar.practice_create_modal.type_search_empty }}</span>
              </multiselect>
            </div>
          </div>
          <div class="form-group" style="flex: 0;">
            <label>{{ app.strings.practice.calendar.practice_create_modal.quantity }}</label>
            <div>
              <!--<vue-number-input
                size="small"
                v-model="practice.form.quantity"
                :min="1"
                :disabled="practice.form.id !== 0"
                @change="() => {
                  practiceCheck('student', practice.form.student.value)
                  practiceCheck('teacher', practice.form.teacher.value)
                  practiceCheck('vehicle', practice.form.vehicle.value)
                }" inline center controls></vue-number-input>-->
                <input
                type="number"
                class="text-center"
                style="padding: 12px 24px;"
                min="1"
                step="1"
                pattern="\d+"
                v-model="practice.form.quantity"
                @change="() => {
                  if (practice.form.quantity <= 0) {
                    practice.form.quantity = 1
                  }
                  practiceCheck('student', practice.form.student.value)
                  practiceCheck('teacher', practice.form.teacher.value)
                  practiceCheck('vehicle', practice.form.vehicle.value)
                }" />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="form-group-flex-row">
          <div class="form-group">
            <label>{{ app.strings.practice.calendar.practice_create_modal.date }}</label>
            <div>
              <datepicker
                v-model="practice.form.date"
                :language="datepicker.language.es"
                :monday-first="true"
                :disabled-dates="datepicker.disabledDates"
                @input="practiceDateUpdated()"></datepicker>
            </div>
          </div>
          <div class="form-group">
            <label>{{ app.strings.practice.calendar.practice_create_modal.hour }}</label>
            <div>
              <vue-timepicker
                v-model="practice.form.hour"
                :minute-interval="15"
                @change="practiceDateUpdated()" hide-clear-button></vue-timepicker>
            </div>
          </div>
          <div class="form-group tablet-hidden">
            <label>{{ app.strings.practice.calendar.practice_create_modal.color }}</label>
            <div class="color-selector" :class="{disabled: practice.form.student.value.code === 'all'}">
              <div class="color-selector-item" :class="{selected: color === practice.form.color}" v-for="color in colors" v-bind:key="color" v-on:click="() => {
                if (practice.form.student.value.code !== 'all') {
                  practice.form.color = color
                }
              }">
                <div :style="'background-color: ' + color + ';'">
                  <font-awesome-icon v-if="color === practice.form.color" :icon="['fas', 'check']" />
                </div>
              </div>
            </div>
          </div>
          <!--<div class="form-group">
            <label>{{ app.strings.practice.calendar.practice_create_modal.time }}</label>
            <div>
              <vue-number-input
                size="small"
                v-model="practice.form.time"
                :step="15"
                :min="15"
                @change="() => {
                  practiceCheck('student', practice.form.student.value)
                  practiceCheck('teacher', practice.form.teacher.value)
                  practiceCheck('vehicle', practice.form.vehicle.value)
                }" inline center controls></vue-number-input>
            </div>
          </div>-->
        </div>
      </div>

      <div class="form-group pc-hidden">
        <label>{{ app.strings.practice.calendar.practice_create_modal.color }}</label>
        <div class="color-selector" :class="{disabled: practice.form.student.value.code === 'all'}">
          <div class="color-selector-item" :class="{selected: color === practice.form.color}" v-for="color in colors" v-bind:key="color" v-on:click="() => {
            if (practice.form.student.value.code !== 'all') {
              practice.form.color = color
            }
          }">
            <div :style="'background-color: ' + color + ';'">
              <font-awesome-icon v-if="color === practice.form.color" :icon="['fas', 'check']" />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" v-if="practice.form.date < (new Date())">
        <div class="form-group-flex-row">
          <div class="form-group">
            <label>{{ app.strings.practice.calendar.practice_create_modal.km_start }}</label>
            <div>
                <input
                type="number"
                class="text-center"
                style="padding: 12px 24px;"
                min="0"
                step="1"
                pattern="\d+"
                v-model="practice.form.km_start"
                @change="() => {
                  if (practice.form.km_start < 0) {
                    practice.form.km_start = 0
                  }

                  if (practice.form.km_end < practice.form.km_start + 1) {
                    practice.form.km_end = parseInt(practice.form.km_start) + 1
                  }
                }" />
            </div>
          </div>

          <div class="form-group">
            <label>{{ app.strings.practice.calendar.practice_create_modal.km_end }}</label>
            <div>
                <input
                type="number"
                class="text-center"
                style="padding: 12px 24px;"
                min="1"
                step="1"
                pattern="\d+"
                v-model="practice.form.km_end"
                @change="() => {
                  if (practice.form.km_end < practice.form.km_start + 1) {
                    practice.form.km_end = practice.form.km_start + 1
                  }
                }" />
            </div>
          </div>

          <div class="form-group">
            <label>{{ app.strings.practice.calendar.practice_create_modal.time }}</label>
            <div>
                <input
                type="number"
                class="text-center"
                style="padding: 12px 24px;"
                min="1"
                step="1"
                pattern="\d+"
                v-model="practice.form.time_real"
                @change="() => {
                  if (practice.form.time_real < 0) {
                    practice.form.time_real = 1
                  }
                }" />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>{{ app.strings.practice.calendar.practice_create_modal.observations }}</label>
        <div>
          <textarea class="h-textarea no-resize w-100 min-h-100" v-model="practice.form.observations">
          </textarea>
        </div>
        <div class="error" v-if="practice.form.student.exists">
          {{ app.strings.practice.calendar.practice_create_modal.student_exists }}
        </div>
        <div class="error" v-if="practice.form.student.value.code === 'all'">
          {{ app.strings.practice.calendar.practice_create_modal.student_empty }}
        </div>
        <div class="error" v-if="practice.form.teacher.exists">
          {{ app.strings.practice.calendar.practice_create_modal.teacher_exists }}
        </div>
        <div class="error" v-if="practice.form.teacher.value.code === 'all'">
          {{ app.strings.practice.calendar.practice_create_modal.teacher_empty }}
        </div>
        <div class="error" v-if="practice.form.vehicle.exists">
          {{ app.strings.practice.calendar.practice_create_modal.vehicle_exists }}
        </div>
        <div class="error" v-if="practice.form.vehicle.value.code === 'all'">
          {{ app.strings.practice.calendar.practice_create_modal.vehicle_empty }}
        </div>
        <div class="error" v-if="practice.form.type.value.id === 0">
          {{ app.strings.practice.calendar.practice_create_modal.type_empty }}
        </div>
        <div class="error" v-if="(practice.form.started === false || practice.form.ended === true) && practice.form.date < (new Date())">
          {{ app.strings.practice.calendar.practice_create_modal.date_bad }}
        </div>
        <div class="error" v-if="practice.form.started === true && practice.form.ended === false">
          {{ app.strings.practice.calendar.practice_create_modal.started }}
        </div>
        <div class="error" v-if="practice.form.color === ''">
          {{ app.strings.practice.calendar.practice_create_modal.color_empty }}
        </div>
      </div>

      <div class="text-right">
        <button class="h-button danger" v-if="practice.form.id !== 0 && !practice.form.loading && app.user.config.practice.delete === 1" v-on:click="practiceDeleteAlert()">
          {{ app.strings.practice.calendar.practice_create_modal.delete }}
        </button>
        <button class="h-button danger ml-10 disabled"  v-if="practice.form.loading">
          <loader-dot :size="'18px'"/>
        </button>

        <button class="h-button info ml-10" v-if="practice.form.id !== 0 && practice.form.started === false && practice.form.ended === false && practice.form.cancelled === false && !practice.form.loading" v-on:click="practiceCancelAlert()">
          {{ app.strings.practice.calendar.practice_create_modal.cancel }}
        </button>
        <button class="h-button info ml-10 disabled" v-if="practice.form.loading">
          <loader-dot :size="'18px'"/>
        </button>

        <button class="h-button default ml-10" v-on:click="practiceSave(0)" v-if="practiceClassCanSave && !practice.form.loading">
          {{ app.strings.practice.calendar.practice_create_modal.save }}
        </button>
        <button class="h-button default ml-10 disabled"  v-if="!practiceClassCanSave && !practice.form.loading && !this.student.loading && !this.teacher.loading && !this.vehicle.loading">
          {{ app.strings.practice.calendar.practice_create_modal.save }}
        </button>
        <button class="h-button default ml-10 disabled"  v-if="practice.form.loading && (!this.student.loading || !this.teacher.loading || !this.vehicle.loading)">
          <loader-dot :size="'18px'"/>
        </button>
        <button class="h-button default ml-10 disabled"  v-if="!practice.form.loading && (this.student.loading || this.teacher.loading || this.vehicle.loading)">
          <loader-dot :size="'18px'"/>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import {es} from 'vuejs-datepicker/dist/locale'

export default {
  name: 'practice-form-modal-component',
  data () {
    return {
      r_open: false,
      practice: {
        form: {
          loading: false,
          id: 0,
          license: 'B',
          circuit: 0,
          exam: 0,
          student: {
            value: {
              code: 'all'
            },
            exists: false,
            query: '',
            timeout: ''
          },
          teacher: {
            value: {
              code: 'all'
            },
            exists: false,
            query: '',
            timeout: ''
          },
          vehicle: {
            value: {
              code: 'all'
            },
            exists: false,
            query: '',
            timeout: ''
          },
          type_id: 0,
          type: {
            value: {
              id: 0
            }
          },
          date: new Date(),
          hour: '08:00',
          time: 45,
          time_real: 1,
          km_start: 0,
          km_end: 1,
          price: 0.0,
          signature: '',
          started_at: new Date(),
          color: '#FFFFFF',
          quantity: 1,
          observations: '',
          cancelled: false,
          ended: false
        },
        last: {
          id: 0,
          student: '',
          teacher: '',
          vehicle: '',
          date: new Date(),
          hour: 0,
          minute: 0,
          time: 0,
          price: 0,
          color: 0
        }
      },
      student: {
        loading: false,
        query: '',
        timeout: '',
        data: [],
        filter: {
          code: 'all'
        }
      },
      teacher: {
        loading: false,
        query: '',
        timeout: '',
        data: [],
        filter: {
          code: 'all'
        }
      },
      vehicle: {
        loading: false,
        query: '',
        timeout: '',
        data: [],
        filter: {
          code: 'all'
        }
      },
      types: {
        loading: false,
        data: []
      },
      colors: [
        '#2665A1',
        '#D43A7C',
        '#FF9424',
        '#14A341',
        '#E72949',
        '#03B8BD',
        '#78679F'
      ],
      datepicker: {
        language: {
          es: es
        },
        disabledDates: {
          to: new Date()
        }
      }
    }
  },
  computed: {
    practiceClassCanSave () {
      if (this.practice.form.student.value.code === 'all') {
        return false
      }

      if (this.practice.form.student.exists) {
        return false
      }

      if (this.student.loading) {
        return false
      }

      if (this.practice.form.teacher.value.code === 'all') {
        return false
      }

      if (this.practice.form.teacher.exists && this.practice.form.teacher.value.config.practice.multiclass === 0) {
        return false
      }

      if (this.teacher.loading) {
        return false
      }

      if (this.practice.form.vehicle.value.code === 'all') {
        return false
      }

      if (this.practice.form.vehicle.exists && this.practice.form.teacher.value.config.practice.multiclass === 0) {
        return false
      }

      if (this.vehicle.loading) {
        return false
      }

      if (this.practice.form.cancelled) {
        return false
      }

      if (this.practice.form.started === true && this.practice.form.ended === false) {
        return false
      }

      if (this.practice.form.type.value.id === 0) {
        return false
      }

      if (this.practice.form.color === '') {
        return false
      }

      if (this.student.loading || this.teacher.loading || this.vehicle.loading) {
        return false
      }

      return true
    },
    studentDataForm () {
      let students = this.student.data

      if (this.student.query.trim().length > 0) {
        students = students.filter((student) => {
          if (student.code === 'all') {
            return false
          }
          let name = student.first_name.toLowerCase() + ' ' + student.last_name_1.toLowerCase() + ' ' + student.last_name_2.toLowerCase()
          return name.indexOf(this.student.query.toLowerCase()) != -1 || student.code.toLowerCase().indexOf(this.student.query.toLowerCase()) != -1
        })
      }

      return students
    },
    teacherDataForm () {
      let teachers = this.teacher.data

      if (this.practice.form.student.value.teachers !== undefined && this.practice.form.student.value.teachers !== null) {
        teachers = teachers.filter((teacher) => this.practice.form.student.value.teachers.includes(teacher.code))
      }

      if (this.teacher.query.trim().length > 0) {
        teachers = teachers.filter((teacher) => {
          if (teacher.code === 'all') {
            return false
          }
          let name = teacher.first_name.toLowerCase() + ' ' + teacher.last_name_1.toLowerCase() + ' ' + teacher.last_name_2.toLowerCase()
          return name.indexOf(this.teacher.query.toLowerCase()) != -1 || teacher.code.toLowerCase().indexOf(this.teacher.query.toLowerCase()) != -1
        })
      }

      return teachers
    },
    vehicleDataForm () {
      let vehicles = this.vehicle.data

      if (this.vehicle.query.trim().length > 0) {
        vehicles = vehicles.filter((vehicle) => {
          if (vehicle.code === 'all') {
            return false
          }
          return vehicle.name.toLowerCase().indexOf(this.vehicle.query.toLowerCase()) != -1 || vehicle.code.toLowerCase().indexOf(this.vehicle.query.toLowerCase()) != -1
        })
      }

      return vehicles
    },
    balance () {
      if (this.practice.form.student.value.bag !== undefined && this.practice.form.student.value.bag !== null) {
        let balance = this.practice.form.student.value.bag.find(b => b.practice_type === this.practice.form.type.value.id)
        if (balance !== undefined && balance !== null) {
          return balance.balance
        }
      }

      return 0
    }
  },
  props: [
    'app',
    'practiceData',
    'studentData',
    'teacherData',
    'vehicleData'
  ],
  mounted () {
    this.datepicker.disabledDates.to.setDate(this.datepicker.disabledDates.to.getDate() - 1)
    this.typesRead()
  },
  methods: {
    open () {
      this.r_open = true
      this.student.data = this.studentData
      this.teacher.data = this.teacherData
      this.vehicle.data = this.vehicleData

      this.practice.form.id = this.practiceData.id
      this.practice.form.ids = this.practiceData.ids
      if (this.practice.form.id === 0) {
        this.practice.form.date = this.practiceData.day.toDate()
        this.practice.form.hour = this.practiceData.hour
        this.practice.form.date.setHours(parseInt(this.practice.form.hour.split(':')[0]), parseInt(this.practice.form.hour.split(':')[1]), 0, 0)

        this.practice.last = this.practiceData.last
        if (this.practice.last.id !== 0) {
          if (this.practiceData.teacher === 'all') {
            this.practice.form.teacher.value = this.teacher.data.find((teacher) => teacher.code === this.practice.last.teacher)
          } else {
            this.practice.form.teacher.value = this.teacher.data.find((teacher) => teacher.code === this.practiceData.teacher)
          }

          if (this.practiceData.student === 'all') {
            this.studentSearch(this.practice.last.student, true)
          } else {
            this.studentSearch(this.practiceData.student, true)
          }

          if (this.practiceData.vehicle === 'all') {
            this.practice.form.vehicle.value = this.vehicle.data.find((vehicle) => vehicle.code === this.practice.last.vehicle)
          } else {
            this.practice.form.vehicle.value = this.vehicle.data.find((vehicle) => vehicle.code === this.practiceData.vehicle)
          }

          this.practice.form.time = this.practice.last.time
          this.practice.form.price = this.practice.last.price
          for (let i in this.types.data) {
            let ttype = this.types.data[i]
            if (this.practice.last.type_id === ttype.id || this.practice.last.type_id === 0) {
              this.practice.form.type.value = ttype
              break
            }
          }
        } else {
          if (this.practiceData.student !== 'all') {
            this.studentSearch(this.practiceData.student, true)
          }
          if (this.practiceData.teacher !== 'all') {
            this.practice.form.teacher.value = this.teacher.data.find((teacher) => teacher.code === this.practiceData.teacher)
          }
          if (this.practiceData.vehicle !== 'all') {
            this.practice.form.vehicle.value = this.vehicle.data.find((vehicle) => vehicle.code === this.practiceData.vehicle)
          }
        }

        this.typesRead()
      } else {
        this.practiceRead(() => {
          this.typesRead()
        })
      }

      this.$refs['practice-form-modal'].open()
      this.$emit('onOpen')
    },
    close () {
      this.$refs['practice-form-modal'].close()
      this.r_open = false
      this.$emit('onClose')
    },
    practiceRead (callback) {
      if (this.practice.loading) {
        return
      }

      this.practice.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.read.one + this.practice.form.id,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            let practice = response.body.data.practice

            this.practice.form.date = this.dateParse(practice.date, 'YYYY-MM-DD')
            let h = practice.hour
            let m = practice.minute
            this.practice.form.hour = (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m
            this.practice.form.time = practice.time
            this.practice.form.time_real = practice.time_real
            this.practice.form.km_start = practice.km_start
            this.practice.form.km_end = practice.km_end
            this.practice.form.price = practice.price
            this.practice.form.color = practice.color
            this.practice.form.type_id = practice.type_id
            this.practice.form.quantity = 1
            this.practice.form.observations = practice.observations
            this.practice.form.cancelled = parseInt(practice.cancelled) === 1
            this.practice.form.started = parseInt(practice.started) === 1
            this.practice.form.ended = parseInt(practice.ended) === 1

            this.practice.form.student.value = response.body.data.student
            this.studentRead(this.practice.form.student.value.code)

            this.practice.form.teacher.value = response.body.data.teacher
            this.practice.form.vehicle.value = response.body.data.vehicle

            if (callback !== undefined) {
              callback()
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-read-one', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.practice.loading = false
      }, () => {
        this.practice.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    typesRead () {
      if (this.types.loading) {
        return
      }

      this.types.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.type.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.types.data = response.body.data.types

            for (let i in this.types.data) {
              let ttype = this.types.data[i]
              if (this.practice.form.type.id === ttype.id || this.practice.form.type.id === 0) {
                this.practice.form.type.value = ttype
                break
              }
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-type-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.types.loading = false
      }, () => {
        this.types.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    updatePracticeType (type) {
      this.practice.form.license = type.license
      this.practice.form.circuit = parseInt(type.circuit)
      this.practice.form.exam = parseInt(type.exam)
      this.practice.form.time = type.time
      this.practice.form.price = type.price
      this.practiceCheck('student', this.practice.form.student.value)
      this.practiceCheck('teacher', this.practice.form.teacher.value)
      this.practiceCheck('vehicle', this.practice.form.vehicle.value)
    },
    studentReadAll (teacher) {
      if (this.student.loading) {
        return
      }

      this.student.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.student.read.all + '?teacher=' + teacher,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.student.data = response.body.data.students
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.student.loading = false
      }, () => {
        this.student.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    studentRead (code) {
      if (this.student.loading) {
        return
      }

      this.student.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.student.read.one + code,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practice.form.student.value = response.body.data.student
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.student.loading = false
      }, () => {
        this.student.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    studentSearch (query, autoselectForm, callback) {
      if (this.student.loading) {
        return
      }

      if (query === '') {
        return
      }

      if (this.student.timeout !== '') {
        clearTimeout(this.student.timeout)
      }

      this.student.timeout = setTimeout(() => {
        if (this.student.loading) {
          return
        }

        if (this.student.data.length > 0) {
          this.student.query = query
          if (this.studentDataForm.length > 0) {
            this.practice.form.student.value = JSON.parse(JSON.stringify(this.studentDataForm[0]))
            this.practice.form.color = this.practice.form.student.value.center.color
          } else {
            this.practice.form.student.value = JSON.parse(JSON.stringify(this.student.data[0]))
            this.practice.form.color = this.practice.form.student.value.center.color
          }
          this.student.query = ''
          this.studentRead(this.practice.form.student.value.code)
          return
        }

        this.student.loading = true

        if (this.student.timeout !== '') {
          clearTimeout(this.student.timeout)
        }

        this.$http.post(
          this.app.url.api + this.app.endpoints.student.search,
          {
            query: query
          },
          {
            emulateJSON: true,
            headers: {
              Authorization: 'Token ' + this.app.token
            }
          }
        ).then(response => {
          if (response.status === 200) {
            this.student.loading = false
            if (response.body.success === true) {
              this.student.data = response.body.data.students
              if (autoselectForm === true) {
                this.practice.form.student.value = this.student.data[0]

                this.practice.form.color = this.practice.form.student.value.center.color

                this.studentRead(this.practice.form.student.value.code)
              }
              if (callback !== undefined) {
                callback(true)
              }
            } else {
              if (response.body.error.code === 'S100') {
                this.app.methods.logout()
              } else {
                let error = response.body.error.code
                this.showError(this.app.strings, 'student-search', error)
              }
              if (callback !== undefined) {
                callback(false)
              }
            }
          } else {
            this.showError(this.app.strings, 'request', 'status-' + response.status)
            if (callback !== undefined) {
              callback(false)
            }
          }
        }, () => {
          this.student.loading = false
          this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
          if (callback !== undefined) {
            callback(false)
          }
        })
      }, 1000)
    },
    practiceClear () {
      /*if (this.practiceData.student === 'all') {
        this.practice.form.student.value = ''
      }
      if (this.practiceData.teacher === 'all') {
        this.practice.form.teacher.value = ''
      }
      if (this.practiceData.vehicle === 'all') {
        this.practice.form.vehicle.value = ''
      }*/
      this.practice.form.date = new Date()
      this.practice.form.hour = '08:00'
      this.practice.form.time = 45
      this.practice.form.quantity = 1
      this.practice.form.observations = ''
    },
    practiceCheck (target, option) {
      if (option === undefined || option === '' || option.code === '' || option.code === 'all') {
        return
      }

      if (target === 'student') {
        if (this.student.loading) {
          return
        }

        this.student.loading = true
      }

      if (target === 'teacher') {
        if (this.teacher.loading) {
          return
        }

        this.teacher.loading = true
      }

      if (target === 'vehicle') {
        if (this.vehicle.loading) {
          return
        }

        this.vehicle.loading = true
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.check,
        {
          id: this.practice.form.id,
          target: target,
          code: option.code,
          date: this.dateFormat(this.practice.form.date, 'YYYY-MM-DD'),
          hour: parseInt(this.practice.form.hour.split(':')[0]),
          minute: parseInt(this.practice.form.hour.split(':')[1]),
          time: parseInt(this.practice.form.time) * parseInt(this.practice.form.quantity)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            if (target === 'student') {
              this.practice.form.student.exists = response.body.exists
              if (this.practice.form.student.exists === false) {
                this.studentRead(this.practice.form.student.value.code)
              }
            } else if (target === 'teacher') {
              this.practice.form.teacher.exists = response.body.exists
              if (this.practice.form.teacher.exists === false) {
                this.student.query = ''
                this.studentReadAll(option.code)
              }
            } else if (target === 'vehicle') {
              this.practice.form.vehicle.exists = response.body.exists
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-check', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        if (target === 'student') {
          this.student.loading = false
        } else if (target === 'teacher') {
          this.teacher.loading = false
        } else if (target === 'vehicle') {
          this.vehicle.loading = false
        }
      }, () => {
        if (target === 'student') {
          this.student.loading = false
        } else if (target === 'teacher') {
          this.teacher.loading = false
        } else if (target === 'vehicle') {
          this.vehicle.loading = false
        }
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    practiceDateUpdated () {
      this.practiceCheck('student', this.practice.form.student.value)
      this.practiceCheck('teacher', this.practice.form.teacher.value)
      this.practiceCheck('vehicle', this.practice.form.vehicle.value)

      this.practice.form.date.setHours(parseInt(this.practice.form.hour.split(':')[0]), parseInt(this.practice.form.hour.split(':')[1]), 0, 0)
    },
    practiceSave (index, confirm, format) {
      if (index === parseInt(this.practice.form.quantity)) {
        this.practiceClear()

        this.close()
        return
      }

      let hour = parseInt(this.practice.form.hour.split(':')[0])
      let minute = parseInt(this.practice.form.hour.split(':')[1])

      if (index > 0) {
        if (format === undefined) {
          format = 'hours'
        }

        if (format === 'hours') {
          let minutes = (parseInt(this.practice.form.hour.split(':')[0]) * 60) + parseInt(this.practice.form.hour.split(':')[1]) + (parseInt(this.practice.form.time) * parseInt(index))

          let hours = (minutes / 60)
          hour = Math.floor(hours)
          minutes = (hours - hour) * 60
          minute = Math.round(minutes)
        } else if (format === 'days') {
          this.practice.form.date.setDate(this.practice.form.date.getDate() + 1)
        }
      }

      if (this.practice.form.loading) {
        return
      }

      if (confirm === undefined && this.practice.form.quantity > 1) {
        this.$swal({
          title: this.app.strings.practice.calendar.multiple.alert.title,
          text: this.app.strings.practice.calendar.multiple.alert.content,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.app.strings.practice.calendar.multiple.alert.hours,
          confirmButtonText: this.app.strings.practice.calendar.multiple.alert.days
        }).then((result) => {
          if (result.value) {
            this.practiceSave(index, true, 'days')
          } else {
            this.practiceSave(index, true, 'hours')
          }
        })
        return
      }

      if (this.practice.form.id === 0) {
        if (this.balance - parseInt(this.practice.form.quantity) < 0 && (this.app.user.config.practice.balance === 0 && this.app.user.role !== 'admin')) {
          this.$swal({
            title: this.app.strings.practice.create.balance_negative_prohibit.title,
            text: this.app.strings.practice.create.balance_negative_prohibit.content,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: this.app.strings.common.confirm
          })
          return
        }

        if (confirm === undefined && this.balance - parseInt(this.practice.form.quantity) < 0) {
          this.$swal({
            title: this.app.strings.practice.create.balance_negative.title,
            text: this.app.strings.practice.create.balance_negative.content,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.app.strings.common.cancel,
            confirmButtonText: this.app.strings.common.confirm
          }).then((result) => {
            if (result.value) {
              this.practiceSave(index, true, format)
            }
          })
          return
        }
      }

      this.practice.form.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.create,
        {
          past: this.practice.form.date < (new Date()),
          practice: JSON.stringify({
            id: this.practice.form.id,
            license: this.practice.form.license,
            circuit: this.practice.form.circuit,
            exam: this.practice.form.exam,
            student: this.practice.form.student.value.code,
            teacher: this.practice.form.teacher.value.code,
            vehicle: this.practice.form.vehicle.value.code,
            date: this.dateFormat(this.practice.form.date, 'YYYY-MM-DD'),
            hour: hour,
            minute: minute,
            time: parseInt(this.practice.form.time),
            time_real: parseInt(this.practice.form.time_real),
            km_start: parseInt(this.practice.form.km_start),
            km_end: parseInt(this.practice.form.km_end),
            price: parseFloat(this.practice.form.price * 1.0),
            color: this.practice.form.color,
            type_id: parseInt(this.practice.form.type.value.id),
            observations: this.practice.form.observations
          })
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.practice.form.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            index++
            this.practiceSave(index, confirm, format)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-create', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.practice.form.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    practiceDeleteAlert (error, balance) {
      if (balance === undefined) {
        balance = !this.practice.form.cancelled
      }

      let multi = ''
      if (this.practice.form.ids !== undefined && this.practice.form.ids !== null && this.practice.form.ids.length > 1) {
        multi = ' ' + this.app.strings.practice.calendar.cancel.alert.multi_1 + ' ' + this.practice.form.ids.length + ' ' + this.app.strings.practice.calendar.cancel.alert.multi_2
      }

      if (this.practice.form.ended === false) {
        this.$swal({
          title: this.app.strings.practice.calendar.delete.alert.title,
          text: this.app.strings.practice.calendar.delete.alert.content + multi,
          icon: 'warning',
          showCancelButton: true,
          html:
          '<div class="form-group">' +
          '<div><input id="balance-input" type="checkbox" value="1" ' + (balance ? 'checked' : '') + ' />' + this.app.strings.practice.create.balance_check + '</div>' +
          '<div><textarea id="delete-comments-input" class="swal2-textarea" style="display: flex; resize: none; font-size: 12px;"></textarea></div>' +
          (error === true ? '<div class="error">' + this.app.strings.practice.create.delete_comments_empty + '</div>' : '') +
          '</div>',
          /*input: 'checkbox',
          inputPlaceholder: this.app.strings.practice.create.balance_check,
          inputValue: this.practice.form.cancelled === false,*/
          cancelButtonText: this.app.strings.common.cancel,
          confirmButtonText: this.app.strings.common.confirm
        }).then((result) => {
          if (result.value === true) {
            balance = document.getElementById('balance-input').checked
            let comments = document.getElementById('delete-comments-input').value

            if (comments.length === 0) {
              this.practiceDeleteAlert(true, balance)
            } else {
              this.practiceDeleteConfirm(balance, comments)
            }
          }
        })
      } else {
        this.$swal({
          title: this.app.strings.practice.calendar.delete.alert.title,
          text: this.app.strings.practice.calendar.delete.alert.content + multi,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.app.strings.common.cancel,
          confirmButtonText: this.app.strings.common.confirm
        }).then((result) => {
          if (result.value) {
            this.practiceDeleteConfirm(false)
          }
        })
      }
    },
    practiceDeleteConfirm (balance, comments) {
      if (this.practice.form.loading) {
        return
      }

      this.practice.form.loading = true

      let ids = [this.practice.form.id]

      if (this.practice.form.ids !== undefined && this.practice.form.ids !== null && this.practice.form.ids.length > 1) {
        ids = this.practice.form.ids
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.delete,
        {
          ids: JSON.stringify(ids),
          balance: balance,
          comments: comments
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.practice.form.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practiceClear()

            this.close()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-delete', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.practice.form.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    practiceCancelAlert () {
      let multi = ''
      if (this.practice.form.ids !== undefined && this.practice.form.ids !== null && this.practice.form.ids.length > 1) {
        multi = ' ' + this.app.strings.practice.calendar.cancel.alert.multi_1 + ' ' + this.practice.form.ids.length + ' ' + this.app.strings.practice.calendar.cancel.alert.multi_2
      }
      if (this.practice.form.ended === false) {
        this.$swal({
          title: this.app.strings.practice.calendar.cancel.alert.title,
          text: this.app.strings.practice.calendar.cancel.alert.content + multi,
          icon: 'warning',
          showCancelButton: true,
          input: 'checkbox',
          inputPlaceholder: this.app.strings.practice.create.balance_check,
          inputValue: true,
          cancelButtonText: this.app.strings.common.cancel,
          confirmButtonText: this.app.strings.common.confirm
        }).then((result) => {
          if (result.dismiss !== 'cancel') {
            this.practiceCancelConfirm(result.value === 1)
          }
        })
      } else {
        this.$swal({
          title: this.app.strings.practice.calendar.cancel.alert.title,
          text: this.app.strings.practice.calendar.cancel.alert.content + multi,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.app.strings.common.cancel,
          confirmButtonText: this.app.strings.common.confirm
        }).then((result) => {
          if (result.value) {
            this.practiceCancelConfirm(false)
          }
        })
      }
    },
    practiceCancelConfirm (balance) {
      if (this.practice.form.loading) {
        return
      }

      this.practice.form.loading = true

      let ids = [this.practice.form.id]

      if (this.practice.form.ids !== undefined && this.practice.form.ids !== null && this.practice.form.ids.length > 1) {
        ids = this.practice.form.ids
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.cancel,
        {
          ids: JSON.stringify(ids),
          balance: balance,
          observations: this.practice.form.observations
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.practice.form.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practiceClear()

            this.close()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-cancel', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.practice.form.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.color-selector {
  display: flex;
}

.color-selector.disabled {
  opacity: 0.5;
}

.color-selector-item {
  padding: 6px;
  box-sizing: border-box;
}

.color-selector-item > div {
  width: 28px;
  height: 28px;
  opacity: 0.5;
  cursor: pointer;
}

.color-selector-item > div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 12px;
}

.color-selector.disabled > .color-selector-item > div {
  cursor: not-allowed !important;
}

.color-selector-item:hover > div,
.color-selector-item.selected > div {
  width: 24px;
  height: 24px;
  border: 2px solid $text-color-1;
  opacity: 1;
}

.color-selector.disabled > .color-selector-item:hover > div {
  width: 28px !important;
  height: 28px !important;
  border: 0px !important;
  opacity: 0.5 !important;
}
</style>
