<template>
  <div class="teacher-calendar navigation-top-app">
    <navigation-top-app
      :app="app"
      :back="back"
    >
      <template v-slot:center>
        <div id="navigation-center">
          <template v-if="step === 1">
            {{ app.strings.practice.create.select_student }}
          </template>
          <template v-else>
            {{ app.strings.practice.create.config_class }}
          </template>
        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="teacher-create-content" v-if="show">
        <template v-if="loading === false && step === 1">
          <student-list
            :app="app"
            :students="student.data"
            :search="true"
            @onSelect="(student) => {
              studentSelect(student)
            }"></student-list>
        </template>
        <template v-if="loading === false && step >= 2">
          <div class="student-data">
            <img src="@/assets/images/avatar.svg" />
            <div>
              {{ practice.form.student.first_name }} {{ practice.form.student.last_name_1 }} {{ practice.form.student.last_name_2 }}
            </div>
            <div>
              {{ practice.form.student.code }}
            </div>
            <template v-if="step >= 4 && practice.form.id === 0">
              <div v-if="balance >= 0">
                {{ app.strings.practice.create.balance_remain_1 }} <b>{{ balance }}</b> {{ app.strings.practice.create.balance_remain_2 }}
              </div>
              <div v-else>
                {{ app.strings.practice.create.balance_remain_3 }} <b>{{ balance * -1 }}</b> {{ app.strings.practice.create.balance_remain_2 }}
              </div>
            </template>
          </div>
        </template>
        <template v-if="loading === false && step >= 2 && step < 5">
          <div class="practice-quantiy">
            <div class="form-label">
              {{ app.strings.practice.create.select_quantity }}
            </div>
            <div class="animated-selector" :class="{open: selectors.quantity}" :style="'height: ' + (selectors.quantity ? (maxQuantity + 1) * 50 : 50) + 'px;'">
              <div class="selected" v-on:click="selectors.quantity = !selectors.quantity">
                <template v-if="practice.form.quantity > 0">
                  {{ app.strings.practice.create.quantity[practice.form.quantity-1] }}
                </template>
                <template v-else>
                  {{ app.strings.practice.create.click_indicator }}
                </template>
              </div>
              <div class="options">
                <div v-for="i in maxQuantity" v-on:click="practice.form.quantity = i; selectors.quantity = false; updateStep(3);" v-bind:key="i" :style="'top: ' + (selectors.quantity ? i * 50 : 0) + 'px;'">
                  {{ app.strings.practice.create.quantity[i-1] }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="loading === false && step >= 3 && step < 5">
          <div class="practice-type">
            <div class="form-label">
              {{ app.strings.practice.create.select_type }}
            </div>
            <div class="animated-selector" :class="{open: selectors.type}" :style="'height: ' + (selectors.type ? (types.data.length + 1) * 50 : 50) + 'px;'">
              <div class="selected" v-on:click="selectors.type = !selectors.type">
                <template v-if="practice.form.type.id !== 0">
                  <b>{{ practice.form.type.license }}</b>, {{ practice.form.type.name }} | {{ practice.form.type.time }}min, {{ practice.form.type.price }}€
                </template>
                <template v-else>
                  {{ app.strings.practice.create.click_indicator }}
                </template>
              </div>
              <div class="options">
                <div v-for="(type, index) in types.data" v-on:click="practice.form.type = type; selectors.type = false; updateStep(4);" v-bind:key="type.id" :style="'top: ' + (selectors.type ? (index + 1) * 50 : 0) + 'px'">
                  <b>{{ type.license }}</b>, {{ type.name }} | {{ type.time }}min, {{ type.price }}€
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="loading === false && step >= 4 && step < 5">
          <div class="practice-vehicle">
            <div class="form-label">
              {{ app.strings.practice.create.select_vehicle }}
            </div>
            <div class="animated-selector" :class="{open: selectors.vehicle}" :style="'height: ' + (selectors.vehicle ? (vehicle.data.length + 1) * 50 : 50) + 'px;'">
              <div class="selected" v-on:click="selectors.vehicle = !selectors.vehicle">
                <template v-if="practice.form.vehicle.id !== 0">
                  {{ practice.form.vehicle.name }}
                </template>
                <template v-else>
                  {{ app.strings.practice.create.click_indicator }}
                </template>
              </div>
              <div class="options">
                <div v-for="(vehicle, index) in vehicle.data" v-on:click="practice.form.vehicle = vehicle; selectors.vehicle = false; updateStep(5, true);" v-bind:key="vehicle.id" :style="'top: ' + (selectors.vehicle ? (index + 1) * 50 : 0) + 'px'">
                  {{ vehicle.name }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="loading === false && step === 5">
          <div class="form-label">
            {{ app.strings.practice.create.select_day }}
          </div>

          <calendar-small
            id="calendar"
            :class="{show: calendar.open}"
            :app="app"
            :title="true"
            :hilighted="{
              day: this.calendar.day,
              month: this.calendar.month
            }"
            @onDayClick="(day) => {
              this.calendar.day = day
            }"
            @onMonthMove="(month) => {
              this.calendar.month = month
            }"
            @onYearMove="(year) => {
              this.calendar.year = year
            }"
          ></calendar-small>

          <div class="form-label">
            {{ app.strings.practice.create.select_time }}
          </div>

          <div class="text-center">
            <vue-timepicker
              v-model="practice.form.time"
              drop-direction="auto"
              :minute-interval="15"
              @change="timeSelected()" hide-clear-button></vue-timepicker>
          </div>

          <div id="availability-button" v-if="hour !== -1 && minute !== -1">
            <button class="h-button w-100 default-2" v-on:click="check()" v-if="!loading">
              {{ app.strings.practice.create.check }}
            </button>
            <button class="h-button w-100 default-2 disabled" v-else>
              <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
            </button>
          </div>
        </template>
        <template v-if="loading === false && step === 6">
          <div class="practice-resume">
            <div class="practice-resume-item" v-on:click="step = 1">
              <div>
                <font-awesome-icon class="icon" :icon="['fas', 'user']" />
              </div>
              <div>
                {{ practice.form.student.first_name }} {{ practice.form.student.last_name_1 }} {{ practice.form.student.last_name_2 }}
              </div>
            </div>
            <div class="practice-resume-item" v-on:click="step = 4">
              <div class="practice-resume-item-price">
                <div>
                  {{ practice.form.type.price }}€
                </div>
              </div>
              <div>
                {{ practice.form.type.name }} - {{ practice.form.type.price }} {{ app.strings.common.minutes }}
              </div>
            </div>
            <div class="practice-resume-item" v-on:click="step = 4">
              <div>
                <font-awesome-icon class="icon" :icon="['fas', 'layer-group']" />
              </div>
              <div v-if="practice.form.quantity === 1">
                {{ practice.form.quantity }} {{ app.strings.practice.create.class }}
              </div>
              <div v-else>
                {{ practice.form.quantity }} {{ app.strings.practice.create.classes }}
              </div>
            </div>
            <div class="practice-resume-item" v-on:click="step = 4">
              <div>
                <font-awesome-icon class="icon" :icon="['fas', 'car']" />
              </div>
              <div>
                {{ practice.form.vehicle.name }}
              </div>
            </div>
            <div class="practice-resume-item" v-on:click="step = 5">
              <div>
                <font-awesome-icon class="icon" :icon="['far', 'calendar-alt']" />
              </div>
              <div>
                {{ resume.date }}
              </div>
            </div>
            <div class="practice-resume-item" v-on:click="step = 5">
              <div>
                <font-awesome-icon class="icon" :icon="['far', 'clock']" />
              </div>
              <div>
                {{ resume.time }}
              </div>
            </div>
          </div>

          <div class="form-label">
            {{ app.strings.practice.create.observations }}
          </div>

          <textarea id="observations" class="h-textarea no-resize w-100 min-h-100" v-model="practice.form.observations">
          </textarea>

          <br>

          <button class="h-button w-100 default-2" v-on:click="save()" v-if="!loading">
            {{ app.strings.common.save }}
          </button>
          <button class="h-button w-100 default-2 disabled" v-else>
            <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
          </button>
        </template>
        <template v-if="loading === true">
          <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app-teacher-practice-create-view',
  components: {
  },
  data () {
    return {
      show: false,
      step: 1,
      maxQuantity: 3,
      practice: {
        loading: false,
        form: {
          id: 0,
          student: {

          },
          quantity: 0,
          type: {
            id: 0,
            name: '',
            price: 0,
            time: 0
          },
          vehicle: {
            id: 0,
            name: ''
          },
          time: '',
          observations: ''
        }
      },
      teacher: {
        loading: false
      },
      student: {
        loading: false,
        data: []
      },
      types: {
        loading: false,
        data: []
      },
      vehicle:{
        loading: false,
        data: []
      },
      calendar: {
        day: 0,
        month: 0,
        year: 0
      },
      selectors: {
        quantity: false,
        type: false,
        vehicle: false
      },
      checked: {
        student: false,
        teacher: false,
        vehicle: false
      },
      allowed: {
        student: false,
        teacher: false,
        vehicle: false
      }
    }
  },
  computed: {
    loading () {
      if (this.student.loading) {
        return true
      }

      if (this.types.loading) {
        return true
      }

      if (this.vehicle.loading) {
        return true
      }

      if (this.practice.loading) {
        return true
      }

      return false
    },
    hour () {
      let time = this.practice.form.time.split(':')

      if (time.length === 0) {
        return -1
      }

      let hour = time[0]

      if (isNaN(hour)) {
        return -1
      }

      return parseInt(hour)
    },
    minute () {
      let time = this.practice.form.time.split(':')

      if (time.length === 0) {
        return -1
      }

      let minute = time[1]

      if (isNaN(minute)) {
        return -1
      }

      return parseInt(minute)
    },
    resume () {
      let date = this.calendar.day + ' ' + this.app.strings.common.of + ' ' + this.app.strings.common.months[this.calendar.month] + ' ' + this.calendar.year

      let time = ''

      if (this.hour !== -1 && this.minute !== -1) {
        let start = this.practice.form.time

        let minutes = (this.hour * 60) + this.minute + parseInt(parseInt(this.practice.form.type.time) * parseInt(this.practice.form.quantity))

        let hours = (minutes / 60)
        let hourEnd = Math.floor(hours)
        minutes = (hours - hourEnd) * 60
        let minuteEnd = Math.round(minutes)

        let end = (hourEnd < 10 ? '0' : '') + hourEnd + ':' + (minuteEnd < 10 ? '0' : '') + minuteEnd

        time = this.app.strings.common.of + ' ' + start + ' ' + this.app.strings.common.to + ' ' + end + ' ' + this.app.strings.common.hours
      }

      return {
        date: date,
        time: time
      }
    },
    balance () {
      if (this.step >= 4) {
        let balance = this.practice.form.student.bag.find(b => b.practice_type === this.practice.form.type.id)
        if (balance !== undefined) {
          return balance.balance
        }
      }

      return 0
    }
  },
  props: [
    'app'
  ],
  mounted () {
    let id = this.$route.query.id

    if (id !== undefined && id !== null) {
      id = parseInt(id)
    } else {
      id = 0
    }

    if (id !== 0) {
      this.practice.form.id = id
      this.step = 6
    }

    this.show = true

    this.practiceRead()
    this.studentReadAll()
    this.typesRead()
    this.vehicleReadAll()
  },
  methods: {
    back () {
      if (this.step === 1) {
        this.$router.push({name: 'app.practice.calendar', query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
      } else if (this.step === 2) {
        this.practice.form.quantity = 0
        this.updateStep(1, true)
      } else if (this.step === 3) {
        this.practice.form.quantity = 0
        this.practice.form.type = 0
        this.updateStep(2)
      } else if (this.step === 4) {
        this.practice.form.type.id = 0
        this.updateStep(3)
      } else if (this.step === 5) {
        this.practice.form.vehicle.id = 0
        this.updateStep(4, true)
      } else if (this.step === 6) {
        this.updateStep(5, true)
      }
    },
    updateStep (step, animation) {
      if (animation === true) {
        this.show = false
        this.step = step
        setTimeout(() => {
          this.show = true
        }, 500)
      } else {
        this.step = step
      }
    },
    practiceRead () {
      if (this.practice.form.id === 0) {
        return
      }

      if (this.practice.loading) {
        return
      }

      this.practice.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.read.one + this.practice.form.id,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            let data = response.body.data.practice

            this.practice.form.student = response.body.data.student

            this.practice.form.quantity = 1
            this.practice.form.type.price = data.price
            this.practice.form.type.time = data.time

            for (let i in this.types.data) {
              let ttype = this.types.data[i]
              if (this.practice.form.type.time === ttype.time && this.practice.form.type.price === ttype.price) {
                this.practice.form.type = ttype
                break
              }
            }

            this.practice.form.vehicle = response.body.data.vehicle

            this.practice.form.observations = data.observations

            let date = new Date(data.date)

            this.calendar.day = date.getDate()
            this.calendar.month = date.getMonth()
            this.calendar.year = date.getFullYear()

            this.practice.form.time = (data.hour < 10 ? '0' : '') + data.hour + ':' + (data.minute < 10 ? '0' : '') + data.minute
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-read-one', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.practice.loading = false
      }, () => {
        this.practice.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    practiceDateUpdated () {

    },
    studentReadAll () {
      if (this.student.loading) {
        return
      }

      this.student.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.student.read.all + '?teacher=' + this.app.user.code,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.student.data = response.body.data.students
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.student.loading = false
      }, () => {
        this.student.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    studentRead (code) {
      if (this.student.loading) {
        return
      }

      this.student.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.student.read.one + code,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practice.form.student = response.body.data.student
            this.updateStep(2, true)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.student.loading = false
      }, () => {
        this.student.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    studentSelect (student) {
      this.studentRead(student.code)
    },
    typesRead () {
      if (this.types.loading) {
        return
      }

      this.types.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.type.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.types.data = response.body.data.types

            for (let i in this.types.data) {
              let ttype = this.types.data[i]
              if (this.practice.form.type.time === ttype.time && this.practice.form.type.price === ttype.price) {
                this.practice.form.type = ttype
                break
              }
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-type-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.types.loading = false
      }, () => {
        this.types.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    vehicleReadAll (vehicle, autoselectFilter) {
      if (this.vehicle.loading) {
        return
      }

      this.vehicle.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.vehicle.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.vehicle.data = response.body.data.vehicles
            if (autoselectFilter === true) {
              for (let i in this.vehicle.data) {
                if (this.vehicle.data[i].code === vehicle) {
                  this.vehicle.filter = this.vehicle.data[i]
                }
              }
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'vehicle-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.vehicle.loading = false
      }, () => {
        this.vehicle.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    timeSelected () {

    },
    check () {
      this.checkTarget('student', this.practice.form.student.code)
      this.checkTarget('teacher', this.app.user.code)
      this.checkTarget('vehicle', this.practice.form.vehicle.code)
    },
    checkTarget (target, code) {
      if (target === 'student') {
        if (this.student.loading) {
          return
        }

        this.student.loading = true
      }

      if (target === 'teacher') {
        if (this.teacher.loading) {
          return
        }

        this.teacher.loading = true
      }

      if (target === 'vehicle') {
        if (this.vehicle.loading) {
          return
        }

        this.vehicle.loading = true
      }

      this.allowed[target] = false

      let date = this.calendar.year + '-' + ((this.calendar.month + 1) < 10 ? '0' : '') + (this.calendar.month + 1) + '-' + (this.calendar.day < 10 ? '0' : '') + this.calendar.day

      let time = this.practice.form.type.time * this.practice.form.quantity

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.check,
        {
          id: this.practice.form.id,
          target: target,
          code: code,
          date: date,
          hour: parseInt(this.practice.form.time.split(':')[0]),
          minute: parseInt(this.practice.form.time.split(':')[1]),
          time: parseInt(time)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.allowed[target] = !response.body.exists
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'event-check', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        if (target === 'student') {
          this.student.loading = false
        } else if (target === 'teacher') {
          this.teacher.loading = false
        } else if (target === 'vehicle') {
          this.vehicle.loading = false
        }
        this.checkend(target)
      }, () => {
        if (target === 'student') {
          this.student.loading = false
        } else if (target === 'teacher') {
          this.teacher.loading = false
        } else if (target === 'vehicle') {
          this.vehicle.loading = false
        }
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
        this.checkend(target)
      })
    },
    checkend (target) {
      this.checked[target] = true
      if (this.checked.student === true && this.checked.teacher === true && this.checked.vehicle === true) {
        this.checked.student = false
        this.checked.teacher = false
        this.checked.vehicle = false

        if (this.allowed.student === true && this.allowed.teacher === true && this.allowed.vehicle === true) {
          this.updateStep(6, true)
        } else {
          let title = ''
          let content = ''

          if (this.allowed.student === false) {
            title = this.app.strings.practice.create.check_error.student.title
            content = this.app.strings.practice.create.check_error.student.content
          } else if (this.allowed.teacher === false) {
            title = this.app.strings.practice.create.check_error.teacher.title
            content = this.app.strings.practice.create.check_error.teacher.content
          } else if (this.allowed.vehicle === false) {
            title = this.app.strings.practice.create.check_error.vehicle.title
            content = this.app.strings.practice.create.check_error.vehicle.content
          }

          this.$swal({
            title: title,
            text: content,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: this.app.strings.common.confirm
          })
        }
      }
    },
    save (index, confirm) {
      if (index === undefined) {
        index = 0
      }

      if (this.practice.loading) {
        return
      }

      if (index === parseInt(this.practice.form.quantity)) {
        setTimeout(() => {
          this.$router.push({name: 'app.practice.calendar', query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
        }, 1000)
        return
      }

      let date = this.calendar.year + '-' + ((this.calendar.month + 1) < 10 ? '0' : '') + (this.calendar.month + 1) + '-' + (this.calendar.day < 10 ? '0' : '') + this.calendar.day

      let hour = parseInt(this.practice.form.time.split(':')[0])
      let minute = parseInt(this.practice.form.time.split(':')[1])

      if (index > 0) {
        let minutes = (parseInt(this.practice.form.time.split(':')[0]) * 60) + parseInt(this.practice.form.time.split(':')[1]) + (parseInt(this.practice.form.type.time) * parseInt(index))

        let hours = (minutes / 60)
        hour = Math.floor(hours)
        minutes = (hours - hour) * 60
        minute = Math.round(minutes)
      }

      if (this.practice.form.id === 0) {
        if (this.balance - parseInt(this.practice.form.quantity) < 0 && this.app.user.config.practice.balance === 0) {
          this.$swal({
            title: this.app.strings.practice.create.balance_negative_prohibit.title,
            text: this.app.strings.practice.create.balance_negative_prohibit.content,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: this.app.strings.common.confirm
          })
          return
        }

        if (confirm === undefined && this.balance - parseInt(this.practice.form.quantity) < 0) {
          this.$swal({
            title: this.app.strings.practice.create.balance_negative.title,
            text: this.app.strings.practice.create.balance_negative.content,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.app.strings.common.cancel,
            confirmButtonText: this.app.strings.common.confirm
          }).then((result) => {
            if (result.value) {
              this.save(index, true)
            }
          })
          return
        }
      }

      this.practice.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.create,
        {
          practice: JSON.stringify({
            id: this.practice.form.id,
            license: this.practice.form.type.license,
            circuit: this.practice.form.type.circuit,
            exam: this.practice.form.type.exam,
            student: this.practice.form.student.code,
            teacher: this.app.user.code,
            vehicle: this.practice.form.vehicle.code,
            date: date,
            hour: hour,
            minute: minute,
            time: parseInt(this.practice.form.type.time),
            price: parseFloat(this.practice.form.type.price * 1.0),
            color: this.practice.form.student.center.color,
            type_id: parseInt(this.practice.form.type.id),
            observations: this.practice.form.observations
          })
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.practice.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            index++
            this.save(index, confirm)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-create', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.practice.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";
@import "@/assets/styles/mobile-app.scss";

#teacher-create-content {
  width: 100%;
  height: 100%;
  padding: 60px 0;
  box-sizing: border-box;
  overflow: auto;
}

.student-data {
  text-align: center;
  padding: 12px;
  box-sizing: border-box;
}

.student-data > img {
  width: 100%;
  max-width: 128px;
  padding: 6px 0;
  box-sizing: border-box;
}

.student-data > div {
  padding: 6px 0;
  box-sizing: border-box;
  color: $text-color-1;
}

.student-data > div:nth-of-type(1) {
  font-size: 16px;
  font-weight: bold;
}

.student-data > div:nth-of-type(2) {
  font-size: 14px;
}

.practice-quantiy {
  margin-top: 12px;
}

#availability-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
}

.practice-resume {
  display: flex;
  flex-direction: column;
}

.practice-resume-item {
  display: flex;
  height: 60px;
  padding: 12px;
  box-sizing: border-box;
  margin-top: 12px;
  background-color: $color-primary-1;
  border-radius: 15px;
}

.practice-resume-item > div {
  display: flex;
  align-items: center;
}

.practice-resume-item > div:nth-of-type(1) {
  width: 60px;
  justify-content: center;
  color: $color-primary-1;
}

.practice-resume-item > div:nth-of-type(1) > .icon {
  color: #FFF;
  font-size: 22px;
}

.practice-resume-item > div:nth-of-type(2) {
  flex: 1;
  color: #FFFFFF;
}

.practice-resume-item-price > div {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: center;
}

#observations {
  border: 2px solid rgba($text-color-1, 0.2);
}
</style>
