import { render, staticRenderFns } from "./loader-dot.component.vue?vue&type=template&id=9d3c5428&scoped=true"
import script from "./loader-dot.component.vue?vue&type=script&lang=js"
export * from "./loader-dot.component.vue?vue&type=script&lang=js"
import style0 from "./loader-dot.component.vue?vue&type=style&index=0&id=9d3c5428&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d3c5428",
  null
  
)

export default component.exports