import PracticeCalendarView from '@/views/practice/calendar.view'

const PracticeRoutes = [
  {
    path: '/practice/calendar',
    name: 'practice.calendar',
    component: PracticeCalendarView
  }
]

export default PracticeRoutes
