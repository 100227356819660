<template>
  <div class="presentation-dashboard">
    <div class="presentation-content">
      <div class="presentation-header">
        <img src="@/assets/images/logo_aues_blue.svg" />
        <div>
          <span>{{ app.user.name }}</span> <b>&#8226;</b> {{ app.user.business.name }}
        </div>
        <div>
          <button class="h-button info" v-on:click="help()">
            <span>
              {{ app.strings.common.help }}
            </span>
          </button>
          <button class="h-button default ml-20" v-on:click="config()">
            <span>
              {{ app.strings.common.config }}
            </span>
          </button>
          <button class="h-button danger ml-20" v-on:click="exit()">
            <span>
              {{ app.strings.common.exit }}
            </span>
          </button>
        </div>
      </div>
      <div class="presentation-dashboard-content">
        <div class="presentation-dashboard-left">
          <div class="presentation-dashboard-last view-item">
            <div>
              <div>
                <font-awesome-icon :icon="['fas', 'archive']" /> {{ app.strings.presentation.dashboard.last.title }}
              </div>
              <div class="presentation-data" v-if="presentations.loading">
                <loader-dot :size="'25px'" :color_1="'rgb(45, 68, 113)'" :color_2="'rgb(45, 68, 113)'" />
              </div>
              <div class="presentation-data" v-if="!presentations.loading && presentations.data.length === 0">
                {{ app.strings.presentation.dashboard.last.empty }}
              </div>
              <div v-if="!presentations.loading && presentations.data.length > 0">
                <div class="presentation-row" v-for="presentation in presentations.data" v-bind:key="presentation.id" v-on:click="open(presentation.business_id, presentation.id)">
                  <div>
                    <p>
                      <b>
                        {{ $options.filters.fillZero(app.user.business.id, 5) + '-' + $options.filters.fillZero(presentation.id, 5) }}
                      </b>
                      <span class="badge" :class="{'badge-warning': presentation.type === 'new', 'badge-info': presentation.type === 'traffic', 'badge-danger': presentation.type === 'circuit'}">
                        {{ app.strings.presentation.dashboard.last.types[presentation.type] }}
                      </span>
                    </p>
                    <p>
                      <b v-if="presentation.dates.replace(', ', '<br>').length > 0" v-html="presentation.dates.replace(', ', '<br>')"></b>
                      <br v-if="presentation.dates.replace(', ', '<br>').length > 0">
                      <br v-if="presentation.dates.replace(', ', '<br>').length > 0">
                      {{ presentation.annotation }}
                      <br v-if="presentation.annotation_association.length > 0">
                      <br v-if="presentation.annotation_association.length > 0">
                      <b v-if="presentation.annotation_association.length > 0">{{ app.strings.presentation.dashboard.last.annotation_association }}:</b>
                      <br v-if="presentation.annotation_association.length > 0">
                      {{ presentation.annotation_association }}
                    </p>
                  </div>
                  <div>
                    <p>
                      {{ (new Date(presentation.date)) | dateFormat('DD/MM/YYYY') }}
                    </p>
                    <p>
                      <span class="badge badge-primary" v-if="[1].includes(presentation.state)">
                        {{ app.strings.presentation.dashboard.last.states[0] }}
                      </span>
                      <span class="badge badge-warning" v-if="[2,3].includes(presentation.state)">
                        {{ app.strings.presentation.dashboard.last.states[1] }}
                      </span>
                      <span class="badge badge-info" v-if="[4].includes(presentation.state)">
                        {{ app.strings.presentation.dashboard.last.states[2] }}
                      </span>
                      <span class="badge badge-danger" v-if="[5].includes(presentation.state)">
                        {{ app.strings.presentation.dashboard.last.states[3] }}
                      </span>
                      <span class="badge badge-info" v-if="[6].includes(presentation.state)">
                        {{ app.strings.presentation.dashboard.last.states[4] }}
                      </span>
                      <span class="badge badge-info" v-if="[7,8].includes(presentation.state)">
                        {{ app.strings.presentation.dashboard.last.states[5] }}
                      </span>
                      <span class="badge badge-success" v-if="[9].includes(presentation.state)">
                        {{ app.strings.presentation.dashboard.last.states[6] }}
                      </span>
                      <span class="badge badge-danger" v-if="[10].includes(presentation.state)">
                        {{ app.strings.presentation.dashboard.last.states[3] }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="presentation-dashboard-new view-item">
            <img src="@/assets/images/admin-laptop.svg" />
            <div>
              <div v-html="app.strings.presentation.dashboard.create.text"></div>
              <div>
                <button class="h-button mt-20" v-on:click="create('new')">
                  <span>
                    {{ app.strings.presentation.dashboard.create.theoretical }}
                  </span>
                </button>
                <button class="h-button mt-20" v-on:click="create('traffic')">
                  <span>
                    {{ app.strings.presentation.dashboard.create.traffic }}
                  </span>
                </button>
                <button class="h-button mt-20" v-on:click="create('circuit')">
                  <span>
                    {{ app.strings.presentation.dashboard.create.circuit }}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="presentation-dashboard-right-top down">
            <div class="presentation-dashboard-calendar view-item">
              <div>
                <div>
                  <font-awesome-icon :icon="['far', 'calendar-alt']" />
                  {{ app.strings.presentation.dashboard.calendar.title }}
                </div>
                <div>
                  {{ calendarMonthName | capitalize }}
                  <br>
                  {{ this.calendar.year }}
                </div>
              </div>
              <div>
                <calendar :year="calendar.year" :month="calendar.month" :weekStartAt="1" :events="calendar.events" :strings="app.strings.components.calendar" @monthChange="(month) => this.calendar.month = month" @yearChange="(year) => this.calendar.year = year"></calendar>
              </div>
            </div>
            <div class="presentation-dashboard-tax view-item">
              <img src="@/assets/images/cart-1.svg" />
              <div>
                <div v-html="app.strings.presentation.dashboard.tax.text"></div>
                <button class="h-button info w-100" v-on:click="$router.push({name: 'tax.list'})">
                  {{ app.strings.presentation.dashboard.tax.button }}
                </button>
              </div>
            </div>
          </div>
          <div class="presentation-dashboard-summary-counts down">
            <div class="view-item">
              <div>
                <font-awesome-icon :icon="['far', 'calendar-check']" />
              </div>
              <div v-html="app.strings.presentation.dashboard.summary.success">
              </div>
              <div>
                <animated-number :number="summary.students.success"></animated-number>
              </div>
            </div>
            <div class="view-item">
              <div>
                <font-awesome-icon :icon="['far', 'calendar-times']" />
              </div>
              <div v-html="app.strings.presentation.dashboard.summary.error">
              </div>
              <div>
                <animated-number :number="summary.students.error"></animated-number>
              </div>
            </div>
          </div>
          <div class="presentation-dashboard-stats view-item down">
            <div>
              {{ app.strings.presentation.dashboard.stats.text }}
              <p>
                {{ statsMonthName | capitalize }} {{ stats.year }}
              </p>
              <button class="h-button mt-20 w-100" v-on:click="statsMonthBack()">
                <span>
                  {{ app.strings.presentation.dashboard.stats.button }}
                </span>
              </button>
            </div>
            <div>
              <chart
                :type="'bars'"
                :scale="{
                  x: stats.total,
                  y: stats.total
                }"
                :data="[
                  {
                    value: stats.total,
                    label: app.strings.presentation.dashboard.stats.total,
                    color: 'rgb(102, 175, 210)'
                  },
                  {
                    value: stats.success,
                    label: app.strings.presentation.dashboard.stats.success,
                    color: 'rgb(110, 177, 64)'
                  },
                  {
                    value: stats.error,
                    label: app.strings.presentation.dashboard.stats.error,
                    color: 'rgb(222, 95, 122)'
                  }
                ]"
              />
            </div>
          </div>
        </div>
        <div class="presentation-dashboard-right">
          <div class="presentation-dashboard-right-top">
            <div class="presentation-dashboard-calendar view-item">
              <div>
                <div>
                  <font-awesome-icon :icon="['far', 'calendar-alt']" />
                  {{ app.strings.presentation.dashboard.calendar.title }}
                </div>
                <div>
                  {{ calendarMonthName | capitalize }}
                  <br>
                  {{ this.calendar.year }}
                </div>
              </div>
              <div>
                <calendar :year="calendar.year" :month="calendar.month" :weekStartAt="1" :events="calendar.events" :strings="app.strings.components.calendar" @monthChange="(month) => this.calendar.month = month" @yearChange="(year) => this.calendar.year = year"></calendar>
              </div>
            </div>
            <div class="presentation-dashboard-tax view-item">
              <img src="@/assets/images/cart-1.svg" />
              <div>
                <div v-html="app.strings.presentation.dashboard.tax.text"></div>
                <button class="h-button info w-100" v-on:click="$router.push({name: 'tax.list'})">
                  {{ app.strings.presentation.dashboard.tax.button }}
                </button>
              </div>
            </div>
          </div>
          <div class="presentation-dashboard-summary-counts">
            <div class="view-item">
              <div>
                <font-awesome-icon :icon="['far', 'calendar-check']" />
              </div>
              <div v-html="app.strings.presentation.dashboard.summary.success">
              </div>
              <div>
                <animated-number :number="summary.students.success"></animated-number>
              </div>
            </div>
            <div class="view-item">
              <div>
                <font-awesome-icon :icon="['far', 'calendar-times']" />
              </div>
              <div v-html="app.strings.presentation.dashboard.summary.error">
              </div>
              <div>
                <animated-number :number="summary.students.error"></animated-number>
              </div>
            </div>
          </div>
          <div class="presentation-dashboard-stats view-item">
            <div>
              {{ app.strings.presentation.dashboard.stats.text }}
              <p>
                {{ statsMonthName | capitalize }} {{ stats.year }}
              </p>
              <button class="h-button mt-20 w-100" v-on:click="statsMonthBack()">
                <span>
                  {{ app.strings.presentation.dashboard.stats.button }}
                </span>
              </button>
            </div>
            <div>
              <chart
                :type="'bars'"
                :scale="{
                  x: stats.total,
                  y: stats.total
                }"
                :data="[
                  {
                    value: stats.total,
                    label: app.strings.presentation.dashboard.stats.total,
                    color: 'rgb(102, 175, 210)'
                  },
                  {
                    value: stats.success,
                    label: app.strings.presentation.dashboard.stats.success,
                    color: 'rgb(110, 177, 64)'
                  },
                  {
                    value: stats.error,
                    label: app.strings.presentation.dashboard.stats.error,
                    color: 'rgb(222, 95, 122)'
                  }
                ]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'presentation-dashboard-view',
  components: {
  },
  data () {
    return {
      presentations: {
        loading: false,
        data: []
      },
      summary: {
        students: {
          success: 0,
          error: 0
        }
      },
      stats: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        total: 0,
        success: 0,
        error: 0
      },
      calendar: {
        loading: false,
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        events: []
      }
    }
  },
  computed: {
    calendarMonthName () {
      return new Date(2000, this.calendar.month, 1).toLocaleString('default', { month: 'long' })
    },
    statsMonthName () {
      return new Date(2000, this.stats.month, 1).toLocaleString('default', { month: 'long' })
    }
  },
  props: [
    'app'
  ],
  mounted () {
    this.presentationReadAll()
    this.presentationReadCalendar()
    this.presentationReadStats()
  },
  methods: {
    help () {
      /*this.summary.students.success = 8
      this.summary.students.error = 4
      this.stats.total = 30
      this.stats.success = 22
      this.stats.error = 8*/
    },
    config () {
      this.$router.push({name: 'presentation.config'})
    },
    exit () {
      this.app.methods.logout()
    },
    open (business_id, id) {
      if (this.app.user.role === 'assoc') {
        this.$router.push({name: 'presentation.association.update', params: {business: business_id, id: id}})
      } else {
        this.$router.push({name: 'presentation.update', params: {id: id}})
      }
    },
    create (type) {
      this.$router.push({name: 'presentation.create', params: {type: type}})
    },
    statsMonthBack () {
      if (this.stats.month === 0) {
        this.stats.month = 11
        this.stats.year--
      } else {
        this.stats.month--
      }
      this.presentationReadStats()
    },
    presentationReadAll () {
      if (this.presentations.loading) {
        return
      }
      this.presentations.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.presentation.read.all + '?page=1&limit=20',
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.presentations.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.presentations.data = response.body.data.presentations
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.presentationReadAll()
          })
        }
      }, () => {
        this.presentations.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.presentationReadAll()
        })
      })
    },
    presentationReadCalendar () {
      if (this.calendar.loading) {
        return
      }
      this.calendar.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.presentation.read.calendar,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.calendar.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            let events = []
            for (let i in response.body.data.events) {
              events.push(new Date(response.body.data.events[i]))
            }
            this.calendar.events = events
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-read-calendar', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.presentationReadCalendar()
          })
        }
      }, () => {
        this.calendar.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.presentationReadCalendar()
        })
      })
    },
    presentationReadStats () {
      if (this.stats.loading) {
        return
      }
      this.stats.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.presentation.read.stats + '?year=' + this.stats.year + '&month=' + (this.stats.month + 1),
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.stats.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            let stats = response.body.data.stats

            setTimeout(() => {
              this.stats.total = 0
              this.stats.success = 0
              this.stats.error = 0
              for (let i in stats) {
                let stat = stats[i]
                this.stats.total += stat.count
                if (stat.state === 2) {
                  this.stats.success = stat.count
                } else if (stat.state === 3) {
                  this.stats.error = stat.count
                }
              }
              this.summary.students = response.body.data.summary
            }, 1000)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-read-stats', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.presentationReadStats()
          })
        }
      }, () => {
        this.stats.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.presentationReadStats()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

#router-view {
  max-width: 1440px;
  margin: auto;
}

.presentation-content {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.presentation-header {
  display: flex;
  margin-bottom: 20px;
}

.presentation-header > img {
  height: 50px;
}

.presentation-header > div:nth-of-type(1) {
  flex: 1;
  justify-content: center;
  align-self: center;
  padding: 0 80px;
  box-sizing: border-box;
  color: $text-color-2;
}

.presentation-header > div:nth-of-type(1) > span {
  font-weight: 600;
}

.presentation-header > div:nth-of-type(1) > b {
  font-size: 20px;
  color: $color-info;
  margin: 0 10px;
}

.presentation-dashboard-content {
  display: flex;
  flex: 1;
}

.presentation-dashboard-content > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.presentation-dashboard-content > div:nth-of-type(1) {
  margin-right: 10px;
}

.presentation-dashboard-content > div:nth-of-type(2) {
  margin-left: 10px;
}

.presentation-dashboard-last {
  position: relative;
  display: flex;
  flex: 1;
  margin-bottom: 10px;
  flex-direction: column;
}

.presentation-dashboard-last > div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 20px 40px;
  box-sizing: border-box;
}

.presentation-dashboard-last > div > div:nth-of-type(1) {
  color: $text-color-1;
  font-weight: bold;
  font-size: 16px;
}

.presentation-dashboard-last > div > div:nth-of-type(1) > svg {
  font-size: 20px;
  color: $color-orange-light;
  margin-right: 5px;
}

.presentation-dashboard-last > div > div:nth-of-type(2) {
  height: 100%;
  overflow: auto;
  padding-bottom: 25px;
  box-sizing: border-box;
}

.presentation-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 14px;
}

.presentation-row {
  display: flex;
  border: 2px solid rgba($color-grey-light, 0.5);
  margin: 10px 0;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.presentation-row > div > p {
  margin: 12px 0 !important;
}

.presentation-row > div:nth-of-type(1) {
  flex: 1;
}

.presentation-row > div:nth-of-type(1) > p:nth-of-type(2) {
  font-size: 12px;
}

.presentation-row > div:nth-of-type(1) > p:nth-of-type(2) > b {
  font-weight: 600;
}

.presentation-row > div:nth-of-type(1) .badge {
  margin-left: 12px;
}

.presentation-row > div:nth-of-type(1) > p:nth-of-type(1),
.presentation-row > div:nth-of-type(2) > p:nth-of-type(1) {
  font-size: 14px;
}

.presentation-row > div:nth-of-type(2) {
  text-align: right;
  display: flex;
  flex-direction: column;
}

.presentation-row > div:nth-of-type(2) > p:nth-of-type(1) {
  font-weight: 600;
}

.presentation-row > div:nth-of-type(2) > p:nth-of-type(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.presentation-dashboard-new {
  display: flex;
  height: 250px;
  background-color: $color-orange-light !important;
  margin-top: 10px;
  padding: 0 !important;
  overflow: hidden;
}

.presentation-dashboard-new > img {
  display: block;
  height: 296px;
}

.presentation-dashboard-new > div {
  padding: 40px;
  box-sizing: border-box;
}

.presentation-dashboard-new > div > div > button {
  padding: 10px 15px;
  font-size: 12px;
}

.presentation-dashboard-new > div > div > button:nth-of-type(1) {
  background-color: $color-orange;
  color: #FFF;
}

.presentation-dashboard-new > div > div > button:nth-of-type(2) {
  background-color: $color-info;
  color: #FFF;
  margin: 0 10px;
}

.presentation-dashboard-new > div > div > button:nth-of-type(3) {
  background-color: $color-danger;
  color: #FFF;
}

.presentation-dashboard-new > div {
  color: $color-orange-light-text-2;
  font-size: 12px;

  /deep/ p {
    color: $color-orange-light-text-1;
    font-size: 16px;
    font-weight: 500;

    /deep/ b {
      color: $color-orange-light-text-1;
      font-size: 34px;
      font-weight: bold;
      margin-left: -5px;
    }
  }
}

.presentation-dashboard-right-top {
  display: flex;
  height: 300px;
}

.presentation-dashboard-calendar {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
}

.presentation-dashboard-calendar > div:nth-of-type(1) {
  display: flex;
}

.presentation-dashboard-calendar > div:nth-of-type(1) > div {
  flex: 1;
}

.presentation-dashboard-calendar > div:nth-of-type(1) > div:nth-of-type(1) {
  color: $text-color-1;
  font-size: 16px;
  font-weight: bold;
}

.presentation-dashboard-calendar > div:nth-of-type(1) > div:nth-of-type(1) > svg {
  color: rgba($color-success, 0.5);
  font-size: 20px;
}

.presentation-dashboard-calendar > div:nth-of-type(1) > div:nth-of-type(2) {
  text-align: right;
  color: $color-success;
  font-size: 20px;
  font-weight: bold;
}

.presentation-dashboard-calendar > div:nth-of-type(2) {
  flex: 1;
}

.presentation-dashboard-tax {
  position: relative;
  flex: 1;
  background-color: $color-info;
  margin-left: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.presentation-dashboard-tax > img {
  position: absolute;
  display: block;
  height: 80%;
  top: 5%;
  right: -30%;
  max-width: 85%;
}

.presentation-dashboard-tax > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.presentation-dashboard-tax > div > div {
  font-size: 18px;
  /deep/ b {
    color: $color-info;
    filter: brightness(30%);
    font-size: 35px;
    margin-left: -5px;
  }
}

.presentation-dashboard-summary-counts {
  display: flex;
  margin-top: 20px;
}

.presentation-dashboard-summary-counts > div {
  display: flex;
  flex: 1;
  height: 90px;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.presentation-dashboard-summary-counts > div > div {
  align-self: center;
}

.presentation-dashboard-summary-counts > div > div:nth-of-type(1) {
  font-size: 50px;
  padding: 0 10px;
  box-sizing: border-box;
}

.presentation-dashboard-summary-counts > div > div:nth-of-type(2) {
  font-size: 14px;
  padding: 0 10px;
  box-sizing: border-box;
  font-weight: 600;
  /deep/ b {
    font-size: 28px;
    color: $text-color-1 !important;
  }
}

.presentation-dashboard-summary-counts > div > div:nth-of-type(3) {
  font-size: 50px;
  padding: 0 10px;
  font-weight: bold;
  box-sizing: border-box;
  filter: brightness(40%);
}

.presentation-dashboard-summary-counts > div:nth-of-type(1) {
  margin-right: 10px;
  background-color: rgba($color-success, 0.5);
}

.presentation-dashboard-summary-counts > div:nth-of-type(1) > div:nth-of-type(1),
.presentation-dashboard-summary-counts > div:nth-of-type(1) > div:nth-of-type(2),
.presentation-dashboard-summary-counts > div:nth-of-type(1) > div:nth-of-type(3) {
  color: $color-success;
}

.presentation-dashboard-summary-counts > div:nth-of-type(2) {
  margin-left: 10px;
  background-color: rgba($color-danger, 0.5);
}

.presentation-dashboard-summary-counts > div:nth-of-type(2) > div:nth-of-type(1),
.presentation-dashboard-summary-counts > div:nth-of-type(2) > div:nth-of-type(2),
.presentation-dashboard-summary-counts > div:nth-of-type(2) > div:nth-of-type(3) {
  color: $color-danger;
}

.presentation-dashboard-stats {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.presentation-dashboard-stats > div {
  flex: 1;
}

.presentation-dashboard-stats > div:nth-of-type(1) {
  color: $text-color-1;
  font-size: 16px;
  font-weight: 500;
  padding: 0 20px;
  p {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
}

.presentation-dashboard-stats > div:nth-of-type(1) > button {
  background-color: $color-grey-1;
}

.presentation-dashboard-stats > div:nth-of-type(2) {
  height: 100%;
}

@media (max-width: 1279px) {
  .presentation-dashboard-last.view-item {
    min-height: 500px;
  }

  .presentation-dashboard-right {
    display: none !important;
  }

  .presentation-dashboard-right-top.down {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .presentation-dashboard-tax > img {
    height: 100% !important;
    max-width: 200% !important;
  }

  .presentation-dashboard-stats.view-item.down {
    margin-bottom: 25px;
  }
}

@media (min-width: 1279px) {
  .presentation-dashboard-right-top.down {
    display: none !important;
  }

  .presentation-dashboard-summary-counts.down {
    display: none !important;
  }

  .presentation-dashboard-stats.view-item.down {
    display: none !important;
  }
}
</style>
