<template>
  <div class="teacher-home navigation-top-app">
    <navigation-top-app :app="app">
    </navigation-top-app>
    <transition name="fade">
      <div id="teacher-home-content" v-if="show">
        <div class="teacher-data">
          <img src="@/assets/images/avatar.svg" />
          <div>
            {{ app.user.name }}
          </div>
        </div>
        <menu-vertical
          :app="app"
          :color="'blue'"
          :bold="true"
          :padding="{
            top: 0,
            sides: 0
          }"
          :options="[
            {
              type: 'button',
              icon: ['fas', 'clipboard-list'],
              label: app.strings.app.teacher.home.menu.calendar,
              action: () => {
                openAnimated('calendar')
              }
            },
            {
              type: 'button',
              icon: ['fas', 'fingerprint'],
              label: app.strings.app.teacher.home.menu.biometric,
              action: () => {
                openAnimated('biometric')
              }
            },
            {
              type: 'button',
              icon: ['fas', 'list'],
              label: app.strings.app.teacher.home.menu.students,
              action: () => {
                openAnimated('student.list')
              }
            },
            {
              type: 'button',
              style: 'background-color: rgb(207, 69, 92);',
              icon: ['fas', 'power-off'],
              label: app.strings.app.teacher.home.menu.logout,
              action: () => {
                this.app.methods.logout()
              }
            }
          ]"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app-teacher-home-view',
  components: {
  },
  data () {
    return {
      show: false
    }
  },
  computed: {

  },
  props: [
    'app'
  ],
  mounted () {
    this.show = true
  },
  methods: {
    openAnimated (target) {
      this.show = false
      setTimeout(() => {
        this.open(target)
      }, 500)
    },
    open (target) {
      switch (target) {
        case 'calendar':
          this.$router.push({name: 'app.practice.calendar', query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          break;

        case 'student.list':
          this.$router.push({name: 'app.student.list', query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          break;

        case 'biometric':
          this.$router.push({name: 'app.teacher.biometric.courses', query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";
@import "@/assets/styles/mobile-app.scss";

.teacher-home {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

#teacher-home-content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 85px 25px 25px 25px;
  box-sizing: border-box;
  flex-direction: column;
}

.teacher-data {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  text-align: center;
}

.teacher-data > img {
  width: 100%;
  max-width: 128px;
  padding: 6px 0;
  box-sizing: border-box;
}

.teacher-data > div {
  font-size: 22px;
  color: $text-color-1;
  padding: 24px 12px;
  box-sizing: border-box;
}
</style>
