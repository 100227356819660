const axios = require('axios')
const LRUCache = require('lru-cache')
const MODEL_URL = '/plugins/face-api/models'

class FaceApi {

  constructor (url = '', crossorigin = true) {
    this.loaded = false
    this.loading = {
      state: 0,
      states: 0,
      percent : 0
    }
    this.timeInMs = 0
    this._faCache = {}
    this.load(url, crossorigin)
  }

  static get MODEL_URL () {
    return MODEL_URL;
  }

  async load (url, crossorigin) {
    /*
    tiny_face_detector_model-weights_manifest.json
    face_landmark_68_model-weights_manifest.json
    face_recognition_model-weights_manifest.json
    */

    this.loaded = false

    if (crossorigin) {
      console.log('Loading face-api with crossorigin...')

      this.loading.states = 3

      await this.getCurrentFaceDetectionNet().load(url + FaceApi.MODEL_URL)
      this.loading.state++

      await window.faceapi.loadFaceLandmarkModel(url + FaceApi.MODEL_URL)
      this.loading.state++

      await window.faceapi.loadFaceRecognitionModel(url + FaceApi.MODEL_URL)
      this.loading.state++
    } else {
      console.log('Loading face-api without crossorigin...')

      this.loading.states = 6

      // const throttleAdapterEnhancer = require('axios-extensions').throttleAdapterEnhancer
      const cacheAdapterEnhancer = require('axios-extensions').cacheAdapterEnhancer
      const retryAdapterEnhancer = require('axios-extensions').retryAdapterEnhancer

      let options = {
        adapter: retryAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, {
          defaultCache: new LRUCache({
            maxAge: 7 * 24 * 60 * 60 * 1000,
            max: 100
          })
        }))
      }

      const axiosCustom = axios.create(options)

      // Request tiny_face_detector_model.weights
      let res = await axiosCustom.get(url + FaceApi.MODEL_URL + '/tiny_face_detector_model.weights', { responseType: 'arraybuffer', cache: true, retryTimes: 3, onDownloadProgress: (progressEvent) => {
         let percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total)
         this.loading.percent = percentCompleted
      }})
      let weights = new Float32Array(res.data)
      this.loading.state++

      await this.getCurrentFaceDetectionNet().load(weights)
      this.loading.state++

      // Request face_landmark_68_model.weights
      res = await axiosCustom.get(url + FaceApi.MODEL_URL + '/face_landmark_68_model.weights', { responseType: 'arraybuffer', cache: true, retryTimes: 3, onDownloadProgress: (progressEvent) => {
         let percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total)
         this.loading.percent = percentCompleted
      }})
      weights = new Float32Array(res.data)
      this.loading.state++

      await window.faceapi.loadFaceLandmarkModel(weights)
      this.loading.state++

      // Request face_recognition_model.weights
      res = await axiosCustom.get(url + FaceApi.MODEL_URL + '/face_recognition_model.weights', { responseType: 'arraybuffer', cache: true, retryTimes: 3, onDownloadProgress: (progressEvent) => {
         let percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total)
         this.loading.percent = percentCompleted
      }})
      weights = new Float32Array(res.data)
      this.loading.state++

      await window.faceapi.loadFaceRecognitionModel(weights)
      this.loading.state++
    }

    this.loaded = true
  }

  updateTimeStats (timeInMs) {
    this.timeInMs = timeInMs
  }

  isFaceDetectionModelLoaded () {
    return !!this.getCurrentFaceDetectionNet().params
  }

  getCurrentFaceDetectionNet () {
    return window.faceapi.nets.tinyFaceDetector
  }

  getFaceDetectorOptions () {
    // tiny_face_detector options
    let inputSize = 512
    let scoreThreshold = 0.5

    return new window.faceapi.TinyFaceDetectorOptions({
      inputSize,
      scoreThreshold
    })
  }

  async compareFaces (face1, face2, accuracy, cache = false) {
    const distance = this.getFaceDistance(face1, face2, cache)

    if (distance > accuracy) {
      return false
    } else {
      return true
    }
  }

  async getFaceDistance (face1, face2, cache = false) {
    let detection1 = null
    if (cache === true && face1 in this._faCache) {
      detection1 = this._faCache[face1]
    } else {
      detection1 = await window.faceapi.detectAllFaces(await window.faceapi.fetchImage(face1), this.getFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptors()
    }

    let detection2 = null
    if (cache === true && face2 in this._faCache) {
      detection2 = this._faCache[face2]
    } else {
      detection2 = await window.faceapi.detectAllFaces(await window.faceapi.fetchImage(face2), this.getFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptors()
    }

    if (cache === true) {
      this._faCache[face1] = detection1
      this._faCache[face2] = detection2
    }

    return window.faceapi.utils.round(window.faceapi.euclideanDistance(detection1[0].descriptor, detection2[0].descriptor))
  }

  async getFaceDetectionDistance (detection1, detection2) {
    return window.faceapi.utils.round(window.faceapi.euclideanDistance(detection1.descriptor, detection2.descriptor))
  }

  async getFaceDetection (face) {
    let detection = await window.faceapi.detectAllFaces(await window.faceapi.fetchImage(face), this.getFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptors()
    if (detection.length > 0) {
      return detection[0]
    } else {
      return null
    }
  }

  clearCache () {
    this._faCache = {}
  }
}

module.exports = FaceApi
