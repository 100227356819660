import PresentationDashboardView from '@/views/presentation/dashboard.view'
import PresentationConfigView from '@/views/presentation/config.view'
import PresentationCreateView from '@/views/presentation/create.view'

const PresentationRoutes = [
  {
    path: '/presentation/dashboard',
    name: 'presentation.dashboard',
    component: PresentationDashboardView
  },
  {
    path: '/presentation/config',
    name: 'presentation.config',
    component: PresentationConfigView
  },
  {
    path: '/presentation/create/:type',
    name: 'presentation.create',
    component: PresentationCreateView
  },
  {
    path: '/presentation/update/:id',
    name: 'presentation.update',
    component: PresentationCreateView
  },
  {
    path: '/presentation/association/update/:business/:id',
    name: 'presentation.association.update',
    component: PresentationCreateView
  }
]

export default PresentationRoutes
