<template>
  <div id="navigation-top-app" :class="{iphonex: iphonex}">
    <div>
      <div class="back">
        <font-awesome-icon :icon="['fas', 'chevron-left']" v-on:click="_back()" v-if="back" />
      </div>
      <div class="center">
        <slot name="center"></slot>
      </div>
      <div class="right">
        <template v-if="right">
          <font-awesome-icon :icon="right.icon" v-on:click="right.action()" v-if="right.action !== null" />
          <font-awesome-icon :icon="right.icon" v-else />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation-top-app-component',
  data () {
    return {
      iphonex: false
    }
  },
  props: [
    'app',
    'back',
    'right'
  ],
  mounted () {
    if (this.app !== undefined) {
      if (this.app.config !== undefined) {
        this.iphonex = this.app.config.style.iphonex
      }
    }
  },
  methods: {
    _back () {
      if (this.back) {
        this.back()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#navigation-top-app {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: $color-primary-1;
  z-index: 1000;
}

#navigation-top-app.iphonex {
  padding-top: 20px;
}

#navigation-top-app > div {
  display: flex;
  width: 100%;
  height: 100%;
}

#navigation-top-app > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.back {
  width: 30px;
}

.center {
  flex: 1;
  justify-content: left !important;
  color: #FFFFFF;
  font-size: 20px;
}

.right {
  width: 30px;
}

.back > svg,
.right > svg {
  cursor: pointer;
  color: #FFFFFF;
  font-size: 18px;
}
</style>
