import Vue from 'vue'

import moment from 'moment'

Vue.mixin({
  filters: {
    truncate (value, max, truncate) {
      if (truncate === undefined) {
        truncate = '...'
      }

      if (value === undefined) {
        return ''
      } else if (value.length < max) {
        return value
      } else {
        return value.substring(0, max) + truncate
      }
    },
    fillZero (value, size) {
      size += 1
      value = value + ''
      let zeroCount = size - value.length
      if (zeroCount > 0) {
        let zero = ''
        for (let i = 0; i < zeroCount; i++) {
          zero += '0'
        }
        return zero + value
      }
      return value
    },
    capitalize (s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    prettySize (bytes) {
      return this.prettySize(bytes)
    }
  },
  methods: {
    prettySize (bytes) {
      if (bytes === 0) return '0 Bytes'

      const k = 1024
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    },
    openWhatsapp (number) {
      number = number.replace('+34', '')
      if (typeof window.orientation !== 'undefined') {
        window.open('https://api.whatsapp.com/send?phone=+34' + number, '_blank')
      } else {
        window.open('https://web.whatsapp.com/send?l=es&phone=34' + number, '_blank')
      }
    },
    showError (strings, target, error, onConfirm) {
      if (error === undefined) {
        error = ''
      }
      this.$swal({
        title: strings.errors[target].title,
        text: strings.errors[target].content + (error.length > 0 ? ' (' + error + ')' : ''),
        icon: 'error'
      }).then(() => {
        if(onConfirm !== undefined) {
          onConfirm()
        }
      })
    }
  }
})

// LocalStorage
Vue.mixin({
  filters: {},
  methods: {
    localStorageSize () {
      let _lsTotal = 0,
      _xLen, _x
      for (_x in localStorage) {
        if (Object.prototype.hasOwnProperty.call(localStorage, _x)) {
        // if (!localStorage.hasOwnProperty(_x)) {
          continue
        }
        _xLen = ((localStorage[_x].length + _x.length) * 2);
        _lsTotal += _xLen
      }
      return this.prettySize(_lsTotal)
    }
  }
})

// Files
Vue.mixin({
  filters: {},
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
    },
    base64ToArrayBuffer (base64) {
      let binary_string = window.atob(base64);
      let len = binary_string.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    base64ToFloat32Array (base64) {
      let blob	= window.atob(base64)	// Base64 string converted to a char array
      let fLen	= blob.length / Float32Array.BYTES_PER_ELEMENT						// How many floats can be made, but be even
      let dView	= new DataView( new ArrayBuffer(Float32Array.BYTES_PER_ELEMENT) )	// ArrayBuffer/DataView to convert 4 bytes into 1 float.
      let fAry	= new Float32Array(fLen)											// Final Output at the correct size
      let p		= 0																// Position

      for(var j=0; j < fLen; j++){
        p = j * 4
        dView.setUint8(0,blob.charCodeAt(p))
        dView.setUint8(1,blob.charCodeAt(p+1))
        dView.setUint8(2,blob.charCodeAt(p+2))
        dView.setUint8(3,blob.charCodeAt(p+3))
        fAry[j] = dView.getFloat32(0,true)
      }

      return fAry
    }
  }
})

// Date and time
Vue.mixin({
  filters: {
    normalizeDate (date, format) {
      if (format === undefined) {
        format = 'DD/MM/YYYY HH:mm:ss'
      }
      return moment(String(date)).format(format)
    },
    normalizeTime (time, letters) {
      if (letters === undefined) {
        letters = true
      }

      time = Number(time)
      let h = Math.floor(time / 3600)
      let m = Math.floor(time % 3600 / 60)
      let s = Math.floor(time % 3600 % 60)

      let hDisplay = h
      let mDisplay = m
      let sDisplay = s

      if (letters) {
        hDisplay += 'h, '
        mDisplay += 'm, '
        sDisplay += 's, '
      } else {
        hDisplay = (h < 10 ? '0' : 0) + hDisplay
        mDisplay = (m < 10 ? '0' : 0) + mDisplay
        sDisplay = (s < 10 ? '0' : 0) + sDisplay

        hDisplay += ':'
        mDisplay += ':'
      }

      return hDisplay + mDisplay + sDisplay
    },
    dateDiff (date) {
      let today = new Date()
      let date_1 = moment(date)
      let date_2 = moment(today)
      let days = date_1.diff(date_2, 'days')
      if (days > 1) {
        return days + ' días'
      } else {
        return days + ' día'
      }
    },
    dateParse (string, format) {
      if (format === undefined) {
        format = 'DD/MM/YYYY HH:mm:ss'
      }
      return moment(string, format).toDate()
    },
    dateFormat (string, format) {
      if (format === undefined) {
        format = 'DD/MM/YYYY HH:mm:ss'
      }
      return moment(String(string)).format(format)
    }
  },
  methods: {
    moment () {
      return moment()
    },
    dateParse (string, format) {
      return moment(string, format).toDate()
    },
    dateFormat (date, format) {
      if (format === undefined) {
        format = 'DD/MM/YYYY HH:mm:ss'
      }
      return moment(String(date)).format(format)
    },
    diffSeconds (date1, date2) {
      let date_start = date1
      if (!(date1 instanceof Date)) {
        date_start = this.pickerToDate(date1)
      }

      let date_end = date2
      if (!(date2 instanceof Date)) {
        date_end = this.pickerToDate(date2)
      }

      return Math.floor((date_end - date_start)/1000)
    },
    diffMinutes (date1, date2) {
      let date_start = date1
      if (!(date1 instanceof Date)) {
        date_start = this.pickerToDate(date1)
      }

      let date_end = date2
      if (!(date2 instanceof Date)) {
        date_end = this.pickerToDate(date2)
      }

      return Math.floor((date_end - date_start)/1000)/60
    },
    diffDays (date) {
      let today = new Date()
      let date_1 = moment(date)
      let date_2 = moment(today)
      let days = date_1.diff(date_2, 'days')
      return days
    },
    pickerToDate (date) {
      if (date instanceof Date) {
        return date
      }
      let date_date = date.split(' ')[0].split('-')
      let date_time = ['00', '00']
      if (date.split(' ').length > 1) {
        date_time = date.split(' ')[1].split(':')
      }
      return new Date(parseInt(date_date[2]), parseInt(date_date[1]) - 1, parseInt(date_date[0]), date_time[0], date_time[1], '00')
    },
    dateDiff (date) {
      let today = new Date()
      let date_1 = moment(date)
      let date_2 = moment(today)
      let days = date_1.diff(date_2, 'days')
      if (days > 1) {
        return days + ' días'
      } else {
        return days + ' día'
      }
    },
    dateFineDiff (date, direction) {
      let today = new Date()

      if (today > date && direction === 1) {
        return [0,0,0,0]
      }

      let delta = Math.abs(date - today)/1000

      let days = Math.floor(delta/86400)
      delta -= days * 86400

      let hours = Math.floor(delta/3600)%24
      delta -= hours * 3600

      let minutes = Math.floor(delta/60)%60
      delta -= minutes * 60

      let seconds = Math.floor(delta % 60)

      return [days, hours, minutes, seconds]
    },
    timeDiff (date) {
      let time_1 = moment(date)
      let time_2 = moment(new Date())
      let minutes = time_2.diff(time_1, 'minutes')
      let modM = minutes % 60
      let hours = Math.trunc(minutes / 60)
      let modH = hours % 24
      let days = Math.trunc(hours / 24)

      return (days > 0 ? days + 'd, ' : '') + (modH > 0 ? modH + 'h, ' : '') + modM + 'm'
    },
    daysInMonth (month, year) {
      return 32 - new Date(year, month, 32).getDate()
    },
    firstDayInMonth (month, year) {
      let day = new Date(year, month).getDay()
      if (day === 0) {
        day = 6
      } else {
        day--
      }
      return day
    },
    currentWeek(start, week, daysTotal) {
      let date = moment()

      let weekStart = date.clone().startOf('week')

      let days = []

      let move = week * daysTotal

      for (let i = start; i <= ((daysTotal - 1) + start); i++) {
        days.push(moment(weekStart).add(i + (move), 'days'))
      }

      return days
    },
    compareDatesWithoutTime (date1, date2) {
      let d1 = new Date(date1)
      d1.setHours(0, 0, 0, 0)

      let d2 = new Date(date2)
      d2.setHours(0, 0, 0, 0)

      return d1.getTime() == d2.getTime()
    },
    sameDay (d1, d2) {
      return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    },
    yesterDay (d1, d2) {
      return d1.getDate() < d2.getDate()
    },
    nextDay (d1, d2) {
      return d1.getDate() > d2.getDate()
    }
  }
})
