<template>
  <div>
    <div class="menu" :class="{grey: color === 'grey', blue: color === 'blue', bold: bold}" :style="'padding: ' + padding.top + 'px ' + padding.sides + 'px;'">
      <template v-for="(option, i) in options">
        <div class="menu-item button" :class="{disabled: option.disabled}" :style="option.style + ';max-width: ' + maxWidth + 'px'" v-if="option.type === 'button'" v-bind:key="i" v-on:click="() => {
          if (option.action !== undefined && (option.disabled === false || option.disabled === undefined || option.disabled === null)) {
            option.action()
          }
        }">
          <font-awesome-icon class="icon" :icon="option.icon" v-if="option.icon !== undefined" />
          <span>
            {{ option.label }}
          </span>
        </div>

        <div class="menu-item input" :style="'max-width: ' + maxWidth + 'px'" v-if="option.type === 'input'" v-bind:key="i" v-on:click="openInput(option.input)">
          <font-awesome-icon class="icon" :icon="option.icon" v-if="option.icon !== undefined" />
          <span>
            {{ option.label }}
          </span>
          <font-awesome-icon class="icon chevron" :icon="['fas', 'chevron-right']" />
        </div>

        <div class="menu-item select" :style="'max-width: ' + maxWidth + 'px'" v-if="option.type === 'select'" v-bind:key="i" v-on:click="openSelect(option.input)">
          <font-awesome-icon class="icon" :icon="option.icon" v-if="option.icon !== undefined" />
          <span>
            {{ option.label }}
          </span>
          <font-awesome-icon class="icon chevron" :icon="['fas', 'chevron-down']" />
        </div>
      </template>
    </div>

    <div class="overlay" v-if="overlay.input">
      <input :type="input.type" class="overlay-input" v-model="input.model" />
      <p>
        <button class="h-button w-100 default-2" v-on:click="changeInput()">
          {{ app.strings.components.menu.vertical.confirm }}
        </button>
      </p>
    </div>

    <div class="overlay white" v-if="overlay.select" v-on:click="overlay.select = false">
      <div class="menu blue">
        <div v-for="option in input.options" class="menu-item input" :style="'max-width: ' + maxWidth + 'px'" v-bind:key="option.key" v-on:click="changeSelect(option)">
          <span>
            {{ option.key }}
          </span>
          <font-awesome-icon class="icon chevron" :icon="['fas', 'chevron-right']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'menu-vertical-component',
  data () {
    return {
      input: {
        type: 'text',
        model: ''
      },
      overlay: {
        input: false,
        select: false
      }
    }
  },
  props: {
    app: {
      type: Object,
      default: () => {
        return {}
      }
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    color: {
      type: String,
      default: () => {
        return 'blue'
      }
    },
    bold: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    padding: {
      type: Object,
      default: () => {
        return {
          top: 0,
          sides: 12
        }
      }
    },
    maxWidth: {
      type: Number,
      default: () => {
        return 300
      }
    }
  },
  methods: {
    openInput (input) {
      this.input = input
      this.overlay.input = true
    },
    changeInput () {
      this.input.change(this.input.model)
      this.overlay.input = false
    },
    openSelect (input) {
      this.input = input
      this.overlay.select = true
    },
    changeSelect (value) {
      this.input.change(value)
      this.overlay.select = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

.menu {
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
}

.menu-item {
  display: flex;
  width: 100%;
  height: 50px;
  background-color: $color-primary-1;
  border-radius: 10px;
  margin-top: 12px;
  align-items: center;
  color: #FFF;
  font-size: 16px;
  padding: 0 25px;
  box-sizing: border-box;
}

.menu-item.disabled {
  opacity: 0.5;
}

.menu.grey .menu-item {
  background-color: $color-grey-light-2 !important;
  color: $text-color-2 !important;
}

.menu.bold .menu-item {
  font-weight: bold !important;
}

.menu-item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.menu-item > .icon {
  width: 50px;
  font-size: 24px;
}

.menu-item > span {
  flex: 1;
}

.menu.blue .menu-item:nth-of-type(2) {
  background-color: rgb(70, 110, 175);
}

.menu.blue .menu-item:nth-of-type(3) {
  background-color: rgb(50, 80, 125);
}

.menu.blue .menu-item:nth-of-type(4) {
  background-color: rgb(25, 45, 75);
}

.menu-item > .icon.chevron {
  font-size: 16px !important;
}

.overlay {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5000;
  background-color: rgba(0,0,0,0.25);
  align-items: center;
  justify-content: center;
  padding: 25px;
  box-sizing: border-box;
  flex-direction: column;
}

.overlay-input {
  width: 100%;
  padding: 12px 24px;
  box-sizing: border-box;
  text-align: center;
}

.overlay.white {
  background-color: rgba(255,255,255,0.8) !important;
}
</style>
