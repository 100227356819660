<template>
  <div class="chart" :class="{bars: type === 'bars'}">
    <template v-if="type === 'bars'">
      <div v-for="e in data" v-bind:key="e.value + ' - ' + e.label">
        <div>
          <animated-number :number="!loaded ? 0 : e.value" :delay="990"></animated-number>
        </div>
        <div>
          <div :style="{'background': e.color, 'max-height': ((!loaded ? 0 : e.value) * 100)/scale.y + '%'}"></div>
        </div>
        <div>
          {{ e.label }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'chart-component',
  data () {
    return {
      loaded: false
    }
  },
  props: {
    type: {
      type: String,
      default: () => {
        return 'bars'
      }
    },
    scale: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0
        }
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.loaded = true
    }, 1000)
  },
  watch: {
    data () {
      this.loaded = false
      setTimeout(() => {
        this.loaded = true
      }, 10)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.chart.bars {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chart.bars > div {
  align-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 10px;
}

.chart.bars > div > div:nth-of-type(1) {
  color: rgb(110, 110, 110);
  font-size: 20px;
  font-weight: 600;
}

.chart.bars > div > div:nth-of-type(2) {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  padding-top: 10px;
  box-sizing: border-box;
}

.chart.bars > div > div:nth-of-type(2) > div {
  width: 100%;
  height: 100%;
  max-height: 0%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: max-height 0.99s ease-in-out;
}

.chart.bars > div > div:nth-of-type(3) {
  color: rgb(110, 110, 110);
  font-size: 12px;
  font-weight: 600;
}
</style>
