<template>
  <div class="calendar">
    <div class="calendar-left" v-on:click="pri_backMonth()">
      <font-awesome-icon :icon="['fas', 'chevron-left']" />
    </div>
    <div class="calendar-center">
      <div class="week-names">
        <div v-for="(day, dayIndex) in pri_week" v-bind:key="dayIndex">
          {{ day }}
        </div>
      </div>
      <div class="week" v-for="(week, weekIndex) in weeks" v-bind:key="weekIndex">
        <div v-for="(day, dayIndex) in week" v-bind:key="dayIndex" :class="{disabled: day.disabled, active: day.active, event: day.event}">
          {{ day.value }}
        </div>
      </div>
    </div>
    <div class="calendar-right" v-on:click="pri_nextMonth()">
      <font-awesome-icon :icon="['fas', 'chevron-right']" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'calendar-component',
  data () {
    return {
      pri_year: new Date().getFullYear(),
      pri_month: new Date().getMonth(),
      pri_events: [],
      pri_week: [],
      pri_weekStartAt: 0
    }
  },
  computed: {
    weeks () {
      let weeks = []
      let totalDays = new Date(this.pri_year, this.pri_month + 1, 0).getDate()
      let fillStartDays = new Date(this.pri_year, this.pri_month, 1).getDay() - this.pri_weekStartAt
      let lastMonthLastDay = new Date(this.pri_year, this.pri_month, 0).getDate()


      if (fillStartDays < 0) {
        fillStartDays = 7 + fillStartDays
      }

      let days = []
      for (let i = lastMonthLastDay - fillStartDays; i < lastMonthLastDay; i++) {
        days.push({
          value: i+1,
          disabled: true,
          active: false,
          event: false
        })
      }
      for (let i = 0; i < totalDays; i++) {
        let d = new Date(this.pri_year, this.pri_month, i+1)
        let event = this.pri_events.map(Number).indexOf(+d) !== -1
        days.push({
          value: i+1,
          disabled: false,
          active: i+1 === new Date().getDate() && this.pri_month === new Date().getMonth() && this.pri_year === new Date().getFullYear(),
          event: event
        })
        if (days.length === 7) {
          weeks.push(days)
          days = []
        }
      }
      if (days.length > 0) {
        if (days.length < 7) {
          let fillEndDays = 7 - days.length
          for (let i = 0; i < fillEndDays; i++) {
            days.push({
              value: i+1,
              disabled: true,
              active: false,
              event: false
            })
          }
        }
        weeks.push(days)
        days = []
      }

      return weeks
    }
  },
  props: {
    year: {
      type: Number,
      default: () => {
        return new Date().getFullYear()
      }
    },
    month: {
      type: Number,
      default: () => {
        return new Date().getMonth()
      }
    },
    weekStartAt: {
      type: Number,
      default: () => {
        return 0
      }
    },
    strings: {
      type: Object,
      default: () => {
        return {
          "week": [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
          ]
        }
      }
    },
    events: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  mounted () {
    this.pri_year = this.year
    this.pri_month = this.month
    this.pri_weekStartAt = this.weekStartAt
    if (this.pri_weekStartAt > 6) {
      this.pri_weekStartAt = 0
    }
    for (let i = this.weekStartAt; i < 7 + this.weekStartAt; i++) {
      let index = i
      if (index >= 7) {
        index = index - 7
      }
      this.pri_week.push(this.strings.week[index])
    }
    for (let i in this.events) {
      let event = this.events[i]
      event.setHours(0,0,0,0);
      this.pri_events.push(event)
    }
  },
  methods: {
    pri_backMonth () {
      if (this.pri_month === 0) {
        this.pri_month = 11
      } else {
        this.pri_month--
      }
      this.$emit('monthChange', this.pri_month)
    },
    pri_nextMonth () {
      if (this.pri_month === 11) {
        this.pri_month = 0
        this.pri_year++
        this.$emit('yearChange', this.pri_year)
      } else {
        this.pri_month++
      }
      this.$emit('monthChange', this.pri_month)
    }
  },
  watch: {
    year () {
      this.pri_year = this.year
    },
    month () {
      this.pri_month = this.month
    },
    events () {
      this.pri_events = []
      for (let i in this.events) {
        let event = this.events[i]
        event.setHours(0,0,0,0);
        this.pri_events.push(event)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.calendar {
  display: flex;
  height: 100%;
}

.calendar > div {
  display: flex;
}

.calendar > div > div {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: $text-color-1;
}

.calendar-center {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.calendar-center > div {
  display: flex;
}

.calendar-center > div > div {
  flex: 1;
  text-align: center;
  font-size: 12px;
  color: $text-color-1;
}

.calendar-left,
.calendar-right {
  width: 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: rgba($text-color-1, 0.5);
}

.calendar-left:hover,
.calendar-right:hover {
  background-color: rgba($color-grey-1, 0.2);
  color: $text-color-1;
}

.calendar .week-names {
  padding: 10px 0;
}

.calendar .week {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.calendar .week > div {
  color: $text-color-2;
  font-size: 12px;
  font-weight: bold;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar .week > div.disabled {
  opacity: 0.25 !important;
}

.calendar .week > div.active {
  background-color: rgba($color-info, 0.2);
  color: $color-info;
}

.calendar .week > div.event {
  background-color: rgba($color-success, 0.2);
  color: $color-success;
}

.calendar .week > div.active.event {
  background-color: rgba($color-warning, 0.2) !important;
  color: $color-warning !important;
}
</style>
