import Vue from 'vue'
import VueRouter from 'vue-router'

import SpeedtestView from '@/views/speedtest.view'

import HomeView from '../views/home.view.vue'

import PremiumPaymentView from '../views/premium/payment.view.vue'

import UserPasswordResetView from '../views/user/password.reset.view.vue'

import SignatureView from '../views/signature/signature.view.vue'

Vue.use(VueRouter)

let routes = [
  {
    path: '/speedtest',
    name: 'speedtest',
    component: SpeedtestView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/premium/payment',
    name: 'premium.payment',
    component: PremiumPaymentView
  },
  {
    path: '/user/password/reset/:token',
    name: 'user.password.reset',
    component: UserPasswordResetView
  },
  {
    path: '/signature/:request',
    name: 'signature',
    component: SignatureView
  }
]

/****************/
/* Presentation */
/****************/

import PresentationRoutes from './presentation.routes'
routes = routes.concat(PresentationRoutes)

/*******/
/* Tax */
/*******/

import TaxRoutes from './tax.routes'
routes = routes.concat(TaxRoutes)

/************/
/* Practice */
/************/

import PracticeRoutes from './practice.routes'
routes = routes.concat(PracticeRoutes)

/*******/
/* App */
/*******/

import AppRoutes from './app.routes'
routes = routes.concat(AppRoutes)

/*************/
/* Biometric */
/*************/

import BiometricRoutes from './biometric.routes'
routes = routes.concat(BiometricRoutes)

// Compose router

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
