var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"teacher-home navigation-top-app"},[_c('navigation-top-app',{attrs:{"app":_vm.app}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{attrs:{"id":"teacher-home-content"}},[_c('div',{staticClass:"teacher-data"},[_c('img',{attrs:{"src":require("@/assets/images/avatar.svg")}}),_c('div',[_vm._v(" "+_vm._s(_vm.app.user.name)+" ")])]),_c('menu-vertical',{attrs:{"app":_vm.app,"color":'blue',"bold":true,"padding":{
          top: 0,
          sides: 0
        },"options":[
          {
            type: 'button',
            icon: ['fas', 'clipboard-list'],
            label: _vm.app.strings.app.teacher.home.menu.calendar,
            action: () => {
              _vm.openAnimated('calendar')
            }
          },
          {
            type: 'button',
            icon: ['fas', 'fingerprint'],
            label: _vm.app.strings.app.teacher.home.menu.biometric,
            action: () => {
              _vm.openAnimated('biometric')
            }
          },
          {
            type: 'button',
            icon: ['fas', 'list'],
            label: _vm.app.strings.app.teacher.home.menu.students,
            action: () => {
              _vm.openAnimated('student.list')
            }
          },
          {
            type: 'button',
            style: 'background-color: rgb(207, 69, 92);',
            icon: ['fas', 'power-off'],
            label: _vm.app.strings.app.teacher.home.menu.logout,
            action: () => {
              this.app.methods.logout()
            }
          }
        ]}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }