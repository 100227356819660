var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"student-evolution navigation-top-app"},[(_vm.report.selected.license === '')?_c('navigation-top-app',{attrs:{"app":_vm.app,"back":() => {
      _vm.$router.push({name: 'app.student.profile', params: {code: this.student.data.code}, query: {origin: 'app', navigationLeft: 'false', token: _vm.app.token}})
    }},scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('div',{attrs:{"id":"navigation-center"}},[_vm._v(" "+_vm._s(_vm.app.strings.app.student.dgtreport.title)+" ")])]},proxy:true}],null,false,4252421122)}):_vm._e(),(_vm.report.selected.license !== '' && _vm.report.selected.test === -1)?_c('navigation-top-app',{attrs:{"app":_vm.app,"back":() => {
      _vm.report.selected.license = ''
    }},scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('div',{attrs:{"id":"navigation-center"}},[_vm._v(" "+_vm._s(_vm.app.strings.app.student.dgtreport.title)+" - "+_vm._s(_vm.report.selected.license)+" ")])]},proxy:true}],null,false,2614824606)}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && !_vm.loading)?_c('div',{attrs:{"id":"student-dgtreport-content"}},[(_vm.report.selected.license === '')?_c('list',{attrs:{"app":_vm.app,"items":_vm.licenses,"index":'value',"value":'name'},on:{"onSelect":(license) => {
        _vm.report.selected.license = license.value
        if (this.licensesWithoutCircuit.includes(license.value.toUpperCase()) === true) {
          _vm.report.selected.test = 0
          _vm.openReport()
        }
      }}}):_vm._e(),(_vm.report.selected.license !== '' && _vm.report.selected.test === -1)?_c('list',{attrs:{"app":_vm.app,"items":_vm.tests,"index":'value',"value":'name'},on:{"onSelect":(test) => {
        _vm.report.selected.test = test.value
        _vm.openReport()
      }}}):_vm._e()],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && _vm.loading)?_c('div',{attrs:{"id":"student-dgtreport-content"}},[_c('loader-dot',{attrs:{"size":'16px',"color_1":'rgb(69, 130, 207)',"color_2":'rgb(69, 130, 207)'}})],1):_vm._e()]),_c('student-dgt-report-component',{ref:"student-report",attrs:{"app":_vm.app},on:{"onClose":() => {
    _vm.report.selected.license = ''
    _vm.report.selected.test = -1
  }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }