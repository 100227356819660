import Vue from 'vue'

// JS imports

// Global components

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

import VueResource from 'vue-resource'
Vue.use(VueResource)

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2, {
  confirmButtonColor: 'rgb(73, 177, 144)',
  cancelButtonColor: 'rgb(207, 69, 92)'
})

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/es/es';
Vue.use(DatePicker)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFileAlt,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarTimes,
  faClock,
  faCommentAlt,
  faFile
} from '@fortawesome/free-regular-svg-icons'
import {
  faPencilAlt,
  faArchive,
  faUndo,
  faRedo,
  faCheck,
  faTimes,
  faCrop,
  faHandRock,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faSlidersH,
  faUser,
  faChalkboardTeacher,
  faCar,
  faExclamationTriangle,
  faInfoCircle,
  faPlus,
  faPowerOff,
  faSync,
  faSearch,
  faClipboardList,
  faFingerprint,
  faList,
  faLayerGroup,
  faMapMarkerAlt,
  faGasPump,
  faTrash,
  faChartPie,
  faPhoneSquareAlt,
  faEnvelopeSquare,
  faShoppingBag
} from '@fortawesome/free-solid-svg-icons'
import {
  faWhatsappSquare
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFileAlt)
library.add(faCalendarAlt)
library.add(faCalendarCheck)
library.add(faCalendarTimes)
library.add(faClock)
library.add(faPencilAlt)
library.add(faArchive)
library.add(faUndo)
library.add(faRedo)
library.add(faCheck)
library.add(faTimes)
library.add(faCrop)
library.add(faHandRock)
library.add(faChevronRight)
library.add(faChevronLeft)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faSlidersH)
library.add(faUser)
library.add(faChalkboardTeacher)
library.add(faCar)
library.add(faExclamationTriangle)
library.add(faInfoCircle)
library.add(faPlus)
library.add(faPowerOff)
library.add(faSync)
library.add(faSearch)
library.add(faClipboardList)
library.add(faFingerprint)
library.add(faList)
library.add(faLayerGroup)
library.add(faMapMarkerAlt)
library.add(faGasPump)
library.add(faTrash)
library.add(faCommentAlt)
library.add(faFile)
library.add(faChartPie)
library.add(faWhatsappSquare)
library.add(faPhoneSquareAlt)
library.add(faEnvelopeSquare)
library.add(faShoppingBag)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueFractionGrid from 'vue-fraction-grid'
Vue.use(VueFractionGrid,{
  container: "100%",
  gutter: "24px",
  approach: "mobile-first",
  breakpoints: {
    compact: "320px 414px"
  }
})

import vueShapeImg from 'vueshapeimg'
Vue.use(vueShapeImg)

import VueAutosuggest from 'vue-autosuggest'
Vue.use(VueAutosuggest)

import browserDetect from "vue-browser-detect-plugin"
Vue.use(browserDetect)

import VueQr from 'vue-qr'
Vue.use(VueQr)

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import Datepicker from 'vuejs-datepicker'
Vue.component('datepicker', Datepicker)

import VueTimepicker from 'vue2-timepicker'
Vue.component('vue-timepicker', VueTimepicker)

import VueNumberInput from '@chenfengyuan/vue-number-input'
Vue.component('vue-number-input', VueNumberInput)

import VueSlider from 'vue-slider-component'
Vue.component('vue-slider', VueSlider)

import VueSignaturePad from 'vue-signature-pad'
Vue.use(VueSignaturePad)

import RadialProgressBar from 'vue-radial-progress'
Vue.component('radial-progress-bar', RadialProgressBar)

// Custom components

import LoaderDotComponent from '@/components/loader-dot.component'
Vue.component('loader-dot', LoaderDotComponent)

import FileUploadComponent from '@/components/file/upload.component'
Vue.component('file-upload', FileUploadComponent)

import ChartComponent from '@/components/chart/chart.component'
Vue.component('chart', ChartComponent)

import CalendarComponent from '@/components/calendar/calendar.component'
Vue.component('calendar', CalendarComponent)

import CalendarSmallComponent from '@/components/calendar/calendar-small.component'
Vue.component('calendar-small', CalendarSmallComponent)

import AnimatedNumberComponent from '@/components/animated-number.component'
Vue.component('animated-number', AnimatedNumberComponent)

import ModalComponent from '@/components/modal/modal.component'
Vue.component('modal', ModalComponent)

import EventFormModalComponent from '@/components/calendar/event/form.modal.component'
Vue.component('event-form-modal', EventFormModalComponent)

import PracticeFormModalComponent from '@/components/practice/form.modal.component'
Vue.component('practice-form-modal', PracticeFormModalComponent)

import NavigationTopAppComponent from '@/components/navigation/navigation-top-app.component'
Vue.component('navigation-top-app', NavigationTopAppComponent)

import StudentListComponent from '@/components/student/student-list.component'
Vue.component('student-list', StudentListComponent)

import StudentDgtReportComponent from '@/components/student/student-dgt-report.component'
Vue.component('student-dgt-report-component', StudentDgtReportComponent)

import StudentEvaluationPracticeComponent from '@/components/student/student-evaluation-practice.component'
Vue.component('student-evaluation-practice-component', StudentEvaluationPracticeComponent)

import MenuVerticalComponent from '@/components/menu-vertical.component'
Vue.component('menu-vertical', MenuVerticalComponent)

import ListComponent from '@/components/list.component'
Vue.component('list', ListComponent)
