<template>
  <div class="premium-payment">
    <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" v-if="loading" />

    <form ref="redsys" name="from" :action="tpvAction" method="POST" v-show="false">
      <input type="hidden" name="Ds_SignatureVersion" :value="tpv.Ds_SignatureVersion"/>
      <input type="hidden" name="Ds_MerchantParameters" :value="tpv.Ds_MerchantParameters"/>
      <input type="hidden" name="Ds_Signature" :value="tpv.Ds_Signature"/>
    </form>

    <template v-if="order.id !== 0 && loading === false">
      <div class="order">
        <div class="order-desc">
          Se va a actualizar a una cuenta <b>PREMIUM</b>. Al finalizar el pago se actualizarán sus contenidos y podrá acceder al 100% de los test online.
        </div>
        <div class="order-ammount" v-if="order.ammount !== 0">
          {{ (order.ammount/100.0).toFixed(2) }} €
        </div>
        <div class="order-ammount-0" v-else>
          Contacta con tu autoescuela para gestionar el alta.
        </div>
        <div class="order-payment-legal" v-if="order.paid === 0 && order.ammount !== 0">
          <input type="checkbox" v-model="legal.check" /> Acepto los <span v-on:click="legal.view = true">Términos y condiciones</span>
        </div>
        <div class="order-payment-button" v-if="order.paid === 0 && order.ammount !== 0 && legal.check === true" v-on:click="pay">
          Ir al pago
        </div>
        <template v-if="order.paid === 1">
          <div class="order-paid">
            Este pedido ya ha sido abonado, si no se ha activado su cuenta correctamente contacte con su autoescuela.
          </div>
          <div class="order-payment-button disabled">
            Ir al pago
          </div>
        </template>
      </div>
    </template>

    <template v-if="action === 'confirm_ok'">
      <div class="confirm ok">
        <div></div>
        <div>
          <font-awesome-icon class="icon chevron" :icon="['fas', 'check']" />
        </div>
        <div>
          {{ app.strings.app.student.practice.bag.shop.confirm.ok }}
          <br>
          <b>{{ app.strings.app.student.practice.bag.shop.confirm.premium }}</b>
        </div>
        <div></div>
        <div></div>
      </div>
    </template>

    <template v-if="action === 'confirm_ok_refresh'">
      <div class="confirm ok">
        <div></div>
        <div>
          <font-awesome-icon class="icon chevron" :icon="['fas', 'check']" />
        </div>
        <div>
          <b>{{ app.strings.app.student.practice.bag.shop.confirm.premium }}</b>
        </div>
        <div></div>
        <div></div>
      </div>
    </template>

    <template v-if="action === 'confirm_ko'">
      <div class="confirm ko">
        <div></div>
        <div>
          <font-awesome-icon class="icon chevron" :icon="['fas', 'times']" />
        </div>
        <div>
          {{ app.strings.app.student.practice.bag.shop.confirm.ko }}
        </div>
        <div></div>
        <div></div>
      </div>
    </template>

    <div id="legal-view" v-if="legal.view === true" v-on:click="legal.view = false">
      <div v-html="this.legal.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'premium-payment-view',
  components: {
  },
  data () {
    return {
      loading: false,
      action: '',
      legal: {
        check: false,
        view: false,
        text: ''
      },
      order: {
        id: 0,
        center_id: 0,
        user_id: 0,
        ammount: 0,
        paid: 0
      },
      tpv: {
        env: 0,
        Ds_SignatureVersion: "",
        Ds_MerchantParameters: "",
        Ds_Signature: ""
      }
    }
  },
  props: [
    'app'
  ],
  computed: {
    tpvAction () {
      if (this.tpv.env === 0) {
        return 'https://sis-t.redsys.es:25443/sis/realizarPago'
      } else {
        return 'https://sis.redsys.es/sis/realizarPago'
      }
    }
  },
  mounted () {
    for (let i = 0; i <= 10; i++) {
      setTimeout(() => {
        this.app.navigationLeft(false)
      }, 100 * i)
    }
    let id = this.$route.query.order
    if (id === undefined || id === null) {
      id = 0
    }
    this.order.id = id

    this.action = this.$route.query.action

    if ((this.action === undefined || this.action === '') && this.order.id !== 0) {
      this.loadOrder()
    }
  },
  methods: {
    loadOrder () {
      if (this.loading) {
        return
      }

      this.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.premium.payment.request,
        {
          order: this.order.id
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.order = response.body.data.order
            this.tpv = response.body.data.redsys
            this.legal.text = this.tpv.legal
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'premium-payment', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    pay () {
      this.loading = true
      this.$refs.redsys.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

.premium-payment {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}

.order {
  width: 100%;
  max-width: 500px;
  padding: 25px;
  box-sizing: border-box;
  background-color: $color-background-grey;
  margin: auto;
}

.order-desc {
  padding: 12px 0;
  font-size: 16px;
  box-sizing: border-box;
}

.order-ammount {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: #FFF;
  background-color: $color-success;
  padding: 12px;
  box-sizing: border-box;
}

.order-ammount-0 {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  background-color: $color-warning;
  padding: 12px;
  box-sizing: border-box;
}

.order-payment-legal {
  padding: 25px 0;
  box-sizing: border-box;
}

.order-payment-legal > span {
  color: $color-primary-1;
  cursor: pointer;
}

.order-payment-legal > span:hover {
  color: $color-primary-2;
}

#legal-view {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 25px;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.25);
}

#legal-view > div {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  display: block;
  top: 0;
  left: 0;
  margin: 0;
  padding: 25px;
  box-sizing: border-box;
  overflow: auto;
}

.order-payment-button {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  background-color: rgba($color-primary-1, 0.5);
  padding: 12px;
  box-sizing: border-box;
  border-radius: 12px;
  cursor: pointer;
}

.order-payment-button:hover {
  background-color: rgba($color-primary-1, 1);
}

.order-payment-button.disabled {
  margin-top: 0px;
  cursor: not-allowed;
  background-color: rgba($color-danger, 0.5);
}

.order-payment-button.disabled:hover {
  background-color: rgba($color-danger, 0.5);
}

.order-paid {
  padding: 25px 0;
  font-size: 14px;
  box-sizing: border-box;
  color: $color-success;
  text-align: center;
}

.confirm {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirm.ok {
  background-color: $color-success;
}

.confirm.ko {
  background-color: $color-danger;
}

.confirm > div {
  padding: 12px 25px;
  box-sizing: border-box;
}

.confirm > div:nth-child(1) {
  flex: 1;
}

.confirm > div:nth-child(2) {
  color: #FFF;
  font-size: 100px;
}

.confirm > div:nth-child(3) {
  color: #FFF;
  font-size: 20px;
  text-align: center;
}

.confirm > div:nth-child(4) {
  color: #FFF;
  font-size: 18px;
}

.confirm > div:nth-last-of-type(1) {
  flex: 1;
}
</style>
