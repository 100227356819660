<template>
  <div>
    {{ displayNumber }}
  </div>
</template>

<script>
export default {
  name: 'animated-number-component',
  props: {
    number: {
      default: 0
    },
    delay: {
      default: 1000
    }
  },
  data () {
    return {
      displayNumber: 0,
      interval: false
    }
  },
  ready () {
    this.displayNumber = this.number ? this.number : 0
  },
  watch: {
    number () {
      clearInterval(this.interval)

      if (this.number == this.displayNumber) {
        return
      }

      this.interval = setInterval(() => {
        if (this.displayNumber != this.number) {
          var change = (this.number - this.displayNumber) / 10
          change = change >= 0 ? Math.ceil(change) : Math.floor(change)
          this.displayNumber = this.displayNumber + change
        }
      }, this.delay/(this.number + 1))
    }
  }
}
</script>

<style scoped>
</style>
