<template>
  <div class="teacher-calendar navigation-top-app">
    <navigation-top-app
      :app="app"
      :back="() => {
        if (filters.student === 'all') {
          $router.push({name: 'app.teacher.home', query: {origin: 'app', navigationLeft: 'false', token: app.token}})
        } else {
          $router.push({name: 'app.student.profile', params: {code: filters.student}, query: {origin: 'app', navigationLeft: 'false', token: app.token}})
        }
      }"
      :right="{
        icon: ['far', 'calendar-alt'],
        action: null
      }"
    >
      <template v-slot:center>
        <div id="navigation-center" v-on:click="toggleCalendar()">
          {{ app.strings.common.months[calendar.month] }}, {{ calendar.year }}
          <font-awesome-icon class="icon" :icon="['fas', 'chevron-up']" v-if="calendar.open" />
          <font-awesome-icon class="icon" :icon="['fas', 'chevron-down']" v-if="!calendar.open" />
        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="teacher-calendar-content" class="max-width-800" v-if="show">
        <calendar-small
          id="calendar"
          :class="{show: calendar.open}"
          :hilighted="today"
          :marks="marks"
          @onDayClick="(day) => {
            scrollToDay(day)
          }"
          @onMonthMove="(month) => {
            this.calendar.month = month
          }"
          @onYearMove="(year) => {
            this.calendar.year = year
          }"
          @onMove="() => {
            this.practiceReadAll()
          }"
        ></calendar-small>
        <div class="practice-list" :class="{calendar: calendar.open}">
          <div>
            <transition-group name="fade">
              <template v-if="!practices.loading">
                <template v-for="(practices, day) in practicesDays">
                  <div class="practice-list-day" :class="'day-' + day.split(' ')[1]" v-bind:key="day">{{ day }}</div>
                  <template v-for="(practice, i) in practices">
                    <div class="practice-data" :class="{ended: practice.ended === 1, cancelled: practice.cancelled === 1}" v-bind:key="day + '-' + i" v-on:click="openPractice(practice)">
                      <div>
                        <div>
                          <div>
                            {{ practice | startTime }}
                          </div>
                          <div></div>
                          <div>
                            {{ practice | endTime }}
                          </div>
                        </div>
                        <div>
                          {{ practice.time }}'
                        </div>
                      </div>
                      <div>
                        <div v-if="app.user.role === 'teacher'">
                          {{ practice.student_name }}
                        </div>
                        <div v-if="app.user.role === 'student'">
                          {{ practice.teacher_name }}
                        </div>
                        <div>
                          {{ practice.vehicle_name }}
                        </div>
                        <div>
                          {{ practice.license }}
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </transition-group>
            <transition name="fade">
              <template v-if="practices.loading">
                <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
              </template>
            </transition>
          </div>
        </div>
        <div id="practice-add" v-on:click="$router.push({name: 'app.teacher.practice.create', query: {origin: 'app', navigationLeft: 'false', token: app.token}})" v-if="app.user.role === 'teacher'">
          <font-awesome-icon class="icon" :icon="['fas', 'plus']"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'app-practice-calendar-view',
  components: {
  },
  data () {
    return {
      show: false,
      today: {
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      },
      calendar: {
        open: true,
        month: 0,
        year: 0
      },
      practices: {
        loading: false,
        data: []
      },
      filters: {
        student: 'all'
      }
    }
  },
  computed: {
    practicesDays () {
      let days = {}

      for (let i in this.practices.data) {
        let practice = this.practices.data[i]
        let day = moment(new Date(practice.date)).locale('es').format('dddd DD')

        if (!(day in days)) {
          days[day] = []
        }

        days[day].push(practice)
      }

      return days
    },
    marks () {
      let marks = []

      for (let i in this.practices.data) {
        let practice = this.practices.data[i]
        let date = new Date(practice.date)

        marks.push(date.getDate())
      }

      return marks
    }
  },
  props: [
    'app'
  ],
  filters: {
    startTime (practice) {
      let hour = (practice.hour < 10 ? '0' : '') + practice.hour
      let minute = (practice.minute < 10 ? '0' : '') + practice.minute

      return hour + ':' + minute
    },
    endTime (practice) {
      let hour = practice.hour
      let minute = practice.minute + practice.time

      while (minute >= 60) {
        hour++
        minute -= 60

        if (hour > 23) {
          hour = 0
        }
      }

      hour = (hour < 10 ? '0' : '') + hour
      minute = (minute < 10 ? '0' : '') + minute

      return hour + ':' + minute
    }
  },
  mounted () {
    let student = this.$route.query.student

    if (student === undefined || student === null) {
      student = 'all'
    }

    this.filters.student = student

    this.calendar.month = this.today.month
    this.calendar.year = this.today.year

    this.practiceReadAll()

    this.show = true
  },
  methods: {
    toggleCalendar () {
      this.calendar.open = !this.calendar.open
    },
    practiceReadAll () {
      if (this.practices.loading) {
        return
      }

      this.practices.loading = true
      this.practices.data = []

      let startDate = this.calendar.year + '-' + (this.calendar.month + 1 < 10 ? '0' : '') + (this.calendar.month + 1) + '-01'
      let endDate = this.calendar.year + '-' + (this.calendar.month + 1 < 10 ? '0' : '') + (this.calendar.month + 1) + '-' + this.daysInMonth(this.calendar.month, this.calendar.year)

      let options = {
        start_date: startDate,
        end_date: endDate,
        student: this.filters.student
      }

      if (this.app.user.role === 'teacher') {
        options.teacher = this.app.user.code
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.filter,
        options,
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.practices.data = response.body.data.practices
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-filter', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.practices.loading = false
      }, () => {
        this.practices.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    scrollToDay (day) {
      const el = this.$el.getElementsByClassName('day-' + day)[0]

      if (el) {
        el.scrollIntoView({block: "start", behavior: "smooth"})
      }
    },
    openPractice (practice) {
      if (this.app.user.role === 'teacher') {
        this.$router.push({name: 'app.teacher.practice.menu', params: {id: practice.id}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";
@import "@/assets/styles/mobile-app.scss";

.teacher-calendar {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

#navigation-center > .icon {
  font-size: 10px;
  margin-left: 6px;
}

#teacher-calendar-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  margin: auto;
}

#calendar {
  position: absolute;
  top: -190px;
  left: 0;
  transition: top 1s ease 0s;
  z-index: 500;
}

#calendar.show {
  top: 60px;
}

.practice-list {
  padding-top: 0px !important;
  padding: 12px;
  box-sizing: border-box;
  transition: padding-top 1s ease 0s;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.practice-list.calendar {
  padding-top: 250px !important;
}

.practice-list > div {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.practice-list-day {
  color: $text-color-1;
  font-size: 20px;
  text-transform: capitalize;
  margin: 12px 0;
}

.practice-data {
  display: flex;
  margin: 6px 0;
  height: 120px;
  background-color: $color-primary-1;
  border-radius: 12px;
}

.practice-data.cancelled {
  background-color: $color-danger;
}

.practice-data.ended {
  background-color: $color-success;
}

.practice-data > div:nth-of-type(1) {
  position: relative;
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: center;
}

.practice-data > div:nth-of-type(1) > div:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: center;
  color: $color-primary-1;
  font-size: 16px;
}

.practice-data.cancelled > div:nth-of-type(1) > div:nth-of-type(1) {
  color: $color-danger;
}

.practice-data.ended > div:nth-of-type(1) > div:nth-of-type(1) {
  color: $color-success;
}

.practice-data > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(2) {
  width: 80%;
  height: 2px;
  background-color: $color-grey-1;
  margin: 4px 0;
}

.practice-data > div:nth-of-type(1) > div:nth-of-type(2) {
  position: absolute;
  display: flex;
  width: 30px;
  height: 30px;
  top: 15px;
  left: 15px;
  border-radius: 15px;
  background-color: $color-grey-2;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 12px;
}

.practice-data > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.practice-data > div:nth-of-type(2) > div:nth-of-type(1) {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  padding: 4px 0;
  box-sizing: border-box;
}

.practice-data > div:nth-of-type(2) > div:nth-of-type(2) {
  color: #FFFFFF;
  font-size: 14px;
  padding: 4px 0;
  box-sizing: border-box;
}

.practice-data > div:nth-of-type(2) > div:nth-of-type(3) {
  color: #FFFFFF;
  font-size: 12px;
  opacity: 0.6;
  padding: 4px 0;
  box-sizing: border-box;
}

#practice-add {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 60px;
  right: 15px;
  background-color: $color-red;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: #FFF;
}
</style>
