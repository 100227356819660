var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"teacher-calendar navigation-top-app"},[_c('navigation-top-app',{attrs:{"app":_vm.app,"back":() => {
      if (_vm.filters.student === 'all') {
        _vm.$router.push({name: 'app.teacher.home', query: {origin: 'app', navigationLeft: 'false', token: _vm.app.token}})
      } else {
        _vm.$router.push({name: 'app.student.profile', params: {code: _vm.filters.student}, query: {origin: 'app', navigationLeft: 'false', token: _vm.app.token}})
      }
    },"right":{
      icon: ['far', 'calendar-alt'],
      action: null
    }},scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('div',{attrs:{"id":"navigation-center"},on:{"click":function($event){return _vm.toggleCalendar()}}},[_vm._v(" "+_vm._s(_vm.app.strings.common.months[_vm.calendar.month])+", "+_vm._s(_vm.calendar.year)+" "),(_vm.calendar.open)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'chevron-up']}}):_vm._e(),(!_vm.calendar.open)?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'chevron-down']}}):_vm._e()],1)]},proxy:true}])}),_c('transition',{attrs:{"name":"fade"}},[(_vm.show)?_c('div',{staticClass:"max-width-800",attrs:{"id":"teacher-calendar-content"}},[_c('calendar-small',{class:{show: _vm.calendar.open},attrs:{"id":"calendar","hilighted":_vm.today,"marks":_vm.marks},on:{"onDayClick":(day) => {
          _vm.scrollToDay(day)
        },"onMonthMove":(month) => {
          this.calendar.month = month
        },"onYearMove":(year) => {
          this.calendar.year = year
        },"onMove":() => {
          this.practiceReadAll()
        }}}),_c('div',{staticClass:"practice-list",class:{calendar: _vm.calendar.open}},[_c('div',[_c('transition-group',{attrs:{"name":"fade"}},[(!_vm.practices.loading)?[_vm._l((_vm.practicesDays),function(practices,day){return [_c('div',{key:day,staticClass:"practice-list-day",class:'day-' + day.split(' ')[1]},[_vm._v(_vm._s(day))]),_vm._l((practices),function(practice,i){return [_c('div',{key:day + '-' + i,staticClass:"practice-data",class:{ended: practice.ended === 1, cancelled: practice.cancelled === 1},on:{"click":function($event){return _vm.openPractice(practice)}}},[_c('div',[_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm._f("startTime")(practice))+" ")]),_c('div'),_c('div',[_vm._v(" "+_vm._s(_vm._f("endTime")(practice))+" ")])]),_c('div',[_vm._v(" "+_vm._s(practice.time)+"' ")])]),_c('div',[(_vm.app.user.role === 'teacher')?_c('div',[_vm._v(" "+_vm._s(practice.student_name)+" ")]):_vm._e(),(_vm.app.user.role === 'student')?_c('div',[_vm._v(" "+_vm._s(practice.teacher_name)+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(practice.vehicle_name)+" ")]),_c('div',[_vm._v(" "+_vm._s(practice.license)+" ")])])])]})]})]:_vm._e()],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.practices.loading)?[_c('loader-dot',{attrs:{"size":'16px',"color_1":'rgb(69, 130, 207)',"color_2":'rgb(69, 130, 207)'}})]:_vm._e()],2)],1)]),(_vm.app.user.role === 'teacher')?_c('div',{attrs:{"id":"practice-add"},on:{"click":function($event){return _vm.$router.push({name: 'app.teacher.practice.create', query: {origin: 'app', navigationLeft: 'false', token: _vm.app.token}})}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'plus']}})],1):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }