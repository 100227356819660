<template>
  <div class="student-evolution navigation-top-app">
    <navigation-top-app
      v-if="evaluation.show === false"
      :app="app"
      :back="() => {
        $router.push({name: 'app.student.profile', params: {code: this.student.data.code}, query: {origin: 'app', navigationLeft: 'false', token: app.token}})
      }"
    >
      <template v-slot:center>
        <div id="navigation-center">
          {{ app.strings.app.student.evolution.title }}
        </div>
      </template>
    </navigation-top-app>
    <navigation-top-app
      v-if="evaluation.show === true"
      :app="app"
      :back="() => {
        this.evaluation.show = false
      }"
    >
      <template v-slot:center>
        <div id="navigation-center">
          {{ evaluation.selected.name | truncate(30) }}
        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="student-evolution-content" class="max-width-800 margin-auto" v-if="show && !loading">
        <list :app="app" :items="evaluation.data" :index="'id'" :value="'name'" @onSelect="(evaluation) => {
          openEvaluation(evaluation)
        }" v-if="evaluation.show === false" />
        <student-evaluation-practice-component :app="app" :student="this.student.data.code" :evaluation="evaluation.selected" :editable="false" v-else />
      </div>
    </transition>
    <transition name="fade">
      <div id="student-evolution-content" class="max-width-800 margin-auto" v-if="show && loading">
        <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app-student-evolution-view',
  data () {
    return {
      show: false,
      student: {
        loading: false,
        data: {
          id: 0
        }
      },
      evaluation: {
        loading: false,
        show: false,
        data: [],
        student: {
          data: []
        },
        selected: {}
      }
    }
  },
  props: [
    'app'
  ],
  computed: {
    loading () {
      if (this.evaluation.loading) {
        return true
      }

      return false
    }
  },
  mounted () {
    this.student.data.code = this.$route.params.code

    this.show = true

    this.practiceEvaluationReadAll()
  },
  methods: {
    practiceEvaluationReadAll () {
      if (this.evaluation.loading) {
        return
      }

      this.evaluation.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.evaluation.read.all + '?student=' + this.student.data.code,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.evaluation.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.evaluation.data = response.body.data.evaluations
            this.evaluation.student.data = response.body.data.student

            this.updateEvaluationValues()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-evaluation-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.evaluation.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    updateEvaluationValues () {
      for (let i in this.evaluation.data) {
        let evaluation = this.evaluation.data[i]
        for (let j in evaluation.groups) {
          let group = evaluation.groups[j]
          for (let u in group.items) {
            let item = group.items[u]
            group.items[u].value = 1
            for (let i in this.evaluation.student.data) {
              let evaluation = this.evaluation.student.data[i]
              if (evaluation.item === item.id && evaluation.business_id === item.business_id) {
                group.items[u].value = evaluation.value
              }
            }
          }
        }
      }
    },
    openEvaluation (evaluation) {
      this.evaluation.selected = evaluation
      this.evaluation.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#student-evolution-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  box-sizing: border-box;
}

</style>
