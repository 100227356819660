<template>
  <div>
    <div v-show="request === 'saved'">
      <img class="signature-saved" src="@/assets/images/signature-saved.jpg" />
    </div>
    <div class="signature" v-show="request !== 'saved'">
      <div id="signature-content">
        <canvas id="signature-pad" class="signature-pad"></canvas>

        <div id="signature-buttons">
          <button class="h-button" id="button-save" v-on:click="save()">Guardar</button>
          <button class="h-button" id="button-clear" v-on:click="clear()">Limpiar</button>
        </div>
      </div>

      <div class="loading" v-if="loading">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad'

export default {
  name: 'signature-view',
  components: {
  },
  data () {
    return {
      loading: true,
      request: '',
      signaturePad: '',
      saving: false
    }
  },
  props: [
    'app'
  ],
  mounted () {
    this.request = this.$route.params.request
    let canvas = document.getElementById('signature-pad')

    setTimeout(() => {
      let width = canvas.parentElement.offsetWidth - 40
      if (width > 300) {
        width = 300
      }
      let height = 300 * 0.5625
      canvas.setAttribute("width", width)
      canvas.setAttribute("height", height)

      this.signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'rgb(255, 255, 255)',
        penColor: 'rgb(0, 0, 0)'
      })

      this.loading = false;
    }, 2500)
  },
  beforeCreate () {
    document.getElementById('app').className = 'signature'
  },
  destroy () {
    document.getElementById('app').className = ''
  },
  methods: {
    clear () {
      this.signaturePad.clear()
    },
    save () {
      if (this.saving === true) {
        return
      }

      this.saving = true

      let data = this.signaturePad.toDataURL('image/jpeg')

      let formData = new FormData()
      formData.append('signature', data)
      formData.append('request', this.request)

      this.$http.post(
        this.app.url.api + this.app.endpoints.signature.update,
        formData
      ).then(response => {
        this.saving = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.$router.push({name: 'signature', params: {request: 'saved'}})
            this.request = 'saved'
            this.clear()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'signature-update', error, () => {
                this.save()
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      })
      .catch(() => {
        this.saving = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  },
  watch: {
    '$route.params.request': {
      handler () {
        this.request = this.$route.params.request
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables.scss";

#app.signature {
  background-color: $color-complementary-2 !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

#router-view {
  padding: 0 !important;
}

.signature {
  width: 100%;
  height: 100%;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  display: flex;
  align-items:  center;
  justify-content: center;
  background-color: $color-complementary-2;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #FFFFFF;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
    } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }

#signature-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

#signature-pad {
  border: 1px solid #333;
}

#signature-buttons {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

button {
  border: 0;
  outline: 0;
  padding: 12px 18px;
  box-sizing: border-box;
  background: rgb(160, 179, 176);
  color: #FFFFFF;
  cursor: pointer;
}

#button-save {
  background: $color-success;
  opacity: 0.5;
  margin-right: 6px;
}

#button-save:hover {
  opacity: 1;
}

#button-clear {
  background: $color-danger;
  opacity: 0.5;
  margin-left: 6px;
}

#button-clear:hover {
  opacity: 1;
}

.signature-saved {
  display: block;
  max-width: 100%;
  margin: auto;
}
</style>
