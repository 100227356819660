<template>
  <div id="practice-view-evaluation">
    <div class="global-mean">
      <div :class="'evaluation-' + globalMean">
        {{ globalMean }}
      </div>
    </div>
    <div class="practice-view-evaluation-group" v-for="(group, i) in evaluation.groups" v-bind:key="group.id" v-show="!groupEmpty(group)">
      <div class="title">
        <div>
          {{ group.name }}
        </div>
        <div class="badge">
          <div :class="'evaluation-' + evaluationMean(group)">
            {{ evaluationMean(group) }}
          </div>
        </div>
      </div>
      <div class="practice-view-evaluation-group-content">
        <div class="practice-view-evaluation-group-item" v-for="(item, j) in group.items" v-bind:key="item.id" v-show="withoutPracticed === false || (withoutPracticed === true && item.value === 1)">
          <div>
            {{ item.name }}
          </div>
          <div>
            <div :class="{marked: item.value === 1}" v-on:click="studentEvaluationUpdate(i, j, 1)"></div>
            <div :class="{marked: item.value === 2}" v-on:click="studentEvaluationUpdate(i, j, 2)"></div>
            <div :class="{marked: item.value === 3}" v-on:click="studentEvaluationUpdate(i, j, 3)"></div>
            <div :class="{marked: item.value === 4}" v-on:click="studentEvaluationUpdate(i, j, 4)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'student-evaluation-practice-component',
  data () {
    return {
      show: false,
      recompile: false
    }
  },
  computed: {
    globalMean () {
      let mean = 0
      let length = 0

      if (this.recompile === true) {
        mean = 0
        length = 0
      }

      for (let i in this.evaluation.groups) {
        let group = this.evaluation.groups[i]
        if (group.items.length > 0) {
          mean += this.evaluationMean(group)
          length++
        }
      }

      mean = parseFloat(parseFloat(mean)/parseFloat(length))

      if (mean < 25) {
        return 0
      } else if (mean >= 25 && mean < 50) {
        return 25
      } else if (mean >= 50 && mean < 75) {
        return 50
      } else if (mean >= 75 && mean < 100) {
        return 75
      } else if (mean === 100) {
        return 100
      }

      return mean
    }
  },
  props: {
    'app': {
      type: Object,
      default: () => {
        return {}
      }
    },
    'student': {
      type: String,
      default: () => {
        return ''
      }
    },
    'evaluation': {
      type: Object,
      default: () => {
        return {
          groups: [],
          items: []
        }
      }
    },
    'withoutPracticed': {
      type: Boolean,
      default: () => {
        return false
      }
    },
    'editable': {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  methods: {
    studentEvaluationUpdate (i, j, value) {
      if (this.editable === false) {
        return
      }

      this.recompile = true
      setTimeout(() => {
        this.recompile = false
        this.$forceUpdate()
      }, 100)

      this.$set(this.evaluation.groups[i].items[j], 'value', value)
      this.rc++

      this.$forceUpdate()

      if (this.withoutPracticed === true && this.withoutPracticedCount === 0) {
        this.$emit('onPractice')
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.evaluation.update,
        {
          business_id: this.evaluation.groups[i].items[j].business_id,
          student: this.student,
          item: this.evaluation.groups[i].items[j].id,
          value: value
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === false) {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'request', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    groupEmpty (group) {
      if (this.withoutPracticed === true) {
        for (let i in group.items) {
          let item = group.items[i]
          if (item.value === 1) {
            return false
          }
        }
        return true
      } else {
        return group.items.length === 0
      }
    },
    evaluationMean (group) {
      let total = 0
      for (let i in group.items) {
        let item = group.items[i]
        total += item.value
      }

      let mean = parseFloat(parseFloat(total)/parseFloat(group.items.length))
      if (mean === 1) {
        return 0
      } else if (mean > 1 && mean < 2) {
        return 25
      } else if (mean >= 2 && mean < 3) {
        return 50
      } else if (mean >= 3 && mean < 4) {
        return 75
      } else if (mean === 4) {
        return 100
      }

      return 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/variables-app.scss';


#practice-view-evaluation {
  flex: 1;
  overflow: auto;
  padding-top: 12px;
  box-sizing: border-box;
}

.global-mean {
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-mean > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 15px;
  color: #FFF;
  font-size: 30px;
}

.global-mean > div.evaluation-0 {
  background-color: $color-grey-3;
}

.global-mean > div.evaluation-25 {
  background-color: $color-grey-3;
}

.global-mean > div.evaluation-50 {
  background-color: $color-primary-1;
}

.global-mean > div.evaluation-75 {
  background-color: rgb(104,204,208);
}

.global-mean > div.evaluation-100 {
  background-color: $color-success;
}

.practice-view-evaluation-group {
  padding: 12px 0;
}

.practice-view-evaluation-group > .title {
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.practice-view-evaluation-group > .title > div {
  flex: 1;
  color: $color-grey-2;
}

.practice-view-evaluation-group > .title > .badge {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.practice-view-evaluation-group > .title > .badge > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: #FFF;
  font-size: 10px;
}

.practice-view-evaluation-group > .title > .badge > div.evaluation-0 {
  background-color: $color-grey-3;
}

.practice-view-evaluation-group > .title > .badge > div.evaluation-25 {
  background-color: $color-grey-3;
}

.practice-view-evaluation-group > .title > .badge > div.evaluation-50 {
  background-color: $color-primary-1;
}

.practice-view-evaluation-group > .title > .badge > div.evaluation-75 {
  background-color: rgb(104,204,208);
}

.practice-view-evaluation-group > .title > .badge > div.evaluation-100 {
  background-color: $color-success;
}

.practice-view-evaluation-group-content {
  border-radius: 6px;
  padding: 12px;
  box-sizing: border-box;
  background-color: $color-grey-light-3;
}

.practice-view-evaluation-group-item {
  display: flex;
  align-items: center;
}

.practice-view-evaluation-group-item > div:nth-of-type(1) {
  font-size: 12px;
  flex: 1;
}

.practice-view-evaluation-group-item > div:nth-of-type(2) {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 6px 0;
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  margin-left: 3px;
  border: 1px solid $color-grey-light-2;
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div:nth-of-type(1) {
  border-right: 1px solid rgb(230,230,230);
  background-color: rgb(235,235,235);
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div:nth-of-type(1).marked {
  background-color: $color-grey-3;
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div:nth-of-type(2) {
  border-right: 1px solid rgb(230,230,230);
  background-color: rgb(240,240,240);
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div:nth-of-type(2).marked {
  background-color: $color-primary-1;
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div:nth-of-type(3) {
  border-right: 1px solid rgb(230,230,230);
  background-color: rgb(245,245,245);
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div:nth-of-type(3).marked {
  background-color: rgb(104,204,208);
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div:nth-of-type(4) {
  background-color: rgb(250,250,250);
}

.practice-view-evaluation-group-item > div:nth-of-type(2) > div:nth-of-type(4).marked {
  background-color: $color-success;
}
</style>
