import { render, staticRenderFns } from "./profile.view.vue?vue&type=template&id=324980b0&scoped=true"
import script from "./profile.view.vue?vue&type=script&lang=js"
export * from "./profile.view.vue?vue&type=script&lang=js"
import style0 from "./profile.view.vue?vue&type=style&index=0&id=324980b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324980b0",
  null
  
)

export default component.exports