import TaxListView from '@/views/tax/tax.list.view'

const TaxRoutes = [
  {
    path: '/tax/list',
    name: 'tax.list',
    component: TaxListView
  }
]

export default TaxRoutes
