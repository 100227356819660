<template>
  <div class="presentation-config">
    <div class="presentation-config-content">
      <div class="presentation-config-header">
        <img src="@/assets/images/logo_aues_blue.svg" />
        <div>
          <span>{{ app.user.name }}</span> <b>&#8226;</b> {{ app.user.business.name }}
        </div>
        <div>
          <button class="h-button info" v-on:click="help()">
            <span>
              {{ app.strings.common.help }}
            </span>
          </button>
          <button class="h-button default ml-20" v-on:click="back()">
            <span>
              {{ app.strings.common.back }}
            </span>
          </button>
        </div>
      </div>
      <div class="presentation-config-container view-item flex-1 overflow-auto">
        <div class="tabs">
          <div :class="{selected: (tab === 'certificates')}" v-on:click="tab = 'certificates'">
            {{ app.strings.presentation.config.tabs.certificates }}
          </div>
          <b v-if="app.user.role !== 'assoc'">&#8226;</b>
          <div v-if="app.user.role !== 'assoc'" :class="{selected: (tab === 'signatures')}" v-on:click="tab = 'signatures'">
            {{ app.strings.presentation.config.tabs.signatures }}
          </div>
          <b v-if="app.user.role !== 'assoc'">&#8226;</b>
          <div v-if="app.user.role !== 'assoc'" :class="{selected: (tab === 'teachers')}" v-on:click="tab = 'teachers'">
            {{ app.strings.presentation.config.tabs.teachers }}
          </div>
          <b v-if="app.user.role !== 'assoc'">&#8226;</b>
          <div v-if="app.user.role !== 'assoc'" :class="{selected: (tab === 'vehicles')}" v-on:click="tab = 'vehicles'">
            {{ app.strings.presentation.config.tabs.vehicles }}
          </div>
          <b v-if="app.user.role !== 'assoc'">&#8226;</b>
          <div v-if="app.user.role !== 'assoc'" :class="{selected: (tab === 'students')}" v-on:click="tab = 'students'">
            {{ app.strings.presentation.config.tabs.students }}
          </div>
          <button class="h-button success" v-on:click="add()" v-if="['teachers', 'vehicles', 'students'].includes(tab)" :class="{disabled: (tab === 'teachers' && teacher.index !== -1) || (tab === 'vehicles' && vehicle.index !== -1)}">
            <span v-if="!loading">
              {{ app.strings.common.add }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
        </div>
        <div v-if="tab === 'certificates'">
          <table class="companies-table" v-if="app.user.role === 'assoc'">
            <tr>
              <td colspan="2">
                <b>&#8226;</b> {{ business.name }}
              </td>
              <td>
                {{ business.traffic_data.certificate_file !== '' ? app.strings.presentation.config.certificates.uploaded : app.strings.presentation.config.certificates.unuploaded }}
              </td>
              <td>
                <button class="h-button default w-100" v-on:click="selectCertificate()">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.certificates.upload }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
            </tr>
          </table>
          <table class="companies-table" v-else>
            <tr v-for="(company, index) in companies" v-bind:key="company.code_letter + ' ' + company.code_number">
              <td>
                <b>&#8226;</b> {{ company.traffic_data.code_letter + ' ' + company.traffic_data.code_number }} , {{ company.name }}
              </td>
              <td>
                <select class="default text-center" v-model="company.traffic_data.certificate_mode" :disabled="loading">
                  <option :value="1">{{ app.strings.presentation.config.certificates.modes.custom }}</option>
                  <option :value="2" v-if="company.association_id !== 0">{{ app.strings.presentation.config.certificates.modes.association }}</option>
                </select>
              </td>
              <td v-if="company.traffic_data.certificate_mode === 1">
                {{ company.traffic_data.certificate_file !== '' ? app.strings.presentation.config.certificates.uploaded : app.strings.presentation.config.certificates.unuploaded }}
              </td>
              <td v-else>
                {{ app.strings.presentation.config.certificates.association }}
              </td>
              <td v-if="company.traffic_data.certificate_mode === 1">
                <button class="h-button default w-100" v-on:click="selectCertificate(index)" :class="{disabled: company.traffic_data.certificate_mode === 2}">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.certificates.upload }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
              <td v-else>
                <button class="h-button success w-100" v-on:click="$data.index = index;certificateUpdate('', 2)" :class="{disabled: company.traffic_data.certificate_mode_current === company.traffic_data.certificate_mode}">
                  <span v-if="!loading">
                    {{ app.strings.common.confirm }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="tab === 'signatures'">
          <table class="companies-table">
            <tr v-for="(company, index) in companies" v-bind:key="company.code_letter + ' ' + company.code_number">
              <td>
                <b>&#8226;</b> {{ company.traffic_data.code_letter + ' ' + company.traffic_data.code_number }} , {{ company.name }}
              </td>
              <td style="width: 200px;">
                <p>
                  <img v-if="company.config.signature.length > 0" :src="'https://hermes.grupofacilauto.com/file/read/' + company.config.signature + '?token=' + app.token" />
                </p>
                <button class="h-button default w-100" v-on:click="selectSignature(index)">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.signatures.upload }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="tab === 'teachers'">
          <table class="basic-table">
            <tr>
              <th style="width: 1%;">
                #
              </th>
              <th>
                {{ app.strings.presentation.config.teachers.code }}
              </th>
              <th>
                {{ app.strings.presentation.config.teachers.first_name }}
              </th>
              <th>
                {{ app.strings.presentation.config.teachers.last_name_1 }}
              </th>
              <th>
                {{ app.strings.presentation.config.teachers.last_name_2 }}
              </th>
              <th colspan="2">
              </th>
            </tr>
            <tr v-for="(t, index) in teachers" v-bind:key="t.code">
              <td style="width: 1%;">
                {{ index+1 }}
              </td>
              <td>
                <input type="text" class="default text-center" v-model="teacher.data.code" :placeholder="app.strings.presentation.config.teachers.code" v-if="teacher.index === index" />
                <span v-else>{{ t.code }}</span>
              </td>
              <td>
                <input type="text" class="default text-center" v-model="teacher.data.first_name" :placeholder="app.strings.presentation.config.teachers.first_name" v-if="teacher.index === index" />
                <span v-else>{{ t.first_name }}</span>
              </td>
              <td>
                <input type="text" class="default text-center" v-model="teacher.data.last_name_1" :placeholder="app.strings.presentation.config.teachers.last_name_1" v-if="teacher.index === index" />
                <span v-else>{{ t.last_name_1 }}</span>
              </td>
              <td>
                <input type="text" class="default text-center" v-model="teacher.data.last_name_2" :placeholder="app.strings.presentation.config.teachers.last_name_2" v-if="teacher.index === index" />
                <span v-else>{{ t.last_name_2 }}</span>
              </td>
              <td style="width: 180px">
                <button class="h-button default w-100" v-on:click="teacher.data = JSON.parse(JSON.stringify(t)); teacher.mode = 'update'; teacher.index = index" v-if="teacher.index === -1">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.teachers.edit }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
                <button class="h-button success w-100" v-on:click="teacherSave(index)" v-if="teacher.index === index">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.teachers.save }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
              <td style="width: 180px">
                <button class="h-button danger w-100" v-on:click="teacherDelete(index)" v-if="teacher.index === -1">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.teachers.delete.button }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
                <button class="h-button danger w-100" v-on:click="teacherCancel()" v-if="teacher.index === index && !loading">
                  <span>
                    {{ app.strings.presentation.config.teachers.cancel }}
                  </span>
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="tab === 'vehicles'">
          <table class="basic-table">
            <tr>
              <th style="width: 1%;">
                #
              </th>
              <th>
                {{ app.strings.presentation.config.vehicles.code }}
              </th>
              <th>
                {{ app.strings.presentation.config.vehicles.name }}
              </th>
              <th colspan="2">
              </th>
            </tr>
            <tr v-for="(v, index) in vehicles" v-bind:key="v.code">
              <td style="width: 1%;">
                {{ index+1 }}
              </td>
              <td>
                <input type="text" class="default text-center" v-model="vehicle.data.code" :placeholder="app.strings.presentation.config.vehicles.code" v-if="vehicle.index === index" />
                <span v-else>{{ v.code }}</span>
              </td>
              <td>
                <input type="text" class="default text-center" v-model="vehicle.data.name" :placeholder="app.strings.presentation.config.vehicles.name" v-if="vehicle.index === index" />
                <span v-else>{{ v.name }}</span>
              </td>
              <td style="width: 180px">
                <button class="h-button default w-100" v-on:click="vehicle.data = JSON.parse(JSON.stringify(v)); vehicle.mode = 'update'; vehicle.index = index" v-if="vehicle.index === -1">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.vehicles.edit }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
                <button class="h-button success w-100" v-on:click="vehicleSave(index)" v-if="vehicle.index === index">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.vehicles.save }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
              <td style="width: 180px">
                <button class="h-button danger w-100" v-on:click="vehicleDelete(index)" v-if="vehicle.index === -1">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.vehicles.delete.button }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
                <button class="h-button danger w-100" v-on:click="vehicleCancel()" v-if="vehicle.index === index && !loading">
                  <span>
                    {{ app.strings.presentation.config.vehicles.cancel }}
                  </span>
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="tab === 'students'">
          <table class="basic-table">
            <tr>
              <th style="width: 1%;">
                #
              </th>
              <th>
                {{ app.strings.presentation.config.students.code }}
              </th>
              <th>
                {{ app.strings.presentation.config.students.first_name }}
              </th>
              <th>
                {{ app.strings.presentation.config.students.last_name_1 }}
              </th>
              <th>
                {{ app.strings.presentation.config.students.last_name_2 }}
              </th>
              <th colspan="4">
              </th>
            </tr>
            <tr v-for="(s, index) in students" v-bind:key="s.code">
              <td style="width: 1%;">
                {{ index+1 }}
              </td>
              <td>
                <input type="text" class="default text-center" v-model="student.data.code" :placeholder="app.strings.presentation.config.students.code" v-if="student.index === index" />
                <span v-else>{{ s.code }}</span>
              </td>
              <td>
                <input type="text" class="default text-center" v-model="student.data.first_name" :placeholder="app.strings.presentation.config.students.first_name" v-if="student.index === index" />
                <span v-else>{{ s.first_name }}</span>
              </td>
              <td>
                <input type="text" class="default text-center" v-model="student.data.last_name_1" :placeholder="app.strings.presentation.config.students.last_name_1" v-if="student.index === index" />
                <span v-else>{{ s.last_name_1 }}</span>
              </td>
              <td>
                <input type="text" class="default text-center" v-model="student.data.last_name_2" :placeholder="app.strings.presentation.config.students.last_name_2" v-if="student.index === index" />
                <span v-else>{{ s.last_name_2 }}</span>
              </td>
              <td style="width: 150px">
                <img style="max-width: 100%;" v-if="s.signature.signature.length > 0" :src="s.signature.signature" />
              </td>
              <td style="width: 180px">
                <button class="h-button info w-100" v-if="student.index === -1" v-on:click="studentSignature(s)">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.students.signature }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
              <td style="width: 180px">
                <button class="h-button default w-100" v-on:click="student.data = JSON.parse(JSON.stringify(s)); student.mode = 'update'; student.index = index" v-if="student.index === -1">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.students.edit }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
                <button class="h-button success w-100" v-on:click="studentSave(index)" v-if="student.index === index">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.students.save }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
              <td style="width: 180px">
                <button class="h-button danger w-100" v-on:click="studentDelete(index)" v-if="student.index === -1">
                  <span v-if="!loading">
                    {{ app.strings.presentation.config.students.delete.button }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
                <button class="h-button danger w-100" v-on:click="studentCancel()" v-if="student.index === index && !loading">
                  <span>
                    {{ app.strings.presentation.config.students.cancel }}
                  </span>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <file-upload ref="file-certificate-upload"
      :dropzone="false"
      :data="{
        strings: app.strings.components.file.upload,
        mode: 'single',
        extensions: ['pfx', 'p12'],
        url: app.url.api + app.endpoints.file.upload,
        auth: 'Token ' + app.token,
        debug: false
      }"
      @onError="onCertificateUploadError"
      @onSuccess="onCertificateUploadSuccess"
    ></file-upload>

    <file-upload ref="file-signature-upload"
      :dropzone="false"
      :data="{
        strings: app.strings.components.file.upload,
        mode: 'single',
        extensions: ['jpg'],
        url: app.url.api + app.endpoints.file.upload,
        auth: 'Token ' + app.token,
        debug: false
      }"
      @onError="onSignatureUploadError"
      @onSuccess="onSignatureUploadSuccess"
    ></file-upload>

    <div class="qr-view" v-if="signature.request.length > 0" v-on:click="signature.request = ''; app.methods.loadUserData()">
      <vue-qr v-if="signature.signature === ''" :text="'https://www.auesgestion.com/#/signature/' + signature.request" :size="300"></vue-qr>
      <img :src="signature.signature" v-else/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'presentation-config-view',
  components: {
  },
  data () {
    return {
      tab: 'certificates',
      loading: false,
      index: 0,
      business: {
        name: '',
        traffic_data: {
          certificate_file: ''
        }
      },
      companies: [],
      teachers: [],
      teacher: {
        index: -1,
        mode: 'update',
        data: {
          code: '',
          first_name: '',
          last_name_1: '',
          last_name_2: ''
        }
      },
      vehicles: [],
      vehicle: {
        index: -1,
        mode: 'update',
        data: {
          code: '',
          name: ''
        }
      },
      students: [],
      student: {
        index: -1,
        mode: 'update',
        data: {
          code: '',
          first_name: '',
          last_name_1: '',
          last_name_2: ''
        }
      },
      signature: {
        request: '',
        signature: ''
      }
    }
  },
  props: [
    'app'
  ],
  mounted () {
    this.app.methods.loadUserData()
    if (this.app.socket !== '') {
      this.socketConnected()
    }
  },
  methods: {
    appUpdated () {
      this.companies = JSON.parse(JSON.stringify(this.app.companies))
      for (let i in this.companies) {
        this.companies[i].traffic_data.certificate_mode_current = this.companies[i].traffic_data.certificate_mode
      }
      this.teachers = JSON.parse(JSON.stringify(this.app.teachers))
      this.vehicles = JSON.parse(JSON.stringify(this.app.vehicles))
      this.students = JSON.parse(JSON.stringify(this.app.students))
      this.business = this.app.user.business
    },
    socketConnected () {
      this.app.socket.on('signature-updated', (response) => {
        let index = this.students.findIndex(s => s.signature.request === response.signature.request)
        if (index !== -1) {
          this.students[index].signature.signature = response.signature.signature
        }
        if (this.signature.request === response.signature.request) {
          this.signature.signature = response.signature.signature
        }
      })
    },
    back () {
      this.$router.push({name: 'presentation.dashboard'})
    },
    add () {
      if (this.tab === 'teachers') {
        if (this.teacher.index !== -1) {
          return
        }
        this.teachers.unshift({
          code: '',
          first_name: '',
          last_name_1: '',
          last_name_2: ''
        })
        this.teacher.mode = 'create'
        this.teacher.index = 0
      } else if (this.tab === 'vehicles') {
        if (this.vehicle.index !== -1) {
          return
        }
        this.vehicles.unshift({
          code: '',
          name: ''
        })
        this.vehicle.mode = 'create'
        this.vehicle.index = 0
      } else if (this.tab === 'students') {
        if (this.student.index !== -1) {
          return
        }
        this.students.unshift({
          code: '',
          first_name: '',
          last_name_1: '',
          last_name_2: '',
          signature: {
            signature: '',
            request: ''
          }
        })
        this.student.mode = 'create'
        this.student.index = 0
      }
    },
    selectCertificate (index) {
      if (this.app.user.name.toLowerCase() === 'demo') {
        return
      }

      if (this.loading) {
        return
      }

      this.loading = true

      if (this.app.user.role !== 'assoc') {
        if (this.companies[index].traffic_data.certificate_mode === 2) {
          return
        }

        this.index = index
      }

      this.$refs['file-certificate-upload'].click()
    },
    onCertificateUploadSuccess (file, response) {
      if (this.app.user.role !== 'assoc') {
        this.companies[this.index].traffic_data.certificate_file = response.name
      } else {
        this.business.traffic_data.certificate_file = response.name
      }
      this.setCertificatePassword()
    },
    onCertificateUploadError (tag, file, queue, index, error) {
      this.loading = false
      this.showError(this.app.strings, 'presentation-config-certificate-upload', error)
    },
    setCertificatePassword () {
      this.$swal({
        title: this.app.strings.presentation.config.certificates.password,
        html: '<input id="certificate-password" class="info text-center w-100" />',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm,
        allowOutsideClick: false
      }).then((result) => {
        if(result.value) {
          let password = document.getElementById('certificate-password').value
          this.certificateUpdate(password, 1)
        } else {
          if (this.app.user.role !== 'assoc') {
            this.companies[this.index].traffic_data.certificate_file = ''
          } else {
            this.business.traffic_data.certificate_file = this.app.user.business.traffic_data.certificate_file
          }
          this.loading = false
        }
      })
    },
    certificateUpdate (password, mode) {
      if (this.loading && mode === 2 && this.companies[this.index].traffic_data.certificate_mode_current === this.companies[this.index].traffic_data.certificate_mode) {
        return
      }

      this.loading = true
      if (mode === 2 && this.app.user.role !== 'assoc') {
        this.companies[this.index].traffic_data.certificate_file = ''
      }

      let url = this.app.url.api + this.app.endpoints.presentation.config.certificate.update
      let file = this.companies[this.index].traffic_data.certificate_file
      if (this.app.user.role === 'assoc') {
        url = this.app.url.api + this.app.endpoints.presentation.config.association.certificate.update
        file = this.business.traffic_data.certificate_file
      }

      this.$http.post(
        url,
        {
          company: this.companies[this.index].id,
          file: file,
          password: password,
          mode: mode
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.companies[this.index].traffic_data.certificate_mode_current = this.companies[this.index].traffic_data.certificate_mode
            this.loading = false
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              if (mode === 1) {
                this.showError(this.app.strings, 'presentation-config-certificate-open', error, () => {
                  this.setCertificatePassword()
                })
              } else {
                this.showError(this.app.strings, 'presentation-config-certificate-update', error)
              }
            }
          }
        } else {
          if (mode === 1) {
            this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
              this.setCertificatePassword()
            })
          } else {
            this.showError(this.app.strings, 'request', 'status-' + response.status)
          }
        }
      }, () => {
        if (mode === 1) {
          this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
            this.setCertificatePassword()
          })
        } else {
          this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
        }
      })
    },
    selectSignature (index) {
      /*if (this.app.user.name.toLowerCase() === 'demo') {
        return
      }*/

      if (this.loading) {
        return
      }

      this.loading = true

      this.index = index

      this.$refs['file-signature-upload'].click()
    },
    onSignatureUploadSuccess (file, response) {
      this.companies[this.index].config.signature = response.name
      setTimeout(() => {
        this.loading = false
        this.signatureUpdate()
      }, 5000)
    },
    onSignatureUploadError (tag, file, queue, index, error) {
      this.loading = false
      this.showError(this.app.strings, 'presentation-config-signature-upload', error)
    },
    signatureUpdate () {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.presentation.config.signature.update
      let file = this.companies[this.index].config.signature

      this.$http.post(
        url,
        {
          company: this.companies[this.index].id,
          file: file
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-config-signature-update', error, () => {
                this.signatureUpdate()
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    signatureRequest (target, id) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.signature.request

      this.$http.post(
        url,
        {
          target: target,
          id: id
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.signature.request = response.body.data.request
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'signature-request', error, () => {
                this.signatureRequest(target, id)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    teacherSave (index) {
      if (this.teacher.mode === 'create') {
        this.teacherCreate(index)
      } else if (this.teacher.mode === 'update') {
        this.teacherUpdate(index)
      }
    },
    teacherCancel () {
      if (this.teacher.mode === 'create') {
        this.teacher.index = -1
        this.teachers.shift()
      } else if (this.teacher.mode === 'update') {
        this.teacher.index = -1
      }
    },
    teacherCreate (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.teacher.create

      this.$http.post(
        url,
        {
          teacher: JSON.stringify(this.teacher.data)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.teachers[this.teacher.index] = JSON.parse(JSON.stringify(this.teacher.data))
            this.teacher.index = -1
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'teacher-create', error, () => {
                this.teacherCreate(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    teacherUpdate (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.teacher.update

      this.$http.post(
        url,
        {
          code: this.teachers[index].code,
          teacher: JSON.stringify(this.teacher.data)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.teachers[this.teacher.index] = JSON.parse(JSON.stringify(this.teacher.data))
            this.teacher.index = -1
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'teacher-update', error, () => {
                this.teacherUpdate(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    teacherDelete (index) {
      this.$swal({
        title: this.app.strings.presentation.config.teachers.delete.title,
        text: this.app.strings.presentation.config.teachers.delete.content,
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm
      }).then((result) => {
        if(result.value) {
          this.teacherDeleteConfirm(index)
        }
      })
    },
    teacherDeleteConfirm (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.teacher.delete

      let teacher = this.teachers[index]

      this.$http.post(
        url,
        {
          code: teacher.code
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'teacher-delete', error, () => {
                this.teacherDelete(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    vehicleSave (index) {
      if (this.vehicle.mode === 'create') {
        this.vehicleCreate(index)
      } else if (this.vehicle.mode === 'update') {
        this.vehicleUpdate(index)
      }
    },
    vehicleCancel () {
      if (this.vehicle.mode === 'create') {
        this.vehicle.index = -1
        this.vehicles.shift()
      } else if (this.vehicle.mode === 'update') {
        this.vehicle.index = -1
      }
    },
    vehicleCreate (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.vehicle.create

      this.$http.post(
        url,
        {
          vehicle: JSON.stringify(this.vehicle.data)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.vehicles[this.vehicle.index] = JSON.parse(JSON.stringify(this.vehicle.data))
            this.vehicle.index = -1
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'vehicle-create', error, () => {
                this.vehicleCreate(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    vehicleUpdate (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.vehicle.update

      this.$http.post(
        url,
        {
          code: this.vehicles[index].code,
          vehicle: JSON.stringify(this.vehicle.data)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.vehicles[this.vehicle.index] = JSON.parse(JSON.stringify(this.vehicle.data))
            this.vehicle.index = -1
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'vehicle-update', error, () => {
                this.vehicleUpdate(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    vehicleDelete (index) {
      this.$swal({
        title: this.app.strings.presentation.config.vehicles.delete.title,
        text: this.app.strings.presentation.config.vehicles.delete.content,
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm
      }).then((result) => {
        if(result.value) {
          this.vehicleDeleteConfirm(index)
        }
      })
    },
    vehicleDeleteConfirm (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.vehicle.delete

      let vehicle = this.vehicles[index]

      this.$http.post(
        url,
        {
          code: vehicle.code
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'vehicle-delete', error, () => {
                this.vehicleDelete(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    studentSave (index) {
      if (this.student.mode === 'create') {
        this.studentCreate(index)
      } else if (this.student.mode === 'update') {
        this.studentUpdate(index)
      }
    },
    studentCancel () {
      if (this.student.mode === 'create') {
        this.vehicle.index = -1
        this.students.shift()
      } else if (this.student.mode === 'update') {
        this.student.index = -1
      }
    },
    studentCreate (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.student.create

      this.$http.post(
        url,
        {
          student: JSON.stringify(this.student.data)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.students[this.student.index] = JSON.parse(JSON.stringify(this.student.data))
            this.student.index = -1
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-create', error, () => {
                this.studentCreate(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    studentUpdate (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.student.update

      this.$http.post(
        url,
        {
          code: this.students[index].code,
          student: JSON.stringify(this.student.data)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.students[this.student.index] = JSON.parse(JSON.stringify(this.student.data))
            this.student.index = -1
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-update', error, () => {
                this.studentUpdate(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    studentDelete (index) {
      this.$swal({
        title: this.app.strings.presentation.config.students.delete.title,
        text: this.app.strings.presentation.config.students.delete.content,
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm
      }).then((result) => {
        if(result.value) {
          this.studentDeleteConfirm(index)
        }
      })
    },
    studentDeleteConfirm (index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.student.delete

      let student = this.students[index]

      this.$http.post(
        url,
        {
          code: student.code
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.app.methods.loadUserData()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-delete', error, () => {
                this.studentDelete(index)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    studentSignature (student) {
      if (student.signature.request !== '') {
        this.signature.request = student.signature.request
        this.signature.signature = ''
      } else {
        this.signatureRequest('student', student.code)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

#router-view {
  max-width: 1440px;
  margin: auto;
}

tr > th:nth-of-type(1) {
  padding-left: 40px;
}

tr > th {
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
}

table td {
  font-size: 14px;
}

.presentation-config-content {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.presentation-config-header {
  display: flex;
  margin-bottom: 20px;
}

.presentation-config-header > img {
  height: 50px;
}

.presentation-config-header > div:nth-of-type(1) {
  flex: 1;
  justify-content: center;
  align-self: center;
  padding: 0 80px;
  box-sizing: border-box;
  color: $text-color-2;
}

.presentation-config-header > div:nth-of-type(1) > span {
  font-weight: 600;
}

.presentation-config-header > div:nth-of-type(1) > b {
  font-size: 20px;
  color: $color-info;
  margin: 0 10px;
}

.presentation-config-container {
  flex-direction: column;
}

.presentation-config-container > div {
  width: 100%;
}

.companies-table {
  width: 100%;
  border-spacing: 0 10px;
}

.companies-table tr > td {
  padding: 20px;
  box-sizing: border-box;
  border-top: 2px solid rgba($color-grey-light, 0.5);
  border-bottom: 2px solid rgba($color-grey-light, 0.5);
}

.companies-table tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 2px solid rgba($color-grey-light, 0.5);
}

.companies-table tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 2px solid rgba($color-grey-light, 0.5);
}

.companies-table tr > td:nth-of-type(1) {
  position: relative;
  padding-left: 40px;
  box-sizing: border-box;
}

.companies-table tr > td:nth-of-type(1) > b {
  font-size: 30px;
  color: $color-info;
}

.companies-table tr > td:nth-of-type(3) {
  width: 400px;
}

.companies-table tr > td:nth-of-type(4) {
  width: 300px;
}

.companies-table tr > td:nth-of-type(2) {
  width: 1%;
  white-space:nowrap;
}

.companies-table tr > td.disabled {
  opacity: 0.5;
}

.companies-table img {
  max-width: 100%;
}
</style>
