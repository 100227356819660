<template>
  <div class="home">

  </div>
</template>

<script>
export default {
  name: 'home-view',
  components: {
  },
  data () {
    return {
    }
  },
  props: [
    'app'
  ]
}
</script>
