<template>
  <div class="student-list">
    <div class="student-search" v-if="search">
      <input type="text" :placeholder="app.strings.common.search" v-model="query" />
      <font-awesome-icon class="icon" :icon="['fas', 'search']" />
    </div>
    <div class="student-list">
      <div class="student-row" v-for="student in studentsFiltered" v-bind:key="student.code" v-on:click="$emit('onSelect', student)">
        <img src="@/assets/images/avatar.svg" />
        <div>{{ student.first_name }} {{ student.last_name_1 }} {{ student.last_name_2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'student-list',
  data () {
    return {
      query: ''
    }
  },
  computed: {
    studentsFiltered () {
      let students = this.students

      if (this.query.trim().length > 0) {
        students = students.filter((student) => {
          if (student.code === 'all') {
            return false
          }
          let name = student.first_name.toLowerCase() + ' ' + student.last_name_1.toLowerCase() + ' ' + student.last_name_2.toLowerCase()
          return name.indexOf(this.query.toLowerCase()) != -1 || student.code.toLowerCase().indexOf(this.query.toLowerCase()) != -1
        })
      }

      this.$emit('onSearch', students)

      return students
    }
  },
  props: {
    'app': {
      type: Object,
      default: () => {
        return {}
      }
    },
    'students': {
      type: Array,
      default: () => {
        return []
      }
    },
    'search': {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  mounted () {

  },
  methods: {

  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/variables-app.scss';

.student-search {
  position: relative;
}

.student-search > input {
  width: 100%;
  height: 40px;
  background-color: rgba($color-primary-1, 0.25);
  border-radius: 10px;
  color: $text-color-1;
  font-size: 16px;
  padding-left: 40px;
}

.student-search > input::placeholder {
  color: $color-primary-1;
  font-size: 16px;
  font-weight: bold;
}

.student-search > .icon {
  position: absolute;
  top: 12px;
  left: 10px;
  color: $color-primary-1;
}

.student-row {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 6px 12px;
  box-sizing: border-box;
  background-color: $color-grey-light;
  margin: 12px 0;
  border-radius: 12px;
}

.student-row > img {
  width: 38px;
}

.student-row > div {
  flex: 1;
  padding: 0 0 0 12px;
  color: $text-color-1;
  font-size: 14px;
  font-weight: 500;
}
</style>
