let ES = {}

// Common

import CommonLocale from './common.json'

ES.common = CommonLocale

// Errors

import ErrorsLocale from './errors.json'

ES.errors = ErrorsLocale

// Components

import NavigationLeftLocale from './components/navigation/left.json'
import FileUploadLocale from './components/file/upload.json'
import CalendarLocale from './components/calendar/calendar.json'
import MenuVerticalLocale from './components/menu/vertical.json'

ES.components = {
  navigation: {
    left: NavigationLeftLocale
  },
  file: {
    upload: FileUploadLocale
  },
  calendar: CalendarLocale,
  menu: {
    vertical: MenuVerticalLocale
  }
}

// User

import UserLoginLocale from './user/login.json'
import UserPasswordLocale from './user/password.json'

ES.user = {}
ES.user.login = UserLoginLocale
ES.user.password = UserPasswordLocale

// Presentation

import PresentationDashboardLocale from './presentation/dashboard.json'
import PresentationConfigLocale from './presentation/config.json'
import PresentationCreateLocale from './presentation/create.json'

ES.presentation = {}
ES.presentation.dashboard = PresentationDashboardLocale
ES.presentation.config = PresentationConfigLocale
ES.presentation.create = PresentationCreateLocale

// Tax

import TaxListLocale from './tax/list.json'

ES.tax = {}
ES.tax.list = TaxListLocale

// Practice

import PracticeCalendarLocale from './practice/calendar.json'
import PracticeCreateLocale from './practice/create.json'
import PracticeMenuLocale from './practice/menu.json'
import PracticeStartLocale from './practice/start.json'
import PracticePerformLocale from './practice/perform.json'

ES.practice = {}
ES.practice.calendar = PracticeCalendarLocale
ES.practice.create = PracticeCreateLocale
ES.practice.menu = PracticeMenuLocale
ES.practice.start = PracticeStartLocale
ES.practice.perform = PracticePerformLocale

// Biometric

import BiometricReigsterLocale from './biometric/register.json'

ES.biometric = {}
ES.biometric.register = BiometricReigsterLocale

// App

import AppTeacherHomeLocale from './app/teacher/home.json'
import AppTeacherBiometricCourseLocale from './app/teacher/biometric.course.json'

ES.app = {
  "compatibility": {
    "title": "Navegador no compatible",
    "content": "Hemos detectado que su navegador no es 100% compatible con nuestro sistema, le rogamos utilize <a href=\"https://www.google.com/chrome/\" target=\"_blank\">Google Chrome</a> para asegurar una experiencia de uso correcta."
  }
}
ES.app.teacher = {}
ES.app.teacher.home = AppTeacherHomeLocale
ES.app.teacher.biometric = {}
ES.app.teacher.biometric.course = AppTeacherBiometricCourseLocale

import AppStudentListLocale from './app/student/list.json'
import AppStudentProfileLocale from './app/student/profile.json'
import AppStudentEvolutionLocale from './app/student/evolution.json'
import AppStudentDGTReportLocale from './app/student/dgtreport.json'
import AppStudentPracticeBagListLocale from './app/student/practice/bag/list.json'
import AppStudentPracticeBagShopLocale from './app/student/practice/bag/shop.json'

ES.app.student = {}
ES.app.student.list = AppStudentListLocale
ES.app.student.profile = AppStudentProfileLocale
ES.app.student.evolution = AppStudentEvolutionLocale
ES.app.student.dgtreport = AppStudentDGTReportLocale
ES.app.student.practice = {}
ES.app.student.practice.bag = {}
ES.app.student.practice.bag.list = AppStudentPracticeBagListLocale
ES.app.student.practice.bag.shop = AppStudentPracticeBagShopLocale

export default ES
