<template>
  <div class="student-bag navigation-top-app">
    <navigation-top-app
      :app="app"
      :back="() => {
        $router.push({name: 'app.student.profile', params: {code: app.user.code}, query: {origin: 'app', navigationLeft: 'false', token: app.token}})
      }"
    >
      <template v-slot:center>
        <div id="navigation-center">
          {{ app.strings.app.student.practice.bag.list.title }}
        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="student-bag-content" class="max-width-800 margin-auto" v-if="show && !loading && bag.data.length > 0">
        <list :app="app">
          <template v-slot:rows>
            <div class="row" v-for="item in bag.data" v-bind:key="item.practice_type.id" :class="{danger: item.balance < 0}">
              <div>{{ item.practice_type.name | truncate(40) }}</div>
              <div class="right">
                <span class="badge">{{ item.balance }}</span>
              </div>
            </div>
          </template>
        </list>
      </div>
    </transition>
    <transition name="fade">
      <div id="student-bag-content" class="max-width-800 margin-auto empty" v-if="show && !loading && bag.data.length === 0">
        {{ app.strings.app.student.practice.bag.list.empty  }}
      </div>
    </transition>
    <transition name="fade">
      <div id="student-bag-content" v-if="show && loading">
        <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
      </div>
    </transition>

    <div id="bag-add" v-on:click="$router.push({name: 'app.student.practice.bag.shop', query: {origin: 'app', navigationLeft: 'false', token: app.token}})" v-if="app.user.role === 'student' && canBuy">
      <font-awesome-icon class="icon" :icon="['fas', 'plus']"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-student-bag-list-view',
  data () {
    return {
      show: false,
      canBuy: false,
      bag: {
        loading: false,
        data: []
      }
    }
  },
  props: [
    'app'
  ],
  computed: {
    loading () {
      if (this.bag.loading) {
        return true
      }

      return false
    }
  },
  mounted () {
    this.show = true

    this.studentPracticeBagReadAll()
  },
  methods: {
    studentPracticeBagReadAll () {
      if (this.bag.loading) {
        return
      }

      this.bag.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.student.practice.bag.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.bag.data = response.body.data.bag
            this.canBuy = response.body.data.can_buy
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-practice-bag-read', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.bag.loading = false
      }, () => {
        this.bag.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#student-bag-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}
#student-bag-content.empty {
  text-align: center;
}

#bag-add {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 60px;
  right: 15px;
  background-color: $color-red;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: #FFF;
}
</style>
