<template>
  <div class="password-reset">
    <div class="password-reset-box">
      <div class="aues-logo">
        <img src="@/assets/images/logo_aues_white.svg" />
      </div>
      <form class="m-login__form m-form" action="" v-on:submit="onSubmit($event)">
        <div class="password-resent-help">
          {{ app.strings.user.password.reset.help }}
        </div>
        <div>
          <input type="password" v-model="password" :disabled="loading" :placeholder="app.strings.user.login.password" />
        </div>
        <div>
          <button class="h-button">
            <span v-if="!loading">
              {{ app.strings.user.password.reset.update }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'user-password-reset-view',
  components: {
  },
  props: [
    'app'
  ],
  data () {
    return {
      loading: false,
      token: '',
      password: ''
    }
  },
  mounted () {
    this.token = this.$route.params.token
  },
  methods: {
    onSubmit (e) {
      e.preventDefault()
      if (this.loading) {
        return
      }

      if (this.password.length < 6 || this.password.length > 16) {
        this.showError(this.app.strings, 'user-login-malformed', '')
        return
      }

      this.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.user.password.update,
        {
          password: this.password,
          token: this.token
        },
        {
          emulateJSON: true
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.$swal({
              title: this.app.strings.user.password.reset.updated.title,
              text: this.app.strings.user.password.reset.updated.content,
              icon: 'success'
            }).then(() => {
              this.$router.push({name: 'home'})
            })
          } else {
            this.loading = false
            let error = response.body.error.code
            this.showError(this.app.strings, 'user-password-reset', error)
          }
        } else {
          this.loading = false
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

.password-reset {
  width: 100% !important;
  height: 100% !important;
  background: $color-complementary-2 url('~@/assets/images/login_background.png') no-repeat center center !important;
  padding: 0 !important;
  margin: 0 !important;
}

.password-reset-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.password-reset-box > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.password-reset-box > form > div {
  align-self: center;
}

.aues-logo > img {
  width: 110px;
  margin-bottom: 20px;
}

.password-resent-help {
  color: #FFF;
}

input {
  width: 200px !important;
  padding: 10px 30px !important;
  box-sizing: border-box !important;
  background: rgb(15, 46, 62) !important;
  border: 0 !important;
  outline: none !important;
  border-radius: 5px !important;
  color: #FFF !important;
  margin-top: 10px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

input::placeholder {
  color: #FFF;
}

.login-lost-password {
  margin-top: 10px;
}

.login-lost-password > a {
  color: rgb(88, 102, 111);
  font-size: 10px;
  text-decoration: none;
  font-weight: 600;
}

.login-lost-password > a:hover {
  color: #FFF;
}

button {
  background-color: rgba($color-success, 0.8);
  border: 0;
  border-radius: 5px;
  color: #FFF;
  padding: 10px 30px;
  box-sizing: border-box;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  transition: background-color 1s;
}
</style>
