<template>
  <div></div>
</template>

<script>
import Cardamomo from '../modules/cardamomo/cardamomo.module'

export default {
  name: 'socket',
  data () {
    return {
      connected: false,
      socket: ''
    }
  },
  props: [
    'app'
  ],
  mounted () {},
  methods: {
    connect (token) {
      var cardamomo = new Cardamomo()

      this.socket = cardamomo.socket(this.app.url.api + '/user')

      // Open / Close
      this.socket.onOpen(() => {
        console.log('Socket opened!')
        this.socket.send('initialize', {
          token: token
        })
        this.$emit('onOpen')
      })

      this.socket.onClose(() => {
        console.log('Socket closed!')
        this.$emit('onClose')
        this.connected = false
      })

      // Initialized
      this.socket.on('initialized', (response) => {
        console.log('Socket initialized with response: ' + JSON.stringify(response))
        if (response.success === true) {
          this.connected = true
          this.$emit('onConnected', this.socket)
        } else {
          this.connected = false
          this.$emit('onError', response.error)
        }
      })
    }
  }
}
</script>
