<template>
  <div class="presentation-create">
    <div class="presentation-content">
      <div class="presentation-header">
        <img src="@/assets/images/logo_aues_blue.svg" />
        <div>
          <span>{{ this.$options.filters.fillZero(app.user.business.id, 5) + '-' + (presentation.data.id !== 0 ? this.$options.filters.fillZero(presentation.data.id, 5) : app.strings.presentation.create.tools.reference_new) }}</span> <b>&#8226;</b> <span>{{ app.user.name }}</span> <b>&#8226;</b> {{ app.user.business.name }}
        </div>
        <div>
          <button class="h-button info" v-on:click="help()">
            <span>
              {{ app.strings.common.help }}
            </span>
          </button>
        </div>
        <div>
          <button class="h-button success ml-10" v-on:click="importFile()">
            <span>
              {{ app.strings.presentation.create.import }}
            </span>
          </button>
        </div>
      </div>
      <div class="presentation-tools">
        <div class="presentation-tools-data view-item">
          <div>
            <div class="label-icon info">
              <font-awesome-icon :icon="['far', 'calendar-alt']" />
              {{ app.strings.presentation.create.tools.date }}
            </div>
            <div>
              <date-picker v-model="presentation.data.date" type="date" input-class="default w-100" format="DD/MM/YYYY"></date-picker>
            </div>
            <div class="label-icon info">
              <font-awesome-icon :icon="['far', 'calendar-alt']" />
              {{ app.strings.presentation.create.tools.date_exam }}
            </div>
            <div>
              <date-picker v-model="presentation.data.date_exam" type="date" input-class="default w-100" format="DD/MM/YYYY"></date-picker>
            </div>
          </div>
          <div>
            <div class="label-icon success">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" />
              {{ app.strings.presentation.create.tools.annotation }}
            </div>
            <div>
              <input type="text" class="default w-100" v-model="presentation.data.annotation" />
            </div>
          </div>
          <div>
            <div class="label-icon salmon">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" />
              {{ app.strings.presentation.create.tools.annotation_association }}
            </div>
            <div>
              <input type="text" class="default w-100" v-model="presentation.data.annotation_association" disabled="true" />
            </div>
          </div>
        </div>
        <div class="view-item">
          <div>
            <ul>
              <li v-html="app.strings.presentation.create.tools.students_new + ' - ' + studentsNew.length + '</b>'" v-if="['new', 'repeater'].includes(type)"></li>
              <li v-html="app.strings.presentation.create.tools.students_repeaters + ' - ' + studentsRepeaters.length + '</b>'" v-if="['new', 'repeater'].includes(type)"></li>
              <li v-html="app.strings.presentation.create.tools.students_traffic + ' - ' + studentsTraffic.length + '</b>'" v-if="type === 'traffic'"></li>
              <li v-html="app.strings.presentation.create.tools.students_circuit + ' - ' + studentsCircuit.length + '</b>'" v-if="type === 'circuit'"></li>
            </ul>
          </div>
          <div>
            <img src="@/assets/images/student.svg" />
            <div v-html="app.strings.presentation.create.tools.students_total">
            </div>
            <div>
              {{ this.students.length }}
            </div>
          </div>
        </div>
      </div>
      <div class="presentation-container view-item flex-1 overflow-auto loading" v-if="loading && !silentlyLoading">
        <loader-dot :size="'25px'" :color_1="'rgb(45, 68, 113)'" :color_2="'rgb(45, 68, 113)'" />
      </div>
      <div class="presentation-container view-item flex-1 overflow-auto" v-else>
        <div class="presentation-tabs">
          <div :class="{selected: (type === 'new')}" v-on:click="type = 'new'" v-if="['new', 'repeater'].includes(type)">
            {{ app.strings.presentation.create.tabs.news }}
          </div>
          <b v-if="['new', 'repeater'].includes(type)">&#8226;</b>
          <div :class="{selected: (type === 'repeater')}" v-on:click="type = 'repeater'" v-if="['new', 'repeater'].includes(type)">
            {{ app.strings.presentation.create.tabs.repeaters }}
          </div>
          <div :class="{selected: (type === 'traffic')}" v-on:click="type = 'traffic'" v-if="type === 'traffic'">
            {{ app.strings.presentation.create.tabs.traffic }}
          </div>
          <div :class="{selected: (type === 'circuit')}" v-on:click="type = 'circuit'" v-if="type === 'circuit'">
            {{ app.strings.presentation.create.tabs.circuit }}
          </div>
        </div>
        <div class="presentation-start" v-if="studentsFiltered.length === 0">
          <div>
            <img src="@/assets/images/students-welcome.svg" />
          </div>
          <div>
            <div v-html="app.strings.presentation.create.welcome"></div>
            <button class="h-button default" v-on:click="add()" v-if="[0, 1, 5].includes(presentation.data.state)">
              <span v-if="!processing">
                {{ app.strings.presentation.create.add }}
              </span>
              <loader-dot :size="'18px'" v-else />
            </button>
          </div>
        </div>
        <div v-else>
          <table class="students-table">
            <tr v-for="(student, index) in studentsFiltered" v-bind:key="student.code">
              <td>
                <div>
                  <b>&#8226;</b> {{ student.first_name }}, {{ student.last_name_1 }} {{ student.last_name_2 }}
                </div>
              </td>
              <td v-if="student.type === 'new' &&  [0, 1, 5].includes(presentation.data.state)">
                <img class="personal-id-icon" src="@/assets/images/personal-id.svg" v-on:click="editStudent(studentIndex(index), false)" />
              </td>
              <td v-if="(student.type === 'traffic' || student.type === 'circuit') &&  [0, 1, 5].includes(presentation.data.state)">
                <img class="personal-id-icon" src="@/assets/images/personal-id.svg" v-on:click="editStudent(studentIndex(index), false)" />
              </td>
              <td v-if="student.type === 'new'">
                <p>
                  <input type="text" class="default text-center" :value="student.code" disabled="true" />
                </p>
                <p>
                  <input type="text" class="default text-center" :value="student.birthdate" disabled="true" />
                </p>
              </td>
              <td v-if="student.type === 'repeater'">
                <input type="text" class="default text-center" :value="student.code" disabled="true" />
              </td>
              <td v-if="student.type === 'traffic' || student.type === 'circuit'">
                <input type="text" class="default text-center" :value="student.code" disabled="true" />
              </td>
              <td v-if="student.type === 'repeater'">
                <input type="text" class="default text-center" :value="student.birthdate" disabled="true" />
              </td>
              <td v-if="student.type === 'new'">
                <p>
                  <select class="default text-center" v-model="student.license" :disabled="![0, 1, 5].includes(presentation.data.state)">
                    <option :value="license" v-for="license in licenses" v-bind:key="license">{{ license }}</option>
                  </select>
                </p>
                <p>
                  <select class="default text-center" v-model="student.language" :disabled="![0, 1, 5].includes(presentation.data.state)">
                    <option value="ES@Castellano@1">Castellano</option>
                    <option value="ING@Inglés@7">Inglés</option>
                    <option value="FRA@Francés@8">Francés</option>
                  </select>
                </p>
                <p>
                  <select class="default text-center" v-model="student.gearshift" :disabled="![0, 1, 5].includes(presentation.data.state)">
                    <option value="manual">{{ app.strings.presentation.create.gearshift.manual }}</option>
                    <option value="auto">{{ app.strings.presentation.create.gearshift.auto }}</option>
                  </select>
                </p>
              </td>
              <td v-if="student.type === 'repeater'">
                <input type="text" class="default text-center" :value="student.license" disabled="true" />
              </td>
              <td v-if="student.type === 'traffic' || student.type === 'circuit'">
                <select class="default text-center" v-model="student.license" :disabled="![0, 1, 5].includes(presentation.data.state)">
                  <option :value="license" v-for="license in licenses" v-bind:key="license">{{ license }}</option>
                </select>
              </td>
              <td>
                <select class="default text-center" v-model="student.center" :disabled="![0, 1, 5].includes(presentation.data.state) || student.type === 'repeater'">
                  <option :value="center" v-for="center in centers" v-bind:key="center.code">{{ center.code }}</option>
                </select>
              </td>
              <td v-if="student.type === 'new' && student.presentation_state === 1">
                <input type="text" class="info text-center" v-model="student.tax" :placeholder="app.strings.presentation.create.student.tax" :disabled="![0, 1, 5].includes(presentation.data.state)" />
              </td>
              <td v-if="student.type === 'traffic' || student.type === 'circuit'">
                <!--<input type="text" class="info text-center" v-model="student.tax" :placeholder="app.strings.presentation.create.student.tax" :disabled="![0, 1, 5].includes(presentation.data.state)" />-->
                <vue-autosuggest
                    class="info"
                    v-model="student.tax"
                    :suggestions="[{data: taxes}]"
                    :input-props="{class: 'info text-center', placeholder: app.strings.presentation.create.student.tax, disabled: ![0, 1, 5].includes(presentation.data.state)}"
                    @selected="(item) => {
                      student.tax = item.item
                      recompute()
                    }"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item }}</span>
                  </template>
                </vue-autosuggest>
              </td>
              <td style="width: 230px; text-align: center;" v-if="[2, 3].includes(presentation.data.state) && student.type === 'new'">
                <span class="badge badge-warning">
                  {{ app.strings.presentation.create.processability[3] }}
                </span>
              </td>
              <td style="width: 230px; text-align: center;" v-if="![2, 3].includes(presentation.data.state) && presentation.data.state < 6 && student.type === 'new'">
                <span class="badge" :class="{'badge-info': student.processability_state === 0, 'badge-success': student.processability_state === 1, 'badge-danger': student.processability_state === 2}">
                  {{ app.strings.presentation.create.processability[student.processability_state] }}
                </span>
                <p class="processability-error" v-if="student.processability_state === 2">
                  {{ student.processability_error.toLowerCase() | capitalize }}
                </p>
                <p class="processability-error" v-if="student.error !== ''">
                  {{ student.error.toLowerCase() | capitalize }}
                </p>
              </td>
              <td style="width: 230px; text-align: center;" v-if="[6, 7, 8].includes(presentation.data.state)">
                <span class="badge" :class="{'badge-info': presentation.data.state === 6, 'badge-warning': presentation.data.state === 7 || presentation.data.state === 8}">
                  {{ app.strings.presentation.create.presentation[presentation.data.state === 6 ? 0 : 1] }}
                </span>
              </td>
              <td style="width: 230px; text-align: center;" v-if="presentation.data.state > 8">
                <span class="badge" :class="{'badge-success': student.presentation_state === 1, 'badge-danger': student.presentation_state === 2}" v-if="student.exam_state <= 1">
                  {{ app.strings.presentation.create.presentation[student.presentation_state + 1] }}
                </span>
                <span class="badge" :class="{'badge-success': student.exam_state === 2, 'badge-danger': student.exam_state === 3}" v-else>
                  {{ app.strings.presentation.create.result[student.exam_state - 2] }}
                </span>
                <p class="processability-error" v-if="student.presentation_state === 2">
                  {{ student.presentation_error.toLowerCase() | capitalize }}
                </p>
                <p class="exam-state" v-if="student.exam_state === 0 && student.presentation_state === 1">
                  {{ app.strings.presentation.create.result[2] }}
                </p>
                <p class="exam-state" v-if="student.exam_state > 0">
                  {{ student.exam_date | dateFormat() }}
                </p>
              </td>
              <td style="width: 180px; text-align: center;">
                <button class="h-button danger" v-on:click="remove(student)" v-if="[0, 1, 5, 10].includes(presentation.data.state)">
                  <span v-if="(!processing && !loading) || (loading && silentlyLoading)">
                    {{ app.strings.common.delete }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
                <button class="h-button danger disabled" v-if="[2, 3, 4, 6, 7, 8, 9].includes(presentation.data.state)">
                  <span>
                    {{ app.strings.common.delete }}
                  </span>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="options-menu">
        <div>
          {{ app.strings.common.options }}
        </div>
        <div>
          <button class="h-button info" v-on:click="stateUpdate(2)" v-if="[1, 5].includes(presentation.data.state) && type !== 'traffic' && type !== 'circuit'">
            <span v-if="(!processing && !loading) || (loading && silentlyLoading)">
              {{ app.strings.presentation.create.check_processability }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
          <button class="h-button default" v-on:click="stateUpdate(1)" v-if="[4, 10].includes(presentation.data.state)">
            <span v-if="(!processing && !loading) || (loading && silentlyLoading)">
              {{ app.strings.presentation.create.edit }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
          <button class="h-button success" v-on:click="stateUpdate(6)" v-if="[4].includes(presentation.data.state) || ([1].includes(presentation.data.state) && (type === 'traffic' || type === 'circuit'))">
            <span v-if="(!processing && !loading) || (loading && silentlyLoading)">
              {{ app.strings.presentation.create.send.button }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
          <button class="h-button success" v-on:click="stateUpdate(6)" v-if="[10].includes(presentation.data.state)">
            <span v-if="(!processing && !loading) || (loading && silentlyLoading)">
              {{ app.strings.presentation.create.send.resend }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
          <button class="h-button default" v-on:click="add()" v-if="[0, 1, 5].includes(presentation.data.state)">
            <span v-if="(!processing && !loading) || (loading && silentlyLoading)">
              {{ app.strings.presentation.create.add }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
          <button class="h-button success" v-on:click="save()" :class="{disabled: students.length === 0}" v-if="[0, 1, 5].includes(presentation.data.state)">
            <span v-if="(!processing && !loading) || (loading && silentlyLoading)">
              {{ app.strings.common.save }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
          <button class="h-button danger" v-on:click="cancel()">
            <span>
              {{ app.strings.common.exit }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <input type='file' v-show="false" accept='application/JSON' ref="import-file" @change="manageImportFile($event.target.files)" />

    <input type="file" v-show="false" ref="personal-id-front-file" name="personal-id-front-file" accept="image/png, image/jpeg" @change="manageFileFront($event.target.files)" />
    <input type="file" v-show="false" ref="personal-id-back-file" name="personal-id-back-file" accept="image/png, image/jpeg" @change="manageFileBack($event.target.files)" />

    <input type="file" v-show="false" ref="authorization-file" name="authorization-file" accept="application/pdf" @change="manageFileAuthorization($event.target.files)" />
    <input type="file" v-show="false" ref="medical-certificate-file" name="medical-certificate-file" accept="application/pdf" @change="manageFileMedicalCertificate($event.target.files)" />

    <file-upload ref="file-upload-image"
      :dropzone="false"
      :data="{
        strings: app.strings.components.file.upload,
        mode: 'single',
        types: ['image'],
        url: app.url.api + app.endpoints.file.upload,
        auth: 'Token ' + app.token,
        debug: false
      }"
      @onError="onUploadError"
      @onSuccess="onUploadSuccess"
    ></file-upload>

    <file-upload ref="file-upload-pdf"
      :dropzone="false"
      :data="{
        strings: app.strings.components.file.upload,
        mode: 'single',
        extensions: ['pdf'],
        url: app.url.api + app.endpoints.file.upload,
        auth: 'Token ' + app.token,
        debug: false
      }"
      @onError="onUploadError"
      @onSuccess="onUploadSuccess"
    ></file-upload>

    <div class="cropping-view" v-show="cropping.state">
      <vue-shape-img ref="vue-shape-image"
        :initRange="[125,125,250,250]"
        :height="500"
        :width="500"
        :useFrame="true"
        :canResizeFrame="true"
      ></vue-shape-img>
      <div class="cropping-actions">
        <button class="h-button danger" v-on:click="croppingCancel()">
          <font-awesome-icon :icon="['fas', 'times']" />
        </button>
        <button class="h-button info" v-on:click="croppingRotate(-1)">
          <font-awesome-icon :icon="['fas', 'undo']" />
        </button>
        <button class="h-button default" v-on:click="croppingCropper(false)" v-if="cropping.cropper">
          <font-awesome-icon :icon="['fas', 'hand-rock']" />
        </button>
        <button class="h-button default" v-on:click="croppingCropper(true)" v-else>
          <font-awesome-icon :icon="['fas', 'crop']" />
        </button>
        <button class="h-button info" v-on:click="croppingRotate(1)">
          <font-awesome-icon :icon="['fas', 'redo']" />
        </button>
        <button class="h-button success" v-on:click="croppingConfirm()">
          <font-awesome-icon :icon="['fas', 'check']" />
        </button>
      </div>
    </div>

    <div class="business-select-view" v-if="business.id === 0">
      <div>
        <div>
          <div>
            <label>{{ app.strings.presentation.create.company.select }}</label>
            <div>
              <select class="default text-center" v-model="business.data">
                <option :value="b" v-for="b in app.business" v-bind:key="b.id">{{ b.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <button class="h-button success" v-on:click="business.id = business.data.id">
            <span>
              {{ app.strings.common.confirm }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="new-form-view" v-if="student.edit" :class="{check: student.check}">
      <div v-if="student.check === false">
        <div>
          <div>
            <label>{{ app.strings.presentation.create.student.code }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.code" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.birthdate }}</label>
            <date-picker v-model="student.data.birthdate_date" type="date" input-class="default w-100" format="DD/MM/YYYY"></date-picker>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.gender }}</label>
            <div>
              <select class="default text-center" v-model="student.data.gender">
                <option value="M">M</option>
                <option value="H">H</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <div>
            <label>{{ app.strings.presentation.create.student.first_name }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.first_name" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.last_name_1 }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.last_name_1" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.last_name_2 }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.last_name_2" :disabled="loading" />
            </div>
          </div>
        </div>

        <div>
          <div>
            <label>{{ app.strings.presentation.create.student.country_birth }}</label>
            <div>
              <select class="default text-center" v-model="student.data.country_birth">
                <option :value="country.code_A3" v-for="(country, index) in countries" v-bind:key="index">{{ country.name }}</option>
              </select>
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.country }}</label>
            <div>
              <select class="default text-center" v-model="student.data.country">
                <option :value="nacionality.code" v-for="(nacionality, index) in nacionalities" v-bind:key="index">{{ nacionality.value }}</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <div style="flex: 0.5;">
            <label>{{ app.strings.presentation.create.student.address_street_type }}</label>
            <div>
              <select class="default text-center" v-model="student.data.address_street_type">
                <option :value="street_type.ine_code" v-for="(street_type, index) in street_types" v-bind:key="index">{{ street_type.value }}</option>
              </select>
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_street_name }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.address_street_name" :disabled="loading" />
            </div>
          </div>
        </div>

        <div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_street_number }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.address_street_number" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_street_block }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.address_street_block" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_street_gate }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.address_street_gate" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_street_stairs }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.address_street_stairs" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_street_floor }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.address_street_floor" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_street_door }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.address_street_door" :disabled="loading" />
            </div>
          </div>
        </div>

        <div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_postal_code }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.address_postal_code" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_state }}</label>
            <div>
              <select class="default text-center" v-model="student.data.address_state">
                <option :value="state.id" v-for="(state, index) in states" v-bind:key="index">{{ state.name }}</option>
              </select>
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.address_city }}</label>
            <div>
              <select class="default text-center" v-model="student.data.address_city">
                <option :value="city.ine_code" v-for="(city, index) in cities.filter((city) => city.state_id === student.data.address_state)" v-bind:key="index">{{ city.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="student.data.type === 'traffic' || student.data.type === 'circuit'">
          <div>
            <label>{{ app.strings.presentation.create.student.practice.teacher_name }}</label>
            <div>
              <!--<input type="text" class="default w-100" v-model="student.data.practice.teacher.name" :disabled="loading" />-->
              <vue-autosuggest
                  v-model="student.data.practice.teacher.name"
                  :suggestions="[{data: teachersNameFiltered}]"
                  :input-props="{class: 'default w-100', placeholder: ''}"
                  @selected="teacherSelected"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.practice.teacher_code }}</label>
            <div>
              <!--<input type="text" class="default w-100" v-model="student.data.practice.teacher.code" :disabled="loading" />-->
              <vue-autosuggest
                  v-model="student.data.practice.teacher.code"
                  :suggestions="[{data: teachersCodeFiltered}]"
                  :input-props="{class: 'default w-100', placeholder: ''}"
                  @selected="teacherSelected"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.code }}</span>
                </template>
              </vue-autosuggest>
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.practice.vehicle_code }}</label>
            <div>
              <!--<input type="text" class="default w-100" v-model="student.data.practice.vehicle.code" :disabled="loading" />-->
              <vue-autosuggest
                  v-model="student.data.practice.vehicle.code"
                  :suggestions="[{data: vehiclesCodeFiltered}]"
                  :input-props="{class: 'default w-100', placeholder: ''}"
                  @selected="vehicleSelected"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.code }}</span>
                </template>
              </vue-autosuggest>
            </div>
          </div>
        </div>

        <div v-if="student.data.type === 'traffic' || student.data.type === 'circuit'">
          <div>
            <label>{{ app.strings.presentation.create.student.practice.register }}</label>
            <div>
              <input type="text" class="default w-100" v-model="student.data.practice.register" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.practice.class_count }}</label>
            <div>
              <input type="number" class="default w-100" v-model="student.data.practice.class_count" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.practice.request_number }}</label>
            <div>
              <input type="number" class="default w-100" v-model="student.data.practice.request_number" :disabled="loading" />
            </div>
          </div>
          <div>
            <label>{{ app.strings.presentation.create.student.practice.convocatory_number }}</label>
            <div>
              <input type="number" class="default w-100" v-model="student.data.practice.convocatory_number" :disabled="loading" />
            </div>
          </div>
        </div>

        <div v-if="student.data.type === 'traffic' || student.data.type === 'circuit'">
          <!--<div class="text-center">
            <label>{{ app.strings.presentation.create.student.practice.authorization }}</label>
            <div>
              <p class="preview-link" v-if="student.data.authorization.length > 0">
                <a :href="app.url.api + '/file/read/' + student.data.authorization + '?token=' + app.token" target="_blank">{{ student.data.authorization }}</a>
              </p>
              <button class="default" v-on:click="uploadAuthorization()" v-if="!loading">
                <span>
                  {{ app.strings.common.select }}
                </span>
              </button>
              <button class="success disabled" v-else>
                <span v-if="!processing && !loading">
                  {{ app.strings.common.save }}
                </span>
                <loader-dot :size="'18px'" v-else />
              </button>
            </div>
          </div>-->
          <div class="text-center">
            <label>{{ app.strings.presentation.create.student.practice.medical_certificate }}</label>
            <div>
              <p class="preview-link" v-if="student.data.medical_certificate.length > 0">
                <a :href="app.url.api + '/file/read/' + student.data.medical_certificate + '?token=' + app.token" target="_blank">{{ student.data.medical_certificate }}</a>
              </p>
              <button class="h-button default" v-on:click="uploadMedicalCertificate()" v-if="!loading">
                <span>
                  {{ app.strings.common.select }}
                </span>
              </button>
              <button class="h-button success disabled" v-else>
                <span v-if="!processing && !loading">
                  {{ app.strings.common.save }}
                </span>
                <loader-dot :size="'18px'" v-else />
              </button>
            </div>
          </div>
        </div>

        <div v-if="student.data.signature !== undefined && student.data.signature.signature !== ''">
          <div class="text-center">
            <img :src="student.data.signature.signature" />
          </div>
        </div>

        <div>
          <button class="h-button danger" v-on:click="cancelStudent()" v-if="!loading">
            <span>
              {{ app.strings.common.cancel }}
            </span>
          </button>
          <button class="h-button info" v-on:click="studentSignature(student.data.code, student.index)">
            <span v-if="!loading">
              {{ app.strings.presentation.config.students.signature }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
          <button class="h-button success" v-on:click="updateStudent()" v-if="studentValid && !loading">
            <span>
              {{ app.strings.common.save }}
            </span>
          </button>
          <button class="h-button success disabled" v-else>
            <span v-if="!processing && !loading">
              {{ app.strings.common.save }}
            </span>
            <loader-dot :size="'18px'" v-else />
          </button>
        </div>
      </div>
      <div v-else>
        <div>
          <div>
            <label>{{ app.strings.presentation.create.student.license }}</label>
            <div>
              <select class="default text-center" v-model="student.data.license">
                <option :value="license" v-for="license in licenses" v-bind:key="license">{{ license }}</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <button class="h-button success" v-on:click="checkStudent()">
            <span>
              {{ app.strings.common.confirm }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="repeaters-view" v-show="repeaters.open">
      <div class="view-item">
        <div class="repeaters-filter">
          <div>
            {{ app.strings.presentation.create.repeaters.select }}
          </div>
          <div>
            <select class="default text-center" v-model="repeaters.center">
              <option :value="center" v-for="center in centers" v-bind:key="center.code">{{ center.code }}</option>
            </select>
            <button class="h-button success ml-20" :class="{disabled: processing || loading}" v-on:click="loadRepeaters()">
              <span v-if="!processing && !loading">
                {{ app.strings.common.search }}
              </span>
              <loader-dot :size="'18px'" v-else />
            </button>
          </div>
        </div>
        <div class="repeaters-list">
          <div class="repeaters-loading" v-if="loading">
            <loader-dot :size="'25px'" :color_1="'rgb(45, 68, 113)'" :color_2="'rgb(45, 68, 113)'" />
          </div>
          <table class="repeaters-table" v-else>
            <tr v-for="repeater in repeatersNotIncluded" v-bind:key="repeater.code + '-' + repeater.license">
              <td>
                {{ repeater.name }}
              </td>
              <td>
                {{ repeater.code }}
              </td>
              <td>
                {{ repeater.birthdate }}
              </td>
              <td>
                {{ repeater.license }}
              </td>
              <td>
                <button class="h-button default ml-20" :class="{disabled: processing || loading}" v-on:click="addRepeater(repeater)">
                  <span v-if="!processing && !loading">
                    {{ app.strings.common.add }}
                  </span>
                  <loader-dot :size="'18px'" v-else />
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div class="repeaters-actions">
          <div></div>
          <div>
            <button class="h-button danger ml-20" :class="{disabled: processing || loading}" v-on:click="repeaters.open = false">
              <span v-if="!processing && !loading">
                {{ app.strings.common.close }}
              </span>
              <loader-dot :size="'18px'" v-else />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="qr-view" v-if="signature.request.length > 0" v-on:click="signature.request = ''">
      <vue-qr v-if="signature.signature === ''" :text="'https://www.auesgestion.com/#/signature/' + signature.request" :size="300"></vue-qr>
      <img :src="signature.signature" v-else/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'presentation-create-view',
  components: {
  },
  data () {
    return {
      recomputation: false,
      processing: false,
      loading: false,
      silentlyLoading: false,
      business: {
        id: 0,
        data: {}
      },
      cropping: {
        state: false,
        cropper: false,
        image: '',
        attempts: 0
      },
      worker: '',
      interval: false,
      licenses: [
        'AM',
        'A1',
        'A2',
        'A',
        'B',
        'B+E',
        'C1',
        'C',
        'D1',
        'D',
        'EC1',
        'C+E',
        'D1+E',
        'D+E',
        'B96'
      ],
      type: 'new',
      presentation: {
        data: {
          id: 0,
          date: new Date(),
          date_exam: new Date(),
          annotation: '',
          annotation_association: '',
          state: 0
        }
      },
      students: [],
      student: {
        edit: false,
        check: false,
        index: 0,
        data: {
          type: '',
          center: {},
          first_name: '',
          last_name_1: '',
          last_name_2: '',
          code: '',
          birthdate: '',
          birthdate_date: new Date(),
          gender: 'M',
          country_birth: 'ESP',
          country: 'ESP',
          address_street_type: 'ACCES',
          address_street_name: '',
          address_street_number: '',
          address_street_block: '',
          address_street_gate: '',
          address_street_stairs: '',
          address_street_floor: '',
          address_street_door: '',
          address_postal_code: '',
          address_state: 1,
          address_city: '01001',
          docs: {
            front: '',
            back: ''
          },
          license: 'B',
          language: 'ES@Castellano@1',
          gearshift: 'manual',
          tax: '',
          processability_state: 0,
          processability_error: '',
          presentation_state: 0,
          presentation_error: '',
          error: ''
        }
      },
      personal_id: {
        front: [],
        back: []
      },
      files_extra: {
        authorization: [],
        medical_certificate: []
      },
      files: [],
      repeaters: {
        open: false,
        data: [],
        center_code: ''
      },
      upload: {
        mode: 'new_manual',
        index: 0,
        target: ''
      },
      countries: [],
      states: [],
      cities: [],
      nacionalities: [],
      street_types: [],
      tax: {
        loading: false,
        data: [],
        count: {
          total: 0,
          free: 0,
          used: 0
        }
      },
      signature: {
        request: '',
        signature: ''
      }
    }
  },
  computed: {
    studentsFiltered () {
      if (this.type === 'new') {
        return this.studentsNew
      } else if (this.type === 'repeater') {
        return this.studentsRepeaters
      } else if (this.type === 'traffic') {
        return this.studentsTraffic
      } else if (this.type === 'circuit') {
        return this.studentsCircuit
      }
      return this.students
    },
    studentsNew () {
      if (this.recomputation) {
        return this.students.filter(student => student.type === 'new')
      }
      return this.students.filter(student => student.type === 'new')
    },
    studentsRepeaters () {
      if (this.recomputation) {
        return this.students.filter(student => student.type === 'repeater')
      }
      return this.students.filter(student => student.type === 'repeater')
    },
    studentsTraffic () {
      if (this.recomputation) {
        return this.students.filter(student => student.type === 'traffic')
      }
      return this.students.filter(student => student.type === 'traffic')
    },
    studentsCircuit () {
      if (this.recomputation) {
        return this.students.filter(student => student.type === 'circuit')
      }
      return this.students.filter(student => student.type === 'circuit')
    },
    repeatersNotIncluded () {
      return this.repeaters.data.filter(repeater => {
        return this.studentsRepeaters.find(student => student.code === repeater.code && student.license === repeater.license) === undefined
      })
    },
    studentValid () {
      let valid = this.student.data.code.length > 0

      /*if (this.student.data.type === 'new') {
        valid = this.student.data.first_name.length > 0 &&
        this.student.data.last_name_1.length > 0 &&
        this.student.data.last_name_2.length > 0 &&
        this.student.data.code.length > 0 &&
        this.student.data.gender.length > 0 &&
        this.student.data.country_birth.length > 0 &&
        this.student.data.country.length > 0 &&
        this.student.data.address_street_type.length > 0 &&
        this.student.data.address_street_name.length > 0 &&
        this.student.data.address_street_number.length > 0 &&
        this.student.data.address_postal_code.length > 0 &&
        this.student.data.address_city.length > 0
      } else if (this.student.data.type === 'traffic' || this.student.data.type === 'circuit') {
        valid = this.student.data.first_name.length > 0 &&
        this.student.data.last_name_1.length > 0 &&
        this.student.data.last_name_2.length > 0 &&
        this.student.data.code.length > 0 &&
        this.student.data.practice.teacher.code.length > 0 &&
        this.student.data.practice.teacher.name.length > 0 &&
        this.student.data.practice.vehicle.code.length > 0 &&
        this.student.data.authorization.length > 0 &&
        this.student.data.medical_certificate.length > 0
      }*/

      return valid
    },
    companies () {
      if (this.app.user.role !== 'assoc') {
        return this.app.companies
      } else {
        return this.app.companies.filter(company => company.business_id === this.business.id)
      }
    },
    centers () {
      let centers = []
      for (let i in this.companies) {
        let company = this.companies[i]
        let companyCenters = this.app.centers.filter(center => center.business_id === company.business_id && center.company_id === company.id)
        for (let j in companyCenters) {
          let center = companyCenters[j]
          centers.push({
            id: center.id,
            company_id: company.id,
            code: company.traffic_data.code_letter + ' ' + this.$options.filters.fillZero(company.traffic_data.code_number, 4) + '/' + this.$options.filters.fillZero(center.code, 2)
          })
        }
      }
      return centers
    },
    studentsNoDoc () {
      for (let i in this.students) {
        let student = this.students[i]
        if (student.type === 'new' && student.docs.front === '') {
          return true
        }
      }
      return false
    },
    teachers () {
      let teachers = []

      for (let i in this.app.teachers) {
        teachers.push({
          code: this.app.teachers[i].code,
          name: this.app.teachers[i].first_name + ' ' + this.app.teachers[i].last_name_1 + ' ' + this.app.teachers[i].last_name_2
        })
      }

      return teachers
    },
    teachersNameFiltered () {
      if (this.student.data.practice.teacher.name.length === 0) {
        return []
      }
      return this.teachers.filter(item => {
        return item.name.toLowerCase().indexOf(this.student.data.practice.teacher.name.toLowerCase()) > -1;
      }).slice(0, 5)
    },
    teachersCodeFiltered () {
      if (this.student.data.practice.teacher.code.length === 0) {
        return []
      }
      return this.teachers.filter(item => {
        return item.code.toLowerCase().indexOf(this.student.data.practice.teacher.code.toLowerCase()) > -1;
      }).slice(0, 5)
    },
    vehicles () {
      let vehicles = []

      for (let i in this.app.vehicles) {
        vehicles.push({
          code: this.app.vehicles[i].code,
          name: this.app.vehicles[i].name
        })
      }

      return vehicles
    },
    vehiclesCodeFiltered () {
      if (this.student.data.practice.vehicle.code.length === 0) {
        return []
      }
      return this.vehicles.filter(item => {
        return item.code.toLowerCase().indexOf(this.student.data.practice.vehicle.code.toLowerCase()) > -1;
      }).slice(0, 5)
    },
    taxes () {
      let free = []
      for (let i in this.tax.data) {
        let tax = this.tax.data[i].tax
        let exists = this.students.findIndex(student => student.tax === tax) !== -1
        if (!exists) {
          free.push(tax)
        }
      }
      if (this.recomputation) {
        return free
      }
      return free
    }
  },
  props: [
    'app'
  ],
  mounted () {
    try {
      this.worker = this.initWorker()
    } catch (err) {
      // console.log(err.message)
      // Error on init worker
    }

    if (this.$router.currentRoute.name === 'presentation.update') {
      this.presentation.data.id = this.$route.params.id
      this.business.id = parseInt(this.app.business[0].id)
      this.business.data = this.app.business[0]
      this.presentationRead(false)
    } else if (this.$router.currentRoute.name === 'presentation.association.update') {
      this.presentation.data.id = this.$route.params.id
      this.business.id = parseInt(this.$route.params.business)
      this.business.data = this.app.business.find(business => business.id === this.business.id)
      this.presentationRead(false)
    } else {
      this.type = this.$route.params.type
      if (this.app.user.role !== 'assoc') {
        this.business.id = parseInt(this.app.business[0].id)
        this.business.data = this.app.business[0]
      } else {
        this.business.id = 0
        this.business.data = this.app.business[0]
      }
      this.loadLocations()

      if (this.type === 'traffic' || this.type === 'circuit') {
        this.taxReadAllFree()
      }
    }

    this.taxCount()

    this.repeaters.center = this.centers[0]

    if (this.app.socket !== '') {
      this.socketConnected()
    }
  },
  methods: {
    socketConnected () {
      this.app.socket.on('signature-updated', (response) => {
        let index = this.students.findIndex((s) => {
          if (s.signature !== undefined) {
            return s.signature.request === response.signature.request
          }
          return false
        })
        if (index !== -1) {
          this.students[index].signature.signature = response.signature.signature
        }
        if (this.signature.request === response.signature.request) {
          this.signature.signature = response.signature.signature
          this.student.data.signature = this.signature
        }
      })
    },
    recompute () {
      this.recomputation = true
      setTimeout(() => {
        this.recomputation = false
      }, 500)
    },
    studentIndex (index) {
      let student = this.studentsFiltered[index]
      return this.students.findIndex(s => s.code === student.code && s.type === student.type)
    },
    presentationRead (silent) {
      this.loading = true
      this.silentlyLoading = silent

      let url = ''
      if (this.$router.currentRoute.name === 'presentation.association.update') {
        url = this.app.url.api + this.app.endpoints.presentation.read.association + '/' + this.business.id + '/' + this.presentation.data.id
      } else if (this.$router.currentRoute.name === 'presentation.update') {
        url = this.app.url.api + this.app.endpoints.presentation.read.one + '/' + this.presentation.data.id
      }

      this.$http.get(
        url,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.presentation.data.date = new Date(response.body.data.presentation.date)
            this.presentation.data.date_exam = new Date(response.body.data.presentation.date_exam)
            this.presentation.data.annotation = response.body.data.presentation.annotation
            this.presentation.data.annotation_association = response.body.data.presentation.annotation_association
            this.presentation.data.state = response.body.data.presentation.state

            clearInterval(this.interval)
            if ([2,3,6,7,8].includes(this.presentation.data.state)) {
              this.interval = setInterval(() => {
                this.presentationRead(true)
              }, 5000)
            }

            this.students = []
            let students = response.body.data.students
            for (let i in students) {
              let student = students[i]

              let birthdate = student.birthdate.split('/')
              birthdate = new Date(parseInt(birthdate[2]), parseInt(birthdate[1]) - 1, parseInt(birthdate[0]))

              if (student.type === 'new' || student.type === 'repeater') {
                this.type = 'new'
              } else if (student.type === 'traffic') {
                this.type = 'traffic'
              } else if (student.type === 'circuit') {
                this.type = 'circuit'
              }

              if (this.type === 'traffic' || this.type === 'circuit') {
                this.taxReadAllFree()
              }

              this.students.push({
                type: student.type,
                center: this.centers.find((center) => {
                  return center.id === student.center_id && center.company_id === student.company_id
                }),
                signature: student.signature,
                first_name: student.first_name.trim(),
                last_name_1: student.last_name_1.trim(),
                last_name_2: student.last_name_2.trim(),
                code: student.code.trim(),
                birthdate: student.birthdate.trim(),
                birthdate_date: birthdate,
                gender: student.gender.trim(),
                country_birth: student.country_birth.trim(),
                country: student.country.trim(),
                address_street_type: student.address_street_type.trim(),
                address_street_name: student.address_street_name.trim(),
                address_street_number: student.address_street_number.trim(),
                address_street_block: student.address_street_block.trim(),
                address_street_gate: student.address_street_gate.trim(),
                address_street_stairs: student.address_street_stairs.trim(),
                address_street_floor: student.address_street_floor.trim(),
                address_street_door: student.address_street_door.trim(),
                address_postal_code: student.address_postal_code.trim(),
                address_state: student.address_state,
                address_city: student.address_city.trim(),
                docs: {
                  front: student.doc_front,
                  back: student.doc_back
                },
                authorization: student.authorization,
                medical_certificate: student.medical_certificate,
                practice: student.practice,
                license: student.license.trim(),
                language: student.language.trim(),
                gearshift: student.gearshift.trim(),
                tax: student.tax.trim(),
                processability_state: student.processability_state,
                processability_error: student.processability_error.trim(),
                presentation_state: student.presentation_state,
                presentation_error: student.presentation_error.trim(),
                exam_state: student.exam_state,
                exam_date: new Date(student.exam_date),
                error: student.error.trim()
              })
            }

            this.loadLocations()
          } else {
            this.loading = false
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-read', error)
            }
          }
        } else {
          this.loading = false
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.presentationRead(silent)
          })
        }
      }, () => {
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.presentationRead(silent)
        })
      })
    },
    cancel () {
      if (this.processing || this.loading) {
        return
      }
      if (![0, 1, 5].includes(this.presentation.data.state)) {
        this.$router.push({name: 'presentation.dashboard'})
      } else {
        this.$swal({
          title: this.app.strings.presentation.create.cancel.title,
          text: this.app.strings.presentation.create.cancel.content,
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: this.app.strings.presentation.create.cancel.exit,
          confirmButtonText: this.app.strings.common.continue
        }).then((result) => {
          if(!result.value) {
            this.$router.push({name: 'presentation.dashboard'})
          }
        })
      }
    },
    save () {
      if (this.app.user.name.toLowerCase() === 'demo') {
        return
      }

      if (this.processing || this.loading || this.students.length === 0) {
        return
      }

      this.loading = true

      if (this.studentsNoDoc) {
        this.requestEmptyDocs(true)
        return
      }

      let students = []
      let letter = ''

      for (let i in this.students) {
        let student = this.students[i]

        let s = {
          business_id: this.business.id,
          company_id: student.center.company_id,
          center_id: student.center.id,
          type: student.type,
          first_name: student.first_name.trim(),
          last_name_1: student.last_name_1.trim(),
          last_name_2: student.last_name_2.trim(),
          code: student.code.trim(),
          birthdate: student.birthdate.trim(),
          gender: student.gender.trim(),
          country_birth: student.country_birth.trim(),
          country: student.country.trim(),
          address_street_type: student.address_street_type.trim(),
          address_street_name: student.address_street_name.trim(),
          address_street_number: student.address_street_number.trim(),
          address_street_block: student.address_street_block.trim(),
          address_street_gate: student.address_street_gate.trim(),
          address_street_stairs: student.address_street_stairs.trim(),
          address_street_floor: student.address_street_floor.trim(),
          address_street_door: student.address_street_door.trim(),
          address_postal_code: student.address_postal_code.trim(),
          address_state: student.address_state,
          address_city: student.address_city.trim(),
          doc_front: student.docs.front,
          doc_back: student.docs.back,
          license: student.license.trim(),
          language: student.language.trim(),
          gearshift: student.gearshift.trim(),
          tax: student.tax.trim()
        }

        if (s.type === 'traffic' || s.type === 'circuit') {
          s.authorization = student.authorization
          s.medical_certificate = student.medical_certificate

          student.practice.class_count = parseInt(student.practice.class_count)
          student.practice.request_number = parseInt(student.practice.request_number)
          student.practice.convocatory_number = parseInt(student.practice.convocatory_number)
          s.practice = student.practice
        }

        students.push(s)

        if (letter !== '' && letter !== student.center.code.split(' ')[0]) {
          this.showError(this.app.strings, 'presentation-create-association')
          return
        }
        letter = student.center.code.split(' ')[0]
      }

      let date = new Date(this.dateFormat(this.presentation.data.date, 'YYYY-MM-DD') + ' EDT')
      let date_exam = new Date(this.dateFormat(this.presentation.data.date_exam, 'YYYY-MM-DD') + ' EDT')

      this.$http.post(
        this.app.url.api + this.app.endpoints.presentation.save,
        {
          id: this.presentation.data.id,
          business_id: this.business.id,
          type: this.type,
          date: date.toISOString(),
          date_exam: date_exam.toISOString(),
          annotation: this.presentation.data.annotation,
          letter: letter,
          students: JSON.stringify(students)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.presentation.data.id = response.body.data.id
            this.$swal({
              title: this.app.strings.presentation.create.success.title,
              text: this.app.strings.presentation.create.success.content,
              icon: 'success',
              confirmButtonText: this.app.strings.common.accept
            }).then(() => {
              if (this.app.user.role === 'assoc') {
                this.$router.push({name: 'presentation.association.update', params: {business: this.business.id, id: response.body.data.id}})
              } else {
                this.$router.push({name: 'presentation.update', params: {id: response.body.data.id}})
              }
              this.presentationRead(false)
              this.app.methods.loadUserData()
            })
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-create', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
        this.loading = false
      })
    },
    stateUpdate (state, confirm, taxConfirm) {
      if (this.app.user.name.toLowerCase() === 'demo') {
        return
      }

      if (this.processing || this.loading || this.presentation.data.state === 0) {
        return
      }

      if (state === 6) {
        for (let i in this.students) {
          let student = this.students[i]

          if (student.first_name.length === 0) {
            this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.first_name)
            this.loading = false
            return
          }
          if (student.last_name_1.length === 0) {
            this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.last_name_1)
            this.loading = false
            return
          }
          if (student.last_name_2.length === 0) {
            this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.last_name_2)
            this.loading = false
            return
          }
          if (student.type === 'new') {
            if (student.last_name_2.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.last_name_2)
              this.loading = false
              return
            }
            if (student.gender.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.gender)
              this.loading = false
              return
            }
            if (student.country_birth.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.country_birth)
              this.loading = false
              return
            }
            if (student.country.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.country)
              this.loading = false
              return
            }
            if (student.address_street_type.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.address_street_type)
              this.loading = false
              return
            }
            if (student.address_street_name.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.address_street_name)
              this.loading = false
              return
            }
            if (student.address_street_number.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.address_street_number)
              this.loading = false
              return
            }
            if (student.address_postal_code.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.address_postal_code)
              this.loading = false
              return
            }
            if (student.address_city.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.address_city)
              this.loading = false
              return
            }
          } else if (student.type === 'traffic' || student.type === 'circuit') {
            if (student.practice.teacher.code.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.practice.teacher_code)
              this.loading = false
              return
            }
            if (student.practice.teacher.name.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.practice.teacher_name)
              this.loading = false
              return
            }
            if (student.practice.vehicle.code.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.practice.vehicle_code)
              this.loading = false
              return
            }
            /*if (student.authorization.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.practice.authorization)
              this.loading = false
              return
            }*/
            if (student.medical_certificate.length === 0) {
              this.showError(this.app.strings, 'presentation-create-field', student.code + ': ' + this.app.strings.presentation.create.student.practice.medical_certificate)
              this.loading = false
              return
            }
          }
        }
        if (this.tax.count.free < this.studentsNew.length && taxConfirm === undefined) {
          this.$swal({
            title: this.app.strings.presentation.create.send.alert_tax.title,
            text: this.app.strings.presentation.create.send.alert_tax.content,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: this.app.strings.common.cancel,
            confirmButtonText: this.app.strings.common.confirm
          }).then((result) => {
            if (result.value) {
              this.stateUpdate(6, true, true)
            }
          })
          return
        }
      }

      if (state === 6 && confirm === undefined && this.app.user.role !== 'assoc') {
        this.$swal({
          title: this.app.strings.presentation.create.send.alert.title,
          text: this.app.strings.presentation.create.send.alert.content,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.app.strings.common.cancel,
          confirmButtonText: this.app.strings.common.confirm
        }).then((result) => {
          if (result.value) {
            this.stateUpdate(6, true, taxConfirm)
          }
        })
        return
      } else if (state === 6 && confirm === undefined && this.app.user.role === 'assoc') {
        this.$swal({
          title: this.app.strings.presentation.create.send.alert_association.title,
          text: this.app.strings.presentation.create.send.alert_association.content,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.app.strings.common.cancel,
          confirmButtonText: this.app.strings.common.confirm
        }).then((result) => {
          if (result.value) {
            this.stateUpdate(6, true, taxConfirm)
          }
        })
        return
      }

      this.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.presentation.state.update,
        {
          business: this.business.id,
          id: this.presentation.data.id,
          state: state
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.$swal({
              title: this.app.strings.presentation.create.state.update.title,
              text: this.app.strings.presentation.create.state.update.content,
              icon: 'success',
              confirmButtonText: this.app.strings.common.accept
            }).then(() => {
              this.presentationRead(false)
            })
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-state-update', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
        this.loading = false
      })
    },
    initWorker() {
      let blob = new Blob(
        [window.drp_worker.toString().replace(/^function .+\{?|\}$/g, '')],
        { type:'text/javascript' }
      )
      let objectURL = URL.createObjectURL(blob)
      let worker = new Worker(objectURL)

      worker.addEventListener('error', (e) => {
        this.addStudentError(e)
      }, false)

      worker.addEventListener('message', (e) => {
        let data = e.data;

        switch (data.type) {
          case 'progress':
            // console.log(data.msg.substr(0, 1).toUpperCase() + data.msg.substr(1))
            break

          case 'error':
            setTimeout(() => {
              // window.alert(data.error)
              this.addStudentError(data.error)
            }, 100)
            break

          case 'result':
            if (data.result.error === undefined) {
              if (data.result.parsed.fields.birthDate === null) {
                this.addStudentError('Parsing error')
              } else {
                this.addStudent(data.result)
              }
            } else {
              this.addStudentError(data.result.error)
            }
            break

          default:
            // console.log(data)
            break
        }
      }, false)

      let pathname = document.location.pathname.split('/')
      pathname.pop()
      pathname = pathname.join('/')

      worker.postMessage({
        cmd: 'config',
        config: {
          fsRootUrl: document.location.origin + pathname
        }
      })

      return worker
    },
    addStudentError (error) {
      if (this.cropping.attempts < 1) {
        this.cropping.attempts++
        this.cropping.state = true
        this.$refs['vue-shape-image'].setImgSrc(this.cropping.image)
        setTimeout(() => {
          this.croppingCropper(true)
        }, 1000)
      } else {
        this.processing = false
        this.$swal({
          title: this.app.strings.presentation.create.ocrizing.error.title,
          text: this.app.strings.presentation.create.ocrizing.error.content + ' (' + error + ')',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: this.app.strings.common.cancel,
          confirmButtonText: this.app.strings.common.retry
        }).then((result) => {
          if (result.value) {
            this.processing = true
            this.cropping.state = true
            this.$refs['vue-shape-image'].setImgSrc(this.cropping.image)
            setTimeout(() => {
              this.croppingCropper(true)
            }, 1000)
          } else {
            this.addStudentEmpty()
          }
        })
      }
    },
    addStudentEmpty () {
      this.students.push({
        type: this.type,
        center: this.centers[0],
        first_name: '',
        last_name_1: '',
        last_name_2: '',
        code: '',
        birthdate: this.dateFormat(new Date(), 'DD/MM/YYYY'),
        birthdate_date: new Date(),
        gender: 'M',
        country_birth: 'ESP',
        country: 'ESP',
        address_street_type: 'ACCES',
        address_street_name: '',
        address_street_number: '',
        address_street_block: '',
        address_street_gate: '',
        address_street_stairs: '',
        address_street_floor: '',
        address_street_door: '',
        address_postal_code: '',
        address_state: 1,
        address_city: '01001',
        docs: {
          front: '',
          back: ''
        },
        authorization: '',
        medical_certificate: '',
        license: 'B',
        language: 'ES@Castellano@1',
        gearshift: 'manual',
        tax: '',
        practice: {
          teacher: {
            name: '',
            code: ''
          },
          vehicle: {
            code: ''
          },
          register: '',
          class_count: 0,
          request_number: 0,
          convocatory_number: 0
        },
        processability_state: 0,
        processability_error: '',
        presentation_state: 0,
        presentation_error: '',
        error: ''
      })

      if (this.presentation.data.state === 5 || this.presentation.data.state === 1) {
        this.presentation.data.state = 0
      }

      this.upload.target = 'docs'
      this.uploadFiles()
    },
    addStudent (data) {
      let birthDate = data.parsed.fields.birthDate
      let birthDateYear = parseInt(birthDate[0] + birthDate[1])
      birthDateYear = (birthDateYear < 90) ? '20' + birthDate[0] + birthDate[1] : '19' + birthDate[0] + birthDate[1]
      let birthDateMonth = birthDate[2] + birthDate[3]
      let birthDateDay = birthDate[4] + birthDate[5]

      let student = this.students.find(o => o.code === data.parsed.fields.optional1)
      if (student !== undefined) {
        this.processing = false
        return
      }

      let last_name = data.parsed.fields.lastName.split(' ')
      let last_name_1 = last_name[0]
      let last_name_2 = (last_name[1] !== undefined ? last_name[1] : last_name_1)

      this.students.push({
        type: this.type,
        center: this.centers[0],
        first_name: data.parsed.fields.firstName.trim(),
        last_name_1: last_name_1.trim(),
        last_name_2: last_name_2.trim(),
        code: data.parsed.fields.optional1.trim(),
        birthdate: birthDateDay + '/' + birthDateMonth + '/' + birthDateYear,
        birthdate_date: new Date(parseInt(birthDateYear), parseInt(birthDateMonth) - 1, parseInt(birthDateDay)),
        gender: 'M',
        country_birth: data.parsed.fields.issuingState.trim(),
        country: data.parsed.fields.issuingState.trim(),
        address_street_type: 'ACCES',
        address_street_name: '',
        address_street_number: '',
        address_street_block: '',
        address_street_gate: '',
        address_street_stairs: '',
        address_street_floor: '',
        address_street_door: '',
        address_postal_code: '',
        address_state: 1,
        address_city: '01001',
        docs: {
          front: '',
          back: ''
        },
        authorization: '',
        medical_certificate: '',
        license: 'B',
        language: 'ES@Castellano@1',
        gearshift: 'manual',
        tax: '',
        practice: {
          teacher: {
            name: '',
            code: ''
          },
          vehicle: {
            code: ''
          },
          register: '',
          class_count: 0,
          request_number: 0,
          convocatory_number: 0
        },
        processability_state: 0,
        processability_error: '',
        presentation_state: 0,
        presentation_error: '',
        error: ''
      })

      if (this.presentation.data.state === 5 || this.presentation.data.state === 1) {
        this.presentation.data.state = 0
      }

      this.upload.target = 'docs'
      this.uploadFiles()
    },
    remove (data) {
      this.$swal({
        title: this.app.strings.presentation.create.remove.title,
        text: this.app.strings.presentation.create.remove.content,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm
      }).then((result) => {
        if (result.value) {
          let index = this.students.findIndex((student) => {
            return student.type === data.type && student.code === data.code
          })
          this.students.splice(index, 1)
        }
      })
    },
    add () {
      if (this.app.user.name.toLowerCase() === 'demo') {
        return
      }

      if (this.processing || this.loading || ![0, 1, 5].includes(this.presentation.data.state)) {
        return
      }

      if (this.type === 'new') {
        this.upload.mode = 'new_manual'
        this.upload.index = 0
        this.uploadStep1()
      } else if (this.type === 'repeater') {
        this.repeaters.open = true
        this.loadRepeaters()
      } else if (this.type === 'traffic' || this.type === 'circuit') {
        this.upload.mode = 'practice_manual'
        this.upload.index = 0
        this.uploadStep1()
      }
    },
    uploadStep1 () {
      let title = this.app.strings.presentation.create.personal_id.step_1_title
      if (this.upload.mode === 'new_import') {
        title = this.app.strings.presentation.create.personal_id.step_1_title + ' - ' + this.students[this.upload.index].code
      }
      this.$swal({
        title: title,
        text: this.app.strings.presentation.create.personal_id.step_1_content,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.continue
      }).then((result) => {
        if(result.value) {
          this.$refs['personal-id-front-file'].click()
        }
      })
    },
    manageFileFront (files) {
      if (files.length === 0) {
        return
      }

      let fileSize = ((files[0].size/1024)/1024).toFixed(2)
      if (fileSize > 2) {
        this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
          this.uploadStep1()
        })
        return
      }

      this.personal_id.front = files

      let reader = new FileReader()
      reader.onload = () => {
        // this.personal_id.front = e.target.result
        this.uploadStep2()
      }
      reader.readAsDataURL(files[0])
    },
    uploadStep2 () {
      let title = this.app.strings.presentation.create.personal_id.step_2_title
      if (this.upload.mode === 'new_import') {
        title = this.app.strings.presentation.create.personal_id.step_2_title + ' - ' + this.students[this.upload.index].code
      }
      this.$swal({
        title: title,
        text: this.app.strings.presentation.create.personal_id.step_2_content,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.continue
      }).then((result) => {
        if(result.value) {
          this.$refs['personal-id-back-file'].click()
        }
      })
    },
    manageFileBack (files) {
      if (files.length === 0) {
        return
      }

      let fileSize = ((files[0].size/1024)/1024).toFixed(2)
      if (fileSize > 2) {
        this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
          this.uploadStep2()
        })
        return
      }

      this.personal_id.back = files

      let reader = new FileReader()
      reader.onload = (e) => {
        if (this.upload.mode === 'new_manual' || this.upload.mode === 'practice_manual') {
          this.processing = true
          // this.personal_id.back = e.target.result

          this.cropping.image = e.target.result
          this.cropping.attempts = 0
          this.worker.postMessage({
            cmd: 'process',
            image: e.target.result
          })
        } else if (this.upload.mode === 'new_import' || this.upload.mode === 'practice_import') {
          this.uploadFiles()
        }
      }
      reader.readAsDataURL(files[0])
    },
    uploadAuthorization () {
      let title = this.app.strings.presentation.create.authorization.title
      if (this.upload.mode === 'new_import') {
        title = this.app.strings.presentation.create.authorization.title + ' - ' + this.students[this.upload.index].code
      }
      this.$swal({
        title: title,
        text: this.app.strings.presentation.create.authorization.content,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.continue
      }).then((result) => {
        if(result.value) {
          this.$refs['authorization-file'].click()
        }
      })
    },
    manageFileAuthorization (files) {
      if (files.length === 0) {
        return
      }

      let fileSize = ((files[0].size/1024)/1024).toFixed(2)
      if (fileSize > 2) {
        this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
          this.uploadStep1()
        })
        return
      }

      this.files_extra.authorization = files

      let reader = new FileReader()
      reader.onload = () => {
        this.upload.mode = 'practice_manual'
        this.upload.index = this.student.index
        this.upload.target = 'authorization'
        this.$refs['file-upload-pdf']._manageFiles(this.files_extra.authorization, this.business.data.oid)
      }
      reader.readAsDataURL(files[0])
    },
    uploadMedicalCertificate () {
      let title = this.app.strings.presentation.create.medical_certificate.title
      if (this.upload.mode === 'new_import') {
        title = this.app.strings.presentation.create.medical_certificate.title + ' - ' + this.students[this.upload.index].code
      }
      this.$swal({
        title: title,
        text: this.app.strings.presentation.create.medical_certificate.content,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.continue
      }).then((result) => {
        if(result.value) {
          this.$refs['medical-certificate-file'].click()
        }
      })
    },
    manageFileMedicalCertificate (files) {
      if (files.length === 0) {
        return
      }

      let fileSize = ((files[0].size/1024)/1024).toFixed(2)
      if (fileSize > 2) {
        this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
          this.uploadStep1()
        })
        return
      }

      this.files_extra.medical_certificate = files

      let reader = new FileReader()
      reader.onload = () => {
        this.upload.mode = 'practice_manual'
        this.upload.index = this.student.index
        this.upload.target = 'medical_certificate'
        this.$refs['file-upload-pdf']._manageFiles(this.files_extra.medical_certificate, this.business.data.oid)
      }
      reader.readAsDataURL(files[0])
    },
    uploadFiles () {
      this.$refs['file-upload-image']._manageFiles(this.personal_id.front, this.business.data.oid)
      this.processing = false
    },
    onUploadSuccess (file, response) {
      if (this.upload.mode === 'new_manual') {
        if (this.students[this.students.length - 1].docs.front === '') {
          this.students[this.students.length - 1].docs.front = response.name
          this.$refs['file-upload-image']._manageFiles(this.personal_id.back, this.business.data.oid)
        } else {
          this.students[this.students.length - 1].docs.back = response.name
          this.processing = false
          this.editStudent(this.students.length - 1, true)
        }
      } else if (this.upload.mode === 'new_import') {
        if (this.students[this.upload.index].docs.front === '') {
          this.students[this.upload.index].docs.front = response.name
          this.$refs['file-upload-image']._manageFiles(this.personal_id.back, this.business.data.oid)
        } else {
          this.students[this.upload.index].docs.back = response.name
          this.requestEmptyDocs()
        }
      } else if (this.upload.mode === 'practice_manual') {
        if (this.upload.target === 'docs') {
          if (this.students[this.students.length - 1].docs.front === '') {
            this.students[this.students.length - 1].docs.front = response.name
            this.$refs['file-upload-image']._manageFiles(this.personal_id.back, this.business.data.oid)
          } else {
            this.students[this.students.length - 1].docs.back = response.name
            this.processing = false
            this.editStudent(this.students.length - 1, true)
          }
        } else if (this.upload.target === 'authorization') {
          this.student.data.authorization = response.name
        } else if (this.upload.target === 'medical_certificate') {
          this.student.data.medical_certificate = response.name
        }
      } else if (this.upload.mode === 'practice_import') {
        if (this.students[this.upload.index].docs.front === '') {
          this.students[this.upload.index].docs.front = response.name
          this.$refs['file-upload-image']._manageFiles(this.personal_id.back, this.business.data.oid)
        } else if (this.students[this.upload.index].docs.back === '') {
          this.students[this.upload.index].docs.back = response.name
          this.$refs['file-upload-pdf']._manageFiles(this.files_extra.authorization, this.business.data.oid)
        } else if (this.students[this.upload.index].authorization === '') {
          this.students[this.upload.index].authorization = response.name
          this.$refs['file-upload-pdf']._manageFiles(this.files_extra.medical_certificate, this.business.data.oid)
        } else {
          this.students[this.upload.index].medical_certificate = response.name
          this.requestEmptyDocs()
        }
      }
    },
    onUploadError (tag, file, queue, index, error) {
      this.processing = false
      this.students.pop()
      this.showError(this.app.strings, 'presentation-create-docs-upload', error, () => {
        this.uploadFiles()
      })
    },
    croppingRotate (deg) {
      this.$refs['vue-shape-image'].rotate(deg)
    },
    croppingCropper (state) {
      this.cropping.cropper = state
      if (state === true) {
        this.$refs['vue-shape-image'].showMask()
      } else {
        this.$refs['vue-shape-image'].closeMask()
      }
    },
    croppingConfirm () {
      this.cropping.state = false
      this.processing = true
      this.worker.postMessage({
        cmd: 'process',
        image: this.$refs['vue-shape-image'].getImg()
      })
    },
    croppingCancel () {
      this.processing = false
      this.loading = false
      this.cropping.state = false
    },
    editStudent (index, check) {
      this.student.edit = true
      this.student.check = check
      this.student.index = index
      this.student.data = JSON.parse(JSON.stringify(this.students[index]))
      this.student.data.birthdate_date = new Date(this.student.data.birthdate_date)
      setTimeout(() => {
        this.student.data.address_postal_code = this.students[index].address_postal_code
      }, 500)
      setTimeout(() => {
        this.student.data.address_state = this.students[index].address_state
      }, 600)
      setTimeout(() => {
        this.student.data.address_city = this.students[index].address_city
      }, 700)
    },
    cancelStudent () {
      this.student.edit = false
    },
    updateStudent () {
      let student = JSON.parse(JSON.stringify(this.student.data))
      student.birthdate_date = new Date(student.birthdate_date)

      let dd = student.birthdate_date.getDate()
      let mm = student.birthdate_date.getMonth() + 1
      let yyyy = student.birthdate_date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      let birthdate = dd + '/' + mm + '/' + yyyy
      student.birthdate = birthdate
      this.students[this.student.index] = student
      this.recompute()
      this.student.edit = false
    },
    checkStudent () {
      if (this.loading === true) {
        return
      }
      this.student.check = false
      this.loading = true

      if (this.student.data.code.trim().length === 0 || this.student.data.birthdate.trim().length === 0 || this.student.data.license.trim().length === 0) {
        this.loading = false
        return
      }

      this.$http.post(
        this.app.url.api + this.app.endpoints.presentation.student.check,
        {
          business: this.business.id,
          company: this.student.data.center.company_id,
          center: this.student.data.center.id,
          code: this.student.data.code,
          birthdate: this.student.data.birthdate,
          license: this.student.data.license
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            let data = response.body.data.data
            this.student.data.first_name = (data.first_name.length > 0 ? data.first_name : this.student.data.first_name)
            this.student.data.last_name_1 = (data.last_name_1.length > 0 ? data.last_name_1 : this.student.data.last_name_1)
            this.student.data.last_name_2 = (data.last_name_2.length > 0 ? data.last_name_2 : this.student.data.last_name_2)
            this.student.data.gender = (data.gender.length > 0 ? data.gender : this.student.data.gender)
            this.student.data.country_birth = (data.country_birth.length > 0 ? data.country_birth : this.student.data.country_birth)
            this.student.data.country = (data.country.length > 0 ? data.country : this.student.data.country)
            this.student.data.address_street_type = (data.address_street_type.length > 0 ? data.address_street_type : this.student.data.address_street_type)
            this.student.data.address_street_name = (data.address_street_name.length > 0 ? data.address_street_name : this.student.data.address_street_name)
            this.student.data.address_street_number = (data.address_street_number.length > 0 ? data.address_street_number : this.student.data.address_street_number)
            this.student.data.address_street_block = (data.address_street_block.length > 0 ? data.address_street_block : this.student.data.address_street_block)
            this.student.data.address_street_gate = (data.address_street_gate.length > 0 ? data.address_street_gate : this.student.data.address_street_gate)
            this.student.data.address_street_stairs = (data.address_street_stairs.length > 0 ? data.address_street_stairs : this.student.data.address_street_stairs)
            this.student.data.address_street_floor = (data.address_street_floor.length > 0 ? data.address_street_floor : this.student.data.address_street_floor)
            this.student.data.address_street_door = (data.address_street_door.length > 0 ? data.address_street_door : this.student.data.address_street_door)
            this.student.data.address_postal_code = (data.address_postal_code.length > 0 ? data.address_postal_code : this.student.data.address_postal_code)
            this.student.data.address_state = ((data.address_state + '').length > 0 ? data.address_state : this.student.data.address_state)
            this.student.data.address_city = ((data.address_city + '').length > 0 ? data.address_city : this.student.data.address_city)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-student-check', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.checkStudent()
          })
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.checkStudent()
        })
      })
    },
    loadRepeaters () {
      if (this.loading === true) {
        return
      }
      this.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.presentation.repeater.read.all + '/' + this.business.id + '/' + this.repeaters.center.company_id + '/' + this.repeaters.center.id,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.repeaters.data = response.body.data.students
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'presentation-repeaters-read', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.loadRepeaters()
          })
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.loadRepeaters()
        })
      })
    },
    addRepeater (repeater) {
      let name = repeater.name.split(' ')
      let first_name = ''
      let last_name = ''
      if (name.length === 2) {
        first_name = name[0]
        last_name = name[1] + '  '
      } else if (name.length === 3) {
        first_name = name[0]
        last_name = name[1] + ' ' + name[2]
      } else if (name.length === 4) {
        first_name = name[0] + ' ' + name[1]
        last_name = name[2] + ' ' + name[3]
      }

      last_name = last_name.split(' ')
      let last_name_1 = last_name[0]
      let last_name_2 = (last_name[1] !== undefined ? last_name[1] : last_name_1)

      this.students.push({
        type: 'repeater',
        center: this.repeaters.center,
        first_name: first_name.trim(),
        last_name_1: last_name_1.trim(),
        last_name_2: last_name_2.trim(),
        code: repeater.code.trim(),
        birthdate: repeater.birthdate.trim(),
        birthdate_date: new Date(),
        gender: 'M',
        country_birth: 'ESP',
        country: 'ESP',
        address_street_type: 'ACCES',
        address_street_name: '',
        address_street_number: '',
        address_street_block: '',
        address_street_gate: '',
        address_street_stairs: '',
        address_street_floor: '',
        address_street_door: '',
        address_postal_code: '',
        address_state: 1,
        address_city: '01001',
        docs: {
          front: '',
          back: ''
        },
        license: repeater.license.trim(),
        language: 'ES@Castellano@1',
        gearshift: 'manual',
        tax: '',
        processability_state: 0,
        processability_error: '',
        presentation_state: 0,
        presentation_error: '',
        error: ''
      })
    },
    loadLocations () {
      this.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.location.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.countries = response.body.data.countries
            this.states = response.body.data.states
            this.cities = response.body.data.cities
            this.nacionalities = response.body.data.nacionalities
            this.street_types = response.body.data.street_types
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'location-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.loadLocations()
          })
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.loadLocations()
        })
      })
    },
    taxCount () {
      if (this.tax.loading) {
        return
      }
      this.tax.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.tax.count + '?business_id=' + this.business.id,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.tax.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.tax.count = response.body.data
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'tax-count', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.taxCount()
          })
        }
      }, () => {
        this.tax.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.taxCount()
        })
      })
    },
    taxReadAllFree () {
      if (this.tax.loading) {
        return
      }
      this.tax.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.tax.read.all + '?state=0',
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.tax.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.tax.data = response.body.data.taxes
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'tax-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.taxCount()
          })
        }
      }, () => {
        this.tax.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.taxCount()
        })
      })
    },
    importFile () {
      if (this.loading) {
        return
      }
      this.$refs['import-file'].click()
    },
    manageImportFile (files) {
      if (files.length === 0) {
        return
      }

      this.loading = true

      let reader = new FileReader()
      reader.onload = () => {
        const input = this.$refs['import-file']
        input.type = 'text'
        input.type = 'file'

        let text = reader.result
        try {
          let data = JSON.parse(text)
          if (data['new'] === undefined && data['repeater'] === undefined && data['traffic'] === undefined && data['circuit'] === undefined) {
            this.showError(this.app.strings, 'presentation-import-malformed', 'Falta alguna de las claves primarias "new", "repeater", "traffic" o "circuit"')
            this.loading = false
            return
          }

          let students = []

          if (data['new'] !== undefined) {
            for (let i in data.new) {
              let student = data.new[i]

              let center = this.centers.find((center) => center.code === student.center)

              if (center === undefined) {
                this.showError(this.app.strings, 'presentation-import-badcenter', student.center)
                this.loading = false
                return
              }

              let s = {
                type: 'new',
                center: center,
                first_name: student.first_name,
                last_name_1: student.last_name_1,
                last_name_2: student.last_name_2,
                code: student.code,
                birthdate: student.birthdate,
                birthdate_date: this.dateParse(student.birthdate, 'DD/MM/YYYY'),
                gender: student.gendet,
                country_birth: student.country_birth,
                country: student.country,
                address_street_type: student.address_street_type,
                address_street_name: student.address_street_name,
                address_street_number: student.address_street_number,
                address_street_block: student.address_street_block,
                address_street_gate: student.address_street_gate,
                address_street_stairs: student.address_street_stairs,
                address_street_floor: student.address_street_floor,
                address_street_door: student.address_street_door,
                address_postal_code: student.address_postal_code,
                address_state: parseInt(student.address_state),
                address_city: student.address_city,
                docs: {
                  front: '',
                  back: ''
                },
                license: student.license,
                language: student.language,
                gearshift: student.gearshift,
                tax: student.tax,
                processability_state: 0,
                processability_error: '',
                presentation_state: 0,
                presentation_error: '',
                error: ''
              }

              if (student.files !== undefined) {
                if (student.files.front !== undefined && student.files.back !== undefined) {
                  if (student.files.front.data != undefined && student.files.back.data !== undefined) {
                    if (student.files.front.data.length > 0 && student.files.back.data.length > 0) {
                      s.files = student.files
                    }
                  }
                }
              }

              students.push(s)
            }
          }

          if (data['repeater'] !== undefined) {
            for (let i in data.repeater) {
              let student = data.repeater[i]

              let center = this.centers.find((center) => center.code === student.center)

              if (center === undefined) {
                this.showError(this.app.strings, 'presentation-import-badcenter', student.center)
                this.loading = false
                return
              }

              students.push({
                type: 'repeater',
                center: center,
                first_name: student.first_name.trim(),
                last_name_1: student.last_name_1.trim(),
                last_name_2: student.last_name_2.trim(),
                code: student.code.trim(),
                birthdate: student.birthdate.trim(),
                birthdate_date: new Date(),
                gender: 'M',
                country_birth: 'ESP',
                country: 'ESP',
                address_street_type: 'ACCES',
                address_street_name: '',
                address_street_number: '',
                address_street_block: '',
                address_street_gate: '',
                address_street_stairs: '',
                address_street_floor: '',
                address_street_door: '',
                address_postal_code: '',
                address_state: 1,
                address_city: '01001',
                docs: {
                  front: '',
                  back: ''
                },
                license: student.license.trim(),
                language: 'ES@Castellano@1',
                gearshift: 'manual',
                tax: '',
                processability_state: 0,
                processability_error: '',
                presentation_state: 0,
                presentation_error: '',
                error: ''
              })
            }
          }

          if (data['traffic'] !== undefined) {
            for (let i in data.traffic) {
              let student = data.traffic[i]

              let center = this.centers.find((center) => center.code === student.center)

              if (center === undefined) {
                this.showError(this.app.strings, 'presentation-import-badcenter', student.center)
                this.loading = false
                return
              }

              students.push({
                type: 'traffic',
                center: center,
                first_name: student.first_name,
                last_name_1: student.last_name_1,
                last_name_2: student.last_name_2,
                code: student.code,
                birthdate: student.birthdate,
                birthdate_date: this.dateParse(student.birthdate, 'DD/MM/YYYY'),
                gender: student.gendet,
                country_birth: student.country_birth,
                country: student.country,
                address_street_type: student.address_street_type,
                address_street_name: student.address_street_name,
                address_street_number: student.address_street_number,
                address_street_block: student.address_street_block,
                address_street_gate: student.address_street_gate,
                address_street_stairs: student.address_street_stairs,
                address_street_floor: student.address_street_floor,
                address_street_door: student.address_street_door,
                address_postal_code: student.address_postal_code,
                address_state: parseInt(student.address_state),
                address_city: student.address_city,
                docs: {
                  front: '',
                  back: ''
                },
                medical_certificate: '',
                authorization: '',
                files: student.files,
                practice: student.practice,
                license: student.license.trim(),
                language: 'ES@Castellano@1',
                gearshift: 'manual',
                tax: student.tax,
                processability_state: 0,
                processability_error: '',
                presentation_state: 0,
                presentation_error: '',
                error: ''
              })
            }
          }

          if (data['circuit'] !== undefined) {
            for (let i in data.circuit) {
              let student = data.circuit[i]

              let center = this.centers.find((center) => center.code === student.center)

              if (center === undefined) {
                this.showError(this.app.strings, 'presentation-import-badcenter', student.center)
                this.loading = false
                return
              }

              students.push({
                type: 'circuit',
                center: center,
                first_name: student.first_name,
                last_name_1: student.last_name_1,
                last_name_2: student.last_name_2,
                code: student.code,
                birthdate: student.birthdate,
                birthdate_date: this.dateParse(student.birthdate, 'DD/MM/YYYY'),
                gender: student.gendet,
                country_birth: student.country_birth,
                country: student.country,
                address_street_type: student.address_street_type,
                address_street_name: student.address_street_name,
                address_street_number: student.address_street_number,
                address_street_block: student.address_street_block,
                address_street_gate: student.address_street_gate,
                address_street_stairs: student.address_street_stairs,
                address_street_floor: student.address_street_floor,
                address_street_door: student.address_street_door,
                address_postal_code: student.address_postal_code,
                address_state: parseInt(student.address_state),
                address_city: student.address_city,
                docs: {
                  front: '',
                  back: ''
                },
                authorization: '',
                medical_certificate: '',
                files: student.files,
                practice: student.practice,
                license: student.license.trim(),
                language: 'ES@Castellano@1',
                gearshift: 'manual',
                tax: student.tax,
                processability_state: 0,
                processability_error: '',
                presentation_state: 0,
                presentation_error: '',
                error: ''
              })
            }
          }

          for (let i in students) {
            let studentA = students[i]
            let exists = false
            for (let j in this.students) {
              let studentB = this.students[j]
              if (studentA.code === studentB.code && studentA.license === studentB.license) {
                exists = true
                this.students[j] = studentA
                break
              }
            }
            if (exists === false) {
              this.students.push(studentA)
            }
          }

          this.requestEmptyDocs()
        } catch (e) {
          this.showError(this.app.strings, 'presentation-import-malformed', e)
          this.loading = false
        }
      }
      reader.readAsText(files[0])
    },
    requestEmptyDocs (save) {
      for (let i in this.students) {
        let student = this.students[i]
        if (student.type === 'new' && student.docs.front === '' && student.files !== undefined) {
          this.upload.mode = 'new_import'
          this.upload.index = i
          this.personal_id.front = []

          let file = this.dataURLtoFile(student.files.front.data, student.files.front.name)

          let fileSize = ((file.size/1024)/1024).toFixed(2)
          if (fileSize > 2) {
            this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
              delete this.students[i].files.front
              this.requestEmptyDocs()
            })
            return
          }

          this.personal_id.front.push(file)
          this.personal_id.back = []

          file = this.dataURLtoFile(student.files.back.data, student.files.back.name)

          fileSize = ((file.size/1024)/1024).toFixed(2)
          if (fileSize > 2) {
            this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
              delete this.students[i].files.back
              this.requestEmptyDocs()
            })
            return
          }

          this.personal_id.back.push(file)
          this.uploadFiles()
          return
        } else if ((student.type === 'traffic' || student.type === 'circuit') && student.docs.front === '' && student.files !== undefined) {
          this.upload.mode = 'practice_import'
          this.upload.index = i
          this.personal_id.front = []

          // Doc front
          let file = this.dataURLtoFile(student.files.front.data, student.files.front.name)

          let fileSize = ((file.size/1024)/1024).toFixed(2)
          if (fileSize > 2) {
            this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
              delete this.students[i].files.front
              this.requestEmptyDocs()
            })
            return
          }

          this.personal_id.front.push(file)

          // Doc back
          this.personal_id.back = []

          file = this.dataURLtoFile(student.files.back.data, student.files.back.name)

          fileSize = ((file.size/1024)/1024).toFixed(2)
          if (fileSize > 2) {
            this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
              delete this.students[i].files.back
              this.requestEmptyDocs()
            })
            return
          }

          this.personal_id.back.push(file)

          // Authorization
          this.files_extra.authorization = []

          file = this.dataURLtoFile(student.files.authorization.data, student.files.authorization.name)

          fileSize = ((file.size/1024)/1024).toFixed(2)
          if (fileSize > 2) {
            this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
              delete this.students[i].files.authorization
              this.requestEmptyDocs()
            })
            return
          }

          this.files_extra.authorization.push(file)

          // Medical certificate
          this.files_extra.medical_certificate = []

          file = this.dataURLtoFile(student.files.medical_certificate.data, student.files.medical_certificate.name)

          fileSize = ((file.size/1024)/1024).toFixed(2)
          if (fileSize > 2) {
            this.showError(this.app.strings, 'presentation-create-docs-upload', fileSize + 'MB', () => {
              delete this.students[i].files.medical_certificate
              this.requestEmptyDocs()
            })
            return
          }

          this.files_extra.medical_certificate.push(file)

          this.uploadFiles()
          return
        }
      }
      for (let i in this.students) {
        let student = this.students[i]
        if (student.type === 'new' && student.docs.front === '') {
          this.upload.mode = 'new_import'
          this.upload.index = i
          this.uploadStep1()
          return
        } else if (student.type === 'new' && student.docs.back === '') {
          this.upload.mode = 'new_import'
          this.upload.index = i
          this.uploadStep2()
          return
        } else if ((student.type === 'traffic' || student.type === 'circuit') && student.docs.front === '') {
          this.upload.mode = 'practice_import'
          this.upload.index = i
          this.uploadStep1()
          return
        } else if ((student.type === 'traffic' || student.type === 'circuit') && student.docs.back === '') {
          this.upload.mode = 'practice_import'
          this.upload.index = i
          this.uploadStep2()
          return
        } else if ((student.type === 'traffic' || student.type === 'circuit') && student.authorization === '') {
          this.upload.mode = 'practice_import'
          this.upload.index = i
          // this.uploadAuthorization()
          this.uploadMedicalCertificate()
          return
        } else if ((student.type === 'traffic' || student.type === 'circuit') && student.medical_certificate === '') {
          this.upload.mode = 'practice_import'
          this.upload.index = i
          this.uploadMedicalCertificate()
          return
        }
      }
      this.loading = false

      if (save === true) {
        this.save()
      }
    },
    teacherSelected (item) {
      this.student.data.practice.teacher.code = item.item.code
      this.student.data.practice.teacher.name = item.item.name
    },
    vehicleSelected (item) {
      this.student.data.practice.vehicle.code = item.item.code
      this.student.data.practice.vehicle.name = item.item.name
    },
    studentSignature (code, index) {
      this.signatureRequest('student', code, index)
    },
    signatureRequest (target, id, index) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.signature.request

      this.$http.post(
        url,
        {
          target: target,
          id: id
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.signature.request = response.body.data.request
            this.signature.signature = ''
            this.students[index].signature = {
              request: this.signature.request,
              signature: ''
            }
            this.student.data.signature = {
              request: this.signature.request,
              signature: ''
            }
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'signature-request', error, () => {
                this.signatureRequest(target, id)
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  },
  watch: {
    'student.data.address_state': {
      handler () {
        let cities = this.cities.filter((city) => city.state_id === this.student.data.address_state)
        if (cities !== undefined && cities.length > 0) {
          this.student.data.address_city = cities[0].ine_code
        }
      },
      deep: true
    },
    'student.data.address_city': {
      handler () {
        let city = this.cities.find((city) => city.ine_code === this.student.data.address_city)
        if (city !== undefined) {
          if (this.student.data.address_postal_code !== city.postal_code) {
            this.student.data.address_postal_code = city.postal_code
          }
        }
      },
      deep: true
    },
    'student.data.address_postal_code': {
      handler () {
        let city = this.cities.find((city) => city.postal_code === this.student.data.address_postal_code)
        if (city !== undefined) {
          let change = true
          let currentCity = this.cities.find((city) => city.ine_code === this.student.data.address_city)
          if (currentCity !== undefined) {
            if (currentCity.postal_code === city.postal_code) {
              change = false
            }
          }
          if (change === true) {
            this.student.data.address_state = city.state_id
            setTimeout(() => {
              this.student.data.address_city = city.ine_code
            }, 1000)
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

#router-view {
  max-width: 1440px;
  margin: auto;
}

.presentation-content {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.presentation-header {
  display: flex;
  margin-bottom: 20px;
}

.presentation-header > img {
  height: 50px;
}

.presentation-header > div:nth-of-type(1) {
  flex: 1;
  justify-content: center;
  align-self: center;
  padding: 0 80px;
  box-sizing: border-box;
  color: $text-color-2;
}

.presentation-header > div:nth-of-type(1) > span {
  font-weight: 600;
}

.presentation-header > div:nth-of-type(1) > b {
  font-size: 20px;
  color: $color-info;
  margin: 0 10px;
}

.presentation-tools {
  display: flex;
  height: 150px;
  margin-bottom: 20px;
}

.presentation-tools > div:nth-of-type(1) {
  margin-right: 10px;
  flex: 0.5;
}

.presentation-tools-data {
  display: flex;
  flex-direction: column;
}

.presentation-tools-data > div:nth-of-type(1) {
  display: flex;
  flex: 1;
  align-items: center;
}

.presentation-tools-data > div:nth-of-type(1) > div {
  flex: 1;
}

.presentation-tools-data > div:nth-of-type(1) > div:nth-of-type(2) {
  margin-right: 10px;
}

.presentation-tools-data > div:nth-of-type(1) > div:nth-of-type(3) {
  margin-left: 10px;
}

.mx-datepicker {
  width: 100% !important;
}

.presentation-tools-data > div:nth-of-type(2) {
  display: flex;
  flex: 1;
  align-items: center;
}

.presentation-tools-data > div:nth-of-type(2) > div:nth-of-type(1) {
  flex: 0.25;
  margin-right: -11px;
}

.presentation-tools-data > div:nth-of-type(2) > div:nth-of-type(2) {
  flex: 0.75;
}

.presentation-tools-data > div:nth-of-type(3) {
  display: flex;
  flex: 1;
  align-items: center;
}

.presentation-tools-data > div:nth-of-type(3) > div:nth-of-type(1) {
  flex: 0.25;
  margin-right: -11px;
}

.presentation-tools-data > div:nth-of-type(3) > div:nth-of-type(2) {
  flex: 0.75;
}

.label-icon.salmon > svg {
  color: $color-salmon;
}

.label-icon.info > svg {
  color: $color-info;
}

.label-icon.success > svg {
  color: $color-success;
}

.presentation-tools > div:nth-of-type(2) {
  position: relative;
  margin-left: 10px;
  flex: 0.5;
  display: flex;
  flex-direction: row;
  background-color: $color-salmon;
}

.presentation-tools > div:nth-of-type(2) > div:nth-of-type(1) {
  flex: 1;
  display: flex;
  align-items: center;
}

.presentation-tools > div:nth-of-type(2) > div:nth-of-type(2) {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 130px;
  box-sizing: border-box;
}

.presentation-tools > div:nth-of-type(2) > div:nth-of-type(2) > img {
  position: absolute;
  top: -40px;
  left: 0;
  height: 180px;
}

.presentation-tools > div:nth-of-type(2) > div:nth-of-type(2) > div:nth-of-type(1) {
  color: $color-salmon-text-1;
  font-size: 12px;
}

.presentation-tools > div:nth-of-type(2) > div:nth-of-type(2) > div:nth-of-type(1) {
  /deep/ b {
    color: #FFF !important;
    font-size: 24px;
    font-weight: bold;
  }
}

.presentation-tools > div:nth-of-type(2) > div:nth-of-type(2) > div:nth-of-type(2) {
  color: $color-salmon-text-2;
  font-weight: bold;
  font-size: 60px;
  padding: 0 20px !important;
  box-sizing: border-box;
}

.presentation-container {
  flex-direction: column;
}

.presentation-container > div {
  width: 100%;
}

.presentation-container.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.presentation-tabs {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 20px 0;
  box-sizing: border-box;
}

.presentation-tabs > div {
  color: $color-grey-1;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.presentation-tabs > div:hover,
.presentation-tabs > div.selected {
  color: $color-danger;
}

.presentation-tabs > b {
  margin: 0 20px;
  color: $color-grey-1;
}

.presentation-start {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.presentation-start > div {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.presentation-start > div:nth-of-type(1) {
  flex: 0.6;
}

.presentation-start > div:nth-of-type(1) > img {
  display: block;
  height: 100%;
  max-height: 100%;
  margin: auto;
}

.presentation-start > div:nth-of-type(2) {
  flex: 0.4;
  font-size: 14px;
  flex-direction: column;
}

.presentation-start > div:nth-of-type(2) > div {
  /deep/ b {
    font-size: 40px;
    font-weight: bold;
  }
}

.presentation-start > div:nth-of-type(2) > button {
  margin: 20px auto;
}

.students-table {
  width: 100%;
  border-spacing: 0 10px;
}

.students-table tr > td {
  padding: 20px;
  box-sizing: border-box;
  border-top: 2px solid rgba($color-grey-light, 0.5);
  border-bottom: 2px solid rgba($color-grey-light, 0.5);
}

.students-table tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 2px solid rgba($color-grey-light, 0.5);
}

.students-table tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 2px solid rgba($color-grey-light, 0.5);
}

.students-table tr > td:nth-of-type(1) {
  padding-left: 40px;
  box-sizing: border-box;
}

.students-table tr > td:nth-of-type(1) > div {
  display: flex;
  align-items: center;
}

.students-table tr > td:nth-of-type(1) > div > b {
  margin-right: 20px;
  font-size: 30px;
  color: $color-info;
}

.students-table tr > td:nth-of-type(2),
.students-table tr > td:nth-of-type(3),
.students-table tr > td:nth-of-type(4),
.students-table tr > td:nth-of-type(5),
.students-table tr > td:nth-of-type(6),
.students-table tr > td:nth-of-type(7),
.students-table tr > td:nth-of-type(8),
.students-table tr > td:nth-of-type(9) {
  width: 1%;
}

.students-table .personal-id-icon {
  height: 50px;
  cursor: pointer;
  opacity: 0.5;
}

.students-table .personal-id-icon:hover {
  opacity: 1;
}

.options-menu {
  margin-top: 20px;
}

.processability-error {
  display: block;
  width: 100%;
  font-size: 10px;
  padding: 10px 0;
  box-sizing: border-box;
}

.exam-state {
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 10px 0;
  box-sizing: border-box;
}

.cropping-view,
.business-select-view,
.new-form-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
}

.cropping-view > div {
  margin: auto;
}

.cropping-actions {
  display: flex;
  width: 500px;
  height: 50px;
}

.cropping-actions > button {
  height: 100%;
  flex: 1;
  border-radius: 0;
}

.business-select-view > div {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 300px;
  background: #FFFFFF;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
}

.business-select-view > div > div > div > div {
  margin: 10px 0;
}

.new-form-view > div {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 800px;
  background: #FFFFFF;
  padding: 20px;
  box-sizing: border-box;
  margin: auto;
}

.new-form-view.check > div {
  max-width: 200px !important;
}

.new-form-view > div > div {
  display: flex;
}

.new-form-view > div > div > div {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}

.new-form-view > div > div > div > div {
  margin: 10px 0;
}

.new-form-view select {
  width: 100%;
}

.new-form-view button {
  margin: auto;
}

.repeaters-view {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.repeaters-view > div {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 800px;
  height: 90%;
  max-height: 600px;
}

.repeaters-filter {
  display: flex;
  padding: 0 0 10px 0;
  border-bottom: 1px solid $color-grey-1;
}

.repeaters-filter > div {
  flex: 1;
}

.repeaters-filter > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.repeaters-actions {
  display: flex;
  padding: 10px 0 0 0;
  border-top: 1px solid $color-grey-1;
}

.repeaters-actions > div {
  flex: 1;
}

.repeaters-actions > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.repeaters-list {
  display: flex;
  flex: 1;
  overflow: auto;
}

.repeaters-loading {
  flex: 1;
}

.repeaters-loading > div {
  display: flex;
  height: 100%;
  align-self: center;
}

.repeaters-table {
  flex: 1;
  width: 100%;
  border-spacing: 0 10px;
}

.repeaters-table tr > td {
  padding: 20px;
  box-sizing: border-box;
  border-top: 2px solid rgba($color-grey-light, 0.5);
  border-bottom: 2px solid rgba($color-grey-light, 0.5);
}

.repeaters-table tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 2px solid rgba($color-grey-light, 0.5);
}

.repeaters-table tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 2px solid rgba($color-grey-light, 0.5);
}

.repeaters-table tr > td:nth-of-type(2),
.repeaters-table tr > td:nth-of-type(3),
.repeaters-table tr > td:nth-of-type(4),
.repeaters-table tr > td:nth-of-type(5) {
  width: 1%;
  white-space: nowrap;
}

.preview-link > a {
  font-size: 12px;
  text-decoration: none;
  color: $color-primary-1;
}

.preview-link > a:hover {
  color: $color-success;
}
</style>
