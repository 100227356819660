<template>
  <div class="student-bag navigation-top-app">
    <template v-if="action != 'confirm_ok' && action != 'confirm_ko'">
      <navigation-top-app
        :app="app"
        :back="() => {
          back()
        }"
      >
        <template v-slot:center>
          <div id="navigation-center">
            {{ app.strings.app.student.practice.bag.shop.title }}
          </div>
        </template>
      </navigation-top-app>
      <transition name="fade">
        <div id="student-bag-content" class="max-width-800 margin-auto" v-if="show && !loading">
          <transition name="fade">
            <div class="practice-type" v-if="form.type.id === 0">
              <div class="form-label">
                {{ app.strings.practice.create.select_type }}
              </div>
              <div class="animated-selector" :class="{open: selectors.type}" :style="'height: ' + (selectors.type ? (types.data.length + 1) * 50 : 50) + 'px;'">
                <div class="selected" v-on:click="selectors.type = !selectors.type">
                  <template v-if="form.type.id !== 0">
                    <b>{{ form.type.license }}</b>, {{ form.type.name }} | {{ form.type.time }}min, {{ form.type.price }}€
                  </template>
                  <template v-else>
                    {{ app.strings.practice.create.click_indicator }}
                  </template>
                </div>
                <div class="options">
                  <div v-for="(type, index) in types.data" v-on:click="typeSelected(type)" v-bind:key="type.id" :style="'top: ' + (selectors.type ? (index + 1) * 50 : 0) + 'px'">
                    <b>{{ type.license }}</b>, {{ type.name }} | {{ type.time }}min, {{ type.price }}€
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div id="practice-data" v-if="toggle">
              <div class="name">
                {{ form.type.name }} | {{ form.type.license }}
              </div>
              <div class="description">
                {{ form.type.description }}
              </div>
              <div class="filler">
              </div>
              <div>
                <label>{{ app.strings.app.student.practice.bag.shop.data.quantity }}</label>
                <vue-number-input
                  size="small"
                  v-model="form.quantity"
                  :step="1"
                  :min="1"
                  center controls></vue-number-input>
              </div>
              <div class="practice-resume">
                <div>
                  <label>{{ app.strings.app.student.practice.bag.shop.data.time }}</label>
                  <div class="c1">
                    {{ form.type.time }}m
                  </div>
                </div>
                <div>
                  <label>{{ app.strings.app.student.practice.bag.shop.data.price }}</label>
                  <div class="c1">
                    {{ form.type.price }}€
                  </div>
                </div>
              </div>
              <div class="practice-resume">
                <div>
                  <label>{{ app.strings.app.student.practice.bag.shop.data.total }}</label>
                  <div class="c2">
                    {{ form.type.price * form.quantity }}€
                  </div>
                </div>
              </div>
              <div class="order-payment-legal">
                <input type="checkbox" v-model="legal.check" /> Acepto los <span v-on:click="legal.view = true">Términos y condiciones</span>
              </div>
              <div id="practice-confirm" v-if="legal.check === true">
                <button class="h-button w-100 default-2" v-on:click="confirm()" v-if="!loading">
                  {{ app.strings.app.student.practice.bag.shop.confirm_order }}
                </button>
              </div>
            </div>
          </transition>
        </div>
      </transition>
      <transition name="fade">
        <div id="student-bag-content" class="max-width-800 margin-auto" v-if="show && loading">
          <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
        </div>
      </transition>

      <form ref="redsys" name="from" :action="tpvAction" method="POST" v-show="false">
        <input type="hidden" name="Ds_SignatureVersion" :value="tpv.Ds_SignatureVersion"/>
        <input type="hidden" name="Ds_MerchantParameters" :value="tpv.Ds_MerchantParameters"/>
        <input type="hidden" name="Ds_Signature" :value="tpv.Ds_Signature"/>
      </form>
    </template>
    <template v-if="action === 'confirm_ok'">
      <div class="confirm ok">
        <div></div>
        <div>
          <font-awesome-icon class="icon chevron" :icon="['fas', 'check']" />
        </div>
        <div>
          {{ app.strings.app.student.practice.bag.shop.confirm.ok }}
        </div>
        <div>
          {{ app.strings.app.student.practice.bag.shop.confirm.redirect }} {{ redirect }}
        </div>
        <div></div>
      </div>
    </template>
    <template v-if="action === 'confirm_ko'">
      <div class="confirm ko">
        <div></div>
        <div>
          <font-awesome-icon class="icon chevron" :icon="['fas', 'times']" />
        </div>
        <div>
          {{ app.strings.app.student.practice.bag.shop.confirm.ko }}
        </div>
        <div>
          {{ app.strings.app.student.practice.bag.shop.confirm.redirect }} {{ redirect }}
        </div>
        <div></div>
      </div>
    </template>

    <div id="legal-view" v-if="legal.view === true" v-on:click="legal.view = false">
      <div v-html="this.legal.text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-student-bag-shop-view',
  data () {
    return {
      show: false,
      toggle: false,
      action: '',
      redirect: 5,
      legal: {
        check: false,
        view: false,
        text: ''
      },
      form: {
        loading: false,
        quantity: 1,
        type: {
          id: 0,
          name: ''
        }
      },
      types: {
        loading: false,
        data: []
      },
      selectors: {
        type: false
      },
      tpv: {
        env: 0,
        Ds_SignatureVersion: "",
        Ds_MerchantParameters: "",
        Ds_Signature: ""
      }
    }
  },
  props: [
    'app'
  ],
  computed: {
    loading () {
      if (this.types.loading) {
        return true
      }

      if (this.form.loading) {
        return true
      }

      return false
    },
    tpvAction () {
      if (this.tpv.env === 0) {
        return 'https://sis-t.redsys.es:25443/sis/realizarPago'
      } else {
        return 'https://sis.redsys.es/sis/realizarPago'
      }
    }
  },
  mounted () {
    this.show = true

    this.action = this.$route.query.action

    if (this.action === 'confirm_ok' || this.action === 'confirm_ko') {
      let intervalID = setInterval(() => {
        this.redirect--
        if (this.redirect === 0) {
          clearInterval(intervalID)
          this.$router.push({name: 'app.student.practice.bag.list', query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
        }
      }, 1000)
    }

    this.getTPV()
    this.typesRead()
  },
  methods: {
    back () {
      if (this.form.type.id === 0) {
        this.$router.push({name: 'app.student.practice.bag.list', query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
      } else {
        this.toggle = false
        setTimeout(() => {
          this.form.type.id = 0
        }, 1100)
      }
    },
    typesRead () {
      if (this.types.loading) {
        return
      }

      this.types.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.type.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.types.data = response.body.data.types
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-type-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.types.loading = false
      }, () => {
        this.types.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    typeSelected (type) {
      this.selectors.type = false;
      this.form.type = type;
      setTimeout(() => {
        this.toggle = true
      }, 1100)
    },
    getTPV () {
      if (this.form.loading) {
        return
      }

      this.form.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.tpv.read.one,
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.form.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.legal.text = response.body.data.tpv.legal
          } else {

            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-practice-bag-order-create', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.form.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    confirm () {
      if (this.form.loading) {
        return
      }

      this.form.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.student.practice.bag.order.create,
        {
          order: JSON.stringify({
            practice_type: this.form.type.id,
            quantity: this.form.quantity
          })
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.tpv = response.body.data.redsys
            setTimeout(() => {
              this.$refs.redsys.submit()
            }, 500)
          } else {
            this.form.loading = false
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-practice-bag-order-create', error)
            }
          }
        } else {
          this.form.loading = false
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.form.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#student-bag-content {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}

.practice-type {
  flex: 1;
}

#practice-data {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#practice-data > .filler {
  flex: 1;
}

#practice-data > div.name {
  font-size: 18px;
  font-weight: bold;
  padding: 12px 0;
  box-sizing: border-box;
}

#practice-data > div.description {
  font-size: 16px;
  padding: 12px 0;
  box-sizing: border-box;
}

#practice-data > div > label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 0;
  text-align: center;
}

.practice-resume {
  display: flex;
  padding: 12px 0;
  box-sizing: border-box;
}

.practice-resume > div {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.practice-resume > div > label {
  font-size: 14px;
  font-weight: bold;
  padding: 12px 0;
}

.practice-resume > div > div {
  display: flex;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  color: #FFF;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.practice-resume > div > div.c1 {
  background-color: $color-primary-1;
}

.practice-resume > div > div.c2 {
  background-color: $color-primary-2;
}

#practice-confirm {
  padding: 12px 0;
  box-sizing: border-box;
}

.confirm {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirm.ok {
  background-color: $color-success;
}

.confirm.ko {
  background-color: $color-danger;
}

.confirm > div {
  padding: 12px 25px;
  box-sizing: border-box;
}

.confirm > div:nth-child(1) {
  flex: 1;
}

.confirm > div:nth-child(2) {
  color: #FFF;
  font-size: 100px;
}

.confirm > div:nth-child(3) {
  color: #FFF;
  font-size: 20px;
}

.confirm > div:nth-child(4) {
  color: #FFF;
  font-size: 18px;
}

.confirm > div:nth-last-of-type(1) {
  flex: 1;
}

.order-payment-legal {
  padding: 25px 0;
  box-sizing: border-box;
  text-align: center;
}

.order-payment-legal > span {
  color: $color-primary-1;
  cursor: pointer;
}

.order-payment-legal > span:hover {
  color: $color-primary-2;
}

#legal-view {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 25px;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.25);
  z-index: 10000;
}

#legal-view > div {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  display: block;
  top: 0;
  left: 0;
  margin: 0;
  padding: 25px;
  box-sizing: border-box;
  overflow: auto;
}
</style>
