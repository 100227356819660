<template>
  <div class="tax-list-view">
    <div class="tax-header">
      <img src="@/assets/images/logo_aues_blue.svg" />
      <div>
        <span>{{ app.user.name }}</span> <b>&#8226;</b> {{ app.user.business.name }}
      </div>
      <div>
        <button class="h-button info" v-on:click="help()">
          <span>
            {{ app.strings.common.help }}
          </span>
        </button>
      </div>
      <div>
        <button class="h-button default ml-20" v-on:click="back()">
          <span>
            {{ app.strings.common.back }}
          </span>
        </button>
      </div>
    </div>
    <div class="tax-list-container view-item flex-1 overflow-auto loading" v-if="loading">
      <loader-dot :size="'25px'" :color_1="'rgb(45, 68, 113)'" :color_2="'rgb(45, 68, 113)'" />
    </div>
    <div class="tax-list-container view-item flex-1 overflow-auto" v-else>
      <div class="tabs">
        <div :class="{selected: (tab === 'free')}" v-on:click="tab = 'free'">
          {{ app.strings.tax.list.tabs.free }}
        </div>
        <b v-if="app.user.role !== 'assoc'">&#8226;</b>
        <div :class="{selected: (tab === 'used')}" v-on:click="tab = 'used'">
          {{ app.strings.tax.list.tabs.used }}
        </div>
        <button class="h-button success" v-on:click="add()" v-if="tab === 'free' && !tax.add">
          <span v-if="!loading">
            {{ app.strings.common.add }}
          </span>
          <loader-dot :size="'18px'" v-else />
        </button>
        <button class="h-button success" v-on:click="taxCreate()" v-if="tab === 'free' && tax.add && tax.data.tax.length > 0">
          <span v-if="!loading">
            {{ app.strings.common.save }}
          </span>
          <loader-dot :size="'18px'" v-else />
        </button>
        <button class="h-button success disabled" v-if="tab === 'free' && tax.add && tax.data.tax.length === 0">
          <span v-if="!loading">
            {{ app.strings.common.save }}
          </span>
          <loader-dot :size="'18px'" v-else />
        </button>
      </div>
      <table class="taxes-table">
        <tr>
          <th class="w-1">
            #
          </th>
          <th class="w-1">
            {{ app.strings.tax.list.header.tax }}
          </th>
          <th>
            {{ app.strings.tax.list.header.company }}
          </th>
          <th style="width: 200px" v-if="tab === 'used'">
            {{ app.strings.tax.list.header.student }}
          </th>
          <th class="w-1">
            {{ app.strings.tax.list.header.type }}
          </th>
          <th class="w-1">
            {{ app.strings.tax.list.header.buy }}
          </th>
          <th style="width: 110px;">
            {{ app.strings.tax.list.header.date }}
          </th>
          <th style="width: 180px;" v-if="tab === 'used'"></th>
          <th style="width: 180px;"></th>
        </tr>
        <tr v-if="tax.add">
          <td></td>
          <td>
            <input type="text" class="default text-center" v-model="tax.data.tax" />
          </td>
          <td>
            <select class="default text-center" v-model="tax.data.company_option">
              <option :value="company.relations.facilauto" v-for="company in app.companies" v-bind:key="company.id">{{ company.name }}</option>
            </select>
          </td>
          <td>
            <select class="default text-center" v-model="tax.data.type">
              <option :value="type" v-for="type in app.strings.tax.list.types" v-bind:key="type">{{ type }}</option>
            </select>
          </td>
          <td>
            <select class="default text-center" v-model="tax.data.buy_option">
              <option :value="index" v-for="(buy, index) in app.strings.tax.list.buy" v-bind:key="index">{{ buy }}</option>
            </select>
          </td>
          <td style="width: 110px;">
            <date-picker v-model="tax.data.date" type="date" input-class="default w-100" format="DD-MM-YYYY"></date-picker>
          </td>
          <td style="width: 180px;">
            <button class="h-button danger w-100" v-on:click="tax.add = false">
              <span v-if="!loading">
                {{ app.strings.common.cancel }}
              </span>
              <loader-dot :size="'18px'" v-else />
            </button>
          </td>
        </tr>
        <tr v-for="(tax, index) in taxesFiltered" v-bind:key="tax.tax">
          <td class="w-1">
            {{ index + 1 }}
          </td>
          <td class="w-1">
            {{ tax.tax }}
          </td>
          <td>
            {{ tax.company.name }}
          </td>
          <td style="width: 200px" v-if="tab === 'used'" v-html="tax.student.replace(' - ', '<br><b>') + '</b>'">
          </td>
          <td class="w-1">
            {{ tax.type }}
          </td>
          <td class="w-1" v-if="tax.buy < 2">
            {{ app.strings.tax.list.buy.dgt }}
          </td>
          <td class="w-1" v-else>
            {{ app.strings.tax.list.buy.association }}
          </td>
          <td style="width: 110px;">
            {{ tax.date | dateParse('YYYY-MM-DD') | dateFormat('DD-MM-YYYY') }}
          </td>
          <td style="width: 180px" v-if="tab === 'used'">
            <button class="h-button default w-100" v-on:click="taxFree(tax)">
              <span v-if="!loading">
                {{ app.strings.tax.list.free.button }}
              </span>
              <loader-dot :size="'18px'" v-else />
            </button>
          </td>
          <td style="width: 180px">
            <button class="h-button danger w-100" v-on:click="taxDelete(tax)">
              <span v-if="!loading">
                {{ app.strings.tax.list.delete.button }}
              </span>
              <loader-dot :size="'18px'" v-else />
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tax-list-view',
  data () {
    return {
      tab: 'free',
      loading: false,
      taxes: {
        data: []
      },
      tax: {
        add: false,
        data: {
          tax: '',
          company_option: 0,
          company: {},
          type: '2.1',
          buy_option: 'association',
          buy: 3,
          date: new Date()
        }
      }
    }
  },
  computed: {
    taxesFiltered () {
      let filtered = this.taxes.data
      if (this.tab === 'free') {
        filtered = this.taxes.data.filter(tax => tax.state === 0)
      } else {
        filtered = this.taxes.data.filter(tax => tax.state === 1)
      }
      return filtered
    }
  },
  props: [
    'app'
  ],
  mounted () {
    this.taxReadAll()
  },
  methods: {
    help () {},
    back () {
      this.$router.push({name: 'presentation.dashboard'})
    },
    add () {
      if (this.tax.add) {
        return
      }
      this.tax.add = true
      this.tax.data = {
        tax: '',
        company_option: this.app.companies[0].relations.facilauto,
        company: this.app.companies[0],
        type: '2.1',
        buy_option: 'association',
        buy: 3,
        date: new Date()
      }
    },
    taxReadAll () {
      this.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.tax.read.all,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.taxes.data = response.body.data.taxes
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'tax-read-all', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status, () => {
            this.taxReadAll()
          })
        }
      }, () => {
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable', () => {
          this.taxReadAll()
        })
      })
    },
    taxCreate () {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.tax.create

      this.tax.data.date = this.dateFormat(this.tax.data.date, 'YYYY-MM-DD')
      this.tax.data.buy = this.tax.data.buy_option === 'association' ? 3 : 2
      this.tax.data.company = this.app.companies.find(company => company.relations.facilauto === this.tax.data.company_option)

      this.$http.post(
        url,
        {
          tax: JSON.stringify(this.tax.data)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.tax.add = false
            this.taxReadAll()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'tax-create', error, () => {
                this.taxCreate()
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    taxDelete (tax) {
      this.$swal({
        title: this.app.strings.tax.list.delete.title,
        text: this.app.strings.tax.list.delete.content,
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm
      }).then((result) => {
        if(result.value) {
          this.taxDeleteConfirm(tax)
        }
      })
    },
    taxDeleteConfirm (tax) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.tax.delete

      this.$http.post(
        url,
        {
          tax: tax.tax
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.taxReadAll()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'tax-delete', error, () => {
                this.taxCreate()
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    taxFree (tax) {
      this.$swal({
        title: this.app.strings.tax.list.free.title,
        text: this.app.strings.tax.list.free.content,
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: this.app.strings.common.cancel,
        confirmButtonText: this.app.strings.common.confirm
      }).then((result) => {
        if(result.value) {
          this.taxFreeConfirm(tax)
        }
      })
    },
    taxFreeConfirm (tax) {
      if (this.loading) {
        return
      }

      this.loading = true

      let url = this.app.url.api + this.app.endpoints.tax.free

      this.$http.post(
        url,
        {
          tax: tax.tax
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.taxReadAll()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'tax-free', error, () => {
                this.taxCreate()
              })
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

#router-view {
  max-width: 1440px;
  margin: auto;
}

.tax-header {
  display: flex;
  margin-bottom: 20px;
}

.tax-header > img {
  height: 50px;
}

.tax-header > div:nth-of-type(1) {
  flex: 1;
  justify-content: center;
  align-self: center;
  padding: 0 80px;
  box-sizing: border-box;
  color: $text-color-2;
}

.tax-header > div:nth-of-type(1) > span {
  font-weight: 600;
}

.tax-header > div:nth-of-type(1) > b {
  font-size: 20px;
  color: $color-info;
  margin: 0 10px;
}

.tax-list-container {
  flex-direction: column;
}

.tax-list-container > div {
  width: 100%;
}

.tax-list-container.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.taxes-table {
  width: 100%;
  border-spacing: 0 10px;
}

table td {
  font-size: 14px;
}

.taxes-table tr > th {
  text-align: left;
  padding: 20px;
}

.taxes-table tr > td {
  padding: 20px;
  box-sizing: border-box;
  border-top: 2px solid rgba($color-grey-light, 0.5);
  border-bottom: 2px solid rgba($color-grey-light, 0.5);
}

.taxes-table tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 2px solid rgba($color-grey-light, 0.5);
}

.taxes-table tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: 2px solid rgba($color-grey-light, 0.5);
}

.taxes-table tr > th:nth-of-type(1),
.taxes-table tr > td:nth-of-type(1) {
  padding-left: 40px;
  box-sizing: border-box;
}

.taxes-table tr > td:nth-of-type(1) > div {
  display: flex;
  align-items: center;
}

.taxes-table tr > td:nth-of-type(1) > div > b {
  margin-right: 20px;
  font-size: 30px;
  color: $color-info;
}

.taxes-table tr > td.w-1,
.taxes-table tr > th.w-1 {
  width: 1%;
}

.mx-datepicker {
  width: 100%;
}

.taxes-table .personal-id-icon {
  height: 50px;
  cursor: pointer;
  opacity: 0.5;
}

.taxes-table .personal-id-icon:hover {
  opacity: 1;
}
</style>
