<template>
  <div class="student-list navigation-top-app">
    <navigation-top-app
      :app="app"
      :back="() => {
        $router.push({name: 'app.teacher.home', query: {origin: 'app', navigationLeft: 'false', token: app.token}})
      }"
    >
      <template v-slot:center>
        <div id="navigation-center">
          {{ app.strings.app.student.list.title }}
        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="student-list-content" v-if="show && !loading">
        <student-list
          :app="app"
          :students="student.data"
          :search="true"
          @onSelect="(student) => {
            openProfile(student)
          }"></student-list>
      </div>
    </transition>
    <transition name="fade">
      <div id="student-list-content" v-if="show && loading">
        <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app-student-list-view',
  data () {
    return {
      show: false,
      student: {
        loading: false,
        data: []
      }
    }
  },
  props: [
    'app'
  ],
  computed: {
    loading () {
      if (this.student.loading) {
        return true
      }

      return false
    }
  },
  mounted () {
    this.show = true

    this.studentReadAll()
  },
  methods: {
    studentReadAll () {
      if (this.student.loading) {
        return
      }

      this.student.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.student.read.all + '?teacher=' + this.app.user.code,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.student.data = response.body.data.students
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.student.loading = false
      }, () => {
        this.student.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    openProfile (student) {
      this.$router.push({name: 'app.student.profile', params: {code: student.code}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#student-list-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}
</style>
