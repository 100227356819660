import BiometricRegisterView from '@/views/biometric/register.view'

const AppRoutes = [
  {
    path: '/biometric/register',
    name: 'biometric.register',
    component: BiometricRegisterView
  }
]

export default AppRoutes
