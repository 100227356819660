<template>
  <div class="student-profile navigation-top-app">
    <navigation-top-app
      :app="app"
      :back="() => {
        if (app.user.role === 'teacher') {
          $router.push({name: 'app.student.list', query: {origin: 'app', navigationLeft: 'false', token: app.token}})
        } else if (app.user.role === 'student') {
          this.app.methods.back()
        }
      }"
    >
      <template v-slot:center>
        <div id="navigation-center">
          {{ app.strings.app.student.profile.title }}
        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="student-profile-content" class="max-width-800 margin-auto" v-if="show && !loading">
        <div class="student-data">
          <img src="@/assets/images/avatar.svg" />
          <div>
            {{ student.data.first_name }} {{ student.data.last_name_1 }} {{ student.data.last_name_2 }}
          </div>
          <div>
            {{ student.data.code }}
            <template v-if="student.data.phone !== '' && student.data.email !== ''">
              <br>
              <div class="form-label">
                {{ app.strings.app.student.profile.contact }}
              </div>
              {{ student.data.phone }}
              <br v-if="student.data.phone !== ''">
              {{ student.data.email }}
              <p class="contact" v-if="app.user.role === 'teacher'">
                <a :href="'tel:' + student.data.phone">
                  <font-awesome-icon class="icon" :icon="['fas', 'phone-square-alt']" />
                </a>
                <a>
                  <font-awesome-icon class="icon" :icon="['fab', 'whatsapp-square']" v-on:click="openWhatsapp(student.data.phone)" />
                </a>
                <a :href="'mailto:' + student.data.email">
                  <font-awesome-icon class="icon" :icon="['fas', 'envelope-square']" />
                </a>
              </p>
            </template>
          </div>
          <div>
            <div class="form-label">
              {{ app.strings.app.student.profile.practice.balance }}
            </div>
            {{ student.data.config.practice.balance }}
          </div>
        </div>

        <menu-vertical
          :app="app"
          :color="'blue'"
          :bold="true"
          :padding="{
            top: 0,
            sides: 0
          }"
          :options="options"
        />
      </div>
    </transition>
    <transition name="fade">
      <div id="student-profile-content" class="max-width-800 margin-auto" v-if="show && loading">
        <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
      </div>
    </transition>
    <div class="student-practice-pending" v-show="this.practice.pending.length > 0">
      <div class="student-practice-pending-form title">
        <div class="student-practice-pending-title">
          {{ app.strings.app.student.profile.practice.pending.title }}
        </div>
      </div>
      <div class="student-practice-pending-form">
        <div class="student-practice-pending-data" v-if="this.practice.pending.length > 0">
          <div>
            <b>{{ app.strings.app.student.profile.practice.pending.date }}</b>
            <br>
            <br>
            {{ this.practice.pending[0].date | normalizeDate('DD/MM/YYYY - HH:mm:ss') }}
          </div>
          <div>
            <b>{{ app.strings.app.student.profile.practice.pending.time }}</b>
            <br>
            <br>
            {{ this.practice.pending[0].time_real }} m
          </div>
        </div>
        <div class="student-practice-pending-signature">
          <div class="signature-pad" :class="{'error-blink': signature.error}">
            <div>
              <VueSignaturePad height="150px" ref="signaturePad" :options="{
                backgroundColor: 'rgb(230, 230, 230)',
                onBegin: () => {$refs.signaturePad.resizeCanvas()}
              }" />
            </div>
            <font-awesome-icon class="icon clear" :icon="['fas', 'trash']" v-on:click="$refs.signaturePad.clearSignature" v-if="$refs.signaturePad !== undefined" />
          </div>
          <br>
          <button class="h-button w-100 default-2" v-on:click="sign()" v-if="signature.loading === false">
            {{ app.strings.app.student.profile.practice.pending.sign }}
          </button>
          <div v-else>
            <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-student-profile-view',
  data () {
    return {
      show: false,
      student: {
        loading: false,
        data: {
          id: 0
        }
      },
      practice: {
        pending: []
      },
      signature: {
        loading: false,
        data: '',
        error: false
      }
    }
  },
  props: [
    'app'
  ],
  computed: {
    loading () {
      if (this.student.loading) {
        return true
      }

      return false
    },
    options () {
      let options = [
        {
          type: 'button',
          icon: ['far', 'calendar-alt'],
          label: this.app.strings.app.student.profile.menu.calendar,
          action: () => {
            this.openAnimated('calendar')
          }
        },
        {
          type: 'button',
          icon: ['fas', 'chart-pie'],
          label: this.app.strings.app.student.profile.menu.evolution_practice,
          action: () => {
            this.openAnimated('evolution')
          }
        }
      ]

      if (this.app.user.role === 'teacher') {
        options.push({
          type: 'button',
          icon: ['far', 'file'],
          label: this.app.strings.app.student.profile.menu.dgt_file,
          action: () => {
            this.openAnimated('reports')
          }
        })
      } else if (this.app.user.role === 'student') {
        options.push({
          type: 'button',
          icon: ['fas', 'shopping-bag'],
          label: this.app.strings.app.student.profile.menu.practice_bag,
          action: () => {
            this.openAnimated('practice_bag')
          }
        })
      }

      return options
    }
  },
  mounted () {
    this.student.data.code = this.$route.params.code

    if (this.app.user.role === 'student') {
      this.student.data.code = this.app.user.code
    }

    this.show = true

    this.studentRead()
  },
  methods: {
    studentRead () {
      if (this.student.loading) {
        return
      }

      this.student.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.student.read.one + this.student.data.code,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        if (response.status === 200) {
          if (response.body.success === true) {
            this.student.data = response.body.data.student

            let pending = response.body.data.practice.pending
            for (let i in pending) {
              pending[i].date = new Date(pending[i].date)
              pending[i].date.setHours(parseInt(pending[i].hour), parseInt(pending[i].minute), 0, 0)
            }
            this.practice.pending = pending
            setTimeout(() => {
              this.$refs.signaturePad.resizeCanvas()
            }, 200)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'student-search', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
        this.student.loading = false
      }, () => {
        this.student.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    openAnimated (target) {
      this.show = false
      setTimeout(() => {
        this.open(target)
      }, 500)
    },
    open (target) {
      switch (target) {
        case 'calendar':
          this.$router.push({name: 'app.practice.calendar', query: {origin: 'app', navigationLeft: 'false', token: this.app.token, student: this.student.data.code}})
          break;
        case 'evolution':
          this.$router.push({name: 'app.student.evolution', params: {code: this.student.data.code}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          break;
        case 'reports':
          this.$router.push({name: 'app.student.dgtreport', params: {code: this.student.data.code}, query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          break;
        case 'practice_bag':
          this.$router.push({name: 'app.student.practice.bag.list', query: {origin: 'app', navigationLeft: 'false', token: this.app.token}})
          break;
      }
    },
    sign () {
      if (this.signature.loading) {
        return
      }

      this.signature.error = false

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature('image/jpeg')
      if (isEmpty) {
        this.signature.error = true
        return
      }

      this.signature.data = data

      this.signature.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.practice.sign,
        {
          id: this.practice.pending[0].id,
          signature: data
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.signature.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.$refs.signaturePad.clearSignature()
            this.practice.pending.splice(0, 1)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-sign', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.signature.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#student-profile-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
}

.student-data {
  text-align: center;
  padding: 12px;
  box-sizing: border-box;
}

.student-data > img {
  width: 100%;
  max-width: 128px;
  padding: 6px 0;
  box-sizing: border-box;
}

.student-data > div {
  padding: 6px 0;
  box-sizing: border-box;
  color: $text-color-1;
}

.student-data > div:nth-of-type(1) {
  font-size: 16px;
  font-weight: bold;
}

.student-data > div:nth-of-type(2) {
  font-size: 14px;
}

.student-data .contact > a > .icon {
  font-size: 30px;
  margin: 0 6px;
}

.student-data .contact > a:nth-of-type(1) > .icon {
  color: #75C045;
}

.student-data .contact > a:nth-of-type(2) > .icon {
  color: #075e54;
}

.student-data .contact > a:nth-of-type(3) > .icon {
  color: #5082E1;
}

.student-practice-pending {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0,0,0,0.25);
  align-items: center;
  justify-content: center;
  padding: 12px;
  box-sizing: border-box;
  flex-direction: column;
}

.student-practice-pending-form {
  width: 500px;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  padding: 12px;
  box-sizing: border-box;
  background-color: #FFF;
  margin: 6px 0;
}

.student-practice-pending-form.title {
  background-color: $color-primary-1;
}

.student-practice-pending-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #FFF;
}

.student-practice-pending-data {
  display: flex;
  padding: 12px 0;
  box-sizing: border-box;
}

.student-practice-pending-data > div {
  text-align: center;
  flex: 1;
}

.signature-pad {
  position: relative;
  margin-top: 12px;
  border-radius: 6px;
  overflow: hidden;
}

.signature-pad .clear {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: $color-danger;
}
</style>
