<template>
  <div id="student-dgt-report" v-if="show">
    <div id="student-dgt-report-pdf">
      <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
      <pdf style="display: none;" :src="url" @num-pages="pageCount = new Array($event)" />
      <pdf v-for="(page, index) in pageCount"
          :key="index"
          :src="url"
          :page="index + 1" />
    </div>
    <div id="student-dgt-report-download">
      <button class="h-button green w-100" v-on:click="download()">
        {{ app.strings.common.download }}
      </button>
    </div>
    <div id="student-dgt-report-back">
      <button class="h-button red w-100" v-on:click="close()">
        {{ app.strings.common.back }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'student-dgt-report',
  data () {
    return {
      show: false,
      pageCount: 0,
      url: ''
    }
  },
  props: {
    'app': {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    open (url) {
      this.url = url
      this.show = true
    },
    download () {
      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          action: 'openlink',
          url: this.url
        }))
      } catch (e) {
        alert('No es posible descargar el documento')
        window.location.href = this.url
      }
    },
    close () {
      this.show = false
      this.$emit('onClose')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/variables-app.scss';

#student-dgt-report {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0,0,0,0.8);
  box-sizing: border-box;
}

#practice-perform.iphonex #student-dgt-report {
  padding-top: 40px;
}

#student-dgt-report-pdf {
  position: relative;
  flex: 1;
  overflow: auto;
}

#student-dgt-report-pdf > .loader-dot {
  position: absolute;
}
</style>
