<template>
  <div class="student-evolution navigation-top-app">
    <navigation-top-app
      v-if="report.selected.license === ''"
      :app="app"
      :back="() => {
        $router.push({name: 'app.student.profile', params: {code: this.student.data.code}, query: {origin: 'app', navigationLeft: 'false', token: app.token}})
      }"
    >
      <template v-slot:center>
        <div id="navigation-center">
          {{ app.strings.app.student.dgtreport.title }}
        </div>
      </template>
    </navigation-top-app>
    <navigation-top-app
      v-if="report.selected.license !== '' && report.selected.test === -1"
      :app="app"
      :back="() => {
        report.selected.license = ''
      }"
    >
      <template v-slot:center>
        <div id="navigation-center">
          {{ app.strings.app.student.dgtreport.title }} - {{ report.selected.license }}
        </div>
      </template>
    </navigation-top-app>
    <transition name="fade">
      <div id="student-dgtreport-content" v-if="show && !loading">
        <list :app="app" :items="licenses" :index="'value'" :value="'name'" @onSelect="(license) => {
          report.selected.license = license.value
          if (this.licensesWithoutCircuit.includes(license.value.toUpperCase()) === true) {
            report.selected.test = 0
            openReport()
          }
        }" v-if="report.selected.license === ''" />
        <list :app="app" :items="tests" :index="'value'" :value="'name'" @onSelect="(test) => {
          report.selected.test = test.value
          openReport()
        }" v-if="report.selected.license !== '' && report.selected.test === -1" />
      </div>
    </transition>
    <transition name="fade">
      <div id="student-dgtreport-content" v-if="show && loading">
        <loader-dot :size="'16px'" :color_1="'rgb(69, 130, 207)'" :color_2="'rgb(69, 130, 207)'" />
      </div>
    </transition>

    <student-dgt-report-component ref="student-report" :app="app" @onClose="() => {
      report.selected.license = ''
      report.selected.test = -1
    }" />
  </div>
</template>

<script>
export default {
  name: 'app-student-dgtreport-view',
  data () {
    return {
      show: false,
      student: {
        loading: false,
        data: {
          id: 0
        }
      },
      report: {
        loading: false,
        licenses: [],
        selected: {
          license: '',
          test: -1
        }
      },
      licensesWithoutCircuit: [
        'B'
      ]
    }
  },
  props: [
    'app'
  ],
  computed: {
    loading () {
      if (this.report.loading) {
        return true
      }

      return false
    },
    licenses () {
      let licenses = []

      for (let i in this.report.licenses) {
        licenses.push({
          value: this.report.licenses[i],
          name: this.report.licenses[i]
        })
      }

      return licenses
    },
    tests () {
      return [
        {
          value: 0,
          name: this.app.strings.app.student.dgtreport.tests.traffic
        },
        {
          value: 1,
          name: this.app.strings.app.student.dgtreport.tests.circuit
        }
      ]
    }
  },
  mounted () {
    this.student.data.code = this.$route.params.code

    this.show = true

    this.practiceReportLicenses()
  },
  methods: {
    practiceReportLicenses () {
      if (this.report.loading) {
        return
      }

      this.report.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.practice.report.licenses + '/' + this.student.data.code,
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.report.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.report.licenses = response.body.data.licenses
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'practice-report-licenses', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.report.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    },
    openReport () {
      this.$refs['student-report'].open(this.app.url.api + '/practice/report/' + this.report.selected.license + '/' + this.report.selected.test + '/' + this.student.data.code + '?token=' + this.app.token)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";

#student-dgtreport-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  box-sizing: border-box;
}

</style>
