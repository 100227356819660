var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"student-evolution navigation-top-app"},[(_vm.evaluation.show === false)?_c('navigation-top-app',{attrs:{"app":_vm.app,"back":() => {
      _vm.$router.push({name: 'app.student.profile', params: {code: this.student.data.code}, query: {origin: 'app', navigationLeft: 'false', token: _vm.app.token}})
    }},scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('div',{attrs:{"id":"navigation-center"}},[_vm._v(" "+_vm._s(_vm.app.strings.app.student.evolution.title)+" ")])]},proxy:true}],null,false,3108896450)}):_vm._e(),(_vm.evaluation.show === true)?_c('navigation-top-app',{attrs:{"app":_vm.app,"back":() => {
      this.evaluation.show = false
    }},scopedSlots:_vm._u([{key:"center",fn:function(){return [_c('div',{attrs:{"id":"navigation-center"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.evaluation.selected.name,30))+" ")])]},proxy:true}],null,false,3864804730)}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && !_vm.loading)?_c('div',{staticClass:"max-width-800 margin-auto",attrs:{"id":"student-evolution-content"}},[(_vm.evaluation.show === false)?_c('list',{attrs:{"app":_vm.app,"items":_vm.evaluation.data,"index":'id',"value":'name'},on:{"onSelect":(evaluation) => {
        _vm.openEvaluation(evaluation)
      }}}):_c('student-evaluation-practice-component',{attrs:{"app":_vm.app,"student":this.student.data.code,"evaluation":_vm.evaluation.selected,"editable":false}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.show && _vm.loading)?_c('div',{staticClass:"max-width-800 margin-auto",attrs:{"id":"student-evolution-content"}},[_c('loader-dot',{attrs:{"size":'16px',"color_1":'rgb(69, 130, 207)',"color_2":'rgb(69, 130, 207)'}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }