<template>
  <div class="teacher-home">
    <div id="teacher-menu-top">
      <div>
        <div class="title">
          {{ app.strings.app.teacher.home.teacher }}
        </div>
        <div class="name">
          {{ app.user.name }}
        </div>
      </div>
      <img src="@/assets/images/mates.png" />
    </div>
    <div id="teacher-biometric-course-content">
      <div class="title" v-if="course.data.id !== 'all'">
        {{ course.data.name }}
      </div>
      <div class="title" v-else>
        {{ app.strings.app.teacher.biometric.course.all_title }}
      </div>
      <div class="subtitle" v-if="course.data.id !== 'all'">
        <div>
          {{ course.data.reference }}
        </div>
      </div>
      <div class="students-count total" v-on:click="showStudents(course.students)" v-if="!loading && !course.loading">
        <div>
          <div>
            {{ app.strings.app.teacher.biometric.course.students_total }}
          </div>
          <div>
            {{ course.students.length }}
          </div>
        </div>
      </div>
      <div class="students-count total" v-else>
        <div>
          <div>
            {{ app.strings.app.teacher.biometric.course.students_total }}
          </div>
          <div>
            <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
          </div>
        </div>
      </div>
      <div class="students-count validated" v-on:click="showStudents(validated)" v-if="!loading && !course.loading">
        <div>
          <div>
            {{ app.strings.app.teacher.biometric.course.students_validated }}
          </div>
          <div>
            {{ validated.length }}
          </div>
        </div>
      </div>
      <div class="students-count validated" v-else>
        <div>
          <div>
            {{ app.strings.app.teacher.biometric.course.students_validated }}
          </div>
          <div>
            <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
          </div>
        </div>
      </div>
      <div class="students-count not-validated" v-on:click="showStudents(notValidated)" v-if="!loading && !course.loading">
        <div>
          <div>
            {{ app.strings.app.teacher.biometric.course.students_not_validated }}
          </div>
          <div>
            {{ notValidated.length }}
          </div>
        </div>
      </div>
      <div class="students-count not-validated" v-else>
        <div>
          <div>
            {{ app.strings.app.teacher.biometric.course.students_not_validated }}
          </div>
          <div>
            <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
          </div>
        </div>
      </div>
      <div id="biometric-access-data-options" v-if="!loading && !course.loading">
        <div>
          <div v-on:click="fingerprintStart()">
            <div>
              <img src="@/assets/images/fingerprint_icon.png" />
            </div>
            <div>
              {{ app.strings.biometric.register.register_with }}
              <div v-html="app.strings.biometric.register.fingerprint"></div>
            </div>
          </div>
        </div>
        <div>
          <div v-on:click="facerecognitionStart()" v-if="this.faceapi.loaded">
            <div>
              <img src="@/assets/images/facerecognition_icon.png" />
            </div>
            <div>
              {{ app.strings.biometric.register.register_with }}
              <div v-html="app.strings.biometric.register.facerecognition"></div>
            </div>
          </div>
          <div style="opacity: 0.5;" v-else>
            <div>
              <img src="@/assets/images/facerecognition_icon.png" />
            </div>
            <div v-if="faceapi.loading !== undefined">
              {{ app.strings.biometric.register.register_with_loading }}
              <div>
                {{ faceapi.loading.state + '/' + faceapi.loading.states }}
                <br>
                {{ faceapi.loading.percent }} %
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="biometric-students-table" v-if="students.show">
        <div class="student" v-for="student in students.data" v-bind:key="student.student">
          <div v-on:click="students.image = student.biometric.image_2">
            {{ student.data.first_name }} {{ student.data.last_name_1 }} {{ student.data.last_name_2 }} ({{ student.student }})
          </div>
        </div>
      </div>
    </div>
    <div class="app-back">
      <button class="h-button default w-100 mb-10" v-on:click="registerPending()" v-if="!loading && !course.loading && course.pending.length > 0">
        {{ app.strings.app.teacher.biometric.course.send_pending }}
      </button>
      <button class="h-button default w-100" v-if="(loading || course.loading) && course.pending.length > 0">
        <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
      </button>
      <button class="h-button success w-100" v-on:click="goBack()" v-if="!loading && !course.loading">
        {{ app.strings.common.back }}
      </button>
      <button class="h-button success w-100" v-else>
        <loader-dot :size="'16px'" :color_1="'rgb(255, 255, 255)'" :color_2="'rgb(255, 255, 255)'" />
      </button>
    </div>

    <div id="biometric-face-image" v-if="students.image !== ''" v-on:click="students.image = ''">
      <div>
        <img :src="app.url.api + '/file/read/' + students.image + '?token=' + app.token" />
      </div>
    </div>

    <div id="biometric-register-content" :style="'background: url(\'' + require('@/assets/images/background_gradient_green.png') + '\') no-repeat;'" v-if="type == 'fingerprint'">
      <div id="biometric-register-fingerprint">
        <img src="@/assets/images/fingerprint_indicator.png" />
        <div>
          {{ app.strings.biometric.register.fingerprint_lector }}
        </div>
      </div>
    </div>

    <div id="biometric-register-content" :style="'background: url(\'' + require('@/assets/images/background_gradient_blue.png') + '\') no-repeat;'" v-if="type == 'facerecognition'">
      <div id="biometric-register-facerecognition" v-if="!faceRegistering">
        <video ref="face-video-src" autoplay playsinline muted style="pointer-events: none;"></video>
        <div class="overlay-canvas">
          <canvas ref="face-overlay" />
          <canvas ref="face-canvas" style="display: none;" />
        </div>
        <div class="overlay-top">
          <div>
            {{ app.strings.biometric.register.facerecognition_instruction }}
          </div>
        </div>
        <div class="overlay-bottom">
          <button class="h-button orange w-100" v-on:click="toggleCamera()" v-if="!cameraChange">
            <font-awesome-icon :icon="['fas', 'sync']" />
          </button>
          <button class="h-button orange w-100 disabled" v-else>
            <font-awesome-icon :icon="['fas', 'sync']" />
          </button>

          <div style="width: 20px"></div>

          <button class="h-button success w-100" v-on:click="registerFace()" v-if="faceCanBeRegistered && !faceRegistering">
            {{ app.strings.biometric.register.facerecognition_register }}
          </button>
          <button class="h-button success w-100 disabled" v-if="!faceCanBeRegistered && !faceRegistering">
            {{ app.strings.biometric.register.facerecognition_searching }}
          </button>
          <button class="h-button success w-100 disabled" v-if="faceRegistering">
            {{ app.strings.biometric.register.facerecognition_registering }}
          </button>

          <!--<div id="camera-toggle">
            <font-awesome-icon :icon="['fas', 'sync']" v-on:click="toggleCamera()" />
          </div>-->
        </div>
      </div>
      <div style="width: 100%;height: 100%;" v-else>
        <loader-dot :size="'20px'" :percentage="facePercentage" />
      </div>
    </div>
  </div>
</template>

<script>
import FaceApi from '@/modules/face-api/face-api.module'

export default {
  name: 'app-teacher-biometric-course-view',
  components: {
  },
  data () {
    return {
      loading: false,
      iframe: false,
      register: false,
      type: '',
      course: {
        loading: false,
        data: {
          id: 0,
          name: '...',
          reference: '...'
        },
        students: [],
        pending: []
      },
      students: {
        show: false,
        data: [],
        image: ''
      },
      cameras: [],
      cameraIndex: 0,
      cameraChange: false,
      cameraStream: '',
      faceapi: '',
      facePercentage: '0',
      faceCanBeRegistered: false,
      faceRegistering: false,
      faceWithBoxes: false
    }
  },
  computed: {
    validated () {
      return this.course.students.filter(student => student.state === 1);
    },
    notValidated () {
      return this.course.students.filter(student => student.state === 0);
    }
  },
  props: [
    'app'
  ],
  mounted () {
    this.course.data.id = this.$route.params.id
    this.biometricCourseReadOne()

    if (localStorage['app-teacher-course-students-pending-' + this.course.data.id]) {
      this.course.pending = JSON.parse(localStorage['app-teacher-course-students-pending-' + this.course.data.id])
    }

    document.addEventListener('message', this.reactEventListener, false)

    this.loadFaceApi()

    this.iframe = this.$route.query.iframe == 'true'
    this.recognition = this.$route.query.recognition == 'true'

    if (this.recognition === true) {
      this.facerecognitionStart()
    }
  },
  beforeDestroy() {
    document.removeEventListener('message', this.reactEventListener);
  },
  methods: {
    reactEventListener (event) {
      let message = JSON.parse(event.data)
      if (message.action === 'fingerprint') {
        let student = this.course.students.find(student => student.student === message.data.student);
        if (student) {
          this.updateStudentState(student)
        } else {
          this.$swal({
            title: this.app.strings.app.teacher.biometric.course.error.title,
            text: this.app.strings.app.teacher.biometric.course.error.content,
            icon: 'error'
          })
        }
      }
    },
    goBack () {
      if (this.students.show === true) {
        this.students.show = false
      } else {
        this.$router.back()
      }
    },
    async loadFaceApi () {
      console.log('Loading face api...')
      // this.faceapi = new FaceApi('http://localhost:8080', false)
      this.faceapi = new FaceApi('https://www.auesgestion.com', false)
    },
    biometricCourseReadOne () {
      if (this.course.loading) {
        return
      }

      this.course.loading = true

      this.$http.get(
        this.app.url.api + this.app.endpoints.biometric.course.read.one + this.course.data.id + '?students=true',
        {
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.course.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            if (this.course.data.id !== 'all') {
              this.course.data = response.body.data.course
              localStorage['app-teacher-course-' + this.course.data.id] = JSON.stringify(this.course.data)
            }

            this.course.students = response.body.data.students

            let students = JSON.parse(JSON.stringify(this.course.students))
            for (let i in students) {
              delete students[i].biometric
            }

            localStorage['app-teacher-course-students-' + this.course.data.id] = JSON.stringify(students)
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'biometric-course-read-one', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.course.loading = false
        if (this.course.data.id !== 'all') {
          this.course.data = JSON.parse(localStorage['app-teacher-course-' + this.course.data.id])
        }
        this.course.students = JSON.parse(localStorage['app-teacher-course-students-' + this.course.data.id])
      })
    },
    showStudents (students) {
      this.students.data = students
      this.students.show = true
    },
    fingerprintStart () {
      this.type = 'fingerprint'

      let students = []
      for (let i in this.course.students) {
        let student = this.course.students[i]
        if (student.biometric !== undefined && student.biometric !== null && student.biometric.data_1 !== '') {
          students.push({
            code: student.student,
            data: student.biometric.data_1
          })
        }
      }

      if (students.length > 0) {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          action: 'fingerprint',
          data: {
            students: students
          }
        }))
      } else {
        // Empty students to check
      }
    },
    async facerecognitionStart () {
      if (this.iframe === true && this.recognition === false) {
        window.open(window.location.href + '&recognition=true', '_blank').focus()
        return
      }

      navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(() => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          for (let i in devices) {
            let device = devices[i]
            if (device.kind === 'videoinput') {
              this.cameras.push(device)
            }
          }

          this.type = 'facerecognition'

          this.openCamera()
        })
      })
    },
    async openCamera () {
      this.cameraChange = true

      let camera = this.cameras[this.cameraIndex]

      if (this.cameraStream !== '') {
        let tracks = this.cameraStream.getTracks()
        tracks.forEach(track => track.stop())
      }

      this.cameraStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: camera.deviceId
        },
        audio: false
      })

      this.$refs['face-video-src'].srcObject = this.cameraStream
      this.$refs['face-video-src'].onloadedmetadata = this.onPlay

      this.cameraChange = false
    },
    async toggleCamera () {
      this.cameraIndex++
      if (this.cameraIndex > this.cameras.length - 1) {
        this.cameraIndex = 0
      }
      this.openCamera()
    },
    async onPlay () {
      const videoEl = this.$refs['face-video-src']

      if(videoEl.paused || videoEl.ended || !this.faceapi.isFaceDetectionModelLoaded() || this.cameraChange) {
        return setTimeout(() => this.onPlay())
      }

      const ts = Date.now()

      const result = await window.faceapi.detectSingleFace(videoEl, this.faceapi.getFaceDetectorOptions()).withFaceLandmarks()

      this.faceapi.updateTimeStats(Date.now() - ts)

      if (result) {
        this.faceCanBeRegistered = true

        const canvas = this.$refs['face-overlay']
        const dims = window.faceapi.matchDimensions(canvas, videoEl, true)
        const resizedResult = window.faceapi.resizeResults(result, dims)

        if (this.faceWithBoxes) {
          window.faceapi.draw.drawDetections(canvas, resizedResult)
        }
        // faceapi.draw.drawFaceLandmarks(canvas, resizedResult)
        const faceLandmarksArray = Array.isArray(result) ? result : [result]
        faceLandmarksArray.forEach(f => {
          new window.faceapi.draw.DrawFaceLandmarks(f.landmarks, new window.faceapi.draw.DrawFaceLandmarksOptions({
            drawLines: true,
            lineWidth: 1,
            lineColor: '#FFFFFF',
            drawPoints: true,
            pointSize: 2,
            pointColor: '#FFFFFF'
          })).draw(canvas)
        })
      } else {
        this.faceCanBeRegistered = false
      }

      if (this.cameraStream !== '') {
        setTimeout(() => this.onPlay())
      }
    },
    async registerFace () {
      if (this.faceRegistering) {
        return
      }

      this.faceRegistering = true
      this.facePercentage = '0'

      let video = this.$refs['face-video-src']
      let canvas = this.$refs['face-canvas']
      canvas.width = 500
      canvas.height = 500 * (video.videoHeight/video.videoWidth)
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)

      let faceReference = canvas.toDataURL()

      this.cameraStream.getTracks()[0].stop()
      this.cameraStream = ''

      let accuracy = 0.5

      /*for (let i in this.course.students) {
        let student = this.course.students[i]
        if (student.biometric !== undefined && student.biometric !== null && student.biometric.data_2 != '') {
          let result = await this.faceapi.compareFaces(student.biometric.data_2, faceReference, accuracy)
          if (result == true) {
            this.updateStudentState(student)
            return
          }
        }
      }*/

      /*let distance = 100.0;
      let student = null;
      for (let i in this.course.students) {
        let s = this.course.students[i]
        if (s.biometric !== undefined && s.biometric !== null && s.biometric.data_2 != '') {
          let d = await this.faceapi.getFaceDistance(s.biometric.data_2, faceReference, true)
          if (d < distance) {
            distance = d
            student = s
          }
        }
        this.facePercentage = parseInt(((parseInt(i)+1) * 100)/this.course.students.length) + ''
      }

      if (distance < accuracy && student !== null) {
        this.updateStudentState(student)
        return
      }*/

      let detectionReference = await this.faceapi.getFaceDetection(faceReference)
      let distance = 100.0;
      let student = null;
      for (let i in this.course.students) {
        let s = this.course.students[i]
        if (s.biometric !== undefined && s.biometric !== null && s.biometric.data_2 != '') {
          let detection = JSON.parse(s.biometric.data_2)
          detection.descriptor = new Float32Array(Object.values(detection.descriptor))
          let d = await this.faceapi.getFaceDetectionDistance(detection, detectionReference, true)
          if (d < distance) {
            distance = d
            student = s
          }
        }
        this.facePercentage = parseInt(((parseInt(i)+1) * 100)/this.course.students.length) + ''
      }

      if (distance < accuracy && student !== null) {
        this.updateStudentState(student)
        return
      }

      this.faceRegistering = false
      this.type = ''

      this.$swal({
        title: this.app.strings.app.teacher.biometric.course.error.title,
        text: this.app.strings.app.teacher.biometric.course.error.content,
        icon: 'error'
      })
    },
    updateStudentState (student) {
      if (this.loading) {
        return
      }

      this.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.biometric.student.toggle,
        {
          id: student.course_id,
          student: student.student,
          device: this.type === 'fingerprint' ? 1 : 0
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        this.faceRegistering = false

        this.type = ''
        if (this.cameraStream !== '') {
          this.cameraStream.getTracks()[0].stop()
          this.cameraStream = ''
        }

        if (response.status === 200) {
          if (response.body.success === true) {
            this.$swal({
              title: this.app.strings.app.teacher.biometric.course.success.title[response.body.data.state],
              text: this.app.strings.app.teacher.biometric.course.success.content[response.body.data.state] + student.data.first_name + ' ' + student.data.last_name_1 + ' ' + student.data.last_name_2 + ' (' + student.student + ')',
              icon: 'success'
            })
            this.biometricCourseReadOne()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'biometric-update', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.faceRegistering = false

        let students = JSON.parse(localStorage['app-teacher-course-students-' + this.course.data.id])
        for (let i in students) {
          if (student.student === students[i].student) {
            students[i].state = students[i].state === 0 ? 1 : 0
          }
        }
        localStorage['app-teacher-course-students-' + this.course.data.id] = JSON.stringify(students)

        this.course.pending.push({
          student: student.student,
          state: student.state === 0 ? 1 : 0,
          date: new Date(),
          device: this.type === 'fingerprint' ? 1 : 0
        })
        localStorage['app-teacher-course-students-pending-' + this.course.data.id] = JSON.stringify(this.course.pending)

        this.type = ''
        if (this.cameraStream !== '') {
          this.cameraStream.getTracks()[0].stop()
          this.cameraStream = ''
        }

        this.showError(this.app.strings, 'biometric-update-offline', '')
        this.biometricCourseReadOne()
      })
    },
    registerPending () {
      if (this.loading) {
        return
      }

      this.loading = true

      this.$http.post(
        this.app.url.api + this.app.endpoints.biometric.student.register.pending,
        {
          id: this.course.data.id,
          registers: JSON.stringify(this.course.pending)
        },
        {
          emulateJSON: true,
          headers: {
            Authorization: 'Token ' + this.app.token
          }
        }
      ).then(response => {
        this.loading = false
        if (response.status === 200) {
          if (response.body.success === true) {
            this.$swal({
              title: this.app.strings.app.teacher.biometric.course.pending.success.title,
              text: this.app.strings.app.teacher.biometric.course.pending.success.content + this.course.pending.length,
              icon: 'success'
            })

            this.course.pending = []
            localStorage['app-teacher-course-students-pending-' + this.course.data.id] = JSON.stringify(this.course.pending)

            this.biometricCourseReadOne()
          } else {
            if (response.body.error.code === 'S100') {
              this.app.methods.logout()
            } else {
              let error = response.body.error.code
              this.showError(this.app.strings, 'biometric-update', error)
            }
          }
        } else {
          this.showError(this.app.strings, 'request', 'status-' + response.status)
        }
      }, () => {
        this.loading = false
        this.showError(this.app.strings, 'request', 'status-network: Connection unreachable')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/variables-app.scss";
@import "@/assets/styles/mobile-app.scss";

.teacher-home {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

#teacher-biometric-course-content {
  position: relative;
  flex: 1;
  overflow: auto;
}

#teacher-biometric-course-content .title {
  font-weight: bold;
  font-size: 22px;
  color: $color-info;
  padding: 12px 25px;
}

.subtitle {
  display: flex;
  padding: 12px 25px;
  box-sizing: border-box;
}

.subtitle > div {
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
  background-color: rgb(180, 180, 180);
}

.students-count {
  padding: 6px 25px;
  box-sizing: border-box;
}

.students-count > div {
  display: flex;
  padding: 12px 25px;
  box-sizing: border-box;
  border-radius: 6px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
}

.students-count.total > div {
  background-color: $color-primary-1;
}

.students-count.validated > div {
  background-color: $color-success;
}

.students-count.not-validated > div {
  background-color: $color-danger;
}

.students-count > div > div:nth-of-type(1) {
  flex: 1;
}

.students-count > div > div:nth-of-type(2) {
  display: flex;
  width: 50px;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #FFFFFF;
  padding-left: 25px;
}

#biometric-students-table {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-background-grey;
  overflow: auto;
}

.student {
  padding: 6px 12px;
  box-sizing: border-box;
}

.student > div {
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.student > div:nth-child(odd) {
  background-color: rgb(180, 180, 180);
}

.student > div:nth-child(odd) {
  background-color: rgb(160, 160, 160);
}

#biometric-access-data-options {
  display: flex;
  flex: 1;
  padding: 6px 12px 0 12px;
  box-sizing: border-box;
}

#biometric-access-data-options > div {
  flex: 1;
  padding: 0 12px;
}

#biometric-access-data-options > div > div {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 6px;
}

#biometric-access-data-options > div > div > div {
  position: relative;
  flex: 1;
  box-sizing: border-box;
}

#biometric-access-data-options > div:nth-of-type(1) > div {
  background-color: $color-success;
}

#biometric-access-data-options > div:nth-of-type(2) > div {
  background-color: $color-info;
}

#biometric-access-data-options > div > div > div > img {
  position: absolute;
  top: 12px;
  right: 12px;
  display: block;
  height: 50px;
}

#biometric-access-data-options > div > div > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 12px 6px 12px;
}

#biometric-access-data-options > div > div > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 12px 12px 12px;
}

#biometric-access-data-options > div > div > div:nth-of-type(2) > div {
  font-size: 18px;
}

#biometric-face-image {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.5);
}

#biometric-face-image > div > img {
  max-width: 100%;
  max-height: 100%;
}

#biometric-register-content {
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: 100% 100% !important;
  z-index: 1000;
}

#biometric-register-fingerprint {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

#biometric-register-fingerprint > img {
  width: 90%;
}

#biometric-register-fingerprint > div {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  padding: 25px;
}

#biometric-register-facerecognition {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#biometric-register-facerecognition > video {
}

#biometric-register-facerecognition > .overlay-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#biometric-register-facerecognition > .overlay-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;
}

#biometric-register-facerecognition > .overlay-bottom {
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  padding: 25px;
  box-sizing: border-box;
}

#camera-toggle {
  display: flex;
  padding: 0 12px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  color: #FFF;
}
</style>
